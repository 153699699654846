import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import { Logger, LogLevel } from "msal";
 
// Msal Configurations
const config = {
  auth: {
    authority: 'https://login.microsoftonline.com/common',
    clientId: '9572bb5f-c2c2-4dda-b08a-8addc00b3051',
    postLogoutRedirectUri: 'https://portal.ecoprism.com/login',
    redirectUri: 'https://portal.ecoprism.com/callback',
    //postLogoutRedirectUri: 'https://portal.ecoprism.com/login',
    //redirectUri: 'https://portal.ecoprism.com/callback',
    validateAuthority: true,
    navigateToLoginRequestUrl: false
    //redirectUri: 'http://localhost:3000/callback'
  },
  system: {
    logger: new Logger(
      (logLevel, message, containsPii) => {
        //console.log("[MSAL]", message);
      },
      {
        level: LogLevel.Verbose,
        piiLoggingEnabled: false
      }
    )
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  }
};
 
// Authentication Parameters
const authenticationParameters = {
  scopes: [
    'user.read'
  ]
}
 
// Options
const options = {
  loginType: LoginType.Popup,
  tokenRefreshUri: window.location.origin + '/auth.html'
}
 
export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)