import React, { useState, useEffect, useRef } from "react";
import { Typography } from "@mui/material";

const GetQuestionData = () => {
  const [inputValues, setInputValues] = useState({
    gov_4: {
      a: { dr: "", paragraph: "", relates: "" },
      b: { dr: "", paragraph: "", relates: "" },
      c: { dr: "", paragraph: "", relates: "" },
      d: { dr: "", paragraph: "", relates: "" },
      e: { dr: "", paragraph: "", relates: "" },
    },
    e1_4: {
      ghgEmissions: { baseYear: "", target2030: "", target2035: "", target2050: "" },
      energyEfficiency: { baseYear: "", target2030: "", target2035: "", target2050: "" },
      materialEfficiency: { baseYear: "", target2030: "", target2035: "", target2050: "" },
      fuelSwitching: { baseYear: "", target2030: "", target2035: "", target2050: "" },
      electrification: { baseYear: "", target2030: "", target2035: "", target2050: "" },
      renewableEnergy: { baseYear: "", target2030: "", target2035: "", target2050: "" },
      productModification: { baseYear: "", target2030: "", target2035: "", target2050: "" },
      processModification: { baseYear: "", target2030: "", target2035: "", target2050: "" },
      other: { baseYear: "", target2030: "", target2035: "", target2050: "" },
    },
    e1_5_1: {
      coalProducts: { comparative: "", yearN: "" },
      crudeOil: { comparative: "", yearN: "" },
      naturalGas: { comparative: "", yearN: "" },
      otherFossil: { comparative: "", yearN: "" },
      fossilElectricity: { comparative: "", yearN: "" },
      totalFossil: { comparative: "", yearN: "" },
      fossilShare: { comparative: "", yearN: "" },
      nuclear: { comparative: "", yearN: "" },
      nuclearShare: { comparative: "", yearN: "" },
      renewablesBiomass: { comparative: "", yearN: "" },
      renewablesElectricity: { comparative: "", yearN: "" },
      selfGeneratedRenewables: { comparative: "", yearN: "" },
      totalRenewables: { comparative: "", yearN: "" },
      renewablesShare: { comparative: "", yearN: "" },
      totalEnergy: { comparative: "", yearN: "" },
    },
    e1_5_2: {
      totalEnergy: {
        comparative: "",
        yearN: "",
        percentage: "",
      },
    },
    e1_5_3: {
      netRevenueHighClimate: { netData: "" },
      netRevenueOther: { netData: "" },
      totalNetRevenue: { netData: "" },
    },
    e1_6_1: {
      scope1Gross: {
        baseYear: "",
        comparative: "",
        n: "",
        percentN: "",
        target2025: "",
        target2030: "",
        target2050: "",
        annualPercentTarget: "",
      },
      scope1Percentage: {
        baseYear: "",
        comparative: "",
        n: "",
        percentN: "",
        target2025: "",
        target2030: "",
        target2050: "",
        annualPercentTarget: "",
      },
      scope2GrossLocationBased: {
        baseYear: "",
        comparative: "",
        n: "",
        percentN: "",
        target2025: "",
        target2030: "",
        target2050: "",
        annualPercentTarget: "",
      },
      scope2GrossMarketBased: {
        baseYear: "",
        comparative: "",
        n: "",
        percentN: "",
        target2025: "",
        target2030: "",
        target2050: "",
        annualPercentTarget: "",
      },
      scope3TotalGrossIndirect: {
        baseYear: "",
        comparative: "",
        n: "",
        percentN: "",
        target2025: "",
        target2030: "",
        target2050: "",
        annualPercentTarget: "",
      },
      scope3PurchasedGoods: {
        baseYear: "",
        comparative: "",
        n: "",
        percentN: "",
        target2025: "",
        target2030: "",
        target2050: "",
        annualPercentTarget: "",
      },
      scope3CapitalGoods: {
        baseYear: "",
        comparative: "",
        n: "",
        percentN: "",
        target2025: "",
        target2030: "",
        target2050: "",
        annualPercentTarget: "",
      },
      scope3FuelEnergy: {
        baseYear: "",
        comparative: "",
        n: "",
        percentN: "",
        target2025: "",
        target2030: "",
        target2050: "",
        annualPercentTarget: "",
      },
      scope3Transportation: {
        baseYear: "",
        comparative: "",
        n: "",
        percentN: "",
        target2025: "",
        target2030: "",
        target2050: "",
        annualPercentTarget: "",
      },
      scope3Waste: {
        baseYear: "",
        comparative: "",
        n: "",
        percentN: "",
        target2025: "",
        target2030: "",
        target2050: "",
        annualPercentTarget: "",
      },
      scope3BusinessTraveling: {
        baseYear: "",
        comparative: "",
        n: "",
        percentN: "",
        target2025: "",
        target2030: "",
        target2050: "",
        annualPercentTarget: "",
      },
      scope3EmployeeCommuting: {
        baseYear: "",
        comparative: "",
        n: "",
        percentN: "",
        target2025: "",
        target2030: "",
        target2050: "",
        annualPercentTarget: "",
      },
      scope3UpstreamLeasedAssets: {
        baseYear: "",
        comparative: "",
        n: "",
        percentN: "",
        target2025: "",
        target2030: "",
        target2050: "",
        annualPercentTarget: "",
      },
      scope3DownstreamTransportation: {
        baseYear: "",
        comparative: "",
        n: "",
        percentN: "",
        target2025: "",
        target2030: "",
        target2050: "",
        annualPercentTarget: "",
      },
      scope3ProcessingSoldProducts: {
        baseYear: "",
        comparative: "",
        n: "",
        percentN: "",
        target2025: "",
        target2030: "",
        target2050: "",
        annualPercentTarget: "",
      },
      scope3UseSoldProducts: {
        baseYear: "",
        comparative: "",
        n: "",
        percentN: "",
        target2025: "",
        target2030: "",
        target2050: "",
        annualPercentTarget: "",
      },
      scope3EndOfLife: {
        baseYear: "",
        comparative: "",
        n: "",
        percentN: "",
        target2025: "",
        target2030: "",
        target2050: "",
        annualPercentTarget: "",
      },
      scope3DownstreamLeasedAssets: {
        baseYear: "",
        comparative: "",
        n: "",
        percentN: "",
        target2025: "",
        target2030: "",
        target2050: "",
        annualPercentTarget: "",
      },
      scope3Franchises: {
        baseYear: "",
        comparative: "",
        n: "",
        percentN: "",
        target2025: "",
        target2030: "",
        target2050: "",
        annualPercentTarget: "",
      },
      scope3Investments: {
        baseYear: "",
        comparative: "",
        n: "",
        percentN: "",
        target2025: "",
        target2030: "",
        target2050: "",
        annualPercentTarget: "",
      },
      totalGHGLocationBased: {
        baseYear: "",
        comparative: "",
        n: "",
        percentN: "",
        target2025: "",
        target2030: "",
        target2050: "",
        annualPercentTarget: "",
      },
      totalGHGMarketBased: {
        baseYear: "",
        comparative: "",
        n: "",
        percentN: "",
        target2025: "",
        target2030: "",
        target2050: "",
        annualPercentTarget: "",
      },
    },
    e1_6_2: {
      totalGHGLocation: { comparative: "", n: "", percentage: "" },
      totalGHGMarket: { comparative: "", n: "", percentage: "" },
      netRevenueUsed: { total: "" },
      netRevenueOther: { total: "" },
      totalNetRevenue: { total: "" },
    },
    e1_7_1: {
      ghgRemovalActivity1: { comparative: "", n: "", percentN: "" },
      ghgRemovalActivity2: { comparative: "", n: "", percentN: "" },
      otherGHGRemovalActivity: { comparative: "", n: "", percentN: "" },
      totalOwnOperations: { comparative: "", n: "", percentN: "" },
      ghgRemovalActivity1Upstream: { comparative: "", n: "", percentN: "" },
      ghgRemovalActivity2Upstream: { comparative: "", n: "", percentN: "" },
      otherGHGRemovalActivityUpstream: { comparative: "", n: "", percentN: "" },
      totalUpstreamDownstream: { comparative: "", n: "", percentN: "" },
      reversals: { comparative: "", n: "", percentN: "" },
    },
    e1_7_2: {
      totalCarbonCreditsCancelled: { comparative: "", n: "" },
      shareRemovalProjects: { comparative: "", n: "" },
      shareReductionProjects: { comparative: "", n: "" },
      recognisedQualityStandard1: { comparative: "", n: "" },
      recognisedQualityStandard2: { comparative: "", n: "" },
      recognisedQualityStandard3: { comparative: "", n: "" },
      otherRecognisedQualityStandards: { comparative: "", n: "" },
      shareEUProjects: { comparative: "", n: "" },
      shareCorrespondingAdjustments: { comparative: "", n: "" },
      totalCarbonCreditsPlanned: { amountUntilPeriod: "" },
    },
    e1_8: {
      capEx: { volumeAtStake: "", pricesApplied: "", perimeterDescription: "" },
      rd: { volumeAtStake: "", pricesApplied: "", perimeterDescription: "" },
      carbonFee: { volumeAtStake: "", pricesApplied: "", perimeterDescription: "" },
      testing: { volumeAtStake: "", pricesApplied: "", perimeterDescription: "" },
      otherICarbon: { volumeAtStake: "", pricesApplied: "", perimeterDescription: "" },
    },
    e1_9: {
      carryingAmount: { netValue: "" },
      adjustingItems: { netValue: "" },
      netRevenue: { netValue: "" },
    },
    e3_4_1: {
      totalWaterConsumption: {
        year: "",
        comparative: "",
      },
      waterConsumptionAtRiskAreas: {
        year: "",
        comparative: "",
      },
      totalWaterRecycled: {
        year: "",
        comparative: "",
      },
      totalWaterStored: {
        year: "",
        comparative: "",
      },
    },
    e3_4_2: {
      totalWaterStored: { netValue: "" },
      eurNetRevenue: { netValue: "" },
      waterIntensity: { netValue: "" },
    },
    e5_4_1: {
      packagingMaterial: {
        bio: "",
        nonBio: "",
        percentBio: "",
      },
      itEquipment: {
        bio: "",
        nonBio: "",
        percentBio: "",
      },
      textiles: {
        bio: "",
        nonBio: "",
        percentBio: "",
      },
      furniture: {
        bio: "",
        nonBio: "",
        percentBio: "",
      },
      buildings: {
        bio: "",
        nonBio: "",
        percentBio: "",
      },
      heavyMachinery: {
        bio: "",
        nonBio: "",
        percentBio: "",
      },
      midWeightMachinery: {
        bio: "",
        nonBio: "",
        percentBio: "",
      },
      lightMachinery: {
        bio: "",
        nonBio: "",
        percentBio: "",
      },
      heavyTransport: {
        bio: "",
        nonBio: "",
        percentBio: "",
      },
      midWeightTransport: {
        bio: "",
        nonBio: "",
        percentBio: "",
      },
      lightTransport: {
        bio: "",
        nonBio: "",
        percentBio: "",
      },
      equipment: {
        bio: "",
        nonBio: "",
        percentBio: "",
      },
      criticalRawMaterial: {
        bio: "",
        nonBio: "",
        percentBio: "",
      },
      rareEarths: {
        bio: "",
        nonBio: "",
        percentBio: "",
      },
      processMaterials: {
        bio: "",
        nonBio: "",
        percentBio: "",
      },
      semimanu: {
        bio: "",
        nonBio: "",
        percentBio: "",
      },
    },
    e5_4_2: {
      packagingMaterial: {
        sus: "",
        nonSus: "",
        percentSus: "",
      },
      itEquipment: {
        sus: "",
        nonSus: "",
        percentSus: "",
      },
      textiles: {
        sus: "",
        nonSus: "",
        percentSus: "",
      },
      furniture: {
        sus: "",
        nonSus: "",
        percentSus: "",
      },
      buildings: {
        sus: "",
        nonSus: "",
        percentSus: "",
      },
      heavyMachinery: {
        sus: "",
        nonSus: "",
        percentSus: "",
      },
      midWeightMachinery: {
        sus: "",
        nonSus: "",
        percentSus: "",
      },
      lightMachinery: {
        sus: "",
        nonSus: "",
        percentSus: "",
      },
      heavyTransport: {
        sus: "",
        nonSus: "",
        percentSus: "",
      },
      midWeightTransport: {
        sus: "",
        nonSus: "",
        percentSus: "",
      },
      lightTransport: {
        sus: "",
        nonSus: "",
        percentSus: "",
      },
      equipment: {
        sus: "",
        nonSus: "",
        percentSus: "",
      },
      criticalRawMaterial: {
        sus: "",
        nonSus: "",
        percentSus: "",
      },
      rareEarths: {
        sus: "",
        nonSus: "",
        percentSus: "",
      },
      processMaterials: {
        sus: "",
        nonSus: "",
        percentSus: "",
      },
      semimanu: {
        sus: "",
        nonSus: "",
        percentSus: "",
      },
    },
    e5_4_3: {
      packagingMaterial: {
        rec: "",
        virgin: "",
        percentRec: "",
      },
      itEquipment: {
        rec: "",
        virgin: "",
        percentRec: "",
      },
      textiles: {
        rec: "",
        virgin: "",
        percentRec: "",
      },
      furniture: {
        rec: "",
        virgin: "",
        percentRec: "",
      },
      buildings: {
        rec: "",
        virgin: "",
        percentRec: "",
      },
      heavyMachinery: {
        rec: "",
        virgin: "",
        percentRec: "",
      },
      midWeightMachinery: {
        rec: "",
        virgin: "",
        percentRec: "",
      },
      lightMachinery: {
        rec: "",
        virgin: "",
        percentRec: "",
      },
      heavyTransport: {
        rec: "",
        virgin: "",
        percentRec: "",
      },
      midWeightTransport: {
        rec: "",
        virgin: "",
        percentRec: "",
      },
      lightTransport: {
        rec: "",
        virgin: "",
        percentRec: "",
      },
      equipment: {
        rec: "",
        virgin: "",
        percentRec: "",
      },
      criticalRawMaterial: {
        rec: "",
        virgin: "",
        percentRec: "",
      },
      rareEarths: {
        rec: "",
        virgin: "",
        percentRec: "",
      },
      processMaterials: {
        rec: "",
        virgin: "",
        percentRec: "",
      },
      semimanu: {
        rec: "",
        virgin: "",
        percentRec: "",
      },
    },
    e5_5_1: {
      product1: {
        durability: "",
        average: "",
      },
      product2: {
        durability: "",
        average: "",
      },
      product3: {
        durability: "",
        average: "",
      },
      product4: {
        durability: "",
        average: "",
      },
    },
    e5_5_2: {
      product1: {
        recProduct: "",
        recPackaging: "",
      },
      product2: {
        recProduct: "",
        recPackaging: "",
      },
      product3: {
        recProduct: "",
        recPackaging: "",
      },
      product4: {
        recProduct: "",
        recPackaging: "",
      },
    },
    e5_5_3: {
      hazardousWaste: {
        totalWeight: "",
        reuse: "",
        recycling: "",
        otherRecovery: "",
        incineration: "",
        landfill: "",
        otherDisposal: "",
        nonRecycledWeight: "",
        nonRecycledPercentage: "",
      },
      nonHazardousWaste: {
        totalWeight: "",
        reuse: "",
        recycling: "",
        otherRecovery: "",
        incineration: "",
        landfill: "",
        otherDisposal: "",
        nonRecycledWeight: "",
        nonRecycledPercentage: "",
      },
    },
    e5_5_4: {
      totalRadio: { netValue: "" },
      totalNonRadio: { netValue: "" },
      totalHazardous: { netValue: "" },
    },
    s1_6: {
      totalEmp: {
        countryA: "",
        countryB: "",
        regionA: "",
        regionB: "",
        total: "",
      },
      permanent: {
        countryA: "",
        countryB: "",
        regionA: "",
        regionB: "",
        total: "",
      },
      temporary: {
        countryA: "",
        countryB: "",
        regionA: "",
        regionB: "",
        total: "",
      },
      nonGuaranteedHours: {
        countryA: "",
        countryB: "",
        regionA: "",
        regionB: "",
        total: "",
      },
      fullTime: {
        countryA: "",
        countryB: "",
        regionA: "",
        regionB: "",
        total: "",
      },
      partTime: {
        countryA: "",
        countryB: "",
        regionA: "",
        regionB: "",
        total: "",
      },
    },
    s1_7: {
      contractEmployees: {
        male: "",
        female: "",
        other: "",
        notDisclosed: "",
        total: "",
      },
      nonEmployees: {
        male: "",
        female: "",
        other: "",
        notDisclosed: "",
        total: "",
      },
    },
    s1_8: {
      rate0_19: {
        EEA: "",
        nonEEA: "",
        socialDialogueEEA: "",
      },
      rate20_39: {
        EEA: "",
        nonEEA: "",
        socialDialogueEEA: "",
      },
      rate40_59: {
        EEA: "",
        nonEEA: "",
        socialDialogueEEA: "",
      },
      rate60_79: {
        EEA: "",
        nonEEA: "",
        socialDialogueEEA: "",
      },
      rate80_100: {
        EEA: "",
        nonEEA: "",
        socialDialogueEEA: "",
      },
    },
    s1_12: {
      disabilityPercent: {
        male: "",
        female: "",
      },
    },
    s1_13: {
      permanentEmployees: {
        male: "",
        female: "",
      },
      temporaryEmployees: {
        male: "",
        female: "",
      },
      nonGuaranteedHoursEmployees: {
        male: "",
        female: "",
      },
      fullTimeEmployees: {
        male: "",
        female: "",
      },
      partTimeEmployees: {
        male: "",
        female: "",
      },
      nonEmployees: {
        male: "",
        female: "",
      },
    },
    s1_15: {
      familyRelatedLeaves: {
        male: "",
        female: "",
      },
      takenFamilyRelatedLeaves: {
        male: "",
        female: "",
      },
      retentionRates: {
        male: "",
        female: "",
      },
    },
    s1_16: {
      averageGrossHourlyEarnings: {
        male: "",
        female: "",
      },
    },
    g1_3: {
      trainingCoverage: {
        atRiskFunctions: "",
        managers: "",
        AMSB: "",
        otherOwnWorkers: "",
      },
      total: {
        atRiskFunctions: "",
        managers: "",
        AMSB: "",
        otherOwnWorkers: "",
      },
      totalReceivingTraining: {
        atRiskFunctions: "",
        managers: "",
        AMSB: "",
        otherOwnWorkers: "",
      },
      deliveryMethodAndDuration: {
        atRiskFunctions: "",
        managers: "",
        AMSB: "",
        otherOwnWorkers: "",
      },
      classroomTraining: {
        atRiskFunctions: "",
        managers: "",
        AMSB: "",
        otherOwnWorkers: "",
      },
      computerBasedTraining: {
        atRiskFunctions: "",
        managers: "",
        AMSB: "",
        otherOwnWorkers: "",
      },
      voluntaryComputerBasedTraining: {
        atRiskFunctions: "",
        managers: "",
        AMSB: "",
        otherOwnWorkers: "",
      },
      frequency: {
        atRiskFunctions: "",
        managers: "",
        AMSB: "",
        otherOwnWorkers: "",
      },
      howOftenTrainingRequired: {
        atRiskFunctions: "",
        managers: "",
        AMSB: "",
        otherOwnWorkers: "",
      },
      topicsCovered: {
        atRiskFunctions: "",
        managers: "",
        AMSB: "",
        otherOwnWorkers: "",
      },
      definitionOfCorruption: {
        atRiskFunctions: "",
        managers: "",
        AMSB: "",
        otherOwnWorkers: "",
      },
      policy: {
        atRiskFunctions: "",
        managers: "",
        AMSB: "",
        otherOwnWorkers: "",
      },
      proceduresOnSuspicionDetection: {
        atRiskFunctions: "",
        managers: "",
        AMSB: "",
        otherOwnWorkers: "",
      },
    },
  });

  const [e1_5_2currentYear, sete1_5_2CurrentYear] = useState("");
  const [e1_5_2previousYear, sete1_5_2PreviousYear] = useState("");
  const [e1_5_2output, sete1_5_2Output] = useState(null);
  const [e1_5_2error, sete1_5_2Error] = useState("");
  const [answers2, setAnswers2] = useState({});

  // Conditional Logic for Energy intensity based on net revenue
  const calculateOutput = () => {
    if (!e1_5_2currentYear || !e1_5_2previousYear) {
      sete1_5_2Error("Both fields are required.");
      return;
    }

    const N = parseFloat(e1_5_2currentYear);
    const NMinus1 = parseFloat(e1_5_2previousYear);

    if (isNaN(N) || isNaN(NMinus1)) {
      sete1_5_2Error("Please enter valid numeric values.");
      return;
    }

    if (NMinus1 === 0) {
      sete1_5_2Error("Previous year value (N-1) cannot be zero.");
      return;
    }

    sete1_5_2Error(""); // Clear any previous errors

    const result = ((N - NMinus1) / NMinus1) * 100;
    sete1_5_2Output(result.toFixed(2)); // Round to 2 decimal places
  };


  useEffect(() => {
    const savedAnswers2 = localStorage.getItem("tableAnswers");
    if (savedAnswers2) {
      setAnswers2(JSON.parse(savedAnswers2));
    }
  }, []);
  // Handle input change for any table and field
  const handleInputChange = (event) => {
    console.log(event.target);
    const { name, value } = event.target;
    const [table, section, field] = name.split("-");
    setInputValues((prevValues) => ({
      ...prevValues,
      [table]: {
        ...prevValues[table],
        [section]: {
          ...(prevValues[table] && prevValues[table][section]), // Add this line
          [field]: value,
        },
      },
    }));
    setAnswers2((tableAnswers) => {
      const updatedAnswers = {
        ...tableAnswers,
        [name]: value,
      };
      localStorage.setItem("tableAnswers", JSON.stringify(updatedAnswers));
      return updatedAnswers;
    });
  };

  //   const [inputValues, setInputValues] = useState({
  //     baseYear: "",
  //     target2030: "",
  //     target2035: "",
  //     upTo2050: "",
  //   });

  //   const handleInputChange = (event) => {
  //     const { name, value } = event.target;
  //     setInputValues({
  //       ...inputValues,
  //       [name]: value,
  //     });
  //   };

  const questionData = {
    /**********************************************
     * Tab: General
     * Topic: ESRS E2 CLIMATE CHANGE
     **********************************************/
    "BP-1 – General basis for preparation of the sustainability statement": [
      {
        id: "q-1",
        question:
          "The undertaking shall disclose the following information: whether the sustainability statement has been prepared on a consolidated or individual basis;",
        tooltip: "",
        module: "General",
        boldPart: "5 a",
      },
      {
        id: "q-2",
        question:
          "for consolidated sustainability statements: i. a confirmation that the scope of consolidation is the same as for the financial statements, or, where applicable, a declaration that the reporting undertaking is not required to draw-up financial statements or that the reporting undertaking is preparing consolidated sustainability reporting pursuant to Article 48i of Directive 2013/34/EU; and ii. where applicable, an indication of which subsidiary undertakings included in the consolidation are exempted from individual or consolidated sustainability reporting pursuant to Articles 19a(9) or 29a(8) of Directive 2013/34/EU;",
        tooltip: "",
        module: "General",
        boldPart: "5 b i",
      },
      {
        id: "q-3",
        question:
          "to what extent the sustainability statement covers the undertaking’s upstream and downstream value chain (see ESRS 1 section 5.1 Reporting undertaking and value chain);",
        tooltip: "",
        module: "General",
        module: "General",
        boldPart: "5 b ii",
      },
      {
        id: "q-4",
        question:
          "to what extent the sustainability statement covers the undertaking’s upstream and downstream value chain (see ESRS 1 section 5.1 Reporting undertaking and value chain);",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 1. When describing to what extent the sustainability statement covers the
              undertaking’s upstream and downstream value chain (see ESRS 1 section 5.1 Reporting
              undertaking and value chain), the undertaking may distinguish between:
            </p>
            <ol className="question-tip" type="a">
              <li>
                the extent to which its materiality assessment of impacts, risks and opportunities
                extends to its upstream and/or downstream value chain;
              </li>
              <li>
                the extent to which its policies, actions and targets extend to its value chain; and
              </li>
              <li>
                the extent to which it includes upstream and/or downstream value chain data when
                disclosing on metrics.
              </li>
            </ol>
          </Typography>
        ),
        module: "General",
        boldPart: "5 c",
      },
      {
        id: "q-5",
        question:
          "whether the undertaking has used the option to omit a specific piece of information corresponding to intellectual property, know-how or the results of innovation (see ESRS 1 section 7.7 Classified and sensitive information and information on intellectual property, know-how or results of innovation);",
        tooltip: "",
        module: "General",
        boldPart: "5 d",
      },
      {
        id: "q-6",
        question:
          "for undertakings based in an EU member state that allows for the exemption from disclosure of impending developments or matters in the course of negotiation, as provided for in articles 19a(3) and 29a(3) of Directive 2013/34/EU, whether the undertaking has used that exemption. ",
        tooltip: "",
        module: "General",
        boldPart: "5 e",
      },
      // Add more questions as needed
    ],

    "BP-2 – Disclosures in relation to specific circumstances": [
      {
        id: "q-7",
        question:
          "The undertaking may report this information alongside the disclosures to which they refer.",
        tooltip: "",
        boldPart: "6",
      },
      {
        id: "q-8",
        question:
          "When the undertaking has deviated from the medium- or long-term time horizons defined by ESRS 1 section 6.4 Definition of short-, medium- and long-term for reporting purposes, it shall describe: 9 (a) its definitions of medium- or long-term time horizons; and 9(b) the reasons for applying those definitions.",
        tooltip: "",
        module: "General",
        boldPart: "9",
      },
      {
        id: "q-11",
        question:
          "When metrics include upstream and/or downstream value chain data estimated using indirect sources, such as sector-average data or other proxies, the undertaking shall: 10 (a) identify the metrics;",
        tooltip: "",
        module: "General",
        boldPart: "10 a",
      },

      {
        id: "q-13",
        question:
          "in relation to each quantitative metric and monetary amount identified: 10 b i. disclose information about the sources of measurement uncertainty (for example, the dependence of the amount on the outcome of a future event, on a measurement technique or on the availability and quality of data from the entity’s upstream and/or downstream value chain). ",
        tooltip: "",
        module: "General",
        boldPart: "10 b",
      },
      {
        id: "q-16",
        question:
          "In accordance with ESRS 1 section 7.2 Sources of estimation and outcome uncertainty, the undertaking shall: (a) identify the quantitative metrics and monetary amounts it has disclosed that are subject to a high level of measurement uncertainty; ",
        tooltip: "",
        module: "General",
        boldPart: "11 a",
      },
      {
        id: "q-17",
        question:
          "in relation to each quantitative metric and monetary amount identified:  i. discloses information about the sources of measurement uncertainty (for example, the dependence of the amount on the outcome of a future event, on a measurement technique or on the availability and quality of data from the entity’s upstream and/or downstream value chain); and ii. disclose the assumptions, approximations and judgements the entity has made in measuring it. 12. When disclosing forward-looking information, the undertaking may indicate that it considers such information to be uncertain.",
        tooltip: "",
        module: "General",
        boldPart: "11 b",
      },
      {
        id: "q-19",
        question:
          "When changes in the preparation and presentation of sustainability information occur compared to the previous reporting period(s), (see ESRS 1 section 7.4 Changes in preparation or presentation in sustainability information), the undertaking shall: ",
        tooltip: "",
        module: "General",
        boldPart: "13 a",
      },
      {
        id: "q-20",
        question:
          "explain the changes and the reasons for them, including why the replaced metric provides more useful information; ",
        tooltip: "",
        module: "General",
        boldPart: "13 b",
      },
      {
        id: "q-21",
        question:
          "disclose the difference between the figure disclosed in the preceding period and the revised comparative figure. ",
        tooltip: "",
        module: "General",
        boldPart: "13 c",
      },
      {
        id: "q-22",
        question:
          "When the undertaking identifies material prior period errors (see ESRS 1 section 7.5 Reporting errors in prior periods), it shall disclose: (a) the nature of the prior period material error; ",
        tooltip: "",
        module: "General",
        boldPart: "14 a",
      },
      {
        id: "q-23",
        question:
          "to the extent practicable, the correction for each prior period included in the sustainability statement; and ",
        tooltip: "",
        module: "General",
        boldPart: "14 b",
      },
      {
        id: "q-24",
        question:
          "if correction of the error is not practicable, the circumstances that led to the existence of that condition. ",
        tooltip: "",
        module: "General",
        boldPart: "14 c",
      },
      {
        id: "q-25",
        question:
          "When the undertaking includes in its sustainability statement information stemming from other legislation which requires the undertaking to disclose sustainability information or from generally accepted sustainability reporting standards and frameworks (see ESRS 1 section 8.2 Content and structure of the sustainability statement), in addition to the information prescribed by ESRS, it shall disclose this fact. In case of partial application of other reporting standards or frameworks, the undertaking shall provide a precise reference to the paragraphs of the standard or framework applied.",
        tooltip: "",
        module: "General",
        boldPart: "15",
      },
      {
        id: "q-26",
        question:
          "The undertaking may disclose whether it relies on any European standards approved by the European Standardisation System (ISO/IEC or CEN/CENELEC standards), as well as the extent to which data and processes that are used for sustainability reporting purposes have been verified by an external assurance provider and found to conform to the corresponding ISO/IEC or CEN/CENELEC standard. ",
        tooltip: "",
        voluntary: true,
        module: "General",
        boldPart: "AR 2",
      },
      {
        id: "q-27",
        question:
          "When the undertaking incorporates information by reference (see ESRS 1 section 9.1 Incorporation by reference), it shall disclose a list of the disclosure requirements of ESRS, or the specific datapoints mandated by a Disclosure Requirement, that have been incorporated by reference. ",
        tooltip: "",
        module: "General",
        boldPart: "16",
      },
      {
        id: "q-28",
        question:
          "If an undertaking or group not exceeding on its balance sheet date the average number of 750 employees during the financial year decides to omit the information required by ESRS E4, ESRS S1, ESRS S2, ESRS S3 or ESRS S4 in accordance with Appendix C of ESRS 1, it shall nevertheless disclose whether the sustainability topics covered respectively by ESRS E4, ESRS S1, ESRS S2, ESRS S3 and ESRS S4 have been assessed to be material as a result of the undertaking’s materiality assessment. In addition, if one or more of these topics has been assessed to be material, the undertaking shall, for each material topic: ",
        tooltip: "",
        module: "General",
        boldPart: "17",
      },
      {
        id: "q-29",
        question:
          "disclose the list of matters (i.e. topic, sub-topic or sub-sub-topic) in AR 16 ESRS 1",
        tooltip: "",
        module: "General",
        boldPart: "17 a",
      },
      {
        id: "q-30",
        question:
          "briefly describe any time-bound targets it has set related to the matters in question, the progress it has made towards achieving those targets, and whether its targets related to biodiversity and ecosystems are based on conclusive scientific evidence; ",
        tooltip: "",
        module: "General",
        boldPart: "17 b",
      },
      {
        id: "q-31",
        question: "briefly describe its policies in relation to the matters in question; ",
        tooltip: "",
        module: "General",
        boldPart: "17 c",
      },
      {
        id: "q-32",
        question:
          "briefly describe actions it has taken to identify, monitor, prevent, mitigate, remediate or bring an end to actual or potential adverse impacts related to the matters in question, and the result of such actions; ",
        tooltip: "",
        module: "General",
        boldPart: "17 d",
      },
      {
        id: "q-33",
        question: "disclose metrics relevant to the matters in question. ",
        tooltip: "",
        module: "General",
        boldPart: "17 e",
      },
      // Add more questions as needed
    ],
    "GOV-1 – The role of the administrative, management and supervisory bodies": [
      {
        id: "q-34",
        question:
          "The undertaking shall disclose the following information about the composition and diversity of the members of the undertaking’s administrative, management and supervisory bodies: 21 (a) the number of executive and non-executive members;",
        tooltip: "",
        module: "General",
        boldPart: "21 a",
      },

      {
        id: "q-36",
        question: "representation of employees and other workers;",
        tooltip: "",
        module: "General",
        boldPart: "21 b",
      },
      {
        id: "q-37",
        question:
          "experience relevant to the sectors, products and geographic locations of the undertaking; ",
        tooltip:
          "AR 5. The description of the level of expertise or access to expertise of the administrative, management and supervisory bodies may be substantiated by illustrating the composition of the bodies, including members on whom these bodies rely for expertise to oversee sustainability matters, and how they leverage that expertise as a body. In the description, the undertaking shall consider how the expertise and skills are relevant to the undertaking’s material impacts, risks and opportunities and whether the bodies and/or its members have  access to other sources of expertise, such as specific experts and training and other educational initiatives to update and develop sustainability-related expertise within these bodies.",
        module: "General",
        boldPart: "21 c",
      },
      {
        id: "q-38",
        question:
          "percentage by gender and other aspects of diversity that the undertaking considers. The board's gender diversity1 shall be calculated as an average ratio of female to male board members; ",
        tooltip: "",
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>
                SFDR<sup>9</sup> reference:
              </strong>{" "}
              Indicator number 13 of Table #1 of Annex 1
            </p>
            <p className="question-tip">
              <strong>
                Benchmark Regulation<sup>10</sup> reference:
              </strong>{" "}
              Commission Delegated Regulation (EU) 2020/1816<sup>12</sup> , Annex II
            </p>
          </Typography>
        ),
        module: "General",
        boldPart: "21 d",
      },
      {
        id: "q-39",
        question:
          "the percentage of independent board members2. oor undertakings with a unitary board, this corresponds to the percentage of independent non-executive board members. oor undertakings with a dual board, it corresponds to the percentage of independent members of the supervisory body. ",
        tooltip: "",
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>
                Benchmark Regulation<sup>10</sup> reference:
              </strong>{" "}
              Delegated Regulation (EU) 2020/1816, Annex II
            </p>
          </Typography>
        ),
        module: "General",
        boldPart: "21 e",
      },
      {
        id: "q-40",
        question:
          "The undertaking shall disclose the following information about the roles and responsibilities of the administrative, (I con) management and supervisory bodies: 22 (a) the identity of the administrative, management and supervisory bodies (such as a board committee or similar) or individual(s) within a body responsible for oversight of impacts, risks and opportunities;  ",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 3. In describing the role and responsibilities of the administrative, management
              and supervisory bodies with regard to sustainability matters, the undertaking may
              specify:
            </p>
            <ol className="question-tip" type="a">
              <li>
                the aspects of sustainability over which oversight is exercised with regard to the
                environmental, social and governance matters the undertaking may be facing,
                including:
                <ol className="question-tip" type="i">
                  <li>
                    any assessment of and changes to sustainability-related aspects of the
                    undertaking’s strategy and business model;
                  </li>
                  <li>
                    the identification and assessment of material risks, opportunities and impacts;
                  </li>
                  <li>
                    related policies and targets, action plans and dedicated resources; and iv.
                    sustainability reporting;
                  </li>
                </ol>
              </li>
              <li>
                the form such oversight takes for each of the above aspects: i.e., information,
                consultation or decision-making; and
              </li>
              <li>
                the way such oversight is organised and formalised, i.e., processes by which the
                administrative, management and supervisory bodies engage with these aspects of
                sustainability.
              </li>
            </ol>
          </Typography>
        ),
        module: "General",
        boldPart: "22 a",
      },

      {
        id: "q-42",
        question:
          "how each body’s or individual’s responsibilities for impacts, risks and opportunities are reflected in the undertaking’s terms of reference, board mandates and other related policies; ",
        tooltip: "",
        module: "General",
        boldPart: "22 b",
      },
      {
        id: "q-43",
        question:
          "a description of management’s role in the governance processes, controls and procedures used to monitor, manage and oversee impacts, risks and opportunities, including: 22 (c) i) whether that role is delegated to a specific management-level position or committee and how oversight is exercised over that position or committee;",
        tooltip: "",
        module: "General",
        boldPart: "22 c i",
      },

      {
        id: "q-45",
        question:
          "information about the reporting lines to the administrative, management and supervisory bodies;",
        tooltip: "",
        module: "General",
        boldPart: "22 c ii",
      },
      {
        id: "q-46",
        question:
          "whether dedicated controls and procedures are applied to the management of impacts, risks and opportunities and, if so, how they are integrated with other internal functions; ",
        tooltip: "",
        module: "General",
        boldPart: "22 c iii",
      },
      {
        id: "q-47",
        question:
          "The disclosure shall include a description of how the administrative, management and supervisory bodies determine whether appropriate skills and expertise are available or will be developed to oversee sustainability matters, including: 23 (a) the sustainability-related expertise that the bodies, as a whole, either directly possess or can leverage, for example through access to experts or training;",
        tooltip:
          "AR 5. The description of the level of expertise or access to expertise of the administrative, management and supervisory bodies may be substantiated by illustrating the composition of the bodies, including members on whom these bodies rely for expertise to oversee sustainability matters, and how they leverage that expertise as a body. In the description, the undertaking shall consider how the expertise and skills are relevant to the undertaking’s material impacts, risks and opportunities and whether the bodies and/or its members have access to other sources of expertise, such as specific experts and training and other educational initiatives to update and develop sustainability-related expertise within these bodies. ",
        module: "General",
        boldPart: "23 a",
      },
      {
        id: "q-49",
        question:
          "how those skills and expertise relate to the undertaking's material impacts, risks and opportunities. ",
        tooltip: "",
        module: "General",
        boldPart: "23 b",
      },
      // Add more questions as needed
    ],
    "GOV-2 – Information provided to and sustainability matters addressed by the undertaking’s administrative, management and supervisory bodies":
      [
        {
          id: "q-50",
          question:
            "The undertaking shall disclose the following information: (a) whether, by whom and how frequently the administrative, management and supervisory bodies, including their relevant committees, are informed about material impacts, risks and opportunities(see Disclosure Requirement IRO–1 - Description of the processes to identify and assess material impacts, risks and opportunities of this Standard), the implementation of due diligence, and the results and effectiveness of policies, actions, metrics and targets adopted to address them;",
          tooltip: "",
          module: "General",
          boldPart: "26 a",
        },
        {
          id: "q-51",
          question:
            "how the administrative, management and supervisory bodies consider impacts, risks and opportunities when overseeing the undertaking’s strategy, its decisions on major transactions, and its risk management process, including whether they have considered trade-offs associated with those impacts, risks and opportunities; ",
          tooltip: "",
          module: "General",
          boldPart: "26 b",
        },
        {
          id: "q-52",
          question:
            "a list of the material impacts, risks and opportunities addressed by the administrative, management and supervisory bodies, or their relevant committees during the reporting period. ",
          tooltip: "",
          module: "General",
          boldPart: "26 c",
        },
        {
          id: "q-53",
          question:
            "Depending on the undertaking’s structure, the administrative, management and supervisory bodies may focus on overarching targets, while management focuses on the more detailed targets. In this case, the undertaking may disclose how the governance bodies ensure that an appropriate mechanism for performance monitoring is in place. ",
          tooltip: "",
          voluntary: true,
          module: "General",
          boldPart: "AR 6",
        },

        // Add more questions as needed
      ],
    "GOV-3 – Integration of sustainability-related performance in incentive schemes": [
      {
        id: "q-54",
        question:
          "The undertaking shall disclose the following information about the incentive schemes and remuneration policies linked to sustainability matters for members of the undertaking's administrative, management and supervisory bodies, where they exist: 29 (a) a description of the key characteristics of the incentive schemes; ",
        tooltip:
          "AR 7. oor listed undertakings, this Disclosure Requirement should be consistent with the remuneration report prescribed in articles 9a and 9b of Directive 2007/36/EC on the exercise of certain rights of shareholders in listed companies. Subject to the provisions of ESRS 1, paragraphs 119, 120 and 122, a listed undertaking may make a reference to its remuneration report. ",
        module: "General",
        boldPart: "29 a",
      },
      {
        id: "q-56",
        question:
          "whether performance is being assessed against specific sustainability-related targets and/or impacts, and if so, which ones;",
        tooltip: "",
        module: "General",
        boldPart: "29 b",
      },
      {
        id: "q-57",
        question:
          "whether and how sustainability-related performance metrics are considered as performance benchmarks or included in remuneration policies; ",
        tooltip: "",
        module: "General",
        boldPart: "29 c",
      },
      {
        id: "q-58",
        question:
          "the proportion of variable remuneration dependent on sustainability-related targets and/or impacts;",
        tooltip: "",
        module: "General",
        boldPart: "29 d",
      },
      {
        id: "q-59",
        question:
          "the level in the undertaking at which the terms of incentive schemes are approved and updated. ",
        tooltip: "",
        module: "General",
        boldPart: "29 e",
      },

      // Add more questions as needed
    ],
    "GOV–4 - Statement on due diligence": [
      {
        id: "q-60",
        question:
          "The main aspects and steps of due diligence referred to under ESRS 1 chapter 4 Due diligence are related to a number of cross-cutting and topical Disclosure Requirements under the ESRS. The undertaking shall provide a mapping that explains how and where its application of the main aspects and steps of the due diligence process are reflected in its sustainability statement, to allow a depiction of the actual practices of the undertaking with regard to due diligence3  Table GOV–4 - Statement on due diligence.",
        tooltip:
          "This disclosure requirement does not mandate any specific behavioural requirements with regard to due diligence actions and does not extend or modify the role of administrative, management and supervisory bodies as mandated by other legislation or regulation. AR 9. The undertaking may include additional columns to the table below to clearly identify those disclosures that relate to impacts on people and/or the environment given that, in some cases, more than one disclosure may provide information about the same due diligence step. AR 10. The main references in the international instruments of the UN Guiding Principles on Business and Human Rights and the OECD Guidelines for Multinational Enterprises to the core elements of the due diligence process are listed in ESRS 1 chapter 4. ",
        table: (
          <table className="question-table">
            <thead>
              <tr>
                <th>Core elements of due diligence</th>
                <th>Relavant DR</th>
                <th>Paragraph in the sustainability statements</th>
                <th>Does the disclosure relate to people or environment</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>a) Embedding due diligence in governance, strategy and business model</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="gov_4-a-dr"
                    value={answers2["gov_4-a-dr"] || inputValues.gov_4.a.dr}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="gov_4-a-paragraph"
                    value={answers2["gov_4-a-paragraph"] || inputValues.gov_4.a.paragraph}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="gov_4-a-relates"
                    value={answers2["gov_4-a-relates"] || inputValues.gov_4.a.relates}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  b) Engaging with affected stakeholders in all key steps of the due diligence
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="gov_4-b-dr"
                    value={answers2["gov_4-b-dr"] || inputValues.gov_4.b.dr}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="gov_4-b-paragraph"
                    value={answers2["gov_4-b-paragraph"] || inputValues.gov_4.b.paragraph}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="gov_4-b-relates"
                    value={answers2["gov_4-b-relates"] || inputValues.gov_4.b.relates}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>c) Identifying and assessing adverse impacts</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="gov_4-c-dr"
                    value={answers2["gov_4-c-dr"] || inputValues.gov_4.c.dr}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="gov_4-c-paragraph"
                    value={answers2["gov_4-c-paragraph"] || inputValues.gov_4.c.paragraph}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="gov_4-c-relates"
                    value={answers2["gov_4-c-relates"] || inputValues.gov_4.c.relates}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>d) Taking actions to address those adverse impacts</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="gov_4-d-dr"
                    value={answers2["gov_4-d-dr"] || inputValues.gov_4.d.dr}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="gov_4-d-paragraph"
                    value={answers2["gov_4-d-paragraph"] || inputValues.gov_4.d.paragraph}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="gov_4-d-relates"
                    value={answers2["gov_4-d-relates"] || inputValues.gov_4.d.relates}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>e) Tracking the effectiveness of these efforts and communicating</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="gov_4-e-dr"
                    value={answers2["gov_4-e-dr"] || inputValues.gov_4.e.dr}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="gov_4-e-paragraph"
                    value={answers2["gov_4-e-paragraph"] || inputValues.gov_4.e.paragraph}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="gov_4-e-relates"
                    value={answers2["gov_4-e-relates"] || inputValues.gov_4.e.relates}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        ),
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 10 Table #3 of Annex 1
            </p>
          </Typography>
        ),
        module: "General",
        boldPart: "30, 32 ",
        fun: "4Column",
      },
      // Add more questions as needed
    ],
    "GOV–5 - Risk management and internal controls over sustainability reporting": [
      {
        id: "q-61",
        question:
          "The undertaking shall disclose the following information: (a) the scope, main features and components of the risk management and internal control processes and systems in relation to sustainability reporting;",
        tooltip:
          "AR 11. This Disclosure Requirement focuses solely on the internal control processes over the sustainability reporting process. The undertaking may consider risks such as the completeness and integrity of the data, the accuracy of estimation results, the availability of upstream and/or downstream value chain data, and the timing of the availability of the information. ",
        module: "General",
        boldPart: "36 a",
      },
      {
        id: "q-62",
        question:
          "the risk assessment approach followed, including the risk prioritisation methodology;",
        tooltip:
          "AR 11. This Disclosure Requirement focuses solely on the internal control processes over the sustainability reporting process. The undertaking may consider risks such as the completeness and integrity of the data, the accuracy of estimation results, the availability of upstream and/or downstream value chain data, and the timing of the availability of the information. ",
        module: "General",
        boldPart: "36 b",
      },
      {
        id: "q-63",
        question:
          "the main risks identified and their mitigation strategies including related controls; ",
        tooltip:
          "AR 11. This Disclosure Requirement focuses solely on the internal control processes over the sustainability reporting process. The undertaking may consider risks such as the completeness and integrity of the data, the accuracy of estimation results, the availability of upstream and/or downstream value chain data, and the timing of the availability of the information. ",
        module: "General",
        boldPart: "36 c",
      },
      {
        id: "q-64",
        question:
          "a description of how the undertaking integrates the findings of its risk assessment and internal controls as regards the sustainability reporting process into relevant internal functions and processes;",
        tooltip:
          "AR 11. This Disclosure Requirement focuses solely on the internal control processes over the sustainability reporting process. The undertaking may consider risks such as the completeness and integrity of the data, the accuracy of estimation results, the availability of upstream and/or downstream value chain data, and the timing of the availability of the information. ",
        module: "General",
        boldPart: "36 d",
      },
      {
        id: "q-65",
        question:
          "a description of the periodic reporting of the findings referred to in point (d) to the administrative, management and supervisory bodies. ",
        tooltip: (
          <Typography>
            <p className="question-tip">
              37. This chapter sets disclosure requirements that enable an understanding of:
            </p>
            <ol className="question-tip" type="a">
              <li>
                the elements of the undertaking’s strategy that relate to or affect sustainability
                matters, its business model and its value chain;
              </li>
              <li>
                how the interests and views of the undertaking’s stakeholders are taken into account
                by the undertaking’s strategy and business model; and
              </li>
              <li>
                the outcome of the undertaking’s assessment of material impacts, risks and
                opportunities, including how they inform its strategy and business model AR 11. This
                Disclosure Requirement focuses solely on the internal control processes over the
                sustainability reporting process. The undertaking may consider risks such as the
                completeness and integrity of the data, the accuracy of estimation results, the
                availability of upstream and/or downstream value chain data, and the timing of the
                availability of the information.
              </li>
            </ol>
          </Typography>
        ),
        module: "General",
        boldPart: "36 e",
      },

      // Add more questions as needed
    ],
    "SBM-1 – Strategy, business model and value chain": [
      {
        id: "q-66",
        question:
          "The undertaking shall disclose the following information about the key elements of its general strategy that relate to or affect sustainability matters: ",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 12. To provide the information on sectors required by paragraph 40, the undertaking
              shall map its significant activities in accordance with ESRS sectors. If a code for a
              sub-sector does not exist, the caption “others” shall be used.
            </p>
            <p className="question-tip">
              AR 13. oor the purposes of the disclosures required in paragraph 40, a group of
              products and/or services offered, a group of markets and/or customer groups served, or
              an ESRS sector, is significant for the undertaking if it meets one or both of the
              following criteria: (b) it is connected with material actual impacts or material
              potential negative impacts of the undertaking.
            </p>
            <p className="question-tip">
              (a) it accounts for more than 10 per cent of the undertaking’s revenue;
            </p>
          </Typography>
        ),
        phasedIn:
          "The undertaking shall report the information prescribed by ESRS 2 SBM-1 paragraph 40(b) (breakdown of total revenue by significant ESRS sector) and 40(c) (list of additional significant ESRS sectors) starting from the application date specified in a Commission Delegated Act to be adopted pursuant to article 29b(1) third subparagraph point (ii) of Directive 2013/34/EU. 40 (a)) i) significant groups of products and/or services offered, including changes in the of Annex I of Commission Delegated Regulation (EU) 2022/1288 with regard to disclosure rules on sustainable investments (“Lack of due diligence”). reporting period (new/removed products and/or services);",
        module: "General",
        boldPart: "40 a i",
      },

      {
        id: "q-68",
        question:
          "significant markets and/or customer groups served, including changes in the reporting period (new/removed markets and/or customer groups);",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 12. To provide the information on sectors required by paragraph 40, the undertaking
              shall map its significant activities in accordance with ESRS sectors. If a code for a
              sub-sector does not exist, the caption “others” shall be used.
            </p>
            <p className="question-tip">
              AR 13. oor the purposes of the disclosures required in paragraph 40, a group of
              products and/or services offered, a group of markets and/or customer groups served, or
              an ESRS sector, is significant for the undertaking if it meets one or both of the
              following criteria: (b) it is connected with material actual impacts or material
              potential negative impacts of the undertaking.
            </p>
            <p className="question-tip">
              (a) it accounts for more than 10 per cent of the undertaking’s revenue;
            </p>
          </Typography>
        ),
        phasedIn:
          "The undertaking shall report the information prescribed by ESRS 2 SBM-1 paragraph 40(b) (breakdown of total revenue by significant ESRS sector) and 40(c) (list of additional significant ESRS sectors) starting from the application date specified in a Commission Delegated Act to be adopted pursuant to article 29b(1) third subparagraph point (ii) of Directive 2013/34/EU.",
        module: "General",
        boldPart: "40 a ii",
      },
      {
        id: "q-69",
        question: "headcount of employees by geographical areas;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 12. To provide the information on sectors required by paragraph 40, the undertaking
              shall map its significant activities in accordance with ESRS sectors. If a code for a
              sub-sector does not exist, the caption “others” shall be used.
            </p>
            <p className="question-tip">
              AR 13. oor the purposes of the disclosures required in paragraph 40, a group of
              products and/or services offered, a group of markets and/or customer groups served, or
              an ESRS sector, is significant for the undertaking if it meets one or both of the
              following criteria: (b) it is connected with material actual impacts or material
              potential negative impacts of the undertaking.
            </p>
            <p className="question-tip">
              (a) it accounts for more than 10 per cent of the undertaking’s revenue;
            </p>
          </Typography>
        ),
        phasedIn:
          "The undertaking shall report the information prescribed by ESRS 2 SBM-1 paragraph 40(b) (breakdown of total revenue by significant ESRS sector) and 40(c) (list of additional significant ESRS sectors) starting from the application date specified in a Commission Delegated Act to be adopted pursuant to article 29b(1) third subparagraph point (ii) of Directive 2013/34/EU.",
        module: "General",
        boldPart: "40 a iii",
      },
      {
        id: "q-70",
        question:
          "where applicable and material, products and services that are banned in certain markets;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 12. To provide the information on sectors required by paragraph 40, the undertaking
              shall map its significant activities in accordance with ESRS sectors. If a code for a
              sub-sector does not exist, the caption “others” shall be used.
            </p>
            <p className="question-tip">
              AR 13. oor the purposes of the disclosures required in paragraph 40, a group of
              products and/or services offered, a group of markets and/or customer groups served, or
              an ESRS sector, is significant for the undertaking if it meets one or both of the
              following criteria: (b) it is connected with material actual impacts or material
              potential negative impacts of the undertaking.
            </p>
            <p className="question-tip">
              (a) it accounts for more than 10 per cent of the undertaking’s revenue;
            </p>
          </Typography>
        ),
        phasedIn:
          "The undertaking shall report the information prescribed by ESRS 2 SBM-1 paragraph 40(b) (breakdown of total revenue by significant ESRS sector) and 40(c) (list of additional significant ESRS sectors) starting from the application date specified in a Commission Delegated Act to be adopted pursuant to article 29b(1) third subparagraph point (ii) of Directive 2013/34/EU.",
        module: "General",
        boldPart: "40 a iv",
      },
      {
        id: "q-71",
        question:
          "a breakdown of total revenue, as included in its financial statements, by significant ESRS sectors. When the undertaking provides segment reporting as required by IoRS 8 Operating segments in its financial statements, this sector revenue information shall be, as far as possible, reconciled with IoRS 8 information;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 12. To provide the information on sectors required by paragraph 40, the undertaking
              shall map its significant activities in accordance with ESRS sectors. If a code for a
              sub-sector does not exist, the caption “others” shall be used.
            </p>
            <p className="question-tip">
              AR 13. oor the purposes of the disclosures required in paragraph 40, a group of
              products and/or services offered, a group of markets and/or customer groups served, or
              an ESRS sector, is significant for the undertaking if it meets one or both of the
              following criteria: (b) it is connected with material actual impacts or material
              potential negative impacts of the undertaking.
            </p>
            <p className="question-tip">
              (a) it accounts for more than 10 per cent of the undertaking’s revenue;
            </p>
          </Typography>
        ),
        phasedIn:
          "The undertaking shall report the information prescribed by ESRS 2 SBM-1 paragraph 40(b) (breakdown of total revenue by significant ESRS sector) and 40(c) (list of additional significant ESRS sectors) starting from the application date specified in a Commission Delegated Act to be adopted pursuant to article 29b(1) third subparagraph point (ii) of Directive 2013/34/EU.",
        module: "General",
        boldPart: "40 b",
      },
      {
        id: "q-72",
        question:
          "a list of the additional significant ESRS sectors beyond the ones reflected under paragraph 40(b), such as activities that give rise to intercompany revenues, in which the undertaking develops significant activities, or in which it is or may be connected to material impacts. The identification of these additional ESRS sectors shall be consistent with the way they have been considered by the undertaking when performing its materiality assessment and with the way it discloses material sector-specific information; ",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 12. To provide the information on sectors required by paragraph 40, the undertaking
              shall map its significant activities in accordance with ESRS sectors. If a code for a
              sub-sector does not exist, the caption “others” shall be used.
            </p>
            <p className="question-tip">
              AR 13. oor the purposes of the disclosures required in paragraph 40, a group of
              products and/or services offered, a group of markets and/or customer groups served, or
              an ESRS sector, is significant for the undertaking if it meets one or both of the
              following criteria: (b) it is connected with material actual impacts or material
              potential negative impacts of the undertaking.
            </p>
            <p className="question-tip">
              (a) it accounts for more than 10 per cent of the undertaking’s revenue;
            </p>
          </Typography>
        ),
        phasedIn:
          "The undertaking shall report the information prescribed by ESRS 2 SBM-1 paragraph 40(b) (breakdown of total revenue by significant ESRS sector) and 40(c) (list of additional significant ESRS sectors) starting from the application date specified in a Commission Delegated Act to be adopted pursuant to article 29b(1) third subparagraph point (ii) of Directive 2013/34/EU.",
        module: "General",
        boldPart: "40 c",
      },
      {
        id: "q-73",
        question:
          "where applicable, a statement indicating, together with the related revenues, that the undertaking is active in: i. the fossil fuel (coal, oil and gas) sector4, (i.e., it derives revenues from exploration, mining, extraction, production, processing, storage, refining or distribution, including transportation, storage and trade, of fossil fuels as defined in Article 2, point (62), of Regulation (EU) 2018/1999 of the European Parliament and the Council 5), including a disaggregation of revenues derived from coal, from oil and from gas, as well as the revenues derived from Taxonomy-aligned economic activities related to fossil gas as required under Article 8(7)(a) of Commission Delegated Regulation 2021/217818",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 12. To provide the information on sectors required by paragraph 40, the undertaking
              shall map its significant activities in accordance with ESRS sectors. If a code for a
              sub-sector does not exist, the caption “others” shall be used.
            </p>
            <p className="question-tip">
              AR 13. oor the purposes of the disclosures required in paragraph 40, a group of
              products and/or services offered, a group of markets and/or customer groups served, or
              an ESRS sector, is significant for the undertaking if it meets one or both of the
              following criteria: (b) it is connected with material actual impacts or material
              potential negative impacts of the undertaking.
            </p>
            <p className="question-tip">
              (a) it accounts for more than 10 per cent of the undertaking’s revenue;
            </p>
          </Typography>
        ),
        phasedIn:
          "The undertaking shall report the information prescribed by ESRS 2 SBM-1 paragraph 40(b) (breakdown of total revenue by significant ESRS sector) and 40(c) (list of additional significant ESRS sectors) starting from the application date specified in a Commission Delegated Act to be adopted pursuant to article 29b(1) third subparagraph point (ii) of Directive 2013/34/EU.",
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicators number 4 Table #1 of Annex 1
            </p>
            <p className="question-tip">
              <strong>
                Pillar reference<sup>24</sup>:
              </strong>{" "}
              Article 449a Regulation (EU) No 575/2013; Commission Implementing Regulation (EU)
              2022/2453<sup>13</sup>Table 1: Qualitative information on Environmental risk and Table
              2: Qualitative information on Social risk.
            </p>
            <p className="question-tip">
              <strong>
                Benchmark reference<sup>25</sup>:
              </strong>{" "}
              Delegated Regulation (EU) 2020/1816, Annex II
            </p>
          </Typography>
        ),
        module: "General",
        boldPart: "40 d i",
      },
      {
        id: "q-74",
        question:
          "chemicals production6, i.e., its activities fall under Division 20.2 of Annex I to Regulation (EC) No 1893/2006;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 12. To provide the information on sectors required by paragraph 40, the undertaking
              shall map its significant activities in accordance with ESRS sectors. If a code for a
              sub-sector does not exist, the caption “others” shall be used.
            </p>
            <p className="question-tip">
              AR 13. oor the purposes of the disclosures required in paragraph 40, a group of
              products and/or services offered, a group of markets and/or customer groups served, or
              an ESRS sector, is significant for the undertaking if it meets one or both of the
              following criteria: (b) it is connected with material actual impacts or material
              potential negative impacts of the undertaking.
            </p>
            <p className="question-tip">
              (a) it accounts for more than 10 per cent of the undertaking’s revenue;
            </p>
          </Typography>
        ),
        phasedIn:
          "The undertaking shall report the information prescribed by ESRS 2 SBM-1 paragraph 40(b) (breakdown of total revenue by significant ESRS sector) and 40(c) (list of additional significant ESRS sectors) starting from the application date specified in a Commission Delegated Act to be adopted pursuant to article 29b(1) third subparagraph point (ii) of Directive 2013/34/EU.",
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 9 Table #2 of Annex 1
            </p>

            <p className="question-tip">
              <strong>
                Benchmark reference<sup>25</sup>:
              </strong>{" "}
              Delegated Regulation (EU) 2020/1816, Annex II
            </p>
          </Typography>
        ),
        module: "General",
        boldPart: "40 d ii",
      },
      {
        id: "q-75",
        question:
          "controversial weapons 7(anti-personnel mines, cluster munitions, chemical weapons and bio weapons);",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 12. To provide the information on sectors required by paragraph 40, the undertaking
              shall map its significant activities in accordance with ESRS sectors. If a code for a
              sub-sector does not exist, the caption “others” shall be used.
            </p>
            <p className="question-tip">
              AR 13. oor the purposes of the disclosures required in paragraph 40, a group of
              products and/or services offered, a group of markets and/or customer groups served, or
              an ESRS sector, is significant for the undertaking if it meets one or both of the
              following criteria: (b) it is connected with material actual impacts or material
              potential negative impacts of the undertaking.
            </p>
            <p className="question-tip">
              (a) it accounts for more than 10 per cent of the undertaking’s revenue;
            </p>
          </Typography>
        ),
        phasedIn:
          "The undertaking shall report the information prescribed by ESRS 2 SBM-1 paragraph 40(b) (breakdown of total revenue by significant ESRS sector) and 40(c) (list of additional significant ESRS sectors) starting from the application date specified in a Commission Delegated Act to be adopted pursuant to article 29b(1) third subparagraph point (ii) of Directive 2013/34/EU.",
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 14 Table #1 of Annex 1
            </p>

            <p className="question-tip">
              <strong>
                Benchmark reference<sup>25</sup>:
              </strong>{" "}
              Delegated Regulation (EU) 2020/1818<sup>14</sup>, Article 12(1) Delegated Regulation
              (EU) 2020/1816, Annex II
            </p>
          </Typography>
        ),
        module: "General",
        boldPart: "40 d iii",
      },
      {
        id: "q-76",
        question: "the cultivation and production of tobacco2",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 12. To provide the information on sectors required by paragraph 40, the undertaking
              shall map its significant activities in accordance with ESRS sectors. If a code for a
              sub-sector does not exist, the caption “others” shall be used.
            </p>
            <p className="question-tip">
              AR 13. oor the purposes of the disclosures required in paragraph 40, a group of
              products and/or services offered, a group of markets and/or customer groups served, or
              an ESRS sector, is significant for the undertaking if it meets one or both of the
              following criteria: (b) it is connected with material actual impacts or material
              potential negative impacts of the undertaking.
            </p>
            <p className="question-tip">
              (a) it accounts for more than 10 per cent of the undertaking’s revenue;
            </p>
          </Typography>
        ),
        phasedIn:
          "The undertaking shall report the information prescribed by ESRS 2 SBM-1 paragraph 40(b) (breakdown of total revenue by significant ESRS sector) and 40(c) (list of additional significant ESRS sectors) starting from the application date specified in a Commission Delegated Act to be adopted pursuant to article 29b(1) third subparagraph point (ii) of Directive 2013/34/EU.",
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>
                Benchmark Regulation<sup>25</sup> reference:
              </strong>{" "}
              Delegated Regulation (EU) 2020/1818, Article 12(1) Delegated Regulation (EU)
              2020/1816, Annex II
            </p>
          </Typography>
        ),
        module: "General",
        boldPart: "40 d iv",
      },
      {
        id: "q-77",
        question:
          "its sustainability-related goals in terms of significant groups of products and services, customer categories, geographical areas and relationships with stakeholders;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 12. To provide the information on sectors required by paragraph 40, the undertaking
              shall map its significant activities in accordance with ESRS sectors. If a code for a
              sub-sector does not exist, the caption “others” shall be used.
            </p>
            <p className="question-tip">
              AR 13. oor the purposes of the disclosures required in paragraph 40, a group of
              products and/or services offered, a group of markets and/or customer groups served, or
              an ESRS sector, is significant for the undertaking if it meets one or both of the
              following criteria: (b) it is connected with material actual impacts or material
              potential negative impacts of the undertaking.
            </p>
            <p className="question-tip">
              (a) it accounts for more than 10 per cent of the undertaking’s revenue;
            </p>
          </Typography>
        ),
        phasedIn:
          "The undertaking shall report the information prescribed by ESRS 2 SBM-1 paragraph 40(b) (breakdown of total revenue by significant ESRS sector) and 40(c) (list of additional significant ESRS sectors) starting from the application date specified in a Commission Delegated Act to be adopted pursuant to article 29b(1) third subparagraph point (ii) of Directive 2013/34/EU.",

        module: "General",
        boldPart: "40 e",
      },
      {
        id: "q-78",
        question:
          "an assessment of its current significant products and/or services, and significant markets and customer groups, in relation to its sustainability-related goals;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 12. To provide the information on sectors required by paragraph 40, the undertaking
              shall map its significant activities in accordance with ESRS sectors. If a code for a
              sub-sector does not exist, the caption “others” shall be used.
            </p>
            <p className="question-tip">
              AR 13. oor the purposes of the disclosures required in paragraph 40, a group of
              products and/or services offered, a group of markets and/or customer groups served, or
              an ESRS sector, is significant for the undertaking if it meets one or both of the
              following criteria: (b) it is connected with material actual impacts or material
              potential negative impacts of the undertaking.
            </p>
            <p className="question-tip">
              (a) it accounts for more than 10 per cent of the undertaking’s revenue;
            </p>
          </Typography>
        ),
        phasedIn:
          "The undertaking shall report the information prescribed by ESRS 2 SBM-1 paragraph 40(b) (breakdown of total revenue by significant ESRS sector) and 40(c) (list of additional significant ESRS sectors) starting from the application date specified in a Commission Delegated Act to be adopted pursuant to article 29b(1) third subparagraph point (ii) of Directive 2013/34/EU.",
        module: "General",
        boldPart: "40 f",
      },
      {
        id: "q-79",
        question:
          "the elements of the undertaking’s strategy that relate to or impact sustainability matters, including the main challenges ahead, critical solutions or projects to be put in place, when relevant for sustainability reporting. ",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 12. To provide the information on sectors required by paragraph 40, the undertaking
              shall map its significant activities in accordance with ESRS sectors. If a code for a
              sub-sector does not exist, the caption “others” shall be used.
            </p>
            <p className="question-tip">
              AR 13. oor the purposes of the disclosures required in paragraph 40, a group of
              products and/or services offered, a group of markets and/or customer groups served, or
              an ESRS sector, is significant for the undertaking if it meets one or both of the
              following criteria: (b) it is connected with material actual impacts or material
              potential negative impacts of the undertaking.
            </p>
            <p className="question-tip">
              (a) it accounts for more than 10 per cent of the undertaking’s revenue;
            </p>
          </Typography>
        ),
        phasedIn:
          "The undertaking shall report the information prescribed by ESRS 2 SBM-1 paragraph 40(b) (breakdown of total revenue by significant ESRS sector) and 40(c) (list of additional significant ESRS sectors) starting from the application date specified in a Commission Delegated Act to be adopted pursuant to article 29b(1) third subparagraph point (ii) of Directive 2013/34/EU.",
        module: "General",
        boldPart: "40 g",
      },
      {
        id: "q-80",
        question:
          "If the undertaking is based in an EU Member State that allows for an exemption from the disclosure of the information referred to in Article 18, paragraph 1, sub-point (a) of Directive 2013/34/EU8, and if the undertaking has made use of that exemption, it may omit the breakdown of revenue by significant ESRS sector required by paragraph 40(b). In this case the undertaking shall nevertheless disclose the list of ESRS sectors that are significant for the undertaking. ",
        tooltip: "",
        phasedIn:
          "The undertaking shall report the information prescribed by ESRS 2 SBM-1 paragraph 40(b) (breakdown of total revenue by significant ESRS sector) and 40(c) (list of additional significant ESRS sectors) starting from the application date specified in a Commission Delegated Act to be adopted pursuant to article 29b(1) third subparagraph point (ii) of Directive 2013/34/EU.",
        module: "General",
        boldPart: "41",
      },
      {
        id: "q-81",
        question:
          "The undertaking shall disclose a description of its business model and value chain, including:",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 14. In preparing disclosures relating to its business model and value chain, the
              undertaking shall consider:
            </p>
            <ol className="question-tip" type="a">
              <li>its key activities, resources, distribution channels and customer segments; </li>
              <li>
                its key business relationships and their key characteristics, including
                relationships with customers and suppliers;
              </li>
              <li>
                the cost structure and revenue of its business segments, in line with IoRS 8
                disclosure requirements in the financial statement, where applicable;
              </li>
              <li>
                the potential impacts, risks and opportunities in its significant sector(s) and
                their possible relationship to its own business model or value chain.
              </li>
            </ol>
          </Typography>
        ),
        phasedIn:
          "The undertaking shall report the information prescribed by ESRS 2 SBM-1 paragraph 40(b) (breakdown of total revenue by significant ESRS sector) and 40(c) (list of additional significant ESRS sectors) starting from the application date specified in a Commission Delegated Act to be adopted pursuant to article 29b(1) third subparagraph point (ii) of Directive 2013/34/EU. 42 (a) Its inputs and its approach to gathering, developing and securing those inputs;",
        module: "General",
        boldPart: "42 a",
      },

      {
        id: "q-83",
        question:
          "its outputs and outcomes in terms of current and expected benefits for customers, investors and other stakeholders;",
        tooltip: "",
        phasedIn:
          "The undertaking shall report the information prescribed by ESRS 2 SBM-1 paragraph 40(b) (breakdown of total revenue by significant ESRS sector) and 40(c) (list of additional significant ESRS sectors) starting from the application date specified in a Commission Delegated Act to be adopted pursuant to article 29b(1) third subparagraph point (ii) of Directive 2013/34/EU.",
        module: "General",
        boldPart: "42 b",
      },
      {
        id: "q-84",
        question:
          "the main features of its upstream and downstream value chain and the undertaking’s position in its value chain, including a description of the main business actors (such as key suppliers, customers, distribution channels and end-users) and their relationship to the undertaking. When the undertaking has multiple value chains, the disclosure shall cover the key value chains. ",
        tooltip:
          "AR 15. Contextual information may be particularly relevant for users of the undertaking’s sustainability statement, to understand to what extent the disclosures include upstream and/or downstream value chain information. The description of the main features of the upstream and/or downstream value chain and where applicable the identification of key value chains should support an understanding of how the undertaking applies the requirements of ESRS 1 chapter 5 and the materiality assessment performed by the undertaking in line with ESRS 1 chapter 3. The description may provide a high-level overview of the key features of upstream and/or downstream value chain entities indicating their relative contribution to the undertaking’s performance and position and explaining how they contribute to the value creation of the undertaking. ",
        phasedIn:
          "The undertaking shall report the information prescribed by ESRS 2 SBM-1 paragraph 40(b) (breakdown of total revenue by significant ESRS sector) and 40(c) (list of additional significant ESRS sectors) starting from the application date specified in a Commission Delegated Act to be adopted pursuant to article 29b(1) third subparagraph point (ii) of Directive 2013/34/EU.",
        module: "General",
        boldPart: "42 c",
      },
      // Add more questions as needed
    ],
    "SBM-2 – Interests and views of stakeholders": [
      {
        id: "q-85",
        question:
          "The undertaking shall disclose a summarised description of: (a) its stakeholder engagement, including: ",
        tooltip:
          "AR 16. The views and interests of stakeholders that are expressed as part of the undertaking’s engagement with stakeholders through its due diligence process may be relevant to one or more aspects of its strategy or business model. As such, they may affect the undertaking’s decisions regarding the future direction of the strategy or business model. ",
        module: "General",
        boldPart: "45 a",
      },
      {
        id: "q-86",
        question: "the undertaking’s key stakeholders; ",
        tooltip:
          "AR 16. The views and interests of stakeholders that are expressed as part of the undertaking’s engagement with stakeholders through its due diligence process may be relevant to one or more aspects of its strategy or business model. As such, they may affect the undertaking’s decisions regarding the future direction of the strategy or business model. ",
        module: "General",
        boldPart: "45 a i",
      },
      {
        id: "q-87",
        question: "whether engagement with them occurs and for which categories of stakeholders;",
        tooltip:
          "AR 16. The views and interests of stakeholders that are expressed as part of the undertaking’s engagement with stakeholders through its due diligence process may be relevant to one or more aspects of its strategy or business model. As such, they may affect the undertaking’s decisions regarding the future direction of the strategy or business model. ",
        module: "General",
        boldPart: "45 a ii",
      },
      {
        id: "q-88",
        question: "how it is organised;",
        tooltip:
          "AR 16. The views and interests of stakeholders that are expressed as part of the undertaking’s engagement with stakeholders through its due diligence process may be relevant to one or more aspects of its strategy or business model. As such, they may affect the undertaking’s decisions regarding the future direction of the strategy or business model. ",
        module: "General",
        boldPart: "45 a iii",
      },
      {
        id: "q-89",
        question: "its purpose; ",
        tooltip:
          "AR 16. The views and interests of stakeholders that are expressed as part of the undertaking’s engagement with stakeholders through its due diligence process may be relevant to one or more aspects of its strategy or business model. As such, they may affect the undertaking’s decisions regarding the future direction of the strategy or business model. ",
        module: "General",
        boldPart: "45 a iv",
      },
      {
        id: "q-90",
        question: "how its outcome is taken into account by the undertaking;",
        tooltip:
          "AR 16. The views and interests of stakeholders that are expressed as part of the undertaking’s engagement with stakeholders through its due diligence process may be relevant to one or more aspects of its strategy or business model. As such, they may affect the undertaking’s decisions regarding the future direction of the strategy or business model. ",
        module: "General",
        boldPart: "45 a v",
      },
      {
        id: "q-91",
        question:
          "the undertaking’s understanding of the interests and views of its key stakeholders as they relate to the undertaking’s strategy and business model, to the extent that these were analysed during the undertaking’s due diligence process and/or materiality assessment process (see Disclosure Requirement IRO-1 of this Standard); ",
        tooltip:
          "AR 16. The views and interests of stakeholders that are expressed as part of the undertaking’s engagement with stakeholders through its due diligence process may be relevant to one or more aspects of its strategy or business model. As such, they may affect the undertaking’s decisions regarding the future direction of the strategy or business model. ",
        module: "General",
        boldPart: "45 b",
      },
      {
        id: "q-92",
        question: "where applicable, amendments to its strategy and/or business model, including: ",
        tooltip: "",
        module: "General",
        boldPart: "45 c",
      },
      {
        id: "q-93",
        question:
          "how the undertaking has amended or expects to amend its strategy and/or business model to address the interests and views of its stakeholders; ",
        tooltip: "",
        module: "General",
        boldPart: "45 c i",
      },
      {
        id: "q-94",
        question: "any further steps that are being planned and in what timeline; ",
        tooltip: "",
        module: "General",
        boldPart: "45 c ii",
      },
      {
        id: "q-95",
        question:
          "whether these steps are likely to modify the relationship with and views of stakeholders;",
        tooltip: "",
        module: "General",
        boldPart: "45 c iii",
      },
      {
        id: "q-96",
        question:
          "whether and how the administrative, management and supervisory bodies are informed about the views and interests of affected stakeholders with regard to the undertaking’s sustainability-related impacts. ",
        tooltip: "",
        module: "General",
        boldPart: "45 d",
      },

      // Add more questions as needed
    ],
    "SBM-3 - Material impacts, risks and opportunities and their interaction with strategy and business model":
      [
        {
          id: "q-97",
          question:
            "a brief description of its material impacts, risks and opportunities resulting from its materiality assessment (see Disclosure Requirement IRO-1 of this standard), including a description of where in its business model, its own operations and its upstream and downstream value chain these material impacts, risks and opportunities are concentrated; ",
          tooltip:
            "The undertaking may disclose the descriptive information required in paragraph 46 alongside the disclosures provided under the corresponding topical ESRS, in which case it shall still present a statement of its material impacts, risks and opportunities alongside its disclosures prepared under this chapter of ESRS 2. ",
          phasedIn:
            "The undertaking may omit the information prescribed by ESRS 2 SBM-3 paragraph 48(e) (anticipated financial effects) for the first year of preparation of its sustainability statement. The undertaking may comply with ESRS 2 SBM-3 paragraph 48(e) by reporting only qualitative disclosures for the first 3 years of preparation of its sustainability statement if it is impracticable to prepare quantitative disclosures.",
          module: "General",
          boldPart: "48 a",
        },
        {
          id: "q-98",
          question:
            "the current and anticipated effects of its material impacts, risks and opportunities on its business model, value chain, strategy and decision-making, and how it has responded or plans to respond to these effects, including any changes it has made or plans to make to its strategy or business model as part of its actions to address particular material impacts or risks, or to pursue particular material opportunities;",
          tooltip: "",
          phasedIn:
            "The undertaking may omit the information prescribed by ESRS 2 SBM-3 paragraph 48(e) (anticipated financial effects) for the first year of preparation of its sustainability statement. The undertaking may comply with ESRS 2 SBM-3 paragraph 48(e) by reporting only qualitative disclosures for the first 3 years of preparation of its sustainability statement if it is impracticable to prepare quantitative disclosures.",

          module: "General",
          boldPart: "48 b",
        },
        {
          id: "q-99",
          question:
            "with reference to the undertaking’s material impacts: how the undertaking’s material negative and positive impacts affect (or, in the case of potential impacts, are likely to affect) people or the environment;",
          tooltip: "",
          phasedIn:
            "The undertaking may omit the information prescribed by ESRS 2 SBM-3 paragraph 48(e) (anticipated financial effects) for the first year of preparation of its sustainability statement. The undertaking may comply with ESRS 2 SBM-3 paragraph 48(e) by reporting only qualitative disclosures for the first 3 years of preparation of its sustainability statement if it is impracticable to prepare quantitative disclosures.",

          module: "General",
          boldPart: "48 c i",
        },
        {
          id: "q-100",
          question:
            "whether and how the impacts originate from or are connected to the undertaking's strategy and business model; ",
          tooltip: "",
          phasedIn:
            "The undertaking may omit the information prescribed by ESRS 2 SBM-3 paragraph 48(e) (anticipated financial effects) for the first year of preparation of its sustainability statement. The undertaking may comply with ESRS 2 SBM-3 paragraph 48(e) by reporting only qualitative disclosures for the first 3 years of preparation of its sustainability statement if it is impracticable to prepare quantitative disclosures.",

          module: "General",
          boldPart: "48 c ii",
        },
        {
          id: "q-101",
          question: "the reasonably expected time horizons of the impacts;",
          tooltip: "",
          phasedIn:
            "The undertaking may omit the information prescribed by ESRS 2 SBM-3 paragraph 48(e) (anticipated financial effects) for the first year of preparation of its sustainability statement. The undertaking may comply with ESRS 2 SBM-3 paragraph 48(e) by reporting only qualitative disclosures for the first 3 years of preparation of its sustainability statement if it is impracticable to prepare quantitative disclosures.",

          module: "General",
          boldPart: "48 c iii",
        },
        {
          id: "q-102",
          question:
            "whether the undertaking is involved with the material impacts through its activities or because of its business relationships, describing the nature of the activities or business relationships concerned; ",
          tooltip:
            "AR 17. When describing where in its upstream and/or downstream value chain material impacts, risks and opportunities are concentrated, the undertaking shall consider: geographical areas, facilities or types of assets, inputs, outputs and distribution channels. ",
          phasedIn:
            "The undertaking may omit the information prescribed by ESRS 2 SBM-3 paragraph 48(e) (anticipated financial effects) for the first year of preparation of its sustainability statement. The undertaking may comply with ESRS 2 SBM-3 paragraph 48(e) by reporting only qualitative disclosures for the first 3 years of preparation of its sustainability statement if it is impracticable to prepare quantitative disclosures.",
          module: "General",
          boldPart: "48 c iv",
        },
        {
          id: "q-103",
          question:
            "the current financial effects of the undertaking’s material risks and opportunities on its financial position, financial performance and cash flows and the material risks and opportunities for which there is a significant risk of a material adjustment within the next annual reporting period to the carrying amounts of assets and liabilities reported in the related financial statements; ",
          tooltip: "",
          phasedIn:
            "The undertaking may omit the information prescribed by ESRS 2 SBM-3 paragraph 48(e) (anticipated financial effects) for the first year of preparation of its sustainability statement. The undertaking may comply with ESRS 2 SBM-3 paragraph 48(e) by reporting only qualitative disclosures for the first 3 years of preparation of its sustainability statement if it is impracticable to prepare quantitative disclosures.",
          module: "General",
          boldPart: "48 e",
        },
        {
          id: "q-104",
          question:
            "the anticipated financial effects of the undertaking’s material risks and opportunities on its financial position, financial performance and cash flows over the short-, medium- and long-term, including the reasonably expected time horizons for those effects. This shall include how the undertaking expects its financial position, financial performance and cash flows to change over the short, medium- and long-term, given its strategy to manage risks and opportunities, taking into consideration: i. its investment and disposal plans (for example, capital expenditure, major acquisitions and divestments, joint ventures, business transformation, innovation, new business areas and asset retirements), including plans the undertaking is not contractually committed to; and ii. its planned sources of funding to implement its strategy.",
          tooltip: "",
          phasedIn:
            "The undertaking may omit the information prescribed by ESRS 2 SBM-3 paragraph 48(e) (anticipated financial effects) for the first year of preparation of its sustainability statement. The undertaking may comply with ESRS 2 SBM-3 paragraph 48(e) by reporting only qualitative disclosures for the first 3 years of preparation of its sustainability statement if it is impracticable to prepare quantitative disclosures.",
          module: "General",
          boldPart: "48 f",
        },
        {
          id: "q-105",
          question:
            "information about the resilience of the undertaking's strategy and business model regarding its capacity to address its material impacts and risks and to take advantage of its material opportunities. The undertaking shall disclose a qualitative and, when applicable, a quantitative analysis of the resilience, including how the analysis was conducted and the time horizons that were applied as 12 defined in ESRS 1 (see ESRS 1 chapter 6 Time horizons). When providing quantitative information, the undertaking may disclose single amounts or ranges;",
          tooltip: "",
          phasedIn:
            "The undertaking may omit the information prescribed by ESRS 2 SBM-3 paragraph 48(e) (anticipated financial effects) for the first year of preparation of its sustainability statement. The undertaking may comply with ESRS 2 SBM-3 paragraph 48(e) by reporting only qualitative disclosures for the first 3 years of preparation of its sustainability statement if it is impracticable to prepare quantitative disclosures.",
          module: "General",
          boldPart: "48 g",
        },
        {
          id: "q-106",
          question:
            "changes to the material impacts, risks and opportunities compared to the previous reporting period;",
          tooltip: (
            <Typography>
              <p className="question-tip">
                a specification of those impacts, risks and opportunities that are covered by ESRS
                Disclosure Requirements as opposed to those covered by the undertaking using
                additional entity-specific disclosures. 50. This chapter sets disclosure
                requirements that enable an understanding of:
              </p>
              <ol className="question-tip" type="a">
                <li>the process to identify material impacts, risks and opportunities; and </li>
                <li>
                  the information that, as a result of its materiality assessment, the undertaking
                  has included in its sustainability statement.
                </li>
              </ol>
            </Typography>
          ),
          phasedIn:
            "The undertaking may omit the information prescribed by ESRS 2 SBM-3 paragraph 48(e) (anticipated financial effects) for the first year of preparation of its sustainability statement. The undertaking may comply with ESRS 2 SBM-3 paragraph 48(e) by reporting only qualitative disclosures for the first 3 years of preparation of its sustainability statement if it is impracticable to prepare quantitative disclosures.",
          module: "General",
          boldPart: "48 h",
        },
        // Add more questions as needed
      ],
    "IRO-1 - Description of the process to identify and assess material impacts, risks and opportunities":
      [
        {
          id: "q-107",
          question:
            "The undertaking shall disclose the following information: (a) a description of the methodologies and assumptions applied in the described process; ",
          tooltip: "",
          module: "General",
          boldPart: "53 a",
        },
        {
          id: "q-108",
          question:
            "an overview of the process to identify, assess, prioritise and monitor the undertaking’s potential and actual impacts on people and the environment, informed by the undertaking’s due diligence process, including an explanation of whether and how the process: ",
          tooltip: "",
          module: "General",
          boldPart: "53 b",
        },
        {
          id: "q-109",
          question:
            "an overview of the process to identify, assess, prioritise and monitor the undertaking’s potential and actual impacts on people and the environment, informed by the undertaking’s due diligence process, including an explanation of whether and how the process: i. focusses on specific activities, business relationships, geographies or other factors that give rise to heightened risk of adverse impacts;",
          tooltip: "",
          module: "General",
          boldPart: "53 b i",
        },
        {
          id: "q-110",
          question:
            "considers the impacts with which the undertaking is involved through its own operations or as a result of its business relationships; ",
          tooltip: "",
          module: "General",
          boldPart: "53 b ii",
        },
        {
          id: "q-111",
          question:
            "includes consultation with affected stakeholders to understand how they may be impacted and with external experts; ",
          tooltip: "",
          module: "General",
          boldPart: "53 b iii",
        },
        {
          id: "q-112",
          question:
            "prioritises negative impacts based on their relative severity and likelihood, (see ESRS 1 section 3.4 Impact materiality) and, if applicable, positive impacts on their relative scale, scope and likelihood, and determines which sustainability matters are material for reporting purposes, including the qualitative or quantitative thresholds and other criteria used as prescribed by ESRS 1 section 3.4 Impact materiality;",
          tooltip: "",
          module: "General",
          boldPart: "53 b iv",
        },
        {
          id: "q-113",
          question:
            "an overview of the process used to identify, assess, prioritise and monitor risks and opportunitiesthat have or may have financial effects.",
          tooltip: "",
          module: "General",
          boldPart: "53 c",
        },
        {
          id: "q-114",
          question:
            "The disclosure shall include: i. how the undertaking has considered the connections of its impacts and dependencies with the risks and opportunities that may arise from those impacts and dependencies; ",
          tooltip: "",
          module: "General",
          boldPart: "53 c i",
        },
        {
          id: "q-115",
          question:
            "how the undertaking assesses the likelihood, magnitude, and nature of effects of the identified risk and opportunities (such as the qualitative or quantitative thresholds and other criteria used as prescribed by ESRS 1 section 3.3 Financial materiality); ",
          tooltip: "",
          module: "General",
          boldPart: "53 c ii",
        },
        {
          id: "q-116",
          question:
            "how the undertaking prioritises sustainability-related risks relative to other types of risks, including its use of risk-assessment tools; ",
          tooltip: "",
          module: "General",
          boldPart: "53 c iii",
        },
        {
          id: "q-117",
          question:
            "a description of the decision-making process and the related internal control procedures; ",
          tooltip: "",
          module: "General",
          boldPart: "53 d",
        },
        {
          id: "q-118",
          question:
            "the extent to which and how the process to identify, assess and manage impacts and risks is integrated into the undertaking’s overall risk management process and used to evaluate the undertaking’s overall risk profile and risk management processes;",
          tooltip: "",
          module: "General",
          boldPart: "53 e",
        },
        {
          id: "q-119",
          question:
            "the extent to which and how the process to identify, assess and manage opportunities is integrated into the undertaking’s overall management process where applicable; ",
          tooltip: "",
          module: "General",
          boldPart: "53 f",
        },
        {
          id: "q-120",
          question:
            "the input parameters it uses (for example, data sources, the scope of operations covered and the detail used in assumptions);",
          tooltip: "",
          module: "General",
          boldPart: "53 g",
        },
        {
          id: "q-121",
          question:
            "whether and how the process has changed compared to the prior reporting period, when the process was modified for the last time and future revision dates of the materiality assessment. ",
          tooltip: "",
          module: "General",
          boldPart: "53 h",
        },

        // Add more questions as needed
      ],
    "IRO-2 – Disclosure Requirements in ESRS covered by the undertaking’s sustainability statement":
      [
        {
          id: "q-122",
          question:
            "sustainability statement, following the outcome of the materiality assessment (see ESRS 1 chapter 3), including the page numbers and/or paragraphs where the related disclosures are located in the sustainability statement. This may be presented as a content index. The undertaking shall also include a table of all the datapoints that derive from other EU legislation as listed in Appendix B of this standard, indicating where they can be found in the sustainability statement and including those that the undertaking has assessed as not material, in which case the undertaking shall indicate “Not material” in the table in accordance with ESRS 1 paragraph 35. ",
          tooltip:
            "AR 18. This disclosure may be expressed in terms of a single impact, risk or opportunity or by aggregating groups of material impacts, risks and opportunities, when this provides more relevant information and does not obscure material information.",
          module: "General",
          boldPart: "56",
        },
        {
          id: "q-123",
          question:
            "If the undertaking concludes that climate change is not material and therefore omits all disclosure requirements in ESRS E1 Climate change, it shall disclose a detailed explanation of the conclusions of its materiality assessment with regard to climate change (see ESRS 2 IRO-2 Disclosure Requirements in ESRS covered by the undertaking’s sustainability statement), including a forward-looking analysis of the conditions that could lead the undertaking to conclude that climate change is material in the future.",
          tooltip: "",
          module: "General",
          boldPart: "57",
        },
        {
          id: "q-124",
          question:
            "If the undertaking concludes that a topic other than climate change is not material and therefore omits all the Disclosure Requirements in the corresponding topical ESRS, it may provide a brief explanation of the conclusions of its materiality assessment for that topic. ",
          tooltip: "",
          voluntary: true,
          module: "General",
          boldPart: "58",
        },
        {
          id: "q-125",
          question:
            "The undertaking shall provide an explanation of how it has determined the material information to be disclosed in relation to the impacts, risks and opportunities that it has assessed to be material, including the use of thresholds and/or how it has implemented the criteria in ESRS 1 section 3.2 Material matters and materiality of information.",
          tooltip: "",
          module: "General",
          boldPart: "59",
        },

        // Add more questions as needed
      ],
    /**********************************************
     * Tab: Environment
     * Topic: ESRS E2 CLIMATE CHANGE
     **********************************************/
    "ESRS 2 GOV-3 Integration of sustainability related performance in incentive schemes": [
      {
        id: "q-126",
        question:
          "The undertaking shall disclose whether and how climate-related considerations are factored into the remuneration of members of the administrative, management and supervisory bodies, including if their performance has been assessed against the GHG emission reduction targets reported under Disclosure Requirement E1-4 and the percentage of the remuneration recognised in the current period that is linked to climate related considerations, with an explanation of what the climate considerations are.",
        tooltip: "",
        module: "Environment",
        boldPart: "13",
      },
      // Add more questions as needed
    ],
    "E1-1 – Transition plan for climate change mitigation": [
      {
        id: "q-127",
        question:
          "The undertaking shall disclose its transition plan for climate change mitigation.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              A transition plan relates to the undertaking’s efforts in climate change mitigation.
              When disclosing its transition plan, the undertaking is expected to provide a
              high-level explanation of how it will adjust its strategy and business model to ensure
              compatibility with the transition to a sustainable economy and with the limiting of
              global warming to 1.5°C in line with the Paris Agreement (or an updated inter national
              agreement on climate change) and the objective of achieving climate neutrality by 2050
              with no or limited overshoot as established in Regulation (EU) 2021/1119 (European
              Climate Law), and where applicable, how it will adjust its exposure to coal, and oil
              and gas-related activities.
            </p>
          </Typography>
        ),
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>
                EU Climate<sup>26</sup> reference:
              </strong>{" "}
              Regulation (EU) 2021/1119, Article 2(1)
            </p>
          </Typography>
        ),
        module: "Environment",
        boldPart: "14",
      },
      {
        id: "q-128",
        question:
          "The information required by paragraph 14 shall include: by reference to GHG emission reduction targets (as required by Disclosure Requirement E1-4), an explanation of how the undertaking’s targets are compatible with the limiting of global warming to 1.5°C in line with the Paris Agreement",
        tooltip: (
          <Typography>
            <p className="question-tip">
              Sectoral pathways have not yet been defined by the public policies for all sectors.
              Hence, the disclosure under paragraph 16 (a) on the compatibility of the transition
              plan with the objective of limiting global warming to 1.5°C should be understood as
              the disclosure of the undertaking’s GHG emissions reduction target. The disclosure
              under paragraph 16 (a) shall be benchmarked in relation to a pathway to 1.5°C. This
              benchmark should be based on either a sectoral decarbonisation pathway if available
              for the undertaking’s sector or an economy-wide scenario bearing in mind its
              limitations (i.e., it is a simple translation of emission reduction objectives from
              the state to undertaking level). This AR should be read also in conjunction with AR 26
              and AR 27 and the sectoral decarbonisation pathways they refer to.
            </p>
          </Typography>
        ),
        module: "Environment",
        boldPart: "16 a",
      },
      {
        id: "q-129",
        question:
          "By reference to GHG emission reduction targets (as required by Disclosure Requirement E1-4) and the climate change mitigation actions (as required by Disclosure Requirement E1-3), an explanation of the decarbonisation levers identified, and key actions planned, including changes in the undertaking’s product and service portfolio and the adoption of new technologies in its own operations, or the upstream and/or downstream value chain;",
        module: "Environment",
        boldPart: "16 b",
      },
      {
        id: "q-130",
        question:
          "By reference to the climate change mitigation actions (as required by Disclosure Requirement E1-3), an explanation and quantification of the undertaking’s investments and funding supporting the implementation of its transition plan, with a reference to the key performance indicators of taxonomy-aligned CapEx, and where relevant the CapEx plans, that the undertaking discloses in accordance with Commission Delegated Regulation (EU) 2021/2178;",
        tooltip: "",
        module: "Environment",
        boldPart: "16 c",
      },
      {
        id: "q-131",
        question:
          "A qualitative assessment of the potential locked-in GHG emissions from the undertaking’s key assets and products. This shall include an explanation of if and how these emissions may jeopardise the achievement of the undertaking’s GHG emission reduction targets and drive transition risk, and if applicable, an explanation of the undertaking’s plans to manage its GHG-intensive and energy-intensive assets and products;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              When disclosing the information required under paragraph 16(d) the undertaking may
              consider:
              <ol className="question-tip" type="a">
                <li>
                  the cumulative locked-in GHG emissions associated with key assets from the
                  reporting year until 2030 and 2050 in tCO2eq. This will be assessed as the sum of
                  the estimated Scopes 1 and 2 GHG emissions over the operating lifetime of the
                  active and firmly planned key assets. Key assets are those owned or controlled by
                  the undertaking, and they consist of existing or planned assets (such as
                  stationary or mobile installations, facilities, and equipment) that are sources of
                  either significant direct or energy-indirect GHG emissions. Firmly planned key
                  assets are those that the undertaking will most likely deploy within the next 5
                  years.
                </li>
                <li>
                  the cumulative locked-in GHG emissions associated with the direct use-phase GHG
                  emissions of sold products in tCO2eq, assessed as the sales volume of products in
                  the reporting year multiplied by the sum of estimated direct use-phase GHG
                  emissions over their expected lifetime. This requirement only applies if the
                  undertaking has identified the Scope 3 category “use of sold products” as
                  significant under Disclosure Requirement E1-6 paragraph 51; and
                </li>
                <li>
                  an explanation of the plans to manage, i.e., to transform, decommission or phase
                  out its GHG-intensive and energy-intensive assets and products."
                </li>
              </ol>
            </p>
          </Typography>
        ),
        module: "Environment",
        boldPart: "16 d",
      },
      {
        id: "q-132",
        question:
          "For undertakings with economic activities that are covered by delegated regulations on climate adaptation or mitigation under the Taxonomy Regulation, an explanation of any objective or plans (CapEX, CapEx plans, OpEX) that the undertaking has for aligning its economic activities (revenues, CapEx, OpEx) with the criteria established in Commission Delegated Regulation 2021/2139",
        tooltip: (
          <Typography>
            <p className="question-tip">
              When disclosing the information required under paragraph 16 (e), the undertaking shall
              explain how the alignment of its economic activities with the provisions of Commission
              Delegated Regulation (EU) 2021/2139 is expected to evolve over time to support its
              transition to a sustainable economy. In doing so, the undertaking shall take account
              of the key performance indicators required to be disclosed under Article 8 of
              Regulation (EU) 2020/852 (in particular taxonomy-aligned revenue and CapEx and, if
              applicable, CapEx plans).
            </p>
          </Typography>
        ),
        module: "Environment",
        boldPart: "16 e",
      },
      {
        id: "q-133",
        question:
          "If applicable, a disclosure of significant CapEx amounts invested during the reporting period related to coal, oil and gas-related economic activities;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              When disclosing the information required under paragraph 16 (f), the undertaking shall
              state whether or not it is excluded from the EU Paris-aligned eenchmarks in accordance
              with the exclusion criteria stated in Articles 12.1 (d) to (g)53 and 12.2 of
              Commission Delegated Regulation (EU) 2020/1818 (Climate eenchmark Standards
              Regulation)
            </p>
          </Typography>
        ),
        module: "Environment",
        boldPart: "16 f",
      },
      {
        id: "q-134",
        question:
          "A disclosure on whether or not the undertaking is excluded from the EU Paris-aligned Benchmarks;",
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>
                Pillar 3<sup>24</sup>
                <sup>9</sup> reference:
              </strong>{" "}
              Article 449a Regulation (EU) No 575/2013; Commission Implementing Regulation (EU)
              2022/2453 Template 1: Banking bookClimate Change transition risk: Credit quality of
              exposures by sector, emissions and residual maturity
            </p>
            <p className="question-tip">
              <strong>
                Benchmark Regulation<sup>25</sup> reference:
              </strong>{" "}
              Delegated Regulation (EU) 2020/1818, Article12.1 (d) to (g), and Article 12.2
            </p>
          </Typography>
        ),
        module: "Environment",
        boldPart: "16 g",
      },
      {
        id: "q-135",
        question:
          "An explanation of how the transition plan is embedded in and aligned with the undertaking’s overall business strategy and financial planning",
        tooltip: "",
        module: "Environment",
        boldPart: "16 h",
      },
      {
        id: "q-136",
        question:
          "Whether the transition plan is approved by the administrative, management and supervisory bodies",
        tooltip: "",
        module: "Environment",
        boldPart: "16 i",
      },
      {
        id: "q-137",
        question:
          " An explanation of the undertaking’s progress in implementing the transition plan.",
        tooltip: "",
        module: "Environment",
        boldPart: "16 j",
      },
      {
        id: "q-138",
        question:
          "In case the undertaking does not have a transition plan in place, it shall indicate whether and, if so, when it will adopt a transition plan.",
        tooltip: "",
        module: "Environment",
        boldPart: "17",
      },
      // Add more questions as needed
    ],
    "E1 ESRS 2 SBM-3 – Material impacts, risks and opportunities and their interaction with strategy and business model":
      [
        {
          id: "q-139",
          question:
            "The undertaking shall explain for each material climate-related risk it has identified, whether the entity considers the risk to be a climate-related physical risk or climate-related transition risk.",
          tooltip: "",
          module: "Environment",
          boldPart: "18",
        },
        {
          id: "q-140",
          question:
            "The undertaking shall describe the resilience of its strategy and business model in relation to climate change. This description shall include: (a) the scope of the resilience analysis",
          tooltip: "",
          module: "Environment",
          boldPart: "19 a",
        },
        {
          id: "q-141",
          question:
            "how and when the resilience analysis has been conducted, including the use of climate scenario analysis as referenced in the Disclosure Requirement related to ESRS 2 IRO-1 and the related application requirement paragraphs; and;",
          tooltip: (
            <Typography>
              <p className="question-tip">
                When disclosing the information on how the resilience analysis has been conducted as
                required under paragraph 19 (b), the undertaking shall explain: (b) the time
                horizons applied and their alignment with the climate and business scenarios
                considered for determining material physical and transition risks (paragraphs AR
                11to AR 12) and setting GHG emissions reduction targets (reported under Disclosure
                Requirement E1-4)
              </p>
            </Typography>
          ),
          module: "Environment",
          boldPart: "19 b",
        },
        {
          id: "q-142",
          question:
            "the results of the resilience analysis including the results from the use of scenario analysis.",
          tooltip: (
            <Typography>
              <p className="question-tip">
                When disclosing the information on the results of the resilience analysis as
                required under paragraph 19 (c), the undertaking shall explain: (a) the areas of
                uncertainties of the resilience analysis and to what extent the assets and business
                activities at risk are considered within the definition of the undertaking’s
                strategy, investment decisions, and current and planned mitigation actions;
              </p>
            </Typography>
          ),
          module: "Environment",
          boldPart: "19 c",
        },
        {
          id: "q-143",
          question:
            "When disclosing the information on the results of the resilience analysis as required under paragraph 19 (c), the undertaking shall explain: (b) the ability of the undertaking to adjust or adapt its strategy and business model to climate change over the short-, medium- and long-term, including securing ongoing access to finance at an affordable cost of capital, the ability to redeploy, upgrade or decommission existing assets, shifting its products and services portfolio, or reskilling its workforce.",
          tooltip: "",
          module: "Environment",
          boldPart: "AR 8b",
        },

        // Add more questions as needed
      ],
    "ESRS 2 IRO-1 – Description of the processes to identify and assess material climate-related impacts, risks and opportunities":
      [
        {
          id: "q-144",
          question:
            "The undertaking shall describe the process to identify and assess climate-related impacts, risks and opportunities. This description shall include its process in relation to:impacts on climate change, in particular, the undertaking’s GHG emissions (as required by Disclosure Requirement ESRS E1-6)",
          tooltip: (
            <Typography>
              <p className="question-tip">
                The undertaking may link the information disclosed under paragraphs 20 (a) and AR 9
                to the information disclosed under the following Disclosure Requirements: Disclosure
                Requirement E1-1, paragraph 16 (d) on locked-in GHG emissions; Disclosure
                Requirement E1-4 and Disclosure Requirement E1-6.
              </p>
            </Typography>
          ),
          module: "Environment",
          boldPart: "20a, AR 9",
        },
        {
          id: "q-145",
          question:
            "The undertaking shall describe the process to identify and assess climate-related impacts, risks and opportunities. This description shall include its process in relation to: climate-related physical risks in own operations and along the upstream and downstream value chain, in particular: i. the identification of climate-related hazards, considering at least high emission climate scenarios; and ii. the assessment of how its assets and business activities may be exposed and are sensitive to these climate-related hazards, creating gross physical risks for the undertaking.",
          tooltip: (
            <Typography>
              <p className="question-tip">
                When disclosing the information required under paragraphs 19, 20, 21, AR 10 and AR
                11, the undertaking shall explain how it has used climate-related scenario analysis
                that is commensurate to its circumstances to inform the identification and
                assessment of physical and transition risks and opportunities over the short-,
                medium- and long-term, including:
              </p>
              <ol className="question-tip" type="a">
                <li>
                  which scenarios were used, their sources and alignment with state-of-the-art
                  science;
                </li>
                <li>
                  narratives, time horizons, and endpoints used with a discussion of why it believes
                  the range of scenarios used covers its plausible risks and uncertainties;
                </li>
                <li>
                  the key forces and drivers taken into consideration in each scenario and why these
                  are relevant to the undertaking, for example, policy assumptions, macroeconomic
                  trends, energy usage and mix, and technology assumptions; and
                </li>
                <li>
                  key inputs and constraints of the scenarios, including their level of detail
                  (e.g., whether the analysis of physical climate-related risks is based on
                  geospatial coordinates specific to the undertaking’s locations or national- or
                  regional-level broad data).
                </li>
              </ol>
              <p className="question-tip">
                When conducting scenario analysis, the undertaking may consider the following
                guidance: TCFD Technical Supplement on “The Use of Scenario Analysis in Disclosure
                of Climate- Related Risks and Opportunities” (2017); TCFD “Guidance on Scenario
                Analysis for NonFinancial Companies” (2020); ISO 14091:2021 “Adaptation to climate
                change — Guidelines on vulnerability, impacts and risk assessment”; any other
                recognised industry standards such as NGFS (Network for Greening the Financial
                System); and EU, national, regional and local regulations.
              </p>
            </Typography>
          ),
          module: "Environment",
          boldPart: "20 b",
        },
        {
          id: "q-146",
          question:
            "When disclosing the information on the processes to identify and assess physical risks as required under paragraph 20 (b), the undertaking shall explain whether and how: (a) it has identified climate-related hazards (see table below) over the short-, medium-and long-term and screened whether its assets and business activities may be exposed to these hazards; ",
          tooltip: (
            <Typography>
              <p className="question-tip">
                When disclosing the information required under paragraphs 19, 20, 21, AR 10 and AR
                11, the undertaking shall explain how it has used climate-related scenario analysis
                that is commensurate to its circumstances to inform the identification and
                assessment of physical and transition risks and opportunities over the short-,
                medium- and long-term, including:
              </p>
              <ol className="question-tip" type="a">
                <li>
                  which scenarios were used, their sources and alignment with state-of-the-art
                  science;
                </li>
                <li>
                  narratives, time horizons, and endpoints used with a discussion of why it believes
                  the range of scenarios used covers its plausible risks and uncertainties;
                </li>
                <li>
                  the key forces and drivers taken into consideration in each scenario and why these
                  are relevant to the undertaking, for example, policy assumptions, macroeconomic
                  trends, energy usage and mix, and technology assumptions; and
                </li>
                <li>
                  key inputs and constraints of the scenarios, including their level of detail
                  (e.g., whether the analysis of physical climate-related risks is based on
                  geospatial coordinates specific to the undertaking’s locations or national- or
                  regional-level broad data).
                </li>
              </ol>
              <p className="question-tip">
                When conducting scenario analysis, the undertaking may consider the following
                guidance: TCFD Technical Supplement on “The Use of Scenario Analysis in Disclosure
                of Climate- Related Risks and Opportunities” (2017); TCFD “Guidance on Scenario
                Analysis for NonFinancial Companies” (2020); ISO 14091:2021 “Adaptation to climate
                change — Guidelines on vulnerability, impacts and risk assessment”; any other
                recognised industry standards such as NGFS (Network for Greening the Financial
                System); and EU, national, regional and local regulations.
              </p>
            </Typography>
          ),
          module: "Environment",
          boldPart: "AR 11a",
        },
        {
          id: "q-147",
          question:
            "When disclosing the information on the processes to identify and assess physical risks as required under paragraph 20 (b), the undertaking shall explain whether and how: (b) it has defined short-, medium- and long-term time horizons and how these definitions are linked to the expected lifetime of its assets, strategic planning horizons and capital allocation plans;",
          tooltip: (
            <Typography>
              <p className="question-tip">
                When disclosing the information required under paragraphs 19, 20, 21, AR 10 and AR
                11, the undertaking shall explain how it has used climate-related scenario analysis
                that is commensurate to its circumstances to inform the identification and
                assessment of physical and transition risks and opportunities over the short-,
                medium- and long-term, including:
              </p>
              <ol className="question-tip" type="a">
                <li>
                  which scenarios were used, their sources and alignment with state-of-the-art
                  science;
                </li>
                <li>
                  narratives, time horizons, and endpoints used with a discussion of why it believes
                  the range of scenarios used covers its plausible risks and uncertainties;
                </li>
                <li>
                  the key forces and drivers taken into consideration in each scenario and why these
                  are relevant to the undertaking, for example, policy assumptions, macroeconomic
                  trends, energy usage and mix, and technology assumptions; and
                </li>
                <li>
                  key inputs and constraints of the scenarios, including their level of detail
                  (e.g., whether the analysis of physical climate-related risks is based on
                  geospatial coordinates specific to the undertaking’s locations or national- or
                  regional-level broad data).
                </li>
              </ol>
              <p className="question-tip">
                When conducting scenario analysis, the undertaking may consider the following
                guidance: TCFD Technical Supplement on “The Use of Scenario Analysis in Disclosure
                of Climate- Related Risks and Opportunities” (2017); TCFD “Guidance on Scenario
                Analysis for NonFinancial Companies” (2020); ISO 14091:2021 “Adaptation to climate
                change — Guidelines on vulnerability, impacts and risk assessment”; any other
                recognised industry standards such as NGFS (Network for Greening the Financial
                System); and EU, national, regional and local regulations.
              </p>
            </Typography>
          ),
          module: "Environment",
          boldPart: "AR 11b",
        },
        {
          id: "q-148",
          question:
            "When disclosing the information on the processes to identify and assess physical risks as required under paragraph 20 (b), the undertaking shall explain whether and how: it has assessed the extent to which its assets and business activities may be exposed and are sensitive to the identified climate-related hazards, taking into consideration the likelihood, magnitude and duration of the hazards as well as the geospatial coordinates (such as Nomenclature of Territorial Units of Statistics- NUTS for the EU territory) specific to the undertaking’s locations and supply chains;",
          tooltip: (
            <Typography>
              <p className="question-tip">
                When disclosing the information required under paragraphs 19, 20, 21, AR 10 and AR
                11, the undertaking shall explain how it has used climate-related scenario analysis
                that is commensurate to its circumstances to inform the identification and
                assessment of physical and transition risks and opportunities over the short-,
                medium- and long-term, including:
              </p>
              <ol className="question-tip" type="a">
                <li>
                  which scenarios were used, their sources and alignment with state-of-the-art
                  science;
                </li>
                <li>
                  narratives, time horizons, and endpoints used with a discussion of why it believes
                  the range of scenarios used covers its plausible risks and uncertainties;
                </li>
                <li>
                  the key forces and drivers taken into consideration in each scenario and why these
                  are relevant to the undertaking, for example, policy assumptions, macroeconomic
                  trends, energy usage and mix, and technology assumptions; and
                </li>
                <li>
                  key inputs and constraints of the scenarios, including their level of detail
                  (e.g., whether the analysis of physical climate-related risks is based on
                  geospatial coordinates specific to the undertaking’s locations or national- or
                  regional-level broad data).
                </li>
              </ol>
              <p className="question-tip">
                When conducting scenario analysis, the undertaking may consider the following
                guidance: TCFD Technical Supplement on “The Use of Scenario Analysis in Disclosure
                of Climate- Related Risks and Opportunities” (2017); TCFD “Guidance on Scenario
                Analysis for NonFinancial Companies” (2020); ISO 14091:2021 “Adaptation to climate
                change — Guidelines on vulnerability, impacts and risk assessment”; any other
                recognised industry standards such as NGFS (Network for Greening the Financial
                System); and EU, national, regional and local regulations.
              </p>
            </Typography>
          ),
          module: "Environment",
          boldPart: "AR 11c",
        },
        {
          id: "q-149",
          question:
            "When disclosing the information on the processes to identify and assess physical risks as required under paragraph 20 (b), the undertaking shall explain whether and how:the identification of climate-related hazards and the assessment of exposure and sensitivity are informed by high emissions climate scenarios, which may, for example, be based on IPCC SSP5-8.5, relevant regional climate projections based on these emission scenarios, or NGFS (Network for Greening the Financial System) climate scenarios with high physical risk such as “Hot house world” or “Too little, too late”. For general requirements regarding climate-related scenario analysis see paragraphs 18, 19, AR 13 to AR 15.",
          tooltip: (
            <Typography>
              <p className="question-tip">
                When disclosing the information required under paragraphs 19, 20, 21, AR 10 and AR
                11, the undertaking shall explain how it has used climate-related scenario analysis
                that is commensurate to its circumstances to inform the identification and
                assessment of physical and transition risks and opportunities over the short-,
                medium- and long-term, including:
              </p>
              <ol className="question-tip" type="a">
                <li>
                  which scenarios were used, their sources and alignment with state-of-the-art
                  science;
                </li>
                <li>
                  narratives, time horizons, and endpoints used with a discussion of why it believes
                  the range of scenarios used covers its plausible risks and uncertainties;
                </li>
                <li>
                  the key forces and drivers taken into consideration in each scenario and why these
                  are relevant to the undertaking, for example, policy assumptions, macroeconomic
                  trends, energy usage and mix, and technology assumptions; and
                </li>
                <li>
                  key inputs and constraints of the scenarios, including their level of detail
                  (e.g., whether the analysis of physical climate-related risks is based on
                  geospatial coordinates specific to the undertaking’s locations or national- or
                  regional-level broad data).
                </li>
              </ol>
              <p className="question-tip">
                When conducting scenario analysis, the undertaking may consider the following
                guidance: TCFD Technical Supplement on “The Use of Scenario Analysis in Disclosure
                of Climate- Related Risks and Opportunities” (2017); TCFD “Guidance on Scenario
                Analysis for NonFinancial Companies” (2020); ISO 14091:2021 “Adaptation to climate
                change — Guidelines on vulnerability, impacts and risk assessment”; any other
                recognised industry standards such as NGFS (Network for Greening the Financial
                System); and EU, national, regional and local regulations.
              </p>
            </Typography>
          ),
          module: "Environment",
          boldPart: "AR 11d",
        },
        {
          id: "q-150",
          question:
            "The undertaking shall describe the process to identify and assess climate-related impacts, risks and opportunities. This description shall include its process in relation to: climate-related transition risks and opportunities in own operations and along the upstream and downstream value chain, in particular: i. the identification of climate-related transition events, considering at least a climate scenario in line with limiting global warming to 1.5°C with no or limited overshoot; and ii. the assessment of how its assets and business activities may be exposed to these climate-related transition events, creating gross transition risks or opportunities for the undertaking.",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "When disclosing the information required under paragraphs 19, 20, 21, AR 10 and AR
                11, the undertaking shall explain how it has used climate-related scenario analysis
                that is commensurate to its circumstances to inform the identification and
                assessment of physical and transition risks and opportunities over the short-,
                medium- and long-term, including: (a) (b)
              </p>
              <ol className="question-tip" type="a">
                <li>
                  which scenarios were used, their sources and alignment with state-of-the-art
                  science;
                </li>
                <li>
                  narratives, time horizons, and endpoints used with a discussion of why it believes
                  the range of scenarios used covers its plausible risks and uncertainties;
                </li>
                <li>
                  the key forces and drivers taken into consideration in each scenario and why these
                  are relevant to the undertaking, for example, policy assumptions, macroeconomic
                  trends, energy usage and mix, and technology assumptions; and
                </li>
                <li>
                  key inputs and constraints of the scenarios, including their level of detail
                  (e.g., whether the analysis of physical climate-related risks is based on
                  geospatial coordinates specific to the undertaking’s locations or national- or
                  regional-level broad data).
                </li>
              </ol>
              <p className="question-tip">
                When conducting scenario analysis, the undertaking may consider the following
                guidance: TCFD Technical Supplement on “The Use of Scenario Analysis in Disclosure
                of Climate- Related Risks and Opportunities” (2017); TCFD “Guidance on Scenario
                Analysis for NonFinancial Companies” (2020); ISO 14091:2021 “Adaptation to climate
                change — Guidelines on vulnerability, impacts and risk assessment”; any other
                recognised industry standards such as NGFS (Network for Greening the Financial
                System); and EU, national, regional and local regulations.
              </p>
            </Typography>
          ),

          module: "Environment",
          boldPart: "20 c",
        },
        {
          id: "q-151",
          question:
            "When disclosing the information on the processes to identify transition risks and opportunities as required under paragraph 20 (c), the undertaking shall explain whether and how it has:(a) identified transition events (see the table with examples below) over the short-, medium- and long-term and screened whether its assets and business activities may be exposed to these events. In case of transition risks and opportunities, what is considered long-term may cover more than 10 years and may be aligned with climate-related public policy goals;",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "When disclosing the information required under paragraphs 19, 20, 21, AR 10 and AR
                11, the undertaking shall explain how it has used climate-related scenario analysis
                that is commensurate to its circumstances to inform the identification and
                assessment of physical and transition risks and opportunities over the short-,
                medium- and long-term, including: (a) (b)
              </p>
              <ol className="question-tip" type="a">
                <li>
                  which scenarios were used, their sources and alignment with state-of-the-art
                  science;
                </li>
                <li>
                  narratives, time horizons, and endpoints used with a discussion of why it believes
                  the range of scenarios used covers its plausible risks and uncertainties;
                </li>
                <li>
                  the key forces and drivers taken into consideration in each scenario and why these
                  are relevant to the undertaking, for example, policy assumptions, macroeconomic
                  trends, energy usage and mix, and technology assumptions; and
                </li>
                <li>
                  key inputs and constraints of the scenarios, including their level of detail
                  (e.g., whether the analysis of physical climate-related risks is based on
                  geospatial coordinates specific to the undertaking’s locations or national- or
                  regional-level broad data).
                </li>
              </ol>
              <p className="question-tip">
                When conducting scenario analysis, the undertaking may consider the following
                guidance: TCFD Technical Supplement on “The Use of Scenario Analysis in Disclosure
                of Climate- Related Risks and Opportunities” (2017); TCFD “Guidance on Scenario
                Analysis for NonFinancial Companies” (2020); ISO 14091:2021 “Adaptation to climate
                change — Guidelines on vulnerability, impacts and risk assessment”; any other
                recognised industry standards such as NGFS (Network for Greening the Financial
                System); and EU, national, regional and local regulations.
              </p>
            </Typography>
          ),
          module: "Environment",
          boldPart: "AR 12a",
        },
        {
          id: "q-152",
          question:
            "When disclosing the information on the processes to identify transition risks and opportunities as required under paragraph 20 (c), the undertaking shall explain whether and how it has:(b) assessed the extent to which its assets and business activities may be exposed and are sensitive to the identified transition events, taking into consideration the likelihood, magnitude and duration of the transition events;",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "When disclosing the information required under paragraphs 19, 20, 21, AR 10 and AR
                11, the undertaking shall explain how it has used climate-related scenario analysis
                that is commensurate to its circumstances to inform the identification and
                assessment of physical and transition risks and opportunities over the short-,
                medium- and long-term, including: (a) (b)
              </p>
              <ol className="question-tip" type="a">
                <li>
                  which scenarios were used, their sources and alignment with state-of-the-art
                  science;
                </li>
                <li>
                  narratives, time horizons, and endpoints used with a discussion of why it believes
                  the range of scenarios used covers its plausible risks and uncertainties;
                </li>
                <li>
                  the key forces and drivers taken into consideration in each scenario and why these
                  are relevant to the undertaking, for example, policy assumptions, macroeconomic
                  trends, energy usage and mix, and technology assumptions; and
                </li>
                <li>
                  key inputs and constraints of the scenarios, including their level of detail
                  (e.g., whether the analysis of physical climate-related risks is based on
                  geospatial coordinates specific to the undertaking’s locations or national- or
                  regional-level broad data).
                </li>
              </ol>
              <p className="question-tip">
                When conducting scenario analysis, the undertaking may consider the following
                guidance: TCFD Technical Supplement on “The Use of Scenario Analysis in Disclosure
                of Climate- Related Risks and Opportunities” (2017); TCFD “Guidance on Scenario
                Analysis for NonFinancial Companies” (2020); ISO 14091:2021 “Adaptation to climate
                change — Guidelines on vulnerability, impacts and risk assessment”; any other
                recognised industry standards such as NGFS (Network for Greening the Financial
                System); and EU, national, regional and local regulations.
              </p>
            </Typography>
          ),
          module: "Environment",
          boldPart: "AR 12b",
        },
        {
          id: "q-153",
          question:
            "When disclosing the information on the processes to identify transition risks and opportunities as required under paragraph 20 (c), the undertaking shall explain whether and how it has:  c) informed the identification of transition events and the assessment of exposure by climate-related scenario analysis, considering at least a scenario consistent with the Paris Agreement and limiting climate change to 1.5°C, for example, based on scenarios of the International Energy Agency (Net zero Emissions by 2050, Sustainable Development Scenario, etc), or NGFS (Network for Greening the Financial System) climate scenarios. For the general requirements related to climate-related scenario analysis see paragraphs 18, 19, AR 13 to AR 15; and",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "When disclosing the information required under paragraphs 19, 20, 21, AR 10 and AR
                11, the undertaking shall explain how it has used climate-related scenario analysis
                that is commensurate to its circumstances to inform the identification and
                assessment of physical and transition risks and opportunities over the short-,
                medium- and long-term, including: (a) (b)
              </p>
              <ol className="question-tip" type="a">
                <li>
                  which scenarios were used, their sources and alignment with state-of-the-art
                  science;
                </li>
                <li>
                  narratives, time horizons, and endpoints used with a discussion of why it believes
                  the range of scenarios used covers its plausible risks and uncertainties;
                </li>
                <li>
                  the key forces and drivers taken into consideration in each scenario and why these
                  are relevant to the undertaking, for example, policy assumptions, macroeconomic
                  trends, energy usage and mix, and technology assumptions; and
                </li>
                <li>
                  key inputs and constraints of the scenarios, including their level of detail
                  (e.g., whether the analysis of physical climate-related risks is based on
                  geospatial coordinates specific to the undertaking’s locations or national- or
                  regional-level broad data).
                </li>
              </ol>
              <p className="question-tip">
                When conducting scenario analysis, the undertaking may consider the following
                guidance: TCFD Technical Supplement on “The Use of Scenario Analysis in Disclosure
                of Climate- Related Risks and Opportunities” (2017); TCFD “Guidance on Scenario
                Analysis for NonFinancial Companies” (2020); ISO 14091:2021 “Adaptation to climate
                change — Guidelines on vulnerability, impacts and risk assessment”; any other
                recognised industry standards such as NGFS (Network for Greening the Financial
                System); and EU, national, regional and local regulations.
              </p>
            </Typography>
          ),
          module: "Environment",
          boldPart: "AR 12c",
        },
        {
          id: "q-154",
          question:
            "When disclosing the information on the processes to identify transition risks and opportunities as required under paragraph 20 (c), the undertaking shall explain whether and how it has:  d) identified assets and business activities that are incompatible with or need significant efforts to be compatible with a transition to a climate-neutral economy (for example, due to significant locked-in GHG emissions or incompatibility with the requirements for Taxonomy-alignment under Commission Delegated Regulation (EU) 2021/2139).",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "When disclosing the information required under paragraphs 19, 20, 21, AR 10 and AR
                11, the undertaking shall explain how it has used climate-related scenario analysis
                that is commensurate to its circumstances to inform the identification and
                assessment of physical and transition risks and opportunities over the short-,
                medium- and long-term, including: (a) (b)
              </p>
              <ol className="question-tip" type="a">
                <li>
                  which scenarios were used, their sources and alignment with state-of-the-art
                  science;
                </li>
                <li>
                  narratives, time horizons, and endpoints used with a discussion of why it believes
                  the range of scenarios used covers its plausible risks and uncertainties;
                </li>
                <li>
                  the key forces and drivers taken into consideration in each scenario and why these
                  are relevant to the undertaking, for example, policy assumptions, macroeconomic
                  trends, energy usage and mix, and technology assumptions; and
                </li>
                <li>
                  key inputs and constraints of the scenarios, including their level of detail
                  (e.g., whether the analysis of physical climate-related risks is based on
                  geospatial coordinates specific to the undertaking’s locations or national- or
                  regional-level broad data).
                </li>
              </ol>
              <p className="question-tip">
                When conducting scenario analysis, the undertaking may consider the following
                guidance: TCFD Technical Supplement on “The Use of Scenario Analysis in Disclosure
                of Climate- Related Risks and Opportunities” (2017); TCFD “Guidance on Scenario
                Analysis for NonFinancial Companies” (2020); ISO 14091:2021 “Adaptation to climate
                change — Guidelines on vulnerability, impacts and risk assessment”; any other
                recognised industry standards such as NGFS (Network for Greening the Financial
                System); and EU, national, regional and local regulations.
              </p>
            </Typography>
          ),
          module: "Environment",
          boldPart: "AR 12d",
        },
        {
          id: "q-155",
          question:
            "When disclosing the information required under paragraphs 20 (b)and 20 (c) the undertaking shall explain how it has used climate-related scenario analysis, including a range of climate scenarios, to inform the identification and assessment of physical risks and transition risks and opportunities over the short-, medium- and long-term.",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "When disclosing the information required under paragraphs 19, 20, 21, AR 10 and AR
                11, the undertaking shall explain how it has used climate-related scenario analysis
                that is commensurate to its circumstances to inform the identification and
                assessment of physical and transition risks and opportunities over the short-,
                medium- and long-term, including: (a) (b)
              </p>
              <ol className="question-tip" type="a">
                <li>
                  which scenarios were used, their sources and alignment with state-of-the-art
                  science;
                </li>
                <li>
                  narratives, time horizons, and endpoints used with a discussion of why it believes
                  the range of scenarios used covers its plausible risks and uncertainties;
                </li>
                <li>
                  the key forces and drivers taken into consideration in each scenario and why these
                  are relevant to the undertaking, for example, policy assumptions, macroeconomic
                  trends, energy usage and mix, and technology assumptions; and
                </li>
                <li>
                  key inputs and constraints of the scenarios, including their level of detail
                  (e.g., whether the analysis of physical climate-related risks is based on
                  geospatial coordinates specific to the undertaking’s locations or national- or
                  regional-level broad data).
                </li>
              </ol>
              <p className="question-tip">
                When conducting scenario analysis, the undertaking may consider the following
                guidance: TCFD Technical Supplement on “The Use of Scenario Analysis in Disclosure
                of Climate- Related Risks and Opportunities” (2017); TCFD “Guidance on Scenario
                Analysis for NonFinancial Companies” (2020); ISO 14091:2021 “Adaptation to climate
                change — Guidelines on vulnerability, impacts and risk assessment”; any other
                recognised industry standards such as NGFS (Network for Greening the Financial
                System); and EU, national, regional and local regulations.
              </p>
            </Typography>
          ),
          module: "Environment",
          boldPart: "21",
        },
        {
          id: "q-156",
          question:
            "The undertaking shall briefly explain how the climate scenarios used are compatible with the critical climate-related assumptions made in the financial statements.",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "When disclosing the information required under paragraphs 19, 20, 21, AR 10 and AR
                11, the undertaking shall explain how it has used climate-related scenario analysis
                that is commensurate to its circumstances to inform the identification and
                assessment of physical and transition risks and opportunities over the short-,
                medium- and long-term, including: (a) (b)
              </p>
              <ol className="question-tip" type="a">
                <li>
                  which scenarios were used, their sources and alignment with state-of-the-art
                  science;
                </li>
                <li>
                  narratives, time horizons, and endpoints used with a discussion of why it believes
                  the range of scenarios used covers its plausible risks and uncertainties;
                </li>
                <li>
                  the key forces and drivers taken into consideration in each scenario and why these
                  are relevant to the undertaking, for example, policy assumptions, macroeconomic
                  trends, energy usage and mix, and technology assumptions; and
                </li>
                <li>
                  key inputs and constraints of the scenarios, including their level of detail
                  (e.g., whether the analysis of physical climate-related risks is based on
                  geospatial coordinates specific to the undertaking’s locations or national- or
                  regional-level broad data).
                </li>
              </ol>
              <p className="question-tip">
                When conducting scenario analysis, the undertaking may consider the following
                guidance: TCFD Technical Supplement on “The Use of Scenario Analysis in Disclosure
                of Climate- Related Risks and Opportunities” (2017); TCFD “Guidance on Scenario
                Analysis for NonFinancial Companies” (2020); ISO 14091:2021 “Adaptation to climate
                change — Guidelines on vulnerability, impacts and risk assessment”; any other
                recognised industry standards such as NGFS (Network for Greening the Financial
                System); and EU, national, regional and local regulations.
              </p>
            </Typography>
          ),
          module: "Environment",
          boldPart: "AR 15",
        },
        // Add more questions as needed
      ],
    "E1-2 – Policies related to climate change mitigation and adaptation": [
      {
        id: "q-157",
        question:
          "The disclosure required by paragraph 22 shall contain the information on the policies the undertaking has in place to manage its material impacts, risks and opportunities related to climate change mitigation and adaptation in accordance with ESRS 2 MDR-P Policies adopted to manage material sustainability matters.",
        tooltip: "",
        module: "Environment",
        boldPart: "24",
      },
      {
        id: "q-158",
        question:
          "The undertaking shall indicate whether and how its policies address the following areas: (a) climate change mitigation; (b) climate change adaptation; (c) energy efficiency; (d) renewable energy deployment; (e) other",
        tooltip: (
          <Typography>
            <p className="question-tip">
              When disclosing the information on the scope of the resilience analysis as required
              under paragraph 19 (a), the undertaking shall explain which part of its own operations
              and upstream and downstream value chain as well as which material physical risks and
              transition risks may have been excluded from the analysis.
            </p>
          </Typography>
        ),
        module: "Environment",
        boldPart: "25",
      },
      {
        id: "q-159",
        question:
          "The undertaking shall disclose information about policies adopted to manage material sustainability matters. The disclosure shall include the following information: (a) a description of the key contents of the policy, including its general objectives and which material impacts, risks or opportunities the policy relates to and the process for monitoring;",
        tooltip: "",
        module: "Environment",
        condition: "yes",
        boldPart: "65 a",
      },
      {
        id: "q-160",
        question:
          "a description of the scope of the policy, or of its exclusions, in terms of activities, upstream and/or downstream value chain, geographies and if relevant, affected stakeholder groups;",
        tooltip: "",
        module: "Environment",
        condition: "yes",
        boldPart: "65 b",
      },
      {
        id: "q-161",
        question:
          "the most senior level in the undertaking’s organisation that is accountable for the implementation of the policy;",
        tooltip: "",
        module: "Environment",
        condition: "yes",
        boldPart: "65 c",
      },
      {
        id: "q-162",
        question:
          "a reference, if relevant, to the third-party standards or initiatives the undertaking commits to respect through the implementation of the policy; ",
        tooltip: "",
        module: "Environment",
        condition: "yes",
        boldPart: "65 d",
      },
      {
        id: "q-163",
        question:
          "if relevant, a description of the consideration given to the interests of key stakeholders in setting the policy; and ",
        tooltip: "",
        module: "Environment",
        condition: "yes",
        boldPart: "65 e",
      },
      {
        id: "q-164",
        question:
          "if relevant, whether and how the undertaking makes the policy available to potentially affected stakeholders, and stakeholders who need to help implement it. ",
        tooltip: "",
        module: "Environment",
        condition: "yes",
        boldPart: "65 f",
      },
      {
        id: "q-165",
        question:
          "If the undertaking cannot disclose the information on policies and actions required under relevant ESRS, because it has not adopted policies and/or actions with reference to the specific sustainability matter concerned, it shall disclose this to be the case, and provide reasons for not having adopted policies and/or actions. ",
        tooltip: "",
        module: "Environment",
        condition: "no",
        boldPart: "62",
      },
      {
        id: "q-166",
        question: "The undertaking may disclose a timeframe in which it aims to adopt them. ",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        condition: "no",
        boldPart: "62",
      },

      // Add more questions as needed
    ],
    "E1-3 – Actions and resources in relation to climate change policies": [
      {
        id: "q-167",
        question:
          "The undertaking shall disclose its climate change mitigation and adaptation actions and the resources allocated for their implementation.",
        tooltip: "",
        module: "Environment",
        boldPart: "28, 29a, 29b",
      },
      {
        id: "q-168",
        question:
          "When disclosing the information on actions as required under paragraphs 29 (a) and 29 (b), the undertaking may: disclose the climate change adaptation actions by type of adaptation solution such as nature-based adaptation, engineering, or technological solutions. ",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 19d",
      },
      {
        id: "q-169",
        question:
          "In line with the requirements of ESRS 2 MDR-A, the undertaking shall explain if and to what extent its ability to implement the actions depends on the availability and allocation of resources. Ongoing access to finance at an affordable cost of capital can be critical for the implementation of the undertaking’s actions, which include its adjustments to supply/demand changes or its related acquisitions and significant research and development (R&D) investments.",
        tooltip: "",
        module: "Environment",
        boldPart: "AR 21",
      },
      {
        id: "q-170",
        question:
          "In addition to ESRS 2 MDR-A, the undertaking shall: (c) relate significant monetary amounts of CapEx and OpEx required to implement the actions taken or planned to: i. the relevant line items or notes in the financial statements;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              When disclosing the information on resources as required under paragraph 29 (c), the
              undertaking shall only disclose the significant OpEx and CapEx amounts required for
              the implementation of the actions as the purpose of this information is to demonstrate
              the credibility of its actions rather than to reconcile the disclosed amounts to the
              financial statements. The disclosed CapEx and OpEx amounts shall be the additions made
              to both tangible and intangible assets during the current financial year as well as
              the planned additions for future periods of implementing the actions. The disclosed
              amounts shall only be the incremental financial investments directly contributing to
              the achievement of the undertaking’s targets.
            </p>
          </Typography>
        ),

        module: "Environment",
        boldPart: "29ci",
      },
      {
        id: "q-171",
        question:
          "In addition to ESRS 2 MDR-A, the undertaking shall: (c) relate significant monetary amounts of CapEx and OpEx required to implement the actions taken or planned to: ii. the key performance indicators required under Commission Delegated Regulation (EU) 2021/2178; and",
        tooltip: (
          <Typography>
            <p className="question-tip">
              When disclosing the information on resources as required under paragraph 29 (c), the
              undertaking shall only disclose the significant OpEx and CapEx amounts required for
              the implementation of the actions as the purpose of this information is to demonstrate
              the credibility of its actions rather than to reconcile the disclosed amounts to the
              financial statements. The disclosed CapEx and OpEx amounts shall be the additions made
              to both tangible and intangible assets during the current financial year as well as
              the planned additions for future periods of implementing the actions. The disclosed
              amounts shall only be the incremental financial investments directly contributing to
              the achievement of the undertaking’s targets.
            </p>
          </Typography>
        ),

        module: "Environment",
        boldPart: "29cii,16c",
      },
      {
        id: "q-172",
        question:
          "In addition to ESRS 2 MDR-A, the undertaking shall: (c) relate significant monetary amounts of CapEx and OpEx required to implement the actions taken or planned to: iii. if applicable, the CapEx plan required by Commission Delegated Regulation (EU) 2021/2178.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              When disclosing the information on resources as required under paragraph 29 (c), the
              undertaking shall only disclose the significant OpEx and CapEx amounts required for
              the implementation of the actions as the purpose of this information is to demonstrate
              the credibility of its actions rather than to reconcile the disclosed amounts to the
              financial statements. The disclosed CapEx and OpEx amounts shall be the additions made
              to both tangible and intangible assets during the current financial year as well as
              the planned additions for future periods of implementing the actions. The disclosed
              amounts shall only be the incremental financial investments directly contributing to
              the achievement of the undertaking’s targets.
            </p>
          </Typography>
        ),

        module: "Environment",
        boldPart: "29ciii,16c",
      },
      {
        id: "q-173",
        question:
          "The amounts of OpEx and CapEx required for the implementation of the actions disclosed under paragraph 29 (c) shall be consistent with the key performance indicators (CapEx and OpEx key performance indicators) and, if applicable, the CapEx plan required by Commission Delegated Regulation (EU) 2021/2178. The undertaking shall explain any potential differences between the significant OpEx and CapEx amounts disclosed under this Standard and the key performance indicators disclosed under Commission Delegated Regulation (EU) 2021/2178 due to, for instance, the disclosure of non-eligible economic activities as defined in that delegated regulation. The undertaking may structure its actions by economic activity to compare its OpEx and CapEx, and if applicable its OpEx and/or CapEx plans to its Taxonomy-aligned key performance indicators.",
        tooltip: "",
        module: "Environment",
        boldPart: "AR 22",
      },
      {
        id: "q-174",
        question:
          "Where the implementation of a policy requires actions, or a comprehensive action plan, to achieve its objectives, as well as when actions are implemented without a specific policy, the undertaking shall disclose the following information: (a) the list of key actions taken in the reporting year and planned for the future, their expected outcomes and, where relevant, how their implementation contributes to the achievement of policy objectives and targets; ",
        tooltip:
          "AR 22. Key actions in the context of this Minimum Disclosure Requirement are those actions that materially contribute to achieving the undertaking’s objectives in addressing material impacts, risks and opportunities. oor reasons of understandability, key actions may be aggregated where appropriate.",
        module: "Environment",
        condition: "yes",
        boldPart: "68 a",
      },
      {
        id: "q-175",
        question:
          "the scope of the key actions (i.e., coverage in terms of activities, upstream and/or downstream value chain, geographies and, where applicable, affected stakeholder groups); ",
        tooltip: "",
        module: "Environment",
        condition: "yes",
        boldPart: "68 b",
      },
      {
        id: "q-176",
        question:
          "the time horizons under which the undertaking intends to complete each key action; ",
        tooltip: "",
        module: "Environment",
        condition: "yes",
        boldPart: "68 c",
      },
      {
        id: "q-177",
        question:
          "if applicable, key actions taken (along with results) to provide for and cooperate in or support the provision of remedy for those harmed by actual material impacts;",
        tooltip: "",
        module: "Environment",
        condition: "yes",
        boldPart: "68 d",
      },
      {
        id: "q-178",
        question:
          "if applicable, quantitative and qualitative information regarding the progress of actions or action plans disclosed in prior periods.",
        tooltip: "",
        module: "Environment",
        condition: "yes",
        boldPart: "68 e",
      },
      {
        id: "q-179",
        question:
          "Where the implementation of an action plan requires significant operational expenditures (Opex) and/or capital expenditures (Capex) the undertaking shall: (a) describe the type of current and future financial and other resources allocated to the action plan, including if applicable, the relevant terms of sustainable finance instruments, such as green bonds, social bonds and green loans, the environmental or social objectives, and whether the ability to implement the actions or action plan depends on specific preconditions, e.g., granting of financial support or public policy and market developments; ",
        tooltip:
          "AR 23. Information on resource allocation may be presented in the form of a table and broken down between capital expenditure and operating expenditure, and across the relevant time horizons, and between resources applied in the current reporting year and the planned allocation of resources over specific time horizons.",
        module: "Environment",
        condition: "yes",
        boldPart: "69 a",
      },
      {
        id: "q-180",
        question:
          "provide the amount of current financial resources and explain how they relate to the most relevant amounts presented in the financial statements; and (c) provide the amount of future financial resources. ",
        tooltip:
          "AR 23. Information on resource allocation may be presented in the form of a table and broken down between capital expenditure and operating expenditure, and across the relevant time horizons, and between resources applied in the current reporting year and the planned allocation of resources over specific time horizons.",
        module: "Environment",
        condition: "yes",
        boldPart: "69 b",
      },
      {
        id: "q-181",
        question:
          "If the undertaking cannot disclose the information on policies and actions required under relevant ESRS, because it has not adopted policies and/or actions with reference to the specific sustainability matter concerned, it shall disclose this to be the case, and provide reasons for not having adopted policies and/or actions. ",
        tooltip: "",
        module: "Environment",
        condition: "no",
        boldPart: "62",
      },
      {
        id: "q-182",
        question: "The undertaking may disclose a timeframe in which it aims to adopt them. ",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        condition: "no",
        boldPart: "62",
      },
      // Add more questions as needed
    ],
    "E1-4 – Targets related to climate change mitigation and adaptation": [
      {
        id: "q-183",
        question:
          "The disclosure of the targets required in paragraph 30 shall contain the information required in ESRS 2 MDR-T Tracking effectiveness of policies and actions through targets.",
        tooltip: "",
        module: "Environment",
        boldPart: "32",
      },
      {
        id: "q-184",
        question:
          "For the disclosure required by paragraph 30, the undertaking shall disclose whether and how it has set GHG emissions reduction targets and/or any other targets to manage material climate-related impacts, risks and opportunities, for example, renewable energy deployment, energy efficiency, climate change adaptation, and physical or transition risk mitigation.",
        tooltip: "",
        module: "Environment",
        boldPart: "33",
      },
      {
        id: "q-185",
        question: "Table :E1-4 – Targets related to climate change mitigation and adaptation",
        tooltip: (
          <Typography>
            <p className="question-tip">
              The total energy consumption with a distinction between fossil, nuclear and renewable
              energy consumption may be presented graphically in the sustainability statement
              showing developments over time (e.g., through a pie or bar chart).
              <br />
              The information required under paragraph 37 (a) is applicable if the undertaking is
              operating in at least one high climate impact sector. The information required under
              paragraph 38 (a) to (e). shall also include energy from fossil sources consumed in
              operations that are not in high climate impact sectors.
            </p>
          </Typography>
        ),
        table: (
          <table className="question-table">
            <thead>
              <tr>
                <th>E1-4 – Targets related to climate change mitigation and adaptation</th>
                <th>Base year (e.g., 2025)</th>
                <th>2030 target</th>
                <th>2035 target</th>
                <th>Up to 2050 target</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>GHG emissions (ktCO2eq)</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_4-ghgEmissions-baseYear"
                    value={
                      answers2["e1_4-ghgEmissions-baseYear"] ||
                      inputValues.e1_4.ghgEmissions.baseYear
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_4-ghgEmissions-target2030"
                    value={
                      answers2["e1_4-ghgEmissions-target2030"] ||
                      inputValues.e1_4.ghgEmissions.target2030
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_4-ghgEmissions-target2035"
                    value={
                      answers2["e1_4-ghgEmissions-target2035"] ||
                      inputValues.e1_4.ghgEmissions.target2035
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_4-ghgEmissions-target2050"
                    value={
                      answers2["e1_4-ghgEmissions-target2050"] ||
                      inputValues.e1_4.ghgEmissions.target2050
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Energy efficiency and consumption reduction</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_4-energyEfficiency-baseYear"
                    value={
                      answers2["e1_4-energyEfficiency-baseYear"] ||
                      inputValues.e1_4.energyEfficiency.baseYear
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_4-energyEfficiency-target2030"
                    value={
                      answers2["e1_4-energyEfficiency-target2030"] ||
                      inputValues.e1_4.energyEfficiency.target2030
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_4-energyEfficiency-target2035"
                    value={
                      answers2["e1_4-energyEfficiency-target2035"] ||
                      inputValues.e1_4.energyEfficiency.target2035
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_4-energyEfficiency-target2050"
                    value={
                      answers2["e1_4-energyEfficiency-target2050"] ||
                      inputValues.e1_4.energyEfficiency.target2050
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Material efficiency and consumption reduction</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_4-materialEfficiency-baseYear"
                    value={
                      answers2["e1_4-materialEfficiency-baseYear"] ||
                      inputValues.e1_4.materialEfficiency.baseYear
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_4-materialEfficiency-target2030"
                    value={
                      answers2["e1_4-materialEfficiency-target2030"] ||
                      inputValues.e1_4.materialEfficiency.target2030
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_4-materialEfficiency-target2035"
                    value={
                      answers2["e1_4-materialEfficiency-target2035"] ||
                      inputValues.e1_4.materialEfficiency.target2035
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_4-materialEfficiency-target2050"
                    value={
                      answers2["e1_4-materialEfficiency-target2050"] ||
                      inputValues.e1_4.materialEfficiency.target2050
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Fuel switching</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_4-fuelSwitching-baseYear"
                    value={
                      answers2["e1_4-fuelSwitching-baseYear"] ||
                      inputValues.e1_4.fuelSwitching.baseYear
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_4-fuelSwitching-target2030"
                    value={
                      answers2["e1_4-fuelSwitching-target2030"] ||
                      inputValues.e1_4.fuelSwitching.target2030
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_4-fuelSwitching-target2035"
                    value={
                      answers2["e1_4-fuelSwitching-target2035"] ||
                      inputValues.e1_4.fuelSwitching.target2035
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_4-fuelSwitching-target2050"
                    value={
                      answers2["e1_4-fuelSwitching-target2050"] ||
                      inputValues.e1_4.fuelSwitching.target2050
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Electrification</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_4-electrification-baseYear"
                    value={
                      answers2["e1_4-electrification-baseYear"] ||
                      inputValues.e1_4.electrification.baseYear
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_4-electrification-target2030"
                    value={
                      answers2["e1_4-electrification-target2030"] ||
                      inputValues.e1_4.electrification.target2030
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_4-electrification-target2035"
                    value={
                      answers2["e1_4-electrification-target2035"] ||
                      inputValues.e1_4.electrification.target2035
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_4-electrification-target2050"
                    value={
                      answers2["e1_4-electrification-target2050"] ||
                      inputValues.e1_4.electrification.target2050
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Use of renewable energy</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_4-renewableEnergy-baseYear"
                    value={
                      answers2["e1_4-renewableEnergy-baseYear"] ||
                      inputValues.e1_4.renewableEnergy.baseYear
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_4-renewableEnergy-target2030"
                    value={
                      answers2["e1_4-renewableEnergy-target2030"] ||
                      inputValues.e1_4.renewableEnergy.target2030
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_4-renewableEnergy-target2035"
                    value={
                      answers2["e1_4-renewableEnergy-target2035"] ||
                      inputValues.e1_4.renewableEnergy.target2035
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_4-renewableEnergy-target2050"
                    value={
                      answers2["e1_4-renewableEnergy-target2050"] ||
                      inputValues.e1_4.renewableEnergy.target2050
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Phase out, substitution or modification of product</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_4-productModification-baseYear"
                    value={
                      answers2["e1_4-productModification-baseYear"] ||
                      inputValues.e1_4.productModification.baseYear
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_4-productModification-target2030"
                    value={
                      answers2["e1_4-productModification-target2030"] ||
                      inputValues.e1_4.productModification.target2030
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_4-productModification-target2035"
                    value={
                      answers2["e1_4-productModification-target2035"] ||
                      inputValues.e1_4.productModification.target2035
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_4-productModification-target2050"
                    value={
                      answers2["e1_4-productModification-target2050"] ||
                      inputValues.e1_4.productModification.target2050
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Phase out, substitution or modification of process</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_4-processModification-baseYear"
                    value={
                      answers2["e1_4-processModification-baseYear"] ||
                      inputValues.e1_4.processModification.baseYear
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_4-processModification-target2030"
                    value={
                      answers2["e1_4-processModification-target2030"] ||
                      inputValues.e1_4.processModification.target2030
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_4-processModification-target2035"
                    value={
                      answers2["e1_4-processModification-target2035"] ||
                      inputValues.e1_4.processModification.target2035
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_4-processModification-target2050"
                    value={
                      answers2["e1_4-processModification-target2050"] ||
                      inputValues.e1_4.processModification.target2050
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Other</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_4-other-baseYear"
                    value={answers2["e1_4-other-baseYear"] || inputValues.e1_4.other.baseYear}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_4-other-target2030"
                    value={answers2["e1_4-other-target2030"] || inputValues.e1_4.other.target2030}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_4-other-target2035"
                    value={answers2["e1_4-other-target2035"] || inputValues.e1_4.other.target2035}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_4-other-target2050"
                    value={answers2["e1_4-other-target2050"] || inputValues.e1_4.other.target2050}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              {/* Add more rows as needed */}
            </tbody>
          </table>
        ),
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 4 Table #2 of Annex 1
            </p>
            <p className="question-tip">
              <strong>
                Pillar 3<sup>24</sup> reference:
              </strong>{" "}
              Article 449a Regulation (EU) No 575/2013; Commission Implementing Regulation (EU)
              2022/2453 Template 3: Banking book – Climate change transition risk: alignment metrics
            </p>
            <p className="question-tip">
              <strong>
                Benchmark Regulation<sup>25</sup> reference:
              </strong>{" "}
              Delegated Regulation (EU) 2020/1818, Article 6
            </p>
          </Typography>
        ),
        module: "Environment",
        boldPart: "34a + 34 b",
        fun: "5Column",
      },
      {
        id: "q-186",
        question:
          "The undertaking shall explain how the consistency of these targets with its GHG inventory boundaries is ensured (as required by Disclosure Requirement E1-6). The GHG emission reduction targets shall be gross targets, meaning that the undertaking shall not include GHG removals, carbon credits or avoided emissions as a means of achieving the GHG emission reduction targets;",
        tooltip: "",
        module: "Environment",
        boldPart: "34b",
      },
      {
        id: "q-187",
        question:
          "The undertaking shall disclose its current base year and baseline value, and from 2030 onwards, update the base year for its GHG emission reduction targets after every five-year period thereafter. The undertaking may disclose the past progress made in meeting its targets before its current base year provided that this information is consistent with the requirements of this Standard;",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        boldPart: "34c",
      },
      {
        id: "q-188",
        question:
          "When disclosing the information required under paragraph 34(c) on base year and baseline value: (a) the undertaking shall briefly explain how it has ensured that the baseline value against which the progress towards the target is measured is representative in terms of the activities covered and the influences from external factors (e.g., temperature anomalies in a certain year influencing the amount of energy consumption and related GHG emissions). This can be done by the normalisation of the baseline value, or, by using a baseline value that is derived from a 3-year average if this increases the representativeness and allows a more faithful representation;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              When disclosing the information required under paragraph 34(c) on base year and
              baseline value: (a) the undertaking shall briefly explain how it has ensured that the
              baseline value against which the progress towards the target is measured is
              representative in terms of the activities covered and the influences from external
              factors (e.g., temperature anomalies in a certain year influencing the amount of
              energy consumption and related GHG emissions). This can be done by the normalisation
              of the baseline value, or, by using a baseline value that is derived from a 3-year
              average if this increases the representativeness and allows a more faithful
              representation;
            </p>
          </Typography>
        ),
        module: "Environment",
        boldPart: "AR 25 a",
      },
      {
        id: "q-189",
        question:
          "When disclosing the information required under paragraph 34(c) on base year and baseline value: the baseline value and base year shall not be changed unless significant changes in either the target or reporting boundary occur. In such a case, the undertaking shall explain how the new baseline value affects the new target, its achievement and presentation of progress over time. To foster comparability, when setting new targets, the undertaking shall select a recent base year that does not precede the first reporting year of the new target period by longer than 3 years. For example, for 2030 as the target year and a target period between 2025 and 2030, the base year shall be selected from the period between 2022 and 2025;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              The baseline value and base year shall not be changed unless significant changes in
              either the target or reporting boundary occur. In such a case, the undertaking shall
              explain how the new baseline value affects the new target, its achievement and
              presentation of progress over time. To foster comparability, when setting new targets,
              the undertaking shall select a recent base year that does not precede the first
              reporting year of the new target period by longer than 3 years. For example, for 2030
              as the target year and a target period between 2025 and 2030, the base year shall be
              selected from the period between 2022 and 2025.
            </p>
          </Typography>
        ),

        module: "Environment",
        boldPart: "AR 25 b",
      },
      {
        id: "q-190",
        question:
          "When disclosing the information required under paragraph 34 (f), the undertaking shall explain: whether and how it has considered a diverse range of climate scenarios, at least including a climate scenario compatible with limiting global warming to 1.5°C, to detect relevant environmental-, societal-, technology-, market- and policy-related developments and determine its decarbonisation levers. ",
        tooltip: "",
        module: "Environment",
        boldPart: "AR 30 c",
      },
      {
        id: "q-191",
        question:
          "The undertaking shall disclose the measurable, outcome-oriented and time-bound targets on material sustainability matters it has set to assess progress. oor each target, the disclosure shall include the following information: (a) a description of the relationship of the target to the policy objectives;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy.
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 a",
      },
      {
        id: "q-192",
        question:
          "the defined target level to be achieved, including, where applicable, whether the target is absolute or relative and in which unit it is measured;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy.
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 b",
      },
      {
        id: "q-193",
        question:
          "the scope of the target, including the undertaking’s activities and/or its upstream and/or downstream value chain where applicable and geographical boundaries;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy.
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 c",
      },
      {
        id: "q-194",
        question: "the baseline value and base year from which progress is measured;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy.
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 d",
      },
      {
        id: "q-195",
        question:
          "the period to which the target applies and if applicable, any milestones or interim targets;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy.
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 e",
      },
      {
        id: "q-196",
        question:
          "the methodologies and significant assumptions used to define targets, including where applicable, the selected scenario, data sources, alignment with national, EU or international policy goals and how the targets consider the wider context of sustainable development and/or local situation in which impacts take place;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy.
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 f",
      },
      {
        id: "q-197",
        question:
          "whether the undertaking’s targets related to environmental matters are based on conclusive scientific evidence;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy.
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 g",
      },
      {
        id: "q-198",
        question:
          "whether and how stakeholders have been involved in target setting for each material sustainability matter;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy.
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 h",
      },
      {
        id: "q-199",
        question:
          "any changes in targets and corresponding metrics or underlying measurement methodologies, significant assumptions, limitations, sources and processes to collect data adopted within the defined 17 time horizon. This includes an explanation of the rationale for those changes and their effect on comparability (see Disclosure Requirement BP-2 Disclosures in relation to specific circumstances of this Standard); and",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy.
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 i",
      },
      {
        id: "q-200",
        question:
          "the performance against its disclosed targets, including information on how the target is monitored and reviewed and the metrics used, whether the progress is in line with what had been initially planned, and an analysis of trends or significant changes in the performance of the undertaking towards achieving the target.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy.
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 j",
      },
      {
        id: "q-201",
        question:
          "If the undertaking has not set any measurable outcome-oriented targets: (a) it may disclose whether such targets will be set and the timeframe for setting them, or the reasons why the undertaking does not plan to set such targets;",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        condition: "no",
        boldPart: "81 a",
      },
      {
        id: "q-202",
        question:
          "it shall disclose whether it nevertheless tracks the effectiveness of its policies and actions in relation to the material sustainability-related impact, risk and opportunity, and if so: i. any processes through which it does so; ii. the defined level of ambition to be achieved and any qualitative or quantitative indicators it uses to evaluate progress, including the base period from which progress is measured. ",
        tooltip: "",
        module: "Environment",
        condition: "no",
        boldPart: "81 b",
      },
      // Add more questions as needed
    ],
    "E1-5 – Energy consumption and mix": [
      {
        id: "q-203",
        question: " E1-5 – Energy consumption and mix",
        tooltip: (
          <Typography>
            <p className="question-tip">
              The total energy consumption with a distinction between fossil, nuclear and renewable
              energy consumption may be presented graphically in the sustainability statement
              showing developments over time (e.g., through a pie or bar chart).
            </p>
          </Typography>
        ),
        table: (
          <table className="question-table">
            <thead>
              <tr>
                <th>Energy consumption and mix</th>
                <th>Comparative</th>
                <th>Year N</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>(1) Fuel consumption from coal and coal products (MWh)</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_5_1-coalProducts-comparative"
                    value={
                      answers2["e1_5_1-coalProducts-comparative"] ||
                      inputValues.e1_5_1.coalProducts.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_5_1-coalProducts-yearN"
                    value={
                      answers2["e1_5_1-coalProducts-yearN"] || inputValues.e1_5_1.coalProducts.yearN
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>(2) Fuel consumption from crude oil and petroleum products (MWh)</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_5_1-crudeOil-comparative"
                    value={
                      answers2["e1_5_1-crudeOil-comparative"] ||
                      inputValues.e1_5_1.crudeOil.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_5_1-crudeOil-yearN"
                    value={answers2["e1_5_1-crudeOil-yearN"] || inputValues.e1_5_1.crudeOil.yearN}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>(3) Fuel consumption from natural gas (MWh)</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_5_1-naturalGas-comparative"
                    value={
                      answers2["e1_5_1-naturalGas-comparative"] ||
                      inputValues.e1_5_1.naturalGas.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_5_1-naturalGas-yearN"
                    value={
                      answers2["e1_5_1-naturalGas-yearN"] || inputValues.e1_5_1.naturalGas.yearN
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>(4) Fuel consumption from other fossil sources (MWh)</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_5_1-otherFossil-comparative"
                    value={
                      answers2["e1_5_1-otherFossil-comparative"] ||
                      inputValues.e1_5_1.otherFossil.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_5_1-otherFossil-yearN"
                    value={
                      answers2["e1_5_1-otherFossil-yearN"] || inputValues.e1_5_1.otherFossil.yearN
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  (5) Consumption of purchased or acquired electricity, heat, steam, and cooling
                  from fossil sources (MWh)
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_5_1-fossilElectricity-comparative"
                    value={
                      answers2["e1_5_1-fossilElectricity-comparative"] ||
                      inputValues.e1_5_1.fossilElectricity.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_5_1-fossilElectricity-yearN"
                    value={
                      answers2["e1_5_1-fossilElectricity-yearN"] ||
                      inputValues.e1_5_1.fossilElectricity.yearN
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  (6) Total fossil energy consumption (MWh) (calculated as the sum of lines 1 to 5)
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_5_1-totalFossil-comparative"
                    value={
                      answers2["e1_5_1-totalFossil-comparative"] ||
                      inputValues.e1_5_1.totalFossil.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_5_1-totalFossil-yearN"
                    value={
                      answers2["e1_5_1-totalFossil-yearN"] || inputValues.e1_5_1.totalFossil.yearN
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Share of fossil sources in total energy consumption (%)</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_5_1-fossilShare-comparative"
                    value={
                      answers2["e1_5_1-fossilShare-comparative"] ||
                      inputValues.e1_5_1.fossilShare.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_5_1-fossilShare-yearN"
                    value={
                      answers2["e1_5_1-fossilShare-yearN"] || inputValues.e1_5_1.fossilShare.yearN
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>(7) Consumption from nuclear sources (MWh)</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_5_1-nuclear-comparative"
                    value={
                      answers2["e1_5_1-nuclear-comparative"] ||
                      inputValues.e1_5_1.nuclear.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_5_1-nuclear-yearN"
                    value={answers2["e1_5_1-nuclear-yearN"] || inputValues.e1_5_1.nuclear.yearN}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Share of consumption from nuclear sources in total energy consumption (%)</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_5_1-nuclearShare-comparative"
                    value={
                      answers2["e1_5_1-nuclearShare-comparative"] ||
                      inputValues.e1_5_1.nuclearShare.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_5_1-nuclearShare-yearN"
                    value={
                      answers2["e1_5_1-nuclearShare-yearN"] || inputValues.e1_5_1.nuclearShare.yearN
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  (8) Fuel consumption for renewable sources, including biomass (also comprising
                  industrial and municipal waste of biologic origin, biogas, renewable hydrogen,
                  etc.) (MWh)
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_5_1-renewablesBiomass-comparative"
                    value={
                      answers2["e1_5_1-renewablesBiomass-comparative"] ||
                      inputValues.e1_5_1.renewablesBiomass.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_5_1-renewablesBiomass-yearN"
                    value={
                      answers2["e1_5_1-renewablesBiomass-yearN"] ||
                      inputValues.e1_5_1.renewablesBiomass.yearN
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  (9) Consumption of purchased or acquired electricity, heat, steam, and cooling
                  from renewable sources (MWh)
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_5_1-renewablesElectricity-comparative"
                    value={
                      answers2["e1_5_1-renewablesElectricity-comparative"] ||
                      inputValues.e1_5_1.renewablesElectricity.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_5_1-renewablesElectricity-yearN"
                    value={
                      answers2["e1_5_1-renewablesElectricity-yearN"] ||
                      inputValues.e1_5_1.renewablesElectricity.yearN
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>(10) The consumption of self-generated non-fuel renewable energy (MWh)</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_5_1-selfGeneratedRenewables-comparative"
                    value={
                      answers2["e1_5_1-selfGeneratedRenewables-comparative"] ||
                      inputValues.e1_5_1.selfGeneratedRenewables.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_5_1-selfGeneratedRenewables-yearN"
                    value={
                      answers2["e1_5_1-selfGeneratedRenewables-yearN"] ||
                      inputValues.e1_5_1.selfGeneratedRenewables.yearN
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  (11) Total renewable energy consumption (MWh) (calculated as the sum of lines 8 to
                  10)
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_5_1-totalRenewables-comparative"
                    value={
                      answers2["e1_5_1-totalRenewables-comparative"] ||
                      inputValues.e1_5_1.totalRenewables.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_5_1-totalRenewables-yearN"
                    value={
                      answers2["e1_5_1-totalRenewables-yearN"] ||
                      inputValues.e1_5_1.totalRenewables.yearN
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Share of renewable sources in total energy consumption (%)</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_5_1-renewablesShare-comparative"
                    value={
                      answers2["e1_5_1-renewablesShare-comparative"] ||
                      inputValues.e1_5_1.renewablesShare.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_5_1-renewablesShare-yearN"
                    value={
                      answers2["e1_5_1-renewablesShare-yearN"] ||
                      inputValues.e1_5_1.renewablesShare.yearN
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Total energy consumption (MWh) (calculated as the sum of lines 6, and 11)</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_5_1-totalEnergy-comparative"
                    value={
                      answers2["e1_5_1-totalEnergy-comparative"] ||
                      inputValues.e1_5_1.totalEnergy.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_5_1-totalEnergy-yearN"
                    value={
                      answers2["e1_5_1-totalEnergy-yearN"] || inputValues.e1_5_1.totalEnergy.yearN
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        ),
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 4 Table #2 of Annex 1 Indicator
              number 5 Table #1 and Indicator n. 5 Table #2 of Annex 1
            </p>
          </Typography>
        ),
        module: "Environment",
        boldPart: "37-42",
        fun: "3Column",
      },
      {
        id: "q-204",
        question: " Table: Energy intensity based on net revenue",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "When preparing the information on energy intensity required under paragraph 40, the
              undertaking shall: <br />
              (a) calculate the energy intensity ratio using the following formula: <br />
              (𝑇𝑜𝑡𝑎𝑙 𝑒𝑛𝑒𝑟𝑔𝑦 𝑐𝑜𝑛𝑠𝑢𝑚𝑝𝑡𝑖𝑜𝑛 𝑓𝑟𝑜𝑚 𝑎𝑐𝑡𝑖𝑣𝑖𝑡𝑖𝑒𝑠 𝑖𝑛 ℎ𝑖𝑔ℎ 𝑐𝑙𝑖𝑚𝑎𝑡𝑒 𝑖𝑚𝑝𝑎𝑐𝑡 𝑠𝑒𝑐𝑡𝑜𝑟𝑠 (𝑀𝑊ℎ)/ 𝑁𝑒𝑡
              𝑟𝑒𝑣𝑒𝑛𝑢𝑒 𝑓𝑟𝑜𝑚 𝑎𝑐𝑡𝑖𝑣𝑖𝑡𝑖𝑒𝑠 𝑖𝑛 ℎ𝑖𝑔ℎ 𝑐𝑙𝑖𝑚𝑎𝑡𝑒 𝑖𝑚𝑝𝑎𝑐𝑡 𝑠𝑒𝑐𝑡𝑜𝑟𝑠 (𝑀𝑜𝑛𝑒𝑡𝑎𝑟𝑦 𝑢𝑛𝑖𝑡)) (b) express
              the total energy consumption in MWh and the net revenue in monetary units (e.g.,
              Euros); <br />
              (c) the numerator and denominator shall only consist of the proportion of the total
              final energy consumption (in the numerator) and net revenue (in the denominator) that
              are attributable to activities in high climate impact sectors. In effect, there should
              be consistency in the scope of both the numerator and denominator;
              <br />
              (d) calculate the total energy consumption in line with the requirement in paragraph
              37;
              <br />
              (e) calculate the net revenue in line with the accounting standards requirements
              applicable for the financial statements, i.e., IFRS 15 Revenue from Contracts with
              Customers or local GAAP requirements."
              <br />
            </p>
          </Typography>
        ),
        table: (
          <table className="question-table">
            <thead>
              <tr>
                <th>Energy intensity per net revenue</th>
                <th>Comparative</th>
                <th>N</th>
                <th style={{ whiteSpace: "nowrap" }}>% N / N-1</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Total energy consumption from activities in high climate impact sectors per net
                  revenue from activities in high climate impact sectors (MWh/Monetary unit)
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_5_2-totalEnergy-comparative"
                    value={
                      answers2["e1_5_2-totalEnergy-comparative"] ||
                      inputValues.e1_5_2.totalEnergy.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_5_2-totalEnergy-yearN"
                    value={
                      answers2["e1_5_2-totalEnergy-yearN"] || inputValues.e1_5_2.totalEnergy.yearN
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_5_2-totalEnergy-percentage"
                    readOnly
                    // value={
                    //   answers2["e1_5_2-totalEnergy-percentage"] ||
                    //   inputValues.e1_5_2.totalEnergy.percentage
                    // }
                    // onChange={handleInputChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        ),
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 6 Table #1 of Annex 1
            </p>
          </Typography>
        ),
        module: "Environment",
        boldPart: "37-42",
        fun: "4ColumnD",
      },
      {
        id: "q-205",
        question: " The undertaking shall provide information on its energy consumption and mix.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "The reconciliation of net revenue from activities in high climate impact sectors to
              the relevant financial statements line item or disclosure (as required by paragraph
              43) may be presented either:
            </p>
            <ol className="question-tip" type="a">
              <li>
                by a cross-reference to the related line item or disclosure in the financial
                statements; or
              </li>
              <li>
                If the net revenue cannot be directly cross-referenced to a line item or disclosure
                in the financial statements, by a quantitative reconciliation using the below
                tabular format.
              </li>
            </ol>
          </Typography>
        ),
        table: (
          <table className="question-table">
            <thead>
              <tr>
                <th colSpan="2">
                  Energy intensity based on net revenue (high climate impact sector)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Net revenue from activities in high climate impact sectors used to calculate
                  energy intensity
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_5_3-netRevenueHighClimate-netData"
                    value={
                      answers2["e1_5_3-netRevenueHighClimate-netData"] ||
                      inputValues.e1_5_3.netRevenueHighClimate.netData
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Net revenue (other)</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_5_3-netRevenueOther-netData"
                    value={
                      answers2["e1_5_3-netRevenueOther-netData"] ||
                      inputValues.e1_5_3.netRevenueOther.netData
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Total net revenue (Financial statements)</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_5_3-totalNetRevenue-netData"
                    value={
                      answers2["e1_5_3-totalNetRevenue-netData"] ||
                      inputValues.e1_5_3.totalNetRevenue.netData
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        ),
        module: "Environment",
        boldPart: "43",
        fun: "2ColumnD",
      },
      {
        id: "q-206",
        question:
          "If the net revenue cannot be directly cross-referenced to a line item or disclosure in the financial statements, by a quantitative reconciliation using the below tabular format.",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 38 b",
      },
      // Add more questions as needed
    ],
    "E1-6 – Gross Scopes 1, 2, 3 and Total GHG emissions": [
      {
        id: "q-207",
        question:
          "The undertaking shall disclose in metric tonnes of CO2 its: (a) gross Scope 1 GHG emissions; (b) gross Scope 2 GHG emissions; (c) gross Scope 3 GHG emissions; and (d) total GHG emissions",
        tooltip: (
          <Typography>
            <p className="question-tip">
              When preparing the information for reporting GHG emissions as required by paragraph
              44, the undertaking shall: (a) consider the principles, requirements and guidance
              provided by the GHG Protocol Corporate Standard (version 2004). The undertaking may
              consider Commission Recommendation (EU) 2021/227958 or the requirements stipulated by
              EN ISO 14064- 1:2018. If the undertaking already applies the GHG accounting
              methodology of ISO
            </p>
          </Typography>
        ),
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicators number 1 and 2 Table #1 of Annex 1
            </p>
            <p className="question-tip">
              <strong>Pillar 3 reference:</strong> Article 449a; Regulation (EU) No 575/2013;
              Commission Implementing Regulation (EU) 2022/2453 Template 1: Banking book – Climate
              change transition risk: Credit quality of exposures by sector, emissions and residual
              maturity
            </p>
            <p className="question-tip">
              <strong>Benchmark Regulation reference:</strong> Delegated Regulation (EU) 2020/1818,
              Article 5(1), 6 and 8(1)
            </p>
          </Typography>
        ),

        module: "Environment",
        boldPart: "44",
      },
      {
        id: "q-208",
        question: " Scope 1, 2, 3 GHG emissions",
        tooltip: (
          <Typography>
            <p className="question-tip">
              In line with ESRS 1 chapter 3.7, the undertaking shall disaggregate information on its
              GHG emissions as appropriate. For example, the undertaking may disaggregate its Scope
              1, 2, 3, or total GHG emissions by country, operating segments, economic activity,
              subsidiary, GHG category (CO2, CH4, N2O, HFCs, PFCs, SF6, NF3, and other GHG
              considered by the undertaking) or source type (stationary combustion, mobile
              combustion, process emissions and fugitive emissions).
            </p>
            <p className="question-tip">
              When preparing the information on gross Scope 3 GHG emissions required under paragraph
              51, the undertaking shall: if it is a financial institution, consider the GHG
              Accounting and Reporting Standard for the Financial Industry from the Partnership for
              Carbon Accounting Financial (PCAF), specifically part A “Financed Emissions” (version
              December 2022);
            </p>
            <p className="question-tip">
              The Scope 3 GHG emissions may also be presented by according to the indirect emission
              categories defined in EN ISO 14064-1:2018.
            </p>
            <p className="question-tip">
              The total GHG emissions disaggregated by Scope 1, 2 and 3 GHG emissions may be
              graphically presented in the sustainability statement (e.g., as a bar or pie chart)
              showing the split of GHG emissions across the value chain (Upstream, Own operations,
              Transport, Downstream).
            </p>
            <p className="question-tip">
              When preparing the information on gross Scope 1 GHG emissions required under paragraph
              48 (a), the undertaking shall:
            </p>
            <ol className="question-tip" type="a">
              <li>
                calculate or measure GHG emissions from stationary combustion, mobile combustion,
                process emissions, and fugitive emissions; and use suitable activity data that
                include the non-renewable fuel consumption;
              </li>
              <li>use suitable and consistent emission factors;</li>
              <li>
                disclose biogenic emissions of CO2 from the combustion or bio-degradation of biomass
                separately from the Scope 1 GHG emissions, but include emissions of other types of
                GHG (in particular CH4 and N2O);
              </li>
              <li>
                not include any removals, or any purchased, sold or transferred carbon credits or
                GHG allowances in the calculation of Scope 1 GHG emissions; and
              </li>
              <li>
                for activities reporting under the EU ETS, report on Scope 1 emissions following the
                EU ETS methodology. The EU ETS methodology may also be applied to activities in
                geographies and sectors that are not covered by the EU ETS.
              </li>
            </ol>
            <p className="question-tip">
              WWhen preparing the information on the percentage of Scope 1 GHG emissions from
              regulated emission trading schemes required under paragraph 48 (b), the undertaking
              shall:
            </p>
            <ol className="question-tip" type="a">
              <li>
                consider GHG emissions from the installations it operates that are subject to
                regulated Emission Trading Schemes (ETS), including the EU-ETS, national ETS and
                non-EU ETS, if applicable;
              </li>
              <li>only include emissions of CO2, CH4, N2O, HFCs, PFCs, SF6, and NF3;</li>
              <li>avoid double counting of GHG emissions reported under Scope 1 or 3;</li>
              <li>
                ensure the same accounting period for gross Scope 1 GHG emissions and GHG emissions
                regulated under the ETS; and
              </li>
              <li>
                calculate the share by using the following formula:
                <br />( GHG Emissions in (t CO2eq) from EU ETS installations + national ETS
                installations + nonEU ETS installations)/ (Scope 1 GHG emissions (t CO2eq))
              </li>
            </ol>

            <p className="question-tip">
              When preparing the information on gross Scope 2 GHG emissions required under paragraph
              49, the undertaking shall:
            </p>
            <ol className="question-tip" type="a">
              <li>
                consider the principles and requirements of the GHG Protocol Scope 2 Guidance
                (version 2015, in particular the Scope 2 quality criteria in chapter 7.1 relating to
                contractual instruments); it may also consider Commission Recommendation (EU)
                2021/2279 or the relevant requirements for the quantification of indirect GHG
                emissions from imported energy in EN ISO 14064-1:2018;
              </li>
              <li>
                include purchased or acquired electricity, steam, heat, and cooling consumed by the
                undertaking;
              </li>
              <li>avoid double counting of GHG emissions reported under Scope 1 or 3;</li>
              <li>
                apply the location-based and market-based methods to calculate Scope 2 GHG emissions
                and provide information on the share and types of contractual instruments.
                Location-based method quantifies Scope 2 GHG emissions based on average energy
                generation emission factors for defined locations, including local, subnational, or
                national boundaries (GHG Protocol, “Scope 2 Guidance”, Glossary, 2015). Market-based
                method quantifies Scope 2 GHG emissions based on GHG emissions emitted by the
                generators from which the reporting entity contractually purchases electricity
                bundled with instruments, or unbundled instruments on their own (GHG Protocol,
                “Scope 2 Guidance”, Glossary, 2015); in this case, the undertaking may disclose the
                share of market-based scope 2 GHG emissions linked to purchased electricity bundled
                with instruments such as Guarantee of Origins or Renewable Energy Certificates. The
                undertaking shall provide information about the share and types of contractual
                instruments used for the sale and purchase of energy bundled with attributes about
                the energy generation or for unbundled energy attribute claims.
              </li>
              <li>
                disclose biogenic emissions of CO2 carbon from the combustion or biodegradation of
                biomass separately from the Scope 2 GHG emissions but include emissions of other
                types of GHG (in particular CH4 and N2O). In case the emission factors applied do
                not separatethe percentage of biomass or biogenic CO2, the undertaking shall
                disclose this. In case GHG emissions other than CO2 (particularly CH4 and N2O) are
                not available for, or excluded from, location-based grid average emissions factors
                or with the market-based method information, the undertaking shall disclose this.
              </li>
              <li>
                not include any removals, or any purchased, sold or transferred carbon credits or
                GHG allowances in the calculation of Scope 2 GHG emissions.
              </li>
            </ol>

            <p className="question-tip">
              When preparing the information on gross Scope 3 GHG emissions required under paragraph
              51, the undertaking shall:
            </p>
            <ol className="question-tip" type="a">
              <li>
                consider the principles and provisions of the GHG Protocol Corporate Value Chain
                (Scope 3) Accounting and Reporting Standard (Version 2011); and it may consider
                Commission Recommendation (EU) 2021/2279 or the relevant requirements for the
                quantification of indirect GHG emissions from EN ISO 14064-1:2018;
              </li>
              <li>
                if it is a financial institution, consider the GHG Accounting and Reporting Standard
                for the Financial Industry from the Partnership for Carbon Accounting Financial
                (PCAF), specifically part A “Financed Emissions” (version December 2022);
              </li>
              <li>
                screen its total Scope 3 GHG emissions based on the 15 Scope 3 categories identified
                by the GHG Protocol Corporate Standard and GHG Protocol Corporate Value Chain (Scope
                3) Accounting and Reporting Standard (Version 2011) using appropriate estimates.
                Alternatively, it may screen its indirect GHG emissions based on the categories
                provided by EN ISO 14064-1:2018 clause 5.2.4 (excluding indirect GHG emissions from
                imported energy);
              </li>
              <li>
                identify and disclose its significant Scope 3 categories based on the magnitude of
                their estimated GHG emissions and other criteria provided by GHG Protocol Corporate
                Value Chain (Scope 3) Accounting and Reporting Standard (Version 2011, p. 61 and
                65-68) or EN ISO 14064-1:2018 Annex H.3.2, such as financial spend, influence,
                related transition risks and opportunities or stakeholder views;
              </li>
              <li>
                calculate or estimate GHG emissions in significant Scope 3 categories using suitable
                emissions factors;
              </li>
              <li>
                update Scope 3 GHG emissions in each significant category every year on the basis of
                current activity data; update the full Scope 3 GHG inventory at least every 3 years
                or on the occurrence of a significant event or a significant change in circumstances
                (a significant event or significant change in circumstances can, for example, relate
                to changes in the undertaking’s activities or structure, changes in the activities
                or structure of its upstream and downstream value chain(s), a change in calculation
                methodology or in the discovery of errors);”);
              </li>
              <li>
                disclose the extent to which the undertaking’s Scope 3 GHG emissions are measured
                using inputs from specific activities within the entity’s upstream and downstream
                value chain, and disclose the percentage of emissions calculated using primary data
                obtained from suppliers or other value chain partners.
              </li>
              <li>
                for each significant Scope 3 GHG category, disclose the reporting boundaries
                considered, the calculation methods for estimating the GHG emissions as well as if
                and which calculation tools were applied. The Scope 3 categories should be
                consistent with the GHGP and include:
                <ol className="question-tip" type="i">
                  <li>
                    indirect Scope 3 GHG emissions from the consolidated accounting group (the
                    parent and its subsidiaries),
                  </li>
                  <li>
                    indirect Scope 3 GHG emissions from associates, joint ventures, and
                    unconsolidated subsidiaries for which the undertaking has the ability to control
                    the operational activities and relationships (i.e., operational control),
                  </li>
                  <li>
                    Scope 1, 2 and 3 GHG emissions from associates, joint ventures, unconsolidated
                    subsidiaries (investment entities) and joint arrangements for which the
                    undertaking
                  </li>
                </ol>
              </li>
            </ol>

            <p className="question-tip">
              When preparing the information on the total GHG emissions required under paragraph 52,
              the undertaking shall:
            </p>
            <ol className="question-tip" type="a">
              <li>
                apply the following formulas to calculate the total GHG emissions:
                <br />
                Total GHG emissions𝑙𝑜𝑐𝑎𝑡𝑖𝑜𝑛−𝑏𝑎𝑠𝑒𝑑 (t CO2eq) = Gross Scope 1 + Gross Scope
                2𝑙𝑜𝑐𝑎𝑡𝑖𝑜𝑛−𝑏𝑎𝑠𝑒𝑑 + Gross Scope 3 Total GHG emissions𝑚𝑎𝑟𝑘𝑒𝑡−𝑏𝑎𝑠𝑒𝑑 (t CO2eq) = Gross
                Scope 1 + Gross Scope 2𝑚𝑎𝑟𝑘𝑒𝑡−𝑏𝑎𝑠𝑒𝑑 + Gross Scope 3
              </li>
              <li>
                disclose total GHG emissions with a distinction between emissions derived from the
                location-based and market-based methods applied while measuring the underlying Scope
                2 GHG emissions.
              </li>
            </ol>
          </Typography>
        ),
        table: (
          <table className="question-table">
            <thead>
              <tr>
                <th rowSpan="2">E1-6 – Gross Scopes 1, 2, 3 and Total GHG emissions</th>
                <th colSpan="4">Retrospective</th>
                <th colSpan="4">Milestones and target years</th>
              </tr>
              <tr>
                <th>Base year</th>
                <th>Comparative</th>
                <th>N</th>
                <th style={{ whiteSpace: "nowrap" }}>% N / N-1</th>
                <th>2025</th>
                <th>2030</th>
                <th>2050</th>
                <th>Annual % target / Base year</th>
              </tr>
            </thead>
            <tbody>
              {/* Scope 1 GHG emissions */}
              <tr>
                <th colSpan="9">Scope 1 GHG emissions</th>
              </tr>
              <tr>
                <td>Gross Scope 1 GHG emissions (tCO2eq)</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope1Gross-baseYear"
                    value={
                      answers2["e1_6_1-scope1Gross-baseYear"] ||
                      inputValues.e1_6_1.scope1Gross.baseYear
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope1Gross-comparative"
                    value={
                      answers2["e1_6_1-scope1Gross-comparative"] ||
                      inputValues.e1_6_1.scope1Gross.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope1Gross-n"
                    value={answers2["e1_6_1-scope1Gross-n"] || inputValues.e1_6_1.scope1Gross.n}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope1Gross-percentN"
                    value={
                      answers2["e1_6_1-scope1Gross-percentN"] ||
                      inputValues.e1_6_1.scope1Gross.percentN
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope1Gross-target2025"
                    value={
                      answers2["e1_6_1-scope1Gross-target2025"] ||
                      inputValues.e1_6_1.scope1Gross.target2025
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope1Gross-target2030"
                    value={
                      answers2["e1_6_1-scope1Gross-target2030"] ||
                      inputValues.e1_6_1.scope1Gross.target2030
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope1Gross-target2050"
                    value={
                      answers2["e1_6_1-scope1Gross-target2050"] ||
                      inputValues.e1_6_1.scope1Gross.target2050
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope1Gross-annualPercentTarget"
                    value={
                      answers2["e1_6_1-scope1Gross-annualPercentTarget"] ||
                      inputValues.e1_6_1.scope1Gross.annualPercentTarget
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Percentage of Scope 1 GHG emissions from regulated emission trading schemes (%)
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope1Percentage-baseYear"
                    value={
                      answers2["e1_6_1-scope1Percentage-baseYear"] ||
                      inputValues.e1_6_1.scope1Percentage.baseYear
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope1Percentage-comparative"
                    value={
                      answers2["e1_6_1-scope1Percentage-comparative"] ||
                      inputValues.e1_6_1.scope1Percentage.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope1Percentage-n"
                    value={
                      answers2["e1_6_1-scope1Percentage-n"] || inputValues.e1_6_1.scope1Percentage.n
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope1Percentage-percentN"
                    value={
                      answers2["e1_6_1-scope1Percentage-percentN"] ||
                      inputValues.e1_6_1.scope1Percentage.percentN
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope1Percentage-target2025"
                    value={
                      answers2["e1_6_1-scope1Percentage-target2025"] ||
                      inputValues.e1_6_1.scope1Percentage.target2025
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope1Percentage-target2030"
                    value={
                      answers2["e1_6_1-scope1Percentage-target2030"] ||
                      inputValues.e1_6_1.scope1Percentage.target2030
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope1Percentage-target2050"
                    value={
                      answers2["e1_6_1-scope1Percentage-target2050"] ||
                      inputValues.e1_6_1.scope1Percentage.target2050
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope1Percentage-annualPercentTarget"
                    value={
                      answers2["e1_6_1-scope1Percentage-annualPercentTarget"] ||
                      inputValues.e1_6_1.scope1Percentage.annualPercentTarget
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              {/* Scope 2 GHG emissions */}
              <tr>
                <th colSpan="9">Scope 2 GHG emissions</th>
              </tr>
              <tr>
                <td>Gross location-based Scope 2 GHG emissions (tCO2eq)</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope2GrossLocationBased-baseYear"
                    value={
                      answers2["e1_6_1-scope2GrossLocationBased-baseYear"] ||
                      inputValues.e1_6_1.scope2GrossLocationBased.baseYear
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope2GrossLocationBased-comparative"
                    value={
                      answers2["e1_6_1-scope2GrossLocationBased-comparative"] ||
                      inputValues.e1_6_1.scope2GrossLocationBased.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope2GrossLocationBased-n"
                    value={
                      answers2["e1_6_1-scope2GrossLocationBased-n"] ||
                      inputValues.e1_6_1.scope2GrossLocationBased.n
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope2GrossLocationBased-percentN"
                    value={
                      answers2["e1_6_1-scope2GrossLocationBased-percentN"] ||
                      inputValues.e1_6_1.scope2GrossLocationBased.percentN
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope2GrossLocationBased-target2025"
                    value={
                      answers2["e1_6_1-scope2GrossLocationBased-target2025"] ||
                      inputValues.e1_6_1.scope2GrossLocationBased.target2025
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope2GrossLocationBased-target2030"
                    value={
                      answers2["e1_6_1-scope2GrossLocationBased-target2030"] ||
                      inputValues.e1_6_1.scope2GrossLocationBased.target2030
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope2GrossLocationBased-target2050"
                    value={
                      answers2["e1_6_1-scope2GrossLocationBased-target2050"] ||
                      inputValues.e1_6_1.scope2GrossLocationBased.target2050
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope2GrossLocationBased-annualPercentTarget"
                    value={
                      answers2["e1_6_1-scope2GrossLocationBased-annualPercentTarget"] ||
                      inputValues.e1_6_1.scope2GrossLocationBased.annualPercentTarget
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Gross market-based Scope 2 GHG emissions (tCO2eq)</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope2GrossMarketBased-baseYear"
                    value={
                      answers2["e1_6_1-scope2GrossMarketBased-baseYear"] ||
                      inputValues.e1_6_1.scope2GrossMarketBased.baseYear
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope2GrossMarketBased-comparative"
                    value={
                      answers2["e1_6_1-scope2GrossMarketBased-comparative"] ||
                      inputValues.e1_6_1.scope2GrossMarketBased.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope2GrossMarketBased-n"
                    value={
                      answers2["e1_6_1-scope2GrossMarketBased-n"] ||
                      inputValues.e1_6_1.scope2GrossMarketBased.n
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope2GrossMarketBased-percentN"
                    value={
                      answers2["e1_6_1-scope2GrossMarketBased-percentN"] ||
                      inputValues.e1_6_1.scope2GrossMarketBased.percentN
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope2GrossMarketBased-target2025"
                    value={
                      answers2["e1_6_1-scope2GrossMarketBased-target2025"] ||
                      inputValues.e1_6_1.scope2GrossMarketBased.target2025
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope2GrossMarketBased-target2030"
                    value={
                      answers2["e1_6_1-scope2GrossMarketBased-target2030"] ||
                      inputValues.e1_6_1.scope2GrossMarketBased.target2030
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope2GrossMarketBased-target2050"
                    value={
                      answers2["e1_6_1-scope2GrossMarketBased-target2050"] ||
                      inputValues.e1_6_1.scope2GrossMarketBased.target2050
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope2GrossMarketBased-annualPercentTarget"
                    value={
                      answers2["e1_6_1-scope2GrossMarketBased-annualPercentTarget"] ||
                      inputValues.e1_6_1.scope2GrossMarketBased.annualPercentTarget
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              {/* Significant scope 3 GHG emissions */}
              <tr>
                <th colSpan="9">Significant scope 3 GHG emissions</th>
              </tr>
              <tr>
                <td>Total Gross indirect (Scope 3) GHG emissions (tCO2eq)</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3TotalGrossIndirect-baseYear"
                    value={
                      answers2["e1_6_1-scope3TotalGrossIndirect-baseYear"] ||
                      inputValues.e1_6_1.scope3TotalGrossIndirect.baseYear
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3TotalGrossIndirect-comparative"
                    value={
                      answers2["e1_6_1-scope3TotalGrossIndirect-comparative"] ||
                      inputValues.e1_6_1.scope3TotalGrossIndirect.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3TotalGrossIndirect-n"
                    value={
                      answers2["e1_6_1-scope3TotalGrossIndirect-n"] ||
                      inputValues.e1_6_1.scope3TotalGrossIndirect.n
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3TotalGrossIndirect-percentN"
                    value={
                      answers2["e1_6_1-scope3TotalGrossIndirect-percentN"] ||
                      inputValues.e1_6_1.scope3TotalGrossIndirect.percentN
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3TotalGrossIndirect-target2025"
                    value={
                      answers2["e1_6_1-scope3TotalGrossIndirect-target2025"] ||
                      inputValues.e1_6_1.scope3TotalGrossIndirect.target2025
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3TotalGrossIndirect-target2030"
                    value={
                      answers2["e1_6_1-scope3TotalGrossIndirect-target2030"] ||
                      inputValues.e1_6_1.scope3TotalGrossIndirect.target2030
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3TotalGrossIndirect-target2050"
                    value={
                      answers2["e1_6_1-scope3TotalGrossIndirect-target2050"] ||
                      inputValues.e1_6_1.scope3TotalGrossIndirect.target2050
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3TotalGrossIndirect-annualPercentTarget"
                    value={
                      answers2["e1_6_1-scope3TotalGrossIndirect-annualPercentTarget"] ||
                      inputValues.e1_6_1.scope3TotalGrossIndirect.annualPercentTarget
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>1 Purchased goods and services</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3PurchasedGoods-baseYear"
                    value={
                      answers2["e1_6_1-scope3PurchasedGoods-baseYear"] ||
                      inputValues.e1_6_1.scope3PurchasedGoods.baseYear
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3PurchasedGoods-comparative"
                    value={
                      answers2["e1_6_1-scope3PurchasedGoods-comparative"] ||
                      inputValues.e1_6_1.scope3PurchasedGoods.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3PurchasedGoods-n"
                    value={
                      answers2["e1_6_1-scope3PurchasedGoods-n"] ||
                      inputValues.e1_6_1.scope3PurchasedGoods.n
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3PurchasedGoods-percentN"
                    value={
                      answers2["e1_6_1-scope3PurchasedGoods-percentN"] ||
                      inputValues.e1_6_1.scope3PurchasedGoods.percentN
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3PurchasedGoods-target2025"
                    value={
                      answers2["e1_6_1-scope3PurchasedGoods-target2025"] ||
                      inputValues.e1_6_1.scope3PurchasedGoods.target2025
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3PurchasedGoods-target2030"
                    value={
                      answers2["e1_6_1-scope3PurchasedGoods-target2030"] ||
                      inputValues.e1_6_1.scope3PurchasedGoods.target2030
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3PurchasedGoods-target2050"
                    value={
                      answers2["e1_6_1-scope3PurchasedGoods-target2050"] ||
                      inputValues.e1_6_1.scope3PurchasedGoods.target2050
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3PurchasedGoods-annualPercentTarget"
                    value={
                      answers2["e1_6_1-scope3PurchasedGoods-annualPercentTarget"] ||
                      inputValues.e1_6_1.scope3PurchasedGoods.annualPercentTarget
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>2 Capital goods</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3CapitalGoods-baseYear"
                    value={
                      answers2["e1_6_1-scope3CapitalGoods-baseYear"] ||
                      inputValues.e1_6_1.scope3CapitalGoods.baseYear
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3CapitalGoods-comparative"
                    value={
                      answers2["e1_6_1-scope3CapitalGoods-comparative"] ||
                      inputValues.e1_6_1.scope3CapitalGoods.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3CapitalGoods-n"
                    value={
                      answers2["e1_6_1-scope3CapitalGoods-n"] ||
                      inputValues.e1_6_1.scope3CapitalGoods.n
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3CapitalGoods-percentN"
                    value={
                      answers2["e1_6_1-scope3CapitalGoods-percentN"] ||
                      inputValues.e1_6_1.scope3CapitalGoods.percentN
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3CapitalGoods-target2025"
                    value={
                      answers2["e1_6_1-scope3CapitalGoods-target2025"] ||
                      inputValues.e1_6_1.scope3CapitalGoods.target2025
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3CapitalGoods-target2030"
                    value={
                      answers2["e1_6_1-scope3CapitalGoods-target2030"] ||
                      inputValues.e1_6_1.scope3CapitalGoods.target2030
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3CapitalGoods-target2050"
                    value={
                      answers2["e1_6_1-scope3CapitalGoods-target2050"] ||
                      inputValues.e1_6_1.scope3CapitalGoods.target2050
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3CapitalGoods-annualPercentTarget"
                    value={
                      answers2["e1_6_1-scope3CapitalGoods-annualPercentTarget"] ||
                      inputValues.e1_6_1.scope3CapitalGoods.annualPercentTarget
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>3 Fuel and energy-related Activities (not included in Scope1 or Scope 2)</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3FuelEnergy-baseYear"
                    value={
                      answers2["e1_6_1-scope3FuelEnergy-baseYear"] ||
                      inputValues.e1_6_1.scope3FuelEnergy.baseYear
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3FuelEnergy-comparative"
                    value={
                      answers2["e1_6_1-scope3FuelEnergy-comparative"] ||
                      inputValues.e1_6_1.scope3FuelEnergy.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3FuelEnergy-n"
                    value={
                      answers2["e1_6_1-scope3FuelEnergy-n"] || inputValues.e1_6_1.scope3FuelEnergy.n
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3FuelEnergy-percentN"
                    value={
                      answers2["e1_6_1-scope3FuelEnergy-percentN"] ||
                      inputValues.e1_6_1.scope3FuelEnergy.percentN
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3FuelEnergy-target2025"
                    value={
                      answers2["e1_6_1-scope3FuelEnergy-target2025"] ||
                      inputValues.e1_6_1.scope3FuelEnergy.target2025
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3FuelEnergy-target2030"
                    value={
                      answers2["e1_6_1-scope3FuelEnergy-target2030"] ||
                      inputValues.e1_6_1.scope3FuelEnergy.target2030
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3FuelEnergy-target2050"
                    value={
                      answers2["e1_6_1-scope3FuelEnergy-target2050"] ||
                      inputValues.e1_6_1.scope3FuelEnergy.target2050
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3FuelEnergy-annualPercentTarget"
                    value={
                      answers2["e1_6_1-scope3FuelEnergy-annualPercentTarget"] ||
                      inputValues.e1_6_1.scope3FuelEnergy.annualPercentTarget
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>4 Upstream transportation and distribution</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3Transportation-baseYear"
                    value={
                      answers2["e1_6_1-scope3Transportation-baseYear"] ||
                      inputValues.e1_6_1.scope3Transportation.baseYear
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3Transportation-comparative"
                    value={
                      answers2["e1_6_1-scope3Transportation-comparative"] ||
                      inputValues.e1_6_1.scope3Transportation.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3Transportation-n"
                    value={
                      answers2["e1_6_1-scope3Transportation-n"] ||
                      inputValues.e1_6_1.scope3Transportation.n
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3Transportation-percentN"
                    value={
                      answers2["e1_6_1-scope3Transportation-percentN"] ||
                      inputValues.e1_6_1.scope3Transportation.percentN
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3Transportation-target2025"
                    value={
                      answers2["e1_6_1-scope3Transportation-target2025"] ||
                      inputValues.e1_6_1.scope3Transportation.target2025
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3Transportation-target2030"
                    value={
                      answers2["e1_6_1-scope3Transportation-target2030"] ||
                      inputValues.e1_6_1.scope3Transportation.target2030
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3Transportation-target2050"
                    value={
                      answers2["e1_6_1-scope3Transportation-target2050"] ||
                      inputValues.e1_6_1.scope3Transportation.target2050
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3Transportation-annualPercentTarget"
                    value={
                      answers2["e1_6_1-scope3Transportation-annualPercentTarget"] ||
                      inputValues.e1_6_1.scope3Transportation.annualPercentTarget
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>5 Waste generated in opera­tions</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3Waste-baseYear"
                    value={
                      answers2["e1_6_1-scope3Waste-baseYear"] ||
                      inputValues.e1_6_1.scope3Waste.baseYear
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3Waste-comparative"
                    value={
                      answers2["e1_6_1-scope3Waste-comparative"] ||
                      inputValues.e1_6_1.scope3Waste.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3Waste-n"
                    value={answers2["e1_6_1-scope3Waste-n"] || inputValues.e1_6_1.scope3Waste.n}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3Waste-percentN"
                    value={
                      answers2["e1_6_1-scope3Waste-percentN"] ||
                      inputValues.e1_6_1.scope3Waste.percentN
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3Waste-target2025"
                    value={
                      answers2["e1_6_1-scope3Waste-target2025"] ||
                      inputValues.e1_6_1.scope3Waste.target2025
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3Waste-target2030"
                    value={
                      answers2["e1_6_1-scope3Waste-target2030"] ||
                      inputValues.e1_6_1.scope3Waste.target2030
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3Waste-target2050"
                    value={
                      answers2["e1_6_1-scope3Waste-target2050"] ||
                      inputValues.e1_6_1.scope3Waste.target2050
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3Waste-annualPercentTarget"
                    value={
                      answers2["e1_6_1-scope3Waste-annualPercentTarget"] ||
                      inputValues.e1_6_1.scope3Waste.annualPercentTarget
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>6 Business travelng</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3BusinessTraveling-baseYear"
                    value={
                      answers2["e1_6_1-scope3BusinessTraveling-baseYear"] ||
                      inputValues.e1_6_1.scope3BusinessTraveling.baseYear
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3BusinessTraveling-comparative"
                    value={
                      answers2["e1_6_1-scope3BusinessTraveling-comparative"] ||
                      inputValues.e1_6_1.scope3BusinessTraveling.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3BusinessTraveling-n"
                    value={
                      answers2["e1_6_1-scope3BusinessTraveling-n"] ||
                      inputValues.e1_6_1.scope3BusinessTraveling.n
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3BusinessTraveling-percentN"
                    value={
                      answers2["e1_6_1-scope3BusinessTraveling-percentN"] ||
                      inputValues.e1_6_1.scope3BusinessTraveling.percentN
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3BusinessTraveling-target2025"
                    value={
                      answers2["e1_6_1-scope3BusinessTraveling-target2025"] ||
                      inputValues.e1_6_1.scope3BusinessTraveling.target2025
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3BusinessTraveling-target2030"
                    value={
                      answers2["e1_6_1-scope3BusinessTraveling-target2030"] ||
                      inputValues.e1_6_1.scope3BusinessTraveling.target2030
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3BusinessTraveling-target2050"
                    value={
                      answers2["e1_6_1-scope3BusinessTraveling-target2050"] ||
                      inputValues.e1_6_1.scope3BusinessTraveling.target2050
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3BusinessTraveling-annualPercentTarget"
                    value={
                      answers2["e1_6_1-scope3BusinessTraveling-annualPercentTarget"] ||
                      inputValues.e1_6_1.scope3BusinessTraveling.annualPercentTarget
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>7 Employee commuting</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3EmployeeCommuting-baseYear"
                    value={
                      answers2["e1_6_1-scope3EmployeeCommuting-baseYear"] ||
                      inputValues.e1_6_1.scope3EmployeeCommuting.baseYear
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3EmployeeCommuting-comparative"
                    value={
                      answers2["e1_6_1-scope3EmployeeCommuting-comparative"] ||
                      inputValues.e1_6_1.scope3EmployeeCommuting.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3EmployeeCommuting-n"
                    value={
                      answers2["e1_6_1-scope3EmployeeCommuting-n"] ||
                      inputValues.e1_6_1.scope3EmployeeCommuting.n
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3EmployeeCommuting-percentN"
                    value={
                      answers2["e1_6_1-scope3EmployeeCommuting-percentN"] ||
                      inputValues.e1_6_1.scope3EmployeeCommuting.percentN
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3EmployeeCommuting-target2025"
                    value={
                      answers2["e1_6_1-scope3EmployeeCommuting-target2025"] ||
                      inputValues.e1_6_1.scope3EmployeeCommuting.target2025
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3EmployeeCommuting-target2030"
                    value={
                      answers2["e1_6_1-scope3EmployeeCommuting-target2030"] ||
                      inputValues.e1_6_1.scope3EmployeeCommuting.target2030
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3EmployeeCommuting-target2050"
                    value={
                      answers2["e1_6_1-scope3EmployeeCommuting-target2050"] ||
                      inputValues.e1_6_1.scope3EmployeeCommuting.target2050
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3EmployeeCommuting-annualPercentTarget"
                    value={
                      answers2["e1_6_1-scope3EmployeeCommuting-annualPercentTarget"] ||
                      inputValues.e1_6_1.scope3EmployeeCommuting.annualPercentTarget
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>8 Upstream leased assets</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3UpstreamLeasedAssets-baseYear"
                    value={
                      answers2["e1_6_1-scope3UpstreamLeasedAssets-baseYear"] ||
                      inputValues.e1_6_1.scope3UpstreamLeasedAssets.baseYear
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3UpstreamLeasedAssets-comparative"
                    value={
                      answers2["e1_6_1-scope3UpstreamLeasedAssets-comparative"] ||
                      inputValues.e1_6_1.scope3UpstreamLeasedAssets.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3UpstreamLeasedAssets-n"
                    value={
                      answers2["e1_6_1-scope3UpstreamLeasedAssets-n"] ||
                      inputValues.e1_6_1.scope3UpstreamLeasedAssets.n
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3UpstreamLeasedAssets-percentN"
                    value={
                      answers2["e1_6_1-scope3UpstreamLeasedAssets-percentN"] ||
                      inputValues.e1_6_1.scope3UpstreamLeasedAssets.percentN
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3UpstreamLeasedAssets-target2025"
                    value={
                      answers2["e1_6_1-scope3UpstreamLeasedAssets-target2025"] ||
                      inputValues.e1_6_1.scope3UpstreamLeasedAssets.target2025
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3UpstreamLeasedAssets-target2030"
                    value={
                      answers2["e1_6_1-scope3UpstreamLeasedAssets-target2030"] ||
                      inputValues.e1_6_1.scope3UpstreamLeasedAssets.target2030
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3UpstreamLeasedAssets-target2050"
                    value={
                      answers2["e1_6_1-scope3UpstreamLeasedAssets-target2050"] ||
                      inputValues.e1_6_1.scope3UpstreamLeasedAssets.target2050
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3UpstreamLeasedAssets-annualPercentTarget"
                    value={
                      answers2["e1_6_1-scope3UpstreamLeasedAssets-annualPercentTarget"] ||
                      inputValues.e1_6_1.scope3UpstreamLeasedAssets.annualPercentTarget
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>9 Downstream transportation</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3DownstreamTransportation-baseYear"
                    value={
                      answers2["e1_6_1-scope3DownstreamTransportation-baseYear"] ||
                      inputValues.e1_6_1.scope3DownstreamTransportation.baseYear
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3DownstreamTransportation-comparative"
                    value={
                      answers2["e1_6_1-scope3DownstreamTransportation-comparative"] ||
                      inputValues.e1_6_1.scope3DownstreamTransportation.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3DownstreamTransportation-n"
                    value={
                      answers2["e1_6_1-scope3DownstreamTransportation-n"] ||
                      inputValues.e1_6_1.scope3DownstreamTransportation.n
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3DownstreamTransportation-percentN"
                    value={
                      answers2["e1_6_1-scope3DownstreamTransportation-percentN"] ||
                      inputValues.e1_6_1.scope3DownstreamTransportation.percentN
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3DownstreamTransportation-target2025"
                    value={
                      answers2["e1_6_1-scope3DownstreamTransportation-target2025"] ||
                      inputValues.e1_6_1.scope3DownstreamTransportation.target2025
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3DownstreamTransportation-target2030"
                    value={
                      answers2["e1_6_1-scope3DownstreamTransportation-target2030"] ||
                      inputValues.e1_6_1.scope3DownstreamTransportation.target2030
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3DownstreamTransportation-target2050"
                    value={
                      answers2["e1_6_1-scope3DownstreamTransportation-target2050"] ||
                      inputValues.e1_6_1.scope3DownstreamTransportation.target2050
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3DownstreamTransportation-annualPercentTarget"
                    value={
                      answers2["e1_6_1-scope3DownstreamTransportation-annualPercentTarget"] ||
                      inputValues.e1_6_1.scope3DownstreamTransportation.annualPercentTarget
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>10 Processing of sold products</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3ProcessingSoldProducts-baseYear"
                    value={
                      answers2["e1_6_1-scope3ProcessingSoldProducts-baseYear"] ||
                      inputValues.e1_6_1.scope3ProcessingSoldProducts.baseYear
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3ProcessingSoldProducts-comparative"
                    value={
                      answers2["e1_6_1-scope3ProcessingSoldProducts-comparative"] ||
                      inputValues.e1_6_1.scope3ProcessingSoldProducts.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3ProcessingSoldProducts-n"
                    value={
                      answers2["e1_6_1-scope3ProcessingSoldProducts-n"] ||
                      inputValues.e1_6_1.scope3ProcessingSoldProducts.n
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3ProcessingSoldProducts-percentN"
                    value={
                      answers2["e1_6_1-scope3ProcessingSoldProducts-percentN"] ||
                      inputValues.e1_6_1.scope3ProcessingSoldProducts.percentN
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3ProcessingSoldProducts-target2025"
                    value={
                      answers2["e1_6_1-scope3ProcessingSoldProducts-target2025"] ||
                      inputValues.e1_6_1.scope3ProcessingSoldProducts.target2025
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3ProcessingSoldProducts-target2030"
                    value={
                      answers2["e1_6_1-scope3ProcessingSoldProducts-target2030"] ||
                      inputValues.e1_6_1.scope3ProcessingSoldProducts.target2030
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3ProcessingSoldProducts-target2050"
                    value={
                      answers2["e1_6_1-scope3ProcessingSoldProducts-target2050"] ||
                      inputValues.e1_6_1.scope3ProcessingSoldProducts.target2050
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3ProcessingSoldProducts-annualPercentTarget"
                    value={
                      answers2["e1_6_1-scope3ProcessingSoldProducts-annualPercentTarget"] ||
                      inputValues.e1_6_1.scope3ProcessingSoldProducts.annualPercentTarget
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>11 Use of sold products</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3UseSoldProducts-baseYear"
                    value={
                      answers2["e1_6_1-scope3UseSoldProducts-baseYear"] ||
                      inputValues.e1_6_1.scope3UseSoldProducts.baseYear
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3UseSoldProducts-comparative"
                    value={
                      answers2["e1_6_1-scope3UseSoldProducts-comparative"] ||
                      inputValues.e1_6_1.scope3UseSoldProducts.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3UseSoldProducts-n"
                    value={
                      answers2["e1_6_1-scope3UseSoldProducts-n"] ||
                      inputValues.e1_6_1.scope3UseSoldProducts.n
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3UseSoldProducts-percentN"
                    value={
                      answers2["e1_6_1-scope3UseSoldProducts-percentN"] ||
                      inputValues.e1_6_1.scope3UseSoldProducts.percentN
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3UseSoldProducts-target2025"
                    value={
                      answers2["e1_6_1-scope3UseSoldProducts-target2025"] ||
                      inputValues.e1_6_1.scope3UseSoldProducts.target2025
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3UseSoldProducts-target2030"
                    value={
                      answers2["e1_6_1-scope3UseSoldProducts-target2030"] ||
                      inputValues.e1_6_1.scope3UseSoldProducts.target2030
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3UseSoldProducts-target2050"
                    value={
                      answers2["e1_6_1-scope3UseSoldProducts-target2050"] ||
                      inputValues.e1_6_1.scope3UseSoldProducts.target2050
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3UseSoldProducts-annualPercentTarget"
                    value={
                      answers2["e1_6_1-scope3UseSoldProducts-annualPercentTarget"] ||
                      inputValues.e1_6_1.scope3UseSoldProducts.annualPercentTarget
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>12 End-of-life treatment of sold products</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3EndOfLife-baseYear"
                    value={
                      answers2["e1_6_1-scope3EndOfLife-baseYear"] ||
                      inputValues.e1_6_1.scope3EndOfLife.baseYear
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3EndOfLife-comparative"
                    value={
                      answers2["e1_6_1-scope3EndOfLife-comparative"] ||
                      inputValues.e1_6_1.scope3EndOfLife.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3EndOfLife-n"
                    value={
                      answers2["e1_6_1-scope3EndOfLife-n"] || inputValues.e1_6_1.scope3EndOfLife.n
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3EndOfLife-percentN"
                    value={
                      answers2["e1_6_1-scope3EndOfLife-percentN"] ||
                      inputValues.e1_6_1.scope3EndOfLife.percentN
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3EndOfLife-target2025"
                    value={
                      answers2["e1_6_1-scope3EndOfLife-target2025"] ||
                      inputValues.e1_6_1.scope3EndOfLife.target2025
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3EndOfLife-target2030"
                    value={
                      answers2["e1_6_1-scope3EndOfLife-target2030"] ||
                      inputValues.e1_6_1.scope3EndOfLife.target2030
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3EndOfLife-target2050"
                    value={
                      answers2["e1_6_1-scope3EndOfLife-target2050"] ||
                      inputValues.e1_6_1.scope3EndOfLife.target2050
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3EndOfLife-annualPercentTarget"
                    value={
                      answers2["e1_6_1-scope3EndOfLife-annualPercentTarget"] ||
                      inputValues.e1_6_1.scope3EndOfLife.annualPercentTarget
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>13 Downstream leased assets</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3DownstreamLeasedAssets-baseYear"
                    value={
                      answers2["e1_6_1-scope3DownstreamLeasedAssets-baseYear"] ||
                      inputValues.e1_6_1.scope3DownstreamLeasedAssets.baseYear
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3DownstreamLeasedAssets-comparative"
                    value={
                      answers2["e1_6_1-scope3DownstreamLeasedAssets-comparative"] ||
                      inputValues.e1_6_1.scope3DownstreamLeasedAssets.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3DownstreamLeasedAssets-n"
                    value={
                      answers2["e1_6_1-scope3DownstreamLeasedAssets-n"] ||
                      inputValues.e1_6_1.scope3DownstreamLeasedAssets.n
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3DownstreamLeasedAssets-percentN"
                    value={
                      answers2["e1_6_1-scope3DownstreamLeasedAssets-percentN"] ||
                      inputValues.e1_6_1.scope3DownstreamLeasedAssets.percentN
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3DownstreamLeasedAssets-target2025"
                    value={
                      answers2["e1_6_1-scope3DownstreamLeasedAssets-target2025"] ||
                      inputValues.e1_6_1.scope3DownstreamLeasedAssets.target2025
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3DownstreamLeasedAssets-target2030"
                    value={
                      answers2["e1_6_1-scope3DownstreamLeasedAssets-target2030"] ||
                      inputValues.e1_6_1.scope3DownstreamLeasedAssets.target2030
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3DownstreamLeasedAssets-target2050"
                    value={
                      answers2["e1_6_1-scope3DownstreamLeasedAssets-target2050"] ||
                      inputValues.e1_6_1.scope3DownstreamLeasedAssets.target2050
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3DownstreamLeasedAssets-annualPercentTarget"
                    value={
                      answers2["e1_6_1-scope3DownstreamLeasedAssets-annualPercentTarget"] ||
                      inputValues.e1_6_1.scope3DownstreamLeasedAssets.annualPercentTarget
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>14 Franchises</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3Franchises-baseYear"
                    value={
                      answers2["e1_6_1-scope3Franchises-baseYear"] ||
                      inputValues.e1_6_1.scope3Franchises.baseYear
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3Franchises-comparative"
                    value={
                      answers2["e1_6_1-scope3Franchises-comparative"] ||
                      inputValues.e1_6_1.scope3Franchises.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3Franchises-n"
                    value={
                      answers2["e1_6_1-scope3Franchises-n"] || inputValues.e1_6_1.scope3Franchises.n
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3Franchises-percentN"
                    value={
                      answers2["e1_6_1-scope3Franchises-percentN"] ||
                      inputValues.e1_6_1.scope3Franchises.percentN
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3Franchises-target2025"
                    value={
                      answers2["e1_6_1-scope3Franchises-target2025"] ||
                      inputValues.e1_6_1.scope3Franchises.target2025
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3Franchises-target2030"
                    value={
                      answers2["e1_6_1-scope3Franchises-target2030"] ||
                      inputValues.e1_6_1.scope3Franchises.target2030
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3Franchises-target2050"
                    value={
                      answers2["e1_6_1-scope3Franchises-target2050"] ||
                      inputValues.e1_6_1.scope3Franchises.target2050
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3Franchises-annualPercentTarget"
                    value={
                      answers2["e1_6_1-scope3Franchises-annualPercentTarget"] ||
                      inputValues.e1_6_1.scope3Franchises.annualPercentTarget
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>15 Investments</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3Investments-baseYear"
                    value={
                      answers2["e1_6_1-scope3Investments-baseYear"] ||
                      inputValues.e1_6_1.scope3Investments.baseYear
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3Investments-comparative"
                    value={
                      answers2["e1_6_1-scope3Investments-comparative"] ||
                      inputValues.e1_6_1.scope3Investments.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3Investments-n"
                    value={
                      answers2["e1_6_1-scope3Investments-n"] ||
                      inputValues.e1_6_1.scope3Investments.n
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3Investments-percentN"
                    value={
                      answers2["e1_6_1-scope3Investments-percentN"] ||
                      inputValues.e1_6_1.scope3Investments.percentN
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3Investments-target2025"
                    value={
                      answers2["e1_6_1-scope3Investments-target2025"] ||
                      inputValues.e1_6_1.scope3Investments.target2025
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3Investments-target2030"
                    value={
                      answers2["e1_6_1-scope3Investments-target2030"] ||
                      inputValues.e1_6_1.scope3Investments.target2030
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3Investments-target2050"
                    value={
                      answers2["e1_6_1-scope3Investments-target2050"] ||
                      inputValues.e1_6_1.scope3Investments.target2050
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-scope3Investments-annualPercentTarget"
                    value={
                      answers2["e1_6_1-scope3Investments-annualPercentTarget"] ||
                      inputValues.e1_6_1.scope3Investments.annualPercentTarget
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <th colSpan="9">Total GHG emissions</th>
              </tr>
              <tr>
                <td>Total GHG emissions (location-based) (tCO2eq)</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-totalGHGLocationBased-baseYear"
                    value={
                      answers2["e1_6_1-totalGHGLocationBased-baseYear"] ||
                      inputValues.e1_6_1.totalGHGLocationBased.baseYear
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-totalGHGLocationBased-comparative"
                    value={
                      answers2["e1_6_1-totalGHGLocationBased-comparative"] ||
                      inputValues.e1_6_1.totalGHGLocationBased.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-totalGHGLocationBased-n"
                    value={
                      answers2["e1_6_1-totalGHGLocationBased-n"] ||
                      inputValues.e1_6_1.totalGHGLocationBased.n
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-totalGHGLocationBased-percentN"
                    value={
                      answers2["e1_6_1-totalGHGLocationBased-percentN"] ||
                      inputValues.e1_6_1.totalGHGLocationBased.percentN
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-totalGHGLocationBased-target2025"
                    value={
                      answers2["e1_6_1-totalGHGLocationBased-target2025"] ||
                      inputValues.e1_6_1.totalGHGLocationBased.target2025
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-totalGHGLocationBased-target2030"
                    value={
                      answers2["e1_6_1-totalGHGLocationBased-target2030"] ||
                      inputValues.e1_6_1.totalGHGLocationBased.target2030
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-totalGHGLocationBased-target2050"
                    value={
                      answers2["e1_6_1-totalGHGLocationBased-target2050"] ||
                      inputValues.e1_6_1.totalGHGLocationBased.target2050
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-totalGHGLocationBased-annualPercentTarget"
                    value={
                      answers2["e1_6_1-totalGHGLocationBased-annualPercentTarget"] ||
                      inputValues.e1_6_1.totalGHGLocationBased.annualPercentTarget
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Total GHG emissions (market-based) (tCO2eq)</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-totalGHGMarketBased-baseYear"
                    value={
                      answers2["e1_6_1-totalGHGMarketBased-baseYear"] ||
                      inputValues.e1_6_1.totalGHGMarketBased.baseYear
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-totalGHGMarketBased-comparative"
                    value={
                      answers2["e1_6_1-totalGHGMarketBased-comparative"] ||
                      inputValues.e1_6_1.totalGHGMarketBased.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-totalGHGMarketBased-n"
                    value={
                      answers2["e1_6_1-totalGHGMarketBased-n"] ||
                      inputValues.e1_6_1.totalGHGMarketBased.n
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-totalGHGMarketBased-percentN"
                    value={
                      answers2["e1_6_1-totalGHGMarketBased-percentN"] ||
                      inputValues.e1_6_1.totalGHGMarketBased.percentN
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-totalGHGMarketBased-target2025"
                    value={
                      answers2["e1_6_1-totalGHGMarketBased-target2025"] ||
                      inputValues.e1_6_1.totalGHGMarketBased.target2025
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-totalGHGMarketBased-target2030"
                    value={
                      answers2["e1_6_1-totalGHGMarketBased-target2030"] ||
                      inputValues.e1_6_1.totalGHGMarketBased.target2030
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-totalGHGMarketBased-target2050"
                    value={
                      answers2["e1_6_1-totalGHGMarketBased-target2050"] ||
                      inputValues.e1_6_1.totalGHGMarketBased.target2050
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_1-totalGHGMarketBased-annualPercentTarget"
                    value={
                      answers2["e1_6_1-totalGHGMarketBased-annualPercentTarget"] ||
                      inputValues.e1_6_1.totalGHGMarketBased.annualPercentTarget
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        ),
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the datapoints on scope 3 emissions and total GHG emissions for the first year of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "48-52",
        fun: "Scope123",
      },
      {
        id: "q-209",
        question: " GHG Intensity",
        tooltip: (
          <Typography>
            <p className="question-tip">
              When disclosing the information on GHG intensity based on net revenue required under
              paragraph 53, the undertaking shall:
            </p>
            <ol className="question-tip" type="a">
              <li>
                calculate the GHG intensity ratio by the following formula:
                <br />
                𝑇𝑜𝑡𝑎𝑙 𝐺𝐻𝐺 𝑒𝑚𝑖𝑠𝑠𝑖𝑜𝑛𝑠 (𝑡 𝐶𝑂2𝑒𝑞)/𝑁𝑒𝑡 𝑟𝑒𝑣𝑒𝑛𝑢𝑒 (𝑀𝑜𝑛𝑒𝑡𝑎𝑟𝑦 𝑢𝑛𝑖𝑡)
              </li>
              <li>
                express the total GHG emissions in metric tonnes of CO2eq and the net revenue in
                monetary units (e.g., Euros) and present the results for the market-based and
                location- based method;
              </li>
              <li>
                include the total GHG emissions in the numerator and overall net revenue in the
                denominator;
              </li>
              <li>
                calculate the total GHG emissions as required by paragraphs 44 (d) and 52; and
              </li>
              <li>
                calculate the net revenue in line with the requirements in accounting standards
                applied for financial statements, i.e., IFRS 15 or local GAAP.
              </li>
            </ol>
            <p className="question-tip">
              The reconciliation of the net revenue used to calculate GHG intensity to the relevant
              line item or notes in the financial statements (as required by paragraph 55) may be
              done by either:
            </p>
            <ol className="question-tip" type="a">
              <li>
                a cross-reference to the related line item or disclosure in the financial
                statements; or
              </li>
              <li>
                if the net revenue cannot be directly cross-referenced to a line item or disclosure
                in the financial statements, by a quantitative reconciliation using the below
                tabular format.
              </li>
            </ol>
          </Typography>
        ),
        table: (
          <table className="question-table">
            <thead>
              <tr>
                <th>GHG intensity per net revenue</th>
                <th>Comparative</th>
                <th>N</th>
                <th>% N / N-1</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Total GHG emissions (location-based) per net revenue (tCO2eq/Monetary unit)</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_2-totalGHGLocation-comparative"
                    value={
                      answers2["e1_6_2-totalGHGLocation-comparative"] ||
                      inputValues.e1_6_2.totalGHGLocation.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_2-totalGHGLocation-n"
                    value={
                      answers2["e1_6_2-totalGHGLocation-n"] || inputValues.e1_6_2.totalGHGLocation.n
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_2-totalGHGLocation-percentage"
                    value={
                      answers2["e1_6_2-totalGHGLocation-percentage"] ||
                      inputValues.e1_6_2.totalGHGLocation.percentage
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>

              <tr>
                <td>Total GHG emissions (market-based) per net revenue (tCO2eq/Monetary unit)</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_2-totalGHGMarket-comparative"
                    value={
                      answers2["e1_6_2-totalGHGMarket-comparative"] ||
                      inputValues.e1_6_2.totalGHGMarket.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_2-totalGHGMarket-n"
                    value={
                      answers2["e1_6_2-totalGHGMarket-n"] || inputValues.e1_6_2.totalGHGMarket.n
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_6_2-totalGHGMarket-percentage"
                    value={
                      answers2["e1_6_2-totalGHGMarket-percentage"] ||
                      inputValues.e1_6_2.totalGHGMarket.percentage
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Net revenue used to calculate GHG intensity</td>
                <td colSpan="3">
                  <textarea
                    type="text"
                    name="e1_6_2-netRevenueUsed-total"
                    value={
                      answers2["e1_6_2-netRevenueUsed-total"] ||
                      inputValues.e1_6_2.netRevenueUsed.total
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Net revenue (other)</td>
                <td colSpan="3">
                  <textarea
                    type="text"
                    name="e1_6_2-netRevenueOther-total"
                    value={
                      answers2["e1_6_2-netRevenueOther-total"] ||
                      inputValues.e1_6_2.netRevenueOther.total
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Total net revenue (in financial statements)</td>
                <td colSpan="3">
                  <textarea
                    type="text"
                    name="e1_6_2-totalNetRevenue-total"
                    value={
                      answers2["e1_6_2-totalNetRevenue-total"] ||
                      inputValues.e1_6_2.totalNetRevenue.total
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        ),
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicators number 3 Table #1 of Annex 1{" "}
            </p>
            <p className="question-tip">
              <strong>Pillar 3 reference:</strong> Article 449a Regulation (EU) No 575/2013;
              Commission Implementing Regulation (EU) 2022/2453 Template 3: Banking book – Climate
              change transition risk: alignment metrics
            </p>
            <p className="question-tip">
              <strong>Benchmark Regulation reference:</strong> Delegated Regulation (EU) 2020/1818,
              Article 8(1)
            </p>
          </Typography>
        ),

        module: "Environment",
        boldPart: "53-55",
        fun: "GHGIntensity",
      },
      {
        id: "q-210",
        question:
          "When preparing the information for reporting GHG emissions as required by paragraph 44, the undertaking shall: disclose the methodologies, significant assumptions and emissions factors used to calculate or measure GHG emissions accompanied by the reasons why they were chosen, and provide a reference or link to any calculation tools used;",
        tooltip: "",
        module: "Environment",
        boldPart: "AR 39 b",
      },
      {
        id: "q-211",
        question:
          "When preparing the information on gross Scope 3 GHG emissions required under paragraph 51, the undertaking shall: screen its total Scope 3 GHG emissions based on the 15 Scope 3 categories identified by the GHG Protocol Corporate Standard and GHG Protocol Corporate Value Chain (Scope 3) Accounting and Reporting Standard (Version 2011) using appropriate estimates. Alternatively, it may screen its indirect GHG emissions based on the categories provided by EN ISO 14064-1:2018 clause 5.2.4 (excluding indirect GHG emissions from imported energy);",
        tooltip: "",
        module: "Environment",
        boldPart: "AR 42 c",
      },
      {
        id: "q-212",
        question:
          "When preparing the information on gross Scope 3 GHG emissions required under paragraph 51, the undertaking shall: screen its total Scope 3 GHG emissions based on the 15 Scope 3 categories identified by the GHG Protocol Corporate Standard and GHG Protocol Corporate Value Chain (Scope 3) Accounting and Reporting Standard (Version 2011) using appropriate estimates. Alternatively, it may screen its indirect GHG emissions based on the categories provided by EN ISO 14064-1:2018 clause 5.2.4 (excluding indirect GHG emissions from imported energy);",
        tooltip: "",
        module: "Environment",
        boldPart: "AR 43c",
      },
      {
        id: "q-213",
        question:
          "When preparing the information on gross Scope 3 GHG emissions required under paragraph 51, the undertaking shall: identify and disclose its significant Scope 3 categories based on the magnitude of their estimated GHG emissions and other criteria provided by GHG Protocol Corporate Value Chain (Scope 3) Accounting and Reporting Standard (Version 2011, p. 61 and 65-68) or EN ISO 14064-1:2018 Annex H.3.2, such as financial spend, influence, related transition risks and opportunities or stakeholder views;",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 45d",
      },
      {
        id: "q-214",
        question:
          "When preparing the information on gross Scope 3 GHG emissions required under paragraph 51, the undertaking shall: calculate or estimate GHG emissions in significant Scope 3 categories using suitable emissions factors;",
        tooltip: "",
        module: "Environment",
        boldPart: "AR 45e",
      },
      {
        id: "q-215",
        question:
          "When preparing the information on gross Scope 3 GHG emissions required under paragraph 51, the undertaking shall: disclose the extent to which the undertaking’s Scope 3 GHG emissions are measured using inputs from specific activities within the entity’s upstream and downstream value chain, and disclose the percentage of emissions calculated using primary data obtained from suppliers or other value chain partners.",
        tooltip: "",
        module: "Environment",
        boldPart: "AR 45g",
      },
      {
        id: "q-216",
        question:
          "When preparing the information on gross Scope 3 GHG emissions required under paragraph 51, the undertaking shall: disclose a list of Scope 3 GHG emissions categories included in and excluded from the inventory with a justification for excluded Scope 3 categories;",
        tooltip: "",
        module: "Environment",
        boldPart: "AR 46i",
      },
      {
        id: "q-217",
        question:
          "When preparing the information on gross Scope 3 GHG emissions required under paragraph 51, the undertaking shall: disclose biogenic emissions of CO2 from the combustion or biodegradation of biomass that occur in its upstream and downstream value chain separately from the gross Scope 3 GHG emissions, and include emissions of other types of GHG (such as CH4 and N2O), and emissions of CO2 that occur in the life cycle of biomass other than from combustion or biodegradation (such as GHG emissions from processing or transporting biomass) in the calculation of Scope 3 GHG emissions",
        tooltip: "",
        module: "Environment",
        boldPart: "AR 46j",
      },
      {
        id: "q-218",
        question:
          "When preparing the information on gross Scope 3 GHG emissions required under paragraph 51, the undertaking shall: for each significant Scope 3 GHG category, disclose the reporting boundaries considered, the calculation methods for estimating the GHG emissions as well as if and which calculation tools were applied. The Scope 3 categories should be consistent with the GHGP and include: i. indirect Scope 3 GHG emissions from the consolidated accounting group (the parent and its subsidiaries), ii. indirect Scope 3 GHG emissions from associates, joint ventures, and unconsolidated subsidiaries for which the undertaking has the ability to control the operational activities and relationships (i.e., operational control), iii. Scope 1, 2 and 3 GHG emissions from associates, joint ventures, unconsolidated subsidiaries (investment entities) and joint arrangements for which the undertaking does not have operational control and when these entities are part of the undertaking’s upstream and dopwnstream value chain.",
        tooltip: "",
        module: "Environment",
        boldPart: "AR 46 h",
      },

      // Add more questions as needed
    ],
    "E1-7 – GHG removals and GHG mitigation projects financed through carbon credits": [
      {
        id: "q-219",
        question:
          "The undertaking shall disclose: (a) GHG removals and storage in metric tonnes of CO2eq resulting from projects it may have developed in its own operations, or contributed to in its upstream and downstream value chain; and",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 56. In addition to their GHG emission inventories, undertakings shall provide
              transparency on how and to what extent they either enhance natural sinks or apply
              technical solutions to remove GHGs from the atmosphere in their own operations and
              upstream and downstream value chain. While there are no generally accepted concepts
              and methodologies for accounting for GHG removals, this Standard aims to increase
              transparency on the undertaking’s efforts to remove GHGs from the atmosphere
              (paragraphs 56 (a) and 58). The GHG removals outside the value chain that the
              undertaking supports through the purchase of carbon credits are to be disclosed
              separately as required by paragraphs 56 (b) and 59.
            </p>
            <p className="question-tip">
              AR 57. When disclosing the information on GHG removals and storage from the
              undertaking’s own operations and its upstream and downstream value chain required
              under paragraphs 56 (a) and 58, for each removal and storage activity, the undertaking
              shall describe:
            </p>
            <ol className="question-tip" type="a">
              <li>the GHGs concerned; </li>
              <li>
                whether removal and storage are biogenic or from land-use change (e.g.,
                afforestation, reforestation, forest restoration, urban tree planting, agroforestry,
                building soil carbon, etc.), technological (e.g., direct air capture), or hybrid
                (e.g., bioenergy with CO2 capture and storage), and technological details about the
                removal, the type of storage and, if applicable, the transport of removed GHGs;
              </li>
              <li>
                if applicable, a brief explanation of whether the activity qualifies as a
                nature-based solution; and
              </li>
              <li>
                how the risk of non-permanence is managed, including determining and monitoring
                leakage and reversal events, as appropriate.
              </li>
            </ol>
          </Typography>
        ),
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>EU Climate Law reference:</strong> Regulation (EU) 2021/1119, Article 2(1)
            </p>
          </Typography>
        ),

        module: "Environment",
        boldPart: "56 a",
      },
      {
        id: "q-220",
        question:
          "the amount of GHG emission reductions or removals from climate change mitigation projects outside its value chain it has financed or intends to finance through any purchase of carbon credits.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 56. In addition to their GHG emission inventories, undertakings shall provide
              transparency on how and to what extent they either enhance natural sinks or apply
              technical solutions to remove GHGs from the atmosphere in their own operations and
              upstream and downstream value chain. While there are no generally accepted concepts
              and methodologies for accounting for GHG removals, this Standard aims to increase
              transparency on the undertaking’s efforts to remove GHGs from the atmosphere
              (paragraphs 56 (a) and 58). The GHG removals outside the value chain that the
              undertaking supports through the purchase of carbon credits are to be disclosed
              separately as required by paragraphs 56 (b) and 59.
            </p>
          </Typography>
        ),
        module: "Environment",
        boldPart: "56b",
      },
      {
        id: "q-221",
        question: " E1-7 – GHG removal",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 56. In addition to their GHG emission inventories, undertakings shall provide
              transparency on how and to what extent they either enhance natural sinks or apply
              technical solutions to remove GHGs from the atmosphere in their own operations and
              upstream and downstream value chain. While there are no generally accepted concepts
              and methodologies for accounting for GHG removals, this Standard aims to increase
              transparency on the undertaking’s efforts to remove GHGs from the atmosphere
              (paragraphs 56 (a) and 58). The GHG removals outside the value chain that the
              undertaking supports through the purchase of carbon credits are to be disclosed
              separately as required by paragraphs 56 (b) and 59.
            </p>
          </Typography>
        ),
        table: (
          <table className="question-table">
            <thead>
              <tr>
                <th>Removals</th>
                <th>Comparative</th>
                <th>N</th>
                <th>% N / N-1</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>GHG removal activity 1 (e.g., forest restoration)</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_7_1-ghgRemovalActivity1-comparative"
                    value={
                      answers2["e1_7_1-ghgRemovalActivity1-comparative"] ||
                      inputValues.e1_7_1.ghgRemovalActivity1.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_7_1-ghgRemovalActivity1-n"
                    value={
                      answers2["e1_7_1-ghgRemovalActivity1-n"] ||
                      inputValues.e1_7_1.ghgRemovalActivity1.n
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_7_1-ghgRemovalActivity1-percentN"
                    value={
                      answers2["e1_7_1-ghgRemovalActivity1-percentN"] ||
                      inputValues.e1_7_1.ghgRemovalActivity1.percentN
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>GHG removal activity 2 (e.g., direct air capture)</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_7_1-ghgRemovalActivity2-comparative"
                    value={
                      answers2["e1_7_1-ghgRemovalActivity2-comparative"] ||
                      inputValues.e1_7_1.ghgRemovalActivity2.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_7_1-ghgRemovalActivity2-n"
                    value={
                      answers2["e1_7_1-ghgRemovalActivity2-n"] ||
                      inputValues.e1_7_1.ghgRemovalActivity2.n
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_7_1-ghgRemovalActivity2-percentN"
                    value={
                      answers2["e1_7_1-ghgRemovalActivity2-percentN"] ||
                      inputValues.e1_7_1.ghgRemovalActivity2.percentN
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Other GHG removal activity</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_7_1-otherGHGRemovalActivity-comparative"
                    value={
                      answers2["e1_7_1-otherGHGRemovalActivity-comparative"] ||
                      inputValues.e1_7_1.otherGHGRemovalActivity.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_7_1-otherGHGRemovalActivity-n"
                    value={
                      answers2["e1_7_1-otherGHGRemovalActivity-n"] ||
                      inputValues.e1_7_1.otherGHGRemovalActivity.n
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_7_1-otherGHGRemovalActivity-percentN"
                    value={
                      answers2["e1_7_1-otherGHGRemovalActivity-percentN"] ||
                      inputValues.e1_7_1.otherGHGRemovalActivity.percentN
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "600" }}>
                  Total GHG removals from own operations (tCO2eq)
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_7_1-totalOwnOperations-comparative"
                    value={
                      answers2["e1_7_1-totalOwnOperations-comparative"] ||
                      inputValues.e1_7_1.totalOwnOperations.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_7_1-totalOwnOperations-n"
                    value={
                      answers2["e1_7_1-totalOwnOperations-n"] ||
                      inputValues.e1_7_1.totalOwnOperations.n
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_7_1-totalOwnOperations-percentN"
                    value={
                      answers2["e1_7_1-totalOwnOperations-percentN"] ||
                      inputValues.e1_7_1.totalOwnOperations.percentN
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>GHG removal activity 1 (e.g., forest restoration) Upstream</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_7_1-ghgRemovalActivity1Upstream-comparative"
                    value={
                      answers2["e1_7_1-ghgRemovalActivity1Upstream-comparative"] ||
                      inputValues.e1_7_1.ghgRemovalActivity1Upstream.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_7_1-ghgRemovalActivity1Upstream-n"
                    value={
                      answers2["e1_7_1-ghgRemovalActivity1Upstream-n"] ||
                      inputValues.e1_7_1.ghgRemovalActivity1Upstream.n
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_7_1-ghgRemovalActivity1Upstream-percentN"
                    value={
                      answers2["e1_7_1-ghgRemovalActivity1Upstream-percentN"] ||
                      inputValues.e1_7_1.ghgRemovalActivity1Upstream.percentN
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>GHG removal activity 2 (e.g., direct air capture) Upstream</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_7_1-ghgRemovalActivity2Upstream-comparative"
                    value={
                      answers2["e1_7_1-ghgRemovalActivity2Upstream-comparative"] ||
                      inputValues.e1_7_1.ghgRemovalActivity2Upstream.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_7_1-ghgRemovalActivity2Upstream-n"
                    value={
                      answers2["e1_7_1-ghgRemovalActivity2Upstream-n"] ||
                      inputValues.e1_7_1.ghgRemovalActivity2Upstream.n
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_7_1-ghgRemovalActivity2Upstream-percentN"
                    value={
                      answers2["e1_7_1-ghgRemovalActivity2Upstream-percentN"] ||
                      inputValues.e1_7_1.ghgRemovalActivity2Upstream.percentN
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Other GHG removal activity Upstream</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_7_1-otherGHGRemovalActivityUpstream-comparative"
                    value={
                      answers2["e1_7_1-otherGHGRemovalActivityUpstream-comparative"] ||
                      inputValues.e1_7_1.otherGHGRemovalActivityUpstream.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_7_1-otherGHGRemovalActivityUpstream-n"
                    value={
                      answers2["e1_7_1-otherGHGRemovalActivityUpstream-n"] ||
                      inputValues.e1_7_1.otherGHGRemovalActivityUpstream.n
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_7_1-otherGHGRemovalActivityUpstream-percentN"
                    value={
                      answers2["e1_7_1-otherGHGRemovalActivityUpstream-percentN"] ||
                      inputValues.e1_7_1.otherGHGRemovalActivityUpstream.percentN
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "600" }}>
                  Total GHG removals in the upstream and downstream value chain (tCO2eq)
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_7_1-totalUpstreamDownstream-comparative"
                    value={
                      answers2["e1_7_1-totalUpstreamDownstream-comparative"] ||
                      inputValues.e1_7_1.totalUpstreamDownstream.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_7_1-totalUpstreamDownstream-n"
                    value={
                      answers2["e1_7_1-totalUpstreamDownstream-n"] ||
                      inputValues.e1_7_1.totalUpstreamDownstream.n
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_7_1-totalUpstreamDownstream-percentN"
                    value={
                      answers2["e1_7_1-totalUpstreamDownstream-percentN"] ||
                      inputValues.e1_7_1.totalUpstreamDownstream.percentN
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "600" }}>Reversals (tCO2eq)</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_7_1-reversals-comparative"
                    value={
                      answers2["e1_7_1-reversals-comparative"] ||
                      inputValues.e1_7_1.reversals.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_7_1-reversals-n"
                    value={answers2["e1_7_1-reversals-n"] || inputValues.e1_7_1.reversals.n}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_7_1-reversals-percentN"
                    value={
                      answers2["e1_7_1-reversals-percentN"] || inputValues.e1_7_1.reversals.percentN
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        ),
        module: "Environment",
        boldPart: "58, AR 58f, AR 60",
        fun: "4Column",
      },
      {
        id: "q-222",
        question: " Table: E1-7 – Carbon credits",
        tooltip: "",
        table: (
          <>
            <table className="question-table">
              <thead>
                <tr>
                  <th>Carbon credits cancelled in the reporting year</th>
                  <th>Comparative</th>
                  <th>N</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ fontWeight: "600" }}>Total (tCO2eq)</td>
                  <td>
                    <textarea
                      type="text"
                      name="e1_7_2-totalCarbonCreditsCancelled-comparative"
                      value={
                        answers2["e1_7_2-totalCarbonCreditsCancelled-comparative"] ||
                        inputValues.e1_7_2.totalCarbonCreditsCancelled.comparative
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <textarea
                      type="text"
                      name="e1_7_2-totalCarbonCreditsCancelled-n"
                      value={
                        answers2["e1_7_2-totalCarbonCreditsCancelled-n"] ||
                        inputValues.e1_7_2.totalCarbonCreditsCancelled.n
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Share from removal projects (%)</td>
                  <td>
                    <textarea
                      type="text"
                      name="e1_7_2-shareRemovalProjects-comparative"
                      value={
                        answers2["e1_7_2-shareRemovalProjects-comparative"] ||
                        inputValues.e1_7_2.shareRemovalProjects.comparative
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <textarea
                      type="text"
                      name="e1_7_2-shareRemovalProjects-n"
                      value={
                        answers2["e1_7_2-shareRemovalProjects-n"] ||
                        inputValues.e1_7_2.shareRemovalProjects.n
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Share from reduction projects (%)</td>
                  <td>
                    <textarea
                      type="text"
                      name="e1_7_2-shareReductionProjects-comparative"
                      value={
                        answers2["e1_7_2-shareReductionProjects-comparative"] ||
                        inputValues.e1_7_2.shareReductionProjects.comparative
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <textarea
                      type="text"
                      name="e1_7_2-shareReductionProjects-n"
                      value={
                        answers2["e1_7_2-shareReductionProjects-n"] ||
                        inputValues.e1_7_2.shareReductionProjects.n
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Recognised quality standard 1 (%)</td>
                  <td>
                    <textarea
                      type="text"
                      name="e1_7_2-recognisedQualityStandard1-comparative"
                      value={
                        answers2["e1_7_2-recognisedQualityStandard1-comparative"] ||
                        inputValues.e1_7_2.recognisedQualityStandard1.comparative
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <textarea
                      type="text"
                      name="e1_7_2-recognisedQualityStandard1-n"
                      value={
                        answers2["e1_7_2-recognisedQualityStandard1-n"] ||
                        inputValues.e1_7_2.recognisedQualityStandard1.n
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Recognised quality standard 2 (%)</td>
                  <td>
                    <textarea
                      type="text"
                      name="e1_7_2-recognisedQualityStandard2-comparative"
                      value={
                        answers2["e1_7_2-recognisedQualityStandard2-comparative"] ||
                        inputValues.e1_7_2.recognisedQualityStandard2.comparative
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <textarea
                      type="text"
                      name="e1_7_2-recognisedQualityStandard2-n"
                      value={
                        answers2["e1_7_2-recognisedQualityStandard2-n"] ||
                        inputValues.e1_7_2.recognisedQualityStandard2.n
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Recognised quality standard 3 (%)</td>
                  <td>
                    <textarea
                      type="text"
                      name="e1_7_2-recognisedQualityStandard3-comparative"
                      value={
                        answers2["e1_7_2-recognisedQualityStandard3-comparative"] ||
                        inputValues.e1_7_2.recognisedQualityStandard3.comparative
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <textarea
                      type="text"
                      name="e1_7_2-recognisedQualityStandard3-n"
                      value={
                        answers2["e1_7_2-recognisedQualityStandard3-n"] ||
                        inputValues.e1_7_2.recognisedQualityStandard3.n
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Other recognised quality standards</td>
                  <td>
                    <textarea
                      type="text"
                      name="e1_7_2-otherRecognisedQualityStandards-comparative"
                      value={
                        answers2["e1_7_2-otherRecognisedQualityStandards-comparative"] ||
                        inputValues.e1_7_2.otherRecognisedQualityStandards.comparative
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <textarea
                      type="text"
                      name="e1_7_2-otherRecognisedQualityStandards-n"
                      value={
                        answers2["e1_7_2-otherRecognisedQualityStandards-n"] ||
                        inputValues.e1_7_2.otherRecognisedQualityStandards.n
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Share from projects within the EU (%)</td>
                  <td>
                    <textarea
                      type="text"
                      name="e1_7_2-shareEUProjects-comparative"
                      value={
                        answers2["e1_7_2-shareEUProjects-comparative"] ||
                        inputValues.e1_7_2.shareEUProjects.comparative
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <textarea
                      type="text"
                      name="e1_7_2-shareEUProjects-n"
                      value={
                        answers2["e1_7_2-shareEUProjects-n"] || inputValues.e1_7_2.shareEUProjects.n
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Share of carbon credits that qualify as corresponding adjustments (%)</td>
                  <td>
                    <textarea
                      type="text"
                      name="e1_7_2-shareCorrespondingAdjustments-comparative"
                      value={
                        answers2["e1_7_2-shareCorrespondingAdjustments-comparative"] ||
                        inputValues.e1_7_2.shareCorrespondingAdjustments.comparative
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <textarea
                      type="text"
                      name="e1_7_2-shareCorrespondingAdjustments-n"
                      value={
                        answers2["e1_7_2-shareCorrespondingAdjustments-n"] ||
                        inputValues.e1_7_2.shareCorrespondingAdjustments.n
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Carbon credits planned to be cancelled in the future</th>
                  <th colSpan="2">Amount until [period]</th>
                </tr>
                <tr>
                  <td style={{ fontWeight: "600" }}>Total (tCO2eq)</td>
                  <td colSpan="2">
                    <textarea
                      type="text"
                      name="e1_7_2-totalCarbonCreditsPlanned-amountUntilPeriod"
                      value={
                        answers2["e1_7_2-totalCarbonCreditsPlanned-amountUntilPeriod"] ||
                        inputValues.e1_7_2.totalCarbonCreditsPlanned.amountUntilPeriod
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        ),
        module: "Environment",
        boldPart: "59 a , 59 b, AR 64",
        fun: "E1-7",
      },
      {
        id: "q-223",
        question:
          "Financing GHG emission reduction projects outside the undertaking’s value chain through purchasing carbon credits that fulfil high-quality standards can be a useful contribution towards mitigating climate change. This Standard requires the undertaking to disclose whether it uses carbon credits separately from the GHG emissions (paragraphs 56 (b) and 59) and GHG emission reduction targets (Disclosure Requirement E1-4). It also requires the undertaking to show the extent of use and which quality criteria it uses for those carbon credits.",
        tooltip: "",
        module: "Environment",
        boldPart: "AR 61",
      },
      {
        id: "q-224",
        question:
          "When disclosing the information on carbon credits required under paragraphs 56 (b) and 59, the undertaking shall disclose the following disaggregation as applicable: the share (percentage of volume) of reduction projects and removal projects;",
        tooltip: "",
        module: "Environment",
        boldPart: "AR 62 a",
      },

      {
        id: "q-225",
        question:
          "When disclosing the information on carbon credits required under paragraphs 56 (b) and 59, the undertaking shall disclose the following disaggregation as applicable: for carbon credits from removal projects, an explanation whether they are from biogenic or technological sinks;",
        tooltip: "",
        module: "Environment",
        boldPart: "AR 62 b",
      },

      {
        id: "q-226",
        question:
          "When disclosing the information on carbon credits required under paragraphs 56 (b) and 59, the undertaking shall disclose the following disaggregation as applicable: the share (percentage of volume) for each recognised quality standard;",
        tooltip: "",
        module: "Environment",
        boldPart: "AR 62 c",
      },

      {
        id: "q-227",
        question:
          "When disclosing the information on carbon credits required under paragraphs 56 (b) and 59, the undertaking shall disclose the following disaggregation as applicable:the share (percentage of volume) issued from projects in the EU; and",
        tooltip: "",
        module: "Environment",
        boldPart: "AR 62 d",
      },
      {
        id: "q-228",
        question:
          "When disclosing the information on carbon credits required under paragraphs 56 (b) and 59, the undertaking shall disclose the following disaggregation as applicable: the share (percentage of volume) that qualifies as a corresponding adjustment under Article. 6 of the Paris Agreement.",
        tooltip: "",
        module: "Environment",
        boldPart: "AR 62 e",
      },
      {
        id: "q-229",
        question:
          "In the case where the undertaking discloses a net-zero target in addition to the gross GHG emission reduction targets in accordance with Disclosure Requirement E1-4, paragraph 30, it shall explain the scope, methodologies and frameworks applied and how the residual GHG emissions (after approximately 90-95% of GHG emission reduction with the possibility for justified sectoral variations in line with a recognised sectoral decarbonisation pathway) are intended to be neutralised by, for example, GHG removals in its own operations and upstream and donwstream value chain.",
        tooltip: "",
        module: "Environment",
        boldPart: "60",
      },
      {
        id: "q-230",
        question:
          "In the case where the undertaking may have made public claims of GHG neutrality that involve the use of carbon credits, it shall explain: (a) whether and how these claims are accompanied by GHG emission reduction targets as required by Disclosure requirement ESRS E1-4;",
        tooltip: "",
        module: "Environment",
        boldPart: "61 a",
      },
      {
        id: "q-231",
        question:
          "Whether and how these claims and the reliance on carbon credits neither impede nor reduce the achievement of its GHG emission reduction targets12, or, if applicable, its net zero target; and",
        tooltip: "",
        module: "Environment",
        boldPart: "61b",
      },
      {
        id: "q-232",
        question:
          "The credibility and integrity of the carbon credits used, including by reference to recognised quality standards.",
        tooltip: "",
        module: "Environment",
        boldPart: "61c",
      },
      {
        id: "q-233",
        question:
          " When disclosing the information on carbon credits required under paragraphs 56 (b) and 59, the undertaking shall disclose the following disaggregation as applicable: (a) the share (percentage of volume) of reduction projects and removal projects; (b) for carbon credits from removal projects, an explanation whether they are from biogenic or technological sinks; (c) the share (percentage of volume) for each recognised quality standard; (d) the share (percentage of volume) issued from projects in the EU; and (e) the share (percentage of volume) that qualifies as a corresponding adjustment under Article. 6 of the Paris Agreement.",
        tooltip: "",
        module: "Environment",
        boldPart: "AR 62",
      },
      // Add more questions as needed
    ],
    "E1-8 – Internal carbon pricing": [
      {
        id: "q-234",
        question: " Table E1-8 – Internal carbon pricing",
        tooltip: "",
        table: (
          <table className="question-table">
            <thead>
              <tr>
                <th>Types of internal carbon prices</th>
                <th>Volume at stake (tCO2eq)</th>
                <th>Prices applied (€/tCO2eq)</th>
                <th>Perimeter description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>CapEx shadow price</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_8-capEx-volumeAtStake"
                    value={
                      answers2["e1_8-capEx-volumeAtStake"] || inputValues.e1_8.capEx.volumeAtStake
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_8-capEx-pricesApplied"
                    value={
                      answers2["e1_8-capEx-pricesApplied"] || inputValues.e1_8.capEx.pricesApplied
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_8-capEx-perimeterDescription"
                    value={
                      answers2["e1_8-capEx-perimeterDescription"] ||
                      inputValues.e1_8.capEx.perimeterDescription
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Research and Development (R&D) investment shadow price</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_8-rd-volumeAtStake"
                    value={answers2["e1_8-rd-volumeAtStake"] || inputValues.e1_8.rd.volumeAtStake}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_8-rd-pricesApplied"
                    value={answers2["e1_8-rd-pricesApplied"] || inputValues.e1_8.rd.pricesApplied}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_8-rd-perimeterDescription"
                    value={
                      answers2["e1_8-rd-perimeterDescription"] ||
                      inputValues.e1_8.rd.perimeterDescription
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Internal carbon fee or fund</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_8-carbonFee-volumeAtStake"
                    value={
                      answers2["e1_8-carbonFee-volumeAtStake"] ||
                      inputValues.e1_8.carbonFee.volumeAtStake
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_8-carbonFee-pricesApplied"
                    value={
                      answers2["e1_8-carbonFee-pricesApplied"] ||
                      inputValues.e1_8.carbonFee.pricesApplied
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_8-carbonFee-perimeterDescription"
                    value={
                      answers2["e1_8-carbonFee-perimeterDescription"] ||
                      inputValues.e1_8.carbonFee.perimeterDescription
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Carbon prices for impairment testing</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_8-testing-volumeAtStake"
                    value={
                      answers2["e1_8-testing-volumeAtStake"] ||
                      inputValues.e1_8.testing.volumeAtStake
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_8-testing-pricesApplied"
                    value={
                      answers2["e1_8-testing-pricesApplied"] ||
                      inputValues.e1_8.testing.pricesApplied
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_8-testing-perimeterDescription"
                    value={
                      answers2["e1_8-testing-perimeterDescription"] ||
                      inputValues.e1_8.testing.perimeterDescription
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Other internal carbon pricing mechanisms</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_8-otherICarbon-volumeAtStake"
                    value={
                      answers2["e1_8-otherICarbon-volumeAtStake"] ||
                      inputValues.e1_8.otherICarbon.volumeAtStake
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_8-otherICarbon-pricesApplied"
                    value={
                      answers2["e1_8-otherICarbon-pricesApplied"] ||
                      inputValues.e1_8.otherICarbon.pricesApplied
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e1_8-otherICarbon-perimeterDescription"
                    value={
                      answers2["e1_8-otherICarbon-perimeterDescription"] ||
                      inputValues.e1_8.otherICarbon.perimeterDescription
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        ),
        module: "Environment",
        boldPart: "63 a, 63 b, 63 c",
        fun: "4Column",
      },
      {
        id: "q-235",
        question:
          "The current year approximate gross GHG emission volumes by Scopes 1, 2 and, where applicable, Scope 3 in metric tonnes of CO2eq covered by these schemes, as well as their share of the undertaking’s overall GHG emissions for each respective Scope.",
        tooltip: "",
        module: "Environment",
        boldPart: "63d",
      },
      {
        id: "q-236",
        question:
          "When disclosing the information required under paragraphs 62 and 63, if applicable, the undertaking shall briefly explain whether and how the carbon prices used in internal carbon pricing schemes are consistent with those used in financial statements. This shall be done in respect of the internal carbon prices used for, (a) the assessment of the useful life and residual value of its assets (intangibles, property, plant and equipment); (b) the impairment of assets; and (c) the fair value measurement of assets acquired through business acquisitions.",

        tooltip: "",
        module: "Environment",
        boldPart: "AR 65",
      },
      // Add more questions as needed
    ],
    "E1-9 – Anticipated financial effects from material physical and transition risks and potential climate-related opportunities":
      [
        {
          id: "q-237",
          question:
            "The disclosure of anticipated financial effects from material physical risks required by paragraph 64 (a) shall include: the monetary amount and proportion (percentage) of assets at material physical risk over the short-, medium- and long-term before considering climate change adaptation actions; with the monetary amounts of these assets disaggregated by acute and chronic physical risk;",
          tooltip: (
            <Typography>
              <p className="question-tip">
                AR 70. When preparing the information on assets at material physical risk that is
                required to be disclosed under paragraph 66 (a), the undertaking shall:
              </p>
              <ol className="question-tip" type="a">
                <li>
                  Calculate the assets at material physical risk in terms of monetary amount and as
                  a proportion (percentage) of total assets at the reporting date (i.e., the
                  proportion is an estimate of the carrying value of assets at material physical
                  risk divided by total carrying value as stated in the statement of financial
                  position or balance sheet). The estimate of assets at material physical risk shall
                  be derived starting from the assets recognised in the financial statements. The
                  estimate of monetary amounts and proportion of assets at physical risk may be
                  presented as either a single amount or range.
                </li>
                <li>
                  All types of assets including finance-lease / right-of-use assets shall be
                  considered when determining the assets at material physical risk.
                </li>
                <li>
                  To contextualise this information, the undertaking shall:
                  <ol className="question-tip" type="i">
                    <li>
                      disclose the location of its significant assets at material physical risk.
                      Significant assets located19 in the EU territory shall be aggregated by NUTS
                      codes 3 level digits (Nomenclature of Territorial Units for Statistics). For
                      significant assets located outside EU territory, the breakdown by NUTS code
                      will only be provided where applicable.
                    </li>
                    <li>
                      disaggregate the monetary amounts of assets at risk by acute and chronic
                      physical risk20.
                    </li>
                  </ol>
                </li>
                <li>
                  calculate the share of assets at material physical risk resulting from paragraph
                  66 (a) that is addressed by the climate change adaptation actions based on the
                  information disclosed under Disclosure Requirement E1-3. This aims at
                  approximating net risks.
                </li>
              </ol>
            </Typography>
          ),
          euReg: (
            <Typography>
              <p className="question-tip">
                <strong>Pillar 3 reference:</strong> 1- Article 449a Regulation (EU) No 575/2013; 2-
                Commission Implementing Regulation (EU) 2022/2453 paragraphs 46 and 47; Template 5:
                Banking book - Climate change physical risk: Exposures subject to physical risk.
              </p>
            </Typography>
          ),
          module: "Environment",
          boldPart: "66a",
        },
        {
          id: "q-238",
          question:
            " E1-9 – Anticipated financial effects from material physical and transition risks and potential climate-related opportunities",
          tooltip:
            "When preparing the information on assets at material physical risk that is required to be disclosed under paragraph 66 (a), the undertaking shall: To contextualise this information, the undertaking shall: i. disclose the location of its significant assets at material physical risk. Significant assets located19 in the EU territory shall be aggregated by NUTS codes 3 level digits (Nomenclature of Territorial Units for Statistics). For significant assets located outside EU territory, the breakdown by NUTS code will only be provided where applicable.",
          table: (
            <table className="question-table">
              <thead></thead>
              <tbody>
                <tr>
                  <td>
                    Carrying amount of assets or liabilities or net revenue vulnerable to either
                    material physical or transition risks
                  </td>
                  <td>
                    <textarea
                      type="text"
                      name="e1_9-carryingAmount-netValue"
                      value={
                        answers2["e1_9-carryingAmount-netValue"] ||
                        inputValues.e1_9.carryingAmount.netValue
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Adjusting items</td>
                  <td>
                    <textarea
                      type="text"
                      name="e1_9-adjustingItems-netValue"
                      value={
                        answers2["e1_9-adjustingItems-netValue"] ||
                        inputValues.e1_9.adjustingItems.netValue
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Assets or liabilities or net revenue in the financial statements</td>
                  <td>
                    <textarea
                      type="text"
                      name="e1_9-netRevenue-netValue"
                      value={
                        answers2["e1_9-netRevenue-netValue"] || inputValues.e1_9.netRevenue.netValue
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          ),
          euReg: (
            <Typography>
              <p className="question-tip">
                <strong>Pillar 3 reference:</strong> 1- Article 449a Regulation (EU) No 575/2013; 2-
                Commission Implementing Regulation (EU) 2022/2453 paragraphs 46 and 47; Template 5:
                Banking book - Climate change physical risk: Exposures subject to physical risk.
              </p>
            </Typography>
          ),
          module: "Environment",
          boldPart: "AR 70 c i)",
          fun: "2ColumnWH",
        },
        {
          id: "q-239",
          question:
            "The proportion of assets at material physical risk addressed by the climate change adaptation actions;",
          tooltip: "",
          module: "Environment",
          boldPart: "66 b",
        },
        {
          id: "q-240",
          question:
            "The monetary amount and proportion (percentage) of net revenue from its business activities at material physical risk over the short-, medium- and long-term",
          tooltip: (
            <Typography>
              <p className="question-tip">
                When preparing the information required under paragraph 64 (a) and 66 (d), the
                undertaking may assess and disclose the share of net revenue from business
                activities at physical risk. This disclosure <br />
                <br />
                (a) shall be based on the net revenue in line with the requirements in accounting
                standards applied for financial statements, i.e., IFRS 15 or local GAAP.
                <br />
                (b) may include a breakdown of the undertaking’s business activities with the
                corresponding details of the associated percentage of total net revenue, the risk
                factors (hazards, exposure and sensitivity) and, if possible, the magnitude of the
                anticipated financial effects in terms of margin erosion over the short-, medium-
                and long-term time horizons. The nature of business activities may also be
                disaggregated by operating segments if the undertaking has disclosed the
                contribution of margins by operational segments in its segment reporting in the
                financial statements.
              </p>
            </Typography>
          ),

          module: "Environment",
          boldPart: "66d",
        },
        {
          id: "q-241",
          question:
            "When disclosing the information required under paragraphs 64 (a) and 66, the undertaking shall explain whether and how: (a) it assessed the anticipated financial effects for assets and business activities at material physical risk, including the scope of application, time horizons, calculation methodology, critical assumptions and parameters and limitations of the assessment; and",
          tooltip: "",
          module: "Environment",
          boldPart: "AR 69 a",
        },
        {
          id: "q-242",
          question:
            "When disclosing the information required under paragraphs 64 (a) and 66, the undertaking shall explain whether and how: (b) the assessment of assets and business activities considered to be at material physical risk relies on or is part of the process to determine material physical risk as required under paragraphs 20 (b) and AR 11 and to determine climate scenarios as required under paragraphs 19 and AR 13 to AR 14. In particular, it shall explain how it has defined medium- and long-term time horizons and how these definitions are linked to the expected lifetime of the undertaking’s assets, strategic planning horizons and capital allocation plans",
          tooltip: "",
          module: "Environment",
          boldPart: "AR 69 b",
        },
        {
          id: "q-243",
          question:
            "When preparing the information required under paragraph 64 (a) and 66 (d), the undertaking may assess and disclose the share of net revenue from business activities at physical risk. This disclosure (b) may include a breakdown of the undertaking’s business activities with the corresponding details of the associated percentage of total net revenue, the risk factors (hazards, exposure and sensitivity) and, if possible, the magnitude of the anticipated financial effects in terms of margin erosion over the short-, medium- and long-term time horizons. The nature of business activities may also be disaggregated by operating segments if the undertaking has disclosed the contribution of margins by operational segments in its segment reporting in the financial statements.",
          tooltip: "",
          voluntary: true,
          module: "Environment",
          boldPart: "AR 71 b",
        },
        {
          id: "q-244",
          question:
            "The disclosure of anticipated financial effects from material transition risks required by paragraph 64 (b) shall include: the monetary amount and proportion (percentage) of assets at material transition risk over the short-, medium- and long-term before considering climate mitigation actions;",
          tooltip: "",
          module: "Environment",
          boldPart: "67a",
        },
        {
          id: "q-245",
          question:
            "The proportion of assets at material transition risk addressed by the climate change mitigation actions",
          tooltip: "",
          module: "Environment",
          boldPart: "67b",
        },
        {
          id: "q-246",
          question:
            "A breakdown of the carrying value of the undertaking’s real estate assets by energyefficiency classes",
          tooltip: "",
          module: "Environment",
          boldPart: "67c",
        },
        {
          id: "q-247",
          question:
            "When disclosing the information required under paragraphs 64 (b) and 67 (a), the undertaking shall explain whether and how: (a) it has assessed the potential effects on future financial performance and position for assets and business activities at material transition risk, including the scope of application, calculation methodology, critical assumptions and parameters, and limitations of the assessment; and When disclosing the information on assets at material transition risk as required under paragraphs 67 (a) and (b): (a) the undertaking shall at the very least include an estimate of the amount of potentially stranded assets (in monetary amounts and as a proportion/percentage) from the reporting year until 2030 and from 2030 to 2050. Stranded assets are understood as the active or firmly planned key assets of the undertaking with significant locked-in GHG emissions over their operating lifetime. Firmly planned key assets are those that the undertaking will most likely deploy within the next 5 years. The amount may be expressed as a range of asset values based on different climate and policy scenarios, including a scenario aligned with limiting climate change to 1.5°C.",
          tooltip: "",
          module: "Environment",
          boldPart: "AR 72a, AR 73a",
        },
        {
          id: "q-248",
          question:
            "When disclosing the information required under paragraphs 64 (b) and 67 (a), the undertaking shall explain whether and how: (b) the assessment of assets and business activities considered to be at material transition risk relies on or is part of the process to determine material transition risks as described under paragraphs 20 (c) and AR 11 and to determine scenarios as required under paragraphs AR 12 to AR 13. In particular, it shall explain how it has defined medium- and long-term time horizons and how these definitions are linked to the expected lifetime of the undertaking’s assets, strategic planning horizons and capital allocation plans.",
          tooltip: "",
          module: "Environment",
          boldPart: "AR 72b",
        },
        {
          id: "q-249",
          question:
            "When disclosing the information on assets at material transition risk as required under paragraphs 67 (a) and (b): (a) the undertaking shall at the very least include an estimate of the amount of potentially stranded assets (in monetary amounts and as a proportion/percentage) from the reporting year until 2030 and from 2030 to 2050. Stranded assets are understood as the active or firmly planned key assets of the undertaking with significant locked-in GHG emissions over their operating lifetime. Firmly planned key assets are those that the undertaking will most likely deploy within the next 5 years. The amount may be expressed as a range of asset values based on different climate and policy scenarios, including a scenario aligned with limiting climate change to 1.5°C.",
          tooltip: "",
          module: "Environment",
          boldPart: "AR 73 a",
        },
        {
          id: "q-250",
          question:
            "When disclosing the information on assets at material transition risk as required under paragraphs 67 (a) and (b): (b) the undertaking shall disclose a breakdown of the carrying value of its real estate assets, including rights-of-use assets, by energy efficiency classes. The energy efficiency shall be represented in terms of either the ranges of energy consumption in kWh/m² or the EPC21 (Energy Performance Certificate)22 label class. If the undertaking cannot obtain this information on a best-effort basis, it shall disclose the total carrying amount of the real estate assets for which the energy consumption is based on internal estimates.",
          tooltip: "",
          module: "Environment",
          boldPart: "AR 73b",
        },
        {
          id: "q-251",
          question:
            "Liabilities that may have to be recognised in financial statements over the short-, medium- and long-term",
          tooltip: "",
          module: "Environment",
          boldPart: "67d",
        },
        {
          id: "q-252",
          question:
            "When disclosing the information on potential liabilities from material transition risks required under paragraph 67(d): (c) In assessing its potential future liabilities, the undertaking may consider and disclose the number of Scope 1 GHG emission allowances within regulated emission trading schemes and the cumulative number of emission allowances stored (from previous allowances) at the beginning of the reporting period;",
          tooltip: "",
          voluntary: true,
          module: "Environment",
          boldPart: "AR 74c",
        },
        {
          id: "q-253",
          question:
            "When disclosing the information on potential liabilities from material transition risks required under paragraph 67(d): (d) undertakings disclosing volumes of carbon credits planned to be cancelled in the near future (Disclosure Requirement E1-7) may disclose the potential future liabilities associated with those based on existing contractual agreements;",
          tooltip: "",
          voluntary: true,
          module: "Environment",
          boldPart: "AR 74d",
        },
        {
          id: "q-254",
          question:
            "When disclosing the information on potential liabilities from material transition risks required under paragraph 67(d): e) the undertaking may also include its monetised gross Scope 1, 2 and total GHG emissions (in monetary units) calculated as follows: i. monetised Scope 1 and 2 GHG emissions in the reporting year by the following formula: (a) (𝑔𝑟𝑜𝑠𝑠 𝑆𝑐𝑜𝑝𝑒 1 𝐺𝐻𝐺 𝑒𝑚𝑖𝑠𝑠𝑖𝑜𝑛𝑠 (𝑡 𝐶𝑂2𝑒𝑞) + 𝑔𝑟𝑜𝑠𝑠 𝑆𝑐𝑜𝑝𝑒 2 𝐺𝐻𝐺 𝑒𝑚𝑖𝑠𝑠𝑖𝑜𝑛 (𝑡 𝐶𝑂2𝑒𝑞)) × 𝐺𝐻𝐺 𝑒𝑚𝑖𝑠𝑠𝑖𝑜𝑛 𝑐𝑜𝑠𝑡 𝑟𝑎𝑡𝑒( €/𝑡 𝐶𝑂2𝑒𝑞); ii. monetised total GHG emissions in the reporting year by the following formula: (b) 𝑇𝑜𝑡𝑎𝑙 𝐺𝐻𝐺 𝑒𝑚𝑖𝑠𝑠𝑖𝑜𝑛𝑠 (𝑡 𝐶𝑂2𝑒𝑞) × 𝐺𝐻𝐺 𝑒𝑚𝑖𝑠𝑠𝑖𝑜𝑛 𝑐𝑜𝑠𝑡 𝑟𝑎𝑡𝑒 (€ /𝑡 𝐶𝑂2𝑒𝑞); iii. by use of a lower, middle and upper cost rate23 for GHG emissions (e.g., market carbon price and different estimates for the societal costs of carbon) and reasons for selecting them.",

          tooltip: "",
          voluntary: true,
          module: "Environment",
          boldPart: "AR 74 (e)",
        },
        {
          id: "q-255",
          question:
            "The monetary amount and proportion (percentage) of net revenue from its business activities at material transition risk over the short-, medium- and long-term including, where relevant, the net revenue from the undertaking’s customers operating in coal, oil and gas-related activities.",
          tooltip: "",
          module: "Environment",
          boldPart: "67e",
        },
        {
          id: "q-256",
          question:
            "When preparing the information required under paragraph 67 (e), the undertaking may assess and disclose the share of net revenue from business activities at transition risks. This disclosure: (a) shall be based on the net revenue in line with the requirements in accounting standards applied for financial statements, i.e., IFRS 15 or local GAAP.",
          tooltip: "",
          voluntary: true,
          module: "Environment",
          boldPart: "AR 76a",
        },
        {
          id: "q-257",
          question:
            "When preparing the information required under paragraph 67 (e), the undertaking may assess and disclose the share of net revenue from business activities at transition risks. This disclosure:(b) may include a breakdown of the undertaking’s business activities with the corresponding details of the associated percentage of current net revenue, risk factors (events and exposure), and when possible, the anticipated financial effects related to margin erosion over the short-, medium- and long-term. The nature of business activities may also be disaggregated by operating segments if the undertaking has disclosed the contribution of margins by operational segments in its segment reporting in the financial statements.",
          tooltip: "",
          voluntary: true,
          module: "Environment",
          boldPart: "AR 76b",
        },
        {
          id: "q-258",
          question:
            "The undertaking shall disclose reconciliations to the relevant line items or notes in the financial statements of the following: significant amounts of the assets and net revenue at material physical risk (as required by paragraph 66);",
          tooltip: "",
          module: "Environment",
          boldPart: "68a",
        },
        {
          id: "q-259",
          question:
            "significant amounts of the assets, liabilities, and net revenue at material transition risk (as required by paragraph 67).",
          tooltip: "",
          module: "Environment",
          boldPart: "68b",
        },
        {
          id: "q-260",
          question:
            "For the disclosure of the potential to pursue climate-related opportunities required by paragraph 64 ( c) the undertaking shall consider its expected cost savings from climate change mitigation and adaptation actions; and",
          tooltip: (
            <Typography>
              <p className="question-tip">
                When disclosing the information under paragraph 69 (a), the undertaking shall
                explain the nature of the cost savings (e.g., from reduced energy consumption), the
                time horizons and the methodology used, including the scope of the assessment,
                critical assumptions, and limitations, and whether and how scenario analysis was
                applied.
              </p>
            </Typography>
          ),
          euReg: (
            <Typography>
              <p className="question-tip">
                <strong>Benchmark Regulation reference:</strong> Delegated Regulation (EU)
                2020/1818, Annex II
              </p>
            </Typography>
          ),

          module: "Environment",
          boldPart: "69a",
        },
        {
          id: "q-261",
          question:
            "the potential market size or expected changes to net revenue from low-carbon products and services or adaptation solutions to which the undertaking has or may have access.",
          tooltip: (
            <Typography>
              <p className="question-tip">
                When disclosing the information required under paragraph 69 (b), the undertaking
                shall explain how it has assessed the market size or any expected changes to net
                revenue from low-carbon products and services or adaptation solutions including the
                scope of the assessment, the time horizon, critical assumptions, and limitations and
                to what extent this market is accessible to the undertaking. The information on the
                market size may be put in perspective to the current taxonomy-aligned revenue
                disclosed under the provisions of Regulation (EU) 2020/852. The entity may also
                explain how it will pursue its climate-related opportunities and, where possible,
                this should be linked to the disclosures on policies, targets and actions under
                Disclosure Requirements E1-2, E1-3 and E1-4.
              </p>
            </Typography>
          ),
          euReg: (
            <Typography>
              <p className="question-tip">
                <strong>Benchmark Regulation reference:</strong> Delegated Regulation (EU)
                2020/1818, Annex II
              </p>
            </Typography>
          ),

          module: "Environment",
          boldPart: "69b",
        },
        // Add more questions as needed
      ],
    /**********************************************
     * Tab: Environment
     * Topic: ESRS E2 POLLUTION
     **********************************************/

    "ESRS 2 IRO-1 – Description of the processes to identify and assess material pollution-related impacts, risks and opportunities":
      [
        {
          id: "q-262",
          question:
            "The undertaking shall describe the process to identify material impacts, risks and opportunities and shall provide information on: whether the undertaking has screened its site locations and business activities in order to identify its actual and potential pollution-related impacts, risks and opportunities in its own operations and upstream and downstream value chain, and if so, the methodologies, assumptions and tools used in the screening;",
          tooltip: (
            <Typography>
              <p className="question-tip">
                AR 1. When conducting a materiality assessment on environmental subtopics, the
                undertaking shall assess the materiality of pollution in its own operations and its
                upstream and downstream value chain, and may consider the four phases below, also
                known as the LEAP approach:
                <ol className="question-tip" type="a">
                  <li>
                    Phase 1: locate where in its own operations and its upstream and downstream
                    value chain the interface with nature takes place;
                  </li>
                  <li>Phase 2: evaluate the pollution-related dependencies and impacts;</li>
                  <li>Phase 3: assess the material risks and opportunities; and</li>
                  <li>Phase 4: prepare and report the results of the materiality assessment.</li>
                </ol>
                <p className="question-tip">
                  AR 2. The materiality assessment for ESRS E2 corresponds to the first three phases
                  of this LEAP approach. The fourth phase addresses the outcome of the process.
                  <br />
                  AR 3. The process to assess the materiality of impacts, dependencies, risks and
                  opportunities shall consider the provisions in ESRS 2 IRO-1 Description of the
                  processes to identify and assess material impacts, risks and opportunities, and
                  IRO-2 Disclosure Requirements in ESRS covered by the undertaking’s sustainability
                  statement.
                  <br />
                  AR 4. The sub-topics covered by the materiality assessment under ESRS E2 include:
                </p>
                <ol className="question-tip" type="a">
                  <li>
                    pollution of air, water and soil (excluding GHG emissions and waste),
                    microplastics, and substances of concern;
                  </li>
                  <li>
                    dependencies on ecosystem services that help to mitigate pollution-related
                    impacts.
                  </li>
                </ol>
                <p className="question-tip">
                  AR 5. In Phase 1, to locate where in its own operations and its upstream and
                  downstream value chain the interface with nature takes place, the undertaking may
                  consider:
                </p>
                <ol className="question-tip" type="a">
                  <li>
                    the site locations of direct assets and operations and related upstream and
                    downstream activities across the value chain;
                  </li>
                  <li>
                    the site locations where emissions of water, soil and air pollutants occur; and
                  </li>
                  <li>
                    the sectors or business units related to those emissions or to the production,
                    use, distribution, commercialisation and import/export of microplastics,
                    substances of concern, and substances of very high concern, on their own, in
                    mixtures or in articles.
                  </li>
                </ol>
                <p className="question-tip">
                  AR 6. Phase 2 relates to the evaluation of the undertaking’s impacts and
                  dependencies for each material site or sector/business unit including by assessing
                  the severity and likelihood of impacts on the environment and human health.
                </p>
                <p className="question-tip">
                  AR 7. In Phase 3, to assess its material risks and opportunities based on the
                  results of Phases 1 and 2, the undertaking may :
                </p>
                <ol className="question-tip" type="a">
                  <li>
                    identify transition risks and opportunities in its own operations and its
                    upstream and downstream value chain by the categories of:
                    <ol className="question-tip" type="i">
                      <li>
                        policy and legal: e.g., introduction of regulation, exposure to sanctions
                        and litigation (e.g., negligence towards ecosystems), enhanced reporting
                        obligations;
                      </li>
                      <li>
                        technology: e.g., substitution of products or services by products or
                        services with a lower impact, transition away from substances of concern;
                      </li>
                      <li>
                        market: e.g., shifting supply, demand and financing, volatility or increased
                        costs of some substances; and
                      </li>
                      <li>
                        reputation: e.g., changing societal, customer or community perceptions as a
                        result of an organisation’s role in pollution prevention and control;
                      </li>
                    </ol>
                  </li>
                  <li>
                    identify physical risks, e.g., sudden interruption of access to clean water,
                    acid rain, or other pollution incidents that are likely to lead to or that have
                    led to pollution with subsequent effects on the environment and society;
                  </li>
                  <li>
                    identify opportunities related to pollution prevention and control categorised
                    by:
                    <ol className="question-tip" type="i">
                      <li>
                        resource efficiency: decrease quantities of substances used or improve
                        efficiency of production process to minimise impacts;
                      </li>
                      <li>markets: e.g., diversification of business activities;</li>
                      <li>financing: e.g., access to green funds, bonds or loans;</li>
                      <li>
                        resilience: e.g., diversification of substances used and control of
                        emissions through innovation or technology; and
                      </li>
                      <li>
                        reputation: positive stakeholder relations as a result of a proactive stance
                        on managing risks.
                      </li>
                    </ol>
                  </li>
                </ol>
                <p className="question-tip">
                  AR 8. In order to assess materiality, the undertaking may consider Commission
                  Recommendation (EU) 2021/2279 on the use of the Environmental Footprint methods to
                  measure and communicate the life cycle environmental performance of products and
                  organisations."
                </p>
              </p>
            </Typography>
          ),

          module: "Environment",
          boldPart: "11 a",
        },
        {
          id: "q-263",
          question:
            "whether and how the undertaking has conducted consultations, in particular with affected communities",
          tooltip: "",
          module: "Environment",
          boldPart: "11 b",
        },
        {
          id: "q-264",
          question:
            "When providing information on the outcome of its materiality assessment, the undertaking shall consider: (a) a list of site locations where pollution is a material issue for the undertaking’s own operations and its upstream and downstream value chain; and (b) a list of business activities associated with pollution material impacts, risks and opportunities.",
          tooltip: "",
          module: "Environment",
          boldPart: "AR 9",
        },

        // Add more questions as needed
      ],
    "E2-1 – Policies related to pollution": [
      {
        id: "q-265",
        question:
          "The disclosure required by paragraph 12 shall contain the information on the policies the undertaking has in place to manage its material impacts, risks and opportunities related to pollution in accordance with ESRS 2 MDR-P Policies adopted to manage material sustainability matters.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              The policies described under this Disclosure Requirement may be integrated in broader
              environmental or sustainability policies covering different subtopics.
            </p>
          </Typography>
        ),

        module: "Environment",
        boldPart: "14",
      },
      {
        id: "q-266",
        question:
          "15 a The undertaking shall indicate, with regard to its own operations and its upstream and donwstream value chain, whether and how its policies address the following areas where material: mitigating negative impacts related to pollution of air, water and soil including prevention and control 15 b substituting and minimising the use of substances of concern, and phasing out substances of very high concern, in particular for non-essential societal use and in consumer products; and 15 c avoiding incidents and emergency situations, and if and when they occur, controlling and limiting their impact on people and the environment.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              The description of the policies shall include information on the pollutant(s) or
              substance(s) covered.
            </p>
          </Typography>
        ),
        module: "Environment",
        boldPart: "15",
      },
      {
        id: "q-269",
        question:
          "When disclosing information under paragraph 11, the undertaking may include contextual information on the relations between its policies implemented and how they may contribute to the EU Action Plan “Towards a Zero Pollution for Air, Water and Soil” with for instance elements on: (a) how it is or may be affected by the targets and measures of the EU Action Plan and the revision of existing directives (e.g., the Industrial Emissions Directive); (b) how it intends to reduce its pollution footprint to contribute to these targets.",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 12",
      },
      {
        id: "q-270",
        question:
          "The undertaking shall disclose information about policies adopted to manage material sustainability matters. The disclosure shall include the following information: (a) a description of the key contents of the policy, including its general objectives and which material impacts, risks or opportunities the policy relates to and the process for monitoring;",
        tooltip: "",
        module: "Environment",
        condition: "yes",
        boldPart: "65 a",
      },
      {
        id: "q-271",
        question:
          "a description of the scope of the policy, or of its exclusions, in terms of activities, upstream and/or downstream value chain, geographies and if relevant, affected stakeholder groups;",
        tooltip: "",
        module: "Environment",
        condition: "yes",
        boldPart: "65 b",
      },
      {
        id: "q-272",
        question:
          "the most senior level in the undertaking’s organisation that is accountable for the implementation of the policy;",
        tooltip: "",
        module: "Environment",
        condition: "yes",
        boldPart: "65 c",
      },
      {
        id: "q-273",
        question:
          "a reference, if relevant, to the third-party standards or initiatives the undertaking commits to respect through the implementation of the policy;",
        tooltip: "",
        module: "Environment",
        condition: "yes",
        boldPart: "65 d",
      },
      {
        id: "q-274",
        question:
          "if relevant, a description of the consideration given to the interests of key stakeholders in setting the policy; and",
        tooltip: "",
        module: "Environment",
        condition: "yes",
        boldPart: "65 e",
      },
      {
        id: "q-275",
        question:
          "if relevant, whether and how the undertaking makes the policy available to potentially affected stakeholders, and stakeholders who need to help implement it. ",
        tooltip: "",
        module: "Environment",
        condition: "yes",
        boldPart: "65 f",
      },
      {
        id: "q-276",
        question:
          "If the undertaking cannot disclose the information on policies and actions required under relevant ESRS, because it has not adopted policies and/or actions with reference to the specific sustainability matter concerned, it shall disclose this to be the case, and provide reasons for not having adopted policies and/or actions. ",
        tooltip: "",
        module: "Environment",
        condition: "no",
        boldPart: "62",
      },
      {
        id: "q-277",
        question: "The undertaking may disclose a timeframe in which it aims to adopt them.",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        condition: "no",
        boldPart: "62",
      },
      // Add more questions as needed
    ],
    "E2-2 – Actions and resources related to pollution": [
      {
        id: "q-278",
        question:
          "The description of the pollution-related action plans and resources shall contain the information prescribed in ESRS 2 MDR-A Actions and resources in relation to material sustainability matters.",
        tooltip: "",
        module: "Environment",
        boldPart: "18",
      },
      {
        id: "q-279",
        question:
          "In addition to ESRS 2 MDR-A, the undertaking may specify to which layer in the following mitigation hierarchy an action and resources can be allocated: (a) avoid pollution including any phase out of materials or compounds that have a negative impact (prevention of pollution at source);",
        tooltip: "",
        module: "Environment",
        boldPart: "19 a",
      },
      {
        id: "q-280",
        question:
          "Where actions extend to upstream or downstream value chain engagements, the undertaking shall provide information on the types of actions reflecting these engagements. ",
        tooltip: "",
        module: "Environment",
        boldPart: "AR 13",
      },

      {
        id: "q-281",
        question:
          "In addition to ESRS 2 MDR-A, the undertaking may specify to which layer in the following mitigation hierarchy an action and resources can be allocated: (b) reduce pollution, including: any phase-out of materials or compounds; meeting enforcement requirements such as Best Available Techniques (BAT) requirements; or meeting the Do No Significant Harm criteria for pollution prevention and control according to the EU Taxonomy Regulation and its Delegated Acts (minimisation of pollution); and (c) restore, regenerate and transform ecosystems where pollution has occurred (control of the impacts both from regular activities and incidents).",

        tooltip: (
          <Typography>
            <p className="question-tip">
              When considering resources, examples of operational expenditures could be investments
              in research and development to innovate and develop safe and sustainable alternatives
              to the use of substances of concern or to decrease emissions in a production process.
            </p>
          </Typography>
        ),
        voluntary: true,
        module: "Environment",
        boldPart: "19 b, 19 c",
      },
      {
        id: "q-282",
        question:
          "Where relevant to achieve its pollution-related policy objectives and targets, the undertaking may provide information on site-level action plans.",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 15",
      },
      {
        id: "q-283",
        question:
          "68. Where the implementation of a policy requires actions, or a comprehensive action plan, to achieve its objectives, as well as when actions are implemented without a specific policy, the undertaking shall disclose the following information: (a) the list of key actions taken in the reporting year and planned for the future, their expected outcomes and, where relevant, how their implementation contributes to the achievement of policy objectives and targets; ",
        tooltip:
          "AR 22. Key actions in the context of this Minimum Disclosure Requirement are those actions that materially contribute to achieving the undertaking’s objectives in addressing material impacts, risks and opportunities. oor reasons of understandability, key actions may be aggregated where appropriate.",
        module: "Environment",
        condition: "yes",
        boldPart: "68 a",
      },
      {
        id: "q-284",
        question:
          "the scope of the key actions (i.e., coverage in terms of activities, upstream and/or downstream value chain, geographies and, where applicable, affected stakeholder groups); ",
        tooltip: "",
        module: "Environment",
        condition: "yes",
        boldPart: "68 b",
      },
      {
        id: "q-285",
        question:
          "the time horizons under which the undertaking intends to complete each key action; ",
        tooltip: "",
        module: "Environment",
        condition: "yes",
        boldPart: "68 c",
      },
      {
        id: "q-286",
        question:
          "if applicable, key actions taken (along with results) to provide for and cooperate in or support the provision of remedy for those harmed by actual material impacts;",
        tooltip: "",
        module: "Environment",
        condition: "yes",
        boldPart: "68 d",
      },
      {
        id: "q-287",
        question:
          " if applicable, quantitative and qualitative information regarding the progress of actions or action plans disclosed in prior periods.",
        tooltip: "",
        module: "Environment",
        condition: "yes",
        boldPart: "68 e",
      },
      {
        id: "q-288",
        question:
          "69. Where the implementation of an action plan requires significant operational expenditures (Opex) and/or capital expenditures (Capex) the undertaking shall: (a) describe the type of current and future financial and other resources allocated to the action plan, including if applicable, the relevant terms of sustainable finance instruments, such as green bonds, social bonds and green loans, the environmental or social objectives, and whether the ability to implement the actions or action plan depends on specific preconditions, e.g., granting of financial support or public policy and market developments; ",
        tooltip:
          "AR 23. Information on resource allocation may be presented in the form of a table and broken down between capital expenditure and operating expenditure, and across the relevant time horizons, and between resources applied in the current reporting year and the planned allocation of resources over specific time horizons.",
        module: "Environment",
        condition: "yes",
        boldPart: "69 a",
      },
      {
        id: "q-289",
        question:
          "provide the amount of current financial resources and explain how they relate to the most relevant amounts presented in the financial statements; and (c) provide the amount of future financial resources. ",
        tooltip:
          "AR 23. Information on resource allocation may be presented in the form of a table and broken down between capital expenditure and operating expenditure, and across the relevant time horizons, and between resources applied in the current reporting year and the planned allocation of resources over specific time horizons.",
        module: "Environment",
        condition: "yes",
        boldPart: "69 b",
      },
      {
        id: "q-290",
        question:
          "If the undertaking cannot disclose the information on policies and actions required under relevant ESRS, because it has not adopted policies and/or actions with reference to the specific sustainability matter concerned, it shall disclose this to be the case, and provide reasons for not having adopted policies and/or actions. ",
        tooltip: "",
        module: "Environment",
        condition: "no",
        boldPart: "62",
      },
      {
        id: "q-291",
        question: "The undertaking may disclose a timeframe in which it aims to adopt them. ",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        condition: "no",
        boldPart: "62",
      },

      // Add more questions as needed
    ],
    "E2-3 – Targets related to pollution": [
      {
        id: "q-292",
        question:
          "The description of targets shall contain the information requirements defined in ESRS 2 MDR-T Tracking effectiveness of policies and actions through targets.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              The targets may cover the undertaking’s own operations and/or the value chain.
            </p>
          </Typography>
        ),

        module: "Environment",
        boldPart: "22",
      },
      {
        id: "q-293",
        question:
          "The disclosure required by paragraph 20 shall indicate whether and how its targets relate to the prevention and control of: (a) air pollutants and respective specific loads;",
        tooltip: "",
        module: "Environment",
        boldPart: "23 a",
      },
      {
        id: "q-294",
        question: "emissions to water and respective specific loads;",
        tooltip: "",
        module: "Environment",
        boldPart: "23 b",
      },
      {
        id: "q-295",
        question: "pollution to soil and respective specific loads; and",
        tooltip: "",
        module: "Environment",
        boldPart: "23 c",
      },
      {
        id: "q-296",
        question: "substances of concern and substances of very high concern.",
        tooltip: "",
        module: "Environment",
        boldPart: "23 d",
      },
      {
        id: "q-297",
        question:
          "In addition to ESRS 2 MDR-T, the undertaking may specify whether ecological thresholds (e.g., the biosphere integrity, stratospheric ozone-depletion, atmospheric aerosol loading, soil depletion, ocean acidification) and entity-specific allocations were taken into consideration when setting targets. If so, the undertaking may specify:",
        tooltip: (
          <Typography>
            <p className="question-tip">
              If the undertaking refers to ecological thresholds when setting targets, it may refer
              to the guidance provided by the Science-Based Targets Initiative for Nature (SBTN) in
              its interim guidance (Initial Guidance for Business, September 2020), or any other
              guidance with a scientifically acknowledged methodology that allows setting of
              science-based targets by identifying ecological thresholds and, if applicable,
              entity-specific allocations. Ecological thresholds can be local, national and/or
              global.
            </p>
          </Typography>
        ),

        module: "Environment",
        boldPart: "24",
        voluntary: true,
      },
      {
        id: "q-298",
        question:
          "the ecological thresholds identified, and the methodology used to identify such thresholds;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              If the undertaking refers to ecological thresholds when setting targets, it may refer
              to the guidance provided by the Science-Based Targets Initiative for Nature (SBTN) in
              its interim guidance (Initial Guidance for Business, September 2020), or any other
              guidance with a scientifically acknowledged methodology that allows setting of
              science-based targets by identifying ecological thresholds and, if applicable,
              entity-specific allocations. Ecological thresholds can be local, national and/or
              global.
            </p>
          </Typography>
        ),

        module: "Environment",
        boldPart: "24 a",
        voluntary: true,
      },
      {
        id: "q-299",
        question:
          "whether or not the thresholds are entity-specific and if so, how they were determined; and",
        tooltip: (
          <Typography>
            <p className="question-tip">
              If the undertaking refers to ecological thresholds when setting targets, it may refer
              to the guidance provided by the Science-Based Targets Initiative for Nature (SBTN) in
              its interim guidance (Initial Guidance for Business, September 2020), or any other
              guidance with a scientifically acknowledged methodology that allows setting of
              science-based targets by identifying ecological thresholds and, if applicable,
              entity-specific allocations. Ecological thresholds can be local, national and/or
              global.
            </p>
          </Typography>
        ),
        module: "Environment",
        boldPart: "24 b",
        voluntary: true,
      },
      {
        id: "q-300",
        question:
          "how responsibility for respecting identified ecological thresholds is allocated in the undertaking",
        tooltip: (
          <Typography>
            <p className="question-tip">
              If the undertaking refers to ecological thresholds when setting targets, it may refer
              to the guidance provided by the Science-Based Targets Initiative for Nature (SBTN) in
              its interim guidance (Initial Guidance for Business, September 2020), or any other
              guidance with a scientifically acknowledged methodology that allows setting of
              science-based targets by identifying ecological thresholds and, if applicable,
              entity-specific allocations. Ecological thresholds can be local, national and/or
              global.
            </p>
          </Typography>
        ),
        module: "Environment",
        boldPart: "24 c",
        voluntary: true,
      },
      {
        id: "q-301",
        question:
          "The undertaking shall specify as part of the contextual information, whether the targets that it has set and presented are mandatory (required by legislation) or voluntary.",
        tooltip: "",
        module: "Environment",
        boldPart: "25",
      },
      {
        id: "q-302",
        question:
          "The undertaking may specify whether the target addresses shortcomings related to the Substantial Contribution criteria for Pollution Prevention and Control as defined in delegated acts adopted pursuant to Article 14(2) of Regulation (EU) 2020/852. Where the Do No Significant Harm (DNSH) criteria for Pollution Prevention and Control as defined in delegated acts adopted pursuant to Article 10(3), Article 11(3), Article 12(2), Article 13(2), and Article 15(2) of Regulation (EU) 2020/852 are not met, the undertaking may specify whether the target addresses shortcomings related those DNSH criteria.",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 17",
      },
      {
        id: "q-303",
        question:
          "Where relevant to support the policies it has adopted, the undertaking may provide information on the targets set at site level.",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 18",
      },
      {
        id: "q-304",
        question:
          "The undertaking shall disclose the measurable, outcome-oriented and time-bound targets on material sustainability matters it has set to assess progress. oor each target, the disclosure shall include the following information: (a) a description of the relationship of the target to the policy objectives; ",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy.
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 a",
      },
      {
        id: "q-305",
        question:
          "the defined target level to be achieved, including, where applicable, whether the target is absolute or relative and in which unit it is measured;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy.
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 b",
      },
      {
        id: "q-306",
        question:
          "the scope of the target, including the undertaking’s activities and/or its upstream and/or downstream value chain where applicable and geographical boundaries;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy.
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 c",
      },
      {
        id: "q-307",
        question: "the baseline value and base year from which progress is measured;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy.
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 d",
      },
      {
        id: "q-308",
        question:
          "the period to which the target applies and if applicable, any milestones or interim targets;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy.
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 e",
      },
      {
        id: "q-309",
        question:
          "the methodologies and significant assumptions used to define targets, including where applicable, the selected scenario, data sources, alignment with national, EU or international policy goals and how the targets consider the wider context of sustainable development and/or local situation in which impacts take place; ",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy.
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 f",
      },
      {
        id: "q-310",
        question:
          "whether the undertaking’s targets related to environmental matters are based on conclusive scientific evidence;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy.
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 g",
      },
      {
        id: "q-311",
        question:
          "whether and how stakeholders have been involved in target setting for each material sustainability matter;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy.
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 h",
      },
      {
        id: "q-312",
        question:
          "any changes in targets and corresponding metrics or underlying measurement methodologies, significant assumptions, limitations, sources and processes to collect data adopted within the defined time horizon. This includes an explanation of the rationale for those changes and their effect on comparability (see Disclosure Requirement BP-2 Disclosures in relation to specific circumstances of this Standard);",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy.
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 i",
      },
      {
        id: "q-313",
        question:
          "the performance against its disclosed targets, including information on how the target is monitored and reviewed and the metrics used, whether the progress is in line with what had been initially planned, and an analysis of trends or significant changes in the performance of the undertaking towards achieving the target.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy.
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 j",
      },
      {
        id: "q-314",
        question:
          ". If the undertaking has not set any measurable outcome-oriented targets: (a) it may disclose whether such targets will be set and the timeframe for setting them, or the reasons why the undertaking does not plan to set such targets; (b) it shall disclose whether it nevertheless tracks the effectiveness of its policies and actions in relation to the material sustainability-related impact, risk and opportunity, and if so: i. any processes through which it does so; ii. the defined level of ambition to be achieved and any qualitative or quantitative indicators it uses to evaluate progress, including the base period from which progress is measured.",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        condition: "no",
        boldPart: "81",
      },
      // Add more questions as needed
    ],
    "E2-4 – Pollution of air, water and soil": [
      {
        id: "q-315",
        question:
          "The undertaking shall disclose the amounts of: each pollutant listed in Annex II of Regulation (EC) No 166/2006 of the European Parliament and of the Council 1 (European Pollutant Release and Transfer Register “EPRTR Regulation”)emitted to air, water and soil, with the exception of emissions of GHGs which are disclosed in accordance with ESRS E1 Climate Change2;",
        tooltip: "",
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 8 Table #1 of Annex 1 Indicator
              number 2 Table #2 of Annex 1 Indicator number 1 Table #2 of Annex 1 Indicator number 3
              Table #2 of Annex 1
            </p>
          </Typography>
        ),

        module: "Environment",
        boldPart: "28 a",
      },
      {
        id: "q-316",
        question:
          "The undertaking shall disclose the amounts of: microplastics generated or used by the undertaking. ",
        tooltip: "",
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 8 Table #1 of Annex 1 Indicator
              number 2 Table #2 of Annex 1 Indicator number 1 Table #2 of Annex 1 Indicator number 3
              Table #2 of Annex 1
            </p>
          </Typography>
        ),
        module: "Environment",
        boldPart: "28 b",
      },
      {
        id: "q-317",
        question:
          "The undertaking shall put its disclosure into context and describe: (a) the changes over time.",
        tooltip: "",
        module: "Environment",
        boldPart: "30 a",
      },
      {
        id: "q-318",
        question:
          "The undertaking shall put its disclosure into context and describe: (b) the measurement methodologies;",
        tooltip: "",
        module: "Environment",
        boldPart: "30 b",
      },
      {
        id: "q-319",
        question:
          "The undertaking shall put its disclosure into context and describe: (c) the process(es) to collect data for pollution-related accounting and reporting, including the type of data needed and the information sources. ",
        tooltip: "",
        module: "Environment",
        boldPart: "30 c",
      },
      {
        id: "q-320",
        question:
          "When providing contextual information on the emissions, the undertaking may consider:the undertaking’s percentage of the total emissions of pollutants to water and soil occurring in areas at water risk, including areas of high-water stress. ",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 23 c",
      },
      {
        id: "q-321",
        question:
          "When an inferior methodology compared to direct measurement of emissions is chosen to quantify emissions, the reasons for choosing this inferior methodology shall be outlined by the undertaking. If the undertaking uses estimates, it shall disclose the standard, sectoral study or sources which form the basis of its estimates, as well as the possible degree of uncertainty and the range of estimates reflecting the measurement uncertainty.",
        tooltip: "",
        module: "Environment",
        boldPart: "31",
      },
      {
        id: "q-322",
        question:
          "Where the undertaking’s activities are subject to Directive 2010/75/EU of the European Parliament and of the Council on industrial emissions (IED) 4 and relevant Best Available Techniques Reference Documents (BREFs), irrespective of whether the activity takes place within the European Union or not, the undertaking may disclose the following additional information: (a) a list of installations operated by the undertaking that fall under the IED and EU- BAT Conclusions;",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 25a",
      },
      {
        id: "q-323",
        question:
          "Where the undertaking’s activities are subject to Directive 2010/75/EU of the European Parliament and of the Council on industrial emissions (IED) 4 and relevant Best Available Techniques Reference Documents (BREFs), irrespective of whether the activity takes place within the European Union or not, the undertaking may disclose the following additional information: (b) a list of any non-compliance incidents or enforcement actions necessary to ensure compliance in case of breaches of permit conditions;",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 25b",
      },
      {
        id: "q-324",
        question:
          "Where the undertaking’s activities are subject to Directive 2010/75/EU of the European Parliament and of the Council on industrial emissions (IED) 4 and relevant Best Available Techniques Reference Documents (BREFs), irrespective of whether the activity takes place within the European Union or not, the undertaking may disclose the following additional information: (c) the actual performance, as specified in the EU-BAT conclusions for industrial installations, and comparison of the undertaking’s environmental performance against “emission levels associated with the best available techniques” the (BATAEL) as described in EU-BAT conclusions;",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 25c",
      },
      {
        id: "q-325",
        question:
          "Where the undertaking’s activities are subject to Directive 2010/75/EU of the European Parliament and of the Council on industrial emissions (IED) 4 and relevant Best Available Techniques Reference Documents (BREFs), irrespective of whether the activity takes place within the European Union or not, the undertaking may disclose the following additional information: (d) the actual performance of the undertaking against “environmental performance levels associated with the best available techniques” (BAT-AEPLs) provided that they are applicable to the sector and installation; and",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 25d",
      },
      {
        id: "q-326",
        question:
          "Where the undertaking’s activities are subject to Directive 2010/75/EU of the European Parliament and of the Council on industrial emissions (IED) 4 and relevant Best Available Techniques Reference Documents (BREFs), irrespective of whether the activity takes place within the European Union or not, the undertaking may disclose the following additional information: (e) a list of any compliance schedules or derogations granted by competent authorities according to Art. 15(4) Directive 2010/75/EU that are associated with the implementation of BAT-AELs.",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 25e",
      },
      // Add more questions as needed
    ],
    "E2-5 – Substances of concern and substances of very high concern": [
      {
        id: "q-327",
        question:
          "The disclosure required by paragraph 32 shall include the total amounts of substances of concern that are generated or used during the production or that are procured, and the total amounts of substances of concern that leave its facilities as emissions, as products, or as part of products or services split into main hazard classes of substances of concern.",
        tooltip:
          "AR 28. In order for the information to be complete, substances in the undertaking’s own operations and those procured shall be included (e.g., embedded in ingredients, semi- finished products, or the final product). AR 29. The volume of pollutants shall be presented in mass units, for example tonnes or kilogrammes or other mass units appropriate for the volumes and type of pollutants being released. AR 30. The information provided under this Disclosure Requirement may refer to information the undertaking is already required to report under other existing legislation (i.e., Directive 2010/75/EU, Regulation (EC) No 166/2006 “E-PRTR”, etc.). ",
        module: "Environment",
        boldPart: "34",
      },
      {
        id: "q-328",
        question:
          "The undertaking shall present separately the information for substances of very high concern.",
        tooltip: "",
        module: "Environment",
        boldPart: "35",
      },
      // Add more questions as needed
    ],
    "E2-6 – Anticipated financial effects from pollution-related impacts, risks and opportunities":
      [
        {
          id: "q-329",
          question:
            "The disclosure shall include: (a) a quantification of the anticipated financial effects in monetary terms before considering pollution-related actions, or where not possible without undue cost or effort, qualitative information. For financial effects arising from opportunities, a quantification is not required if it would result in disclosure that does not meet the qualitative characteristics of information (see ESRS 1 Appendix B Qualitative characteristics of information);",
          tooltip: (
            <Typography>
              <p className="question-tip">
                Incidents may include for instance interruptions of production, whether arising from
                the supply chain and/or from own operations, which resulted in pollution. The
                quantification of the anticipated financial effects in monetary terms under
                paragraph 38(a) may be a single amount or a range.
              </p>
            </Typography>
          ),
          phasedIn:
            "The undertaking may omit the information prescribed by ESRS E2-6 for the first year of preparation of its sustainability statement. Except for the information prescribed by paragraph 40 (b) on the operating and capital expenditures occurred in the reporting period in conjunction with major incidents and deposits, the undertaking may comply with ESRS E2-6 by reporting only qualitative disclosures, for the first 3 years of preparation of its sustainability statement.",
          module: "Environment",
          boldPart: "39 a",
        },
        {
          id: "q-330",
          question:
            "a description of the effects considered, the related impacts and the time horizons in which they are likely to materialise;",
          tooltip: "",
          module: "Environment",
          boldPart: "39 b",
        },
        {
          id: "q-331",
          question:
            "the critical assumptions used to quantify the anticipated financial effects, as well as the sources and level of uncertainty of those assumptions",
          tooltip: (
            <Typography>
              <p className="question-tip">
                The undertaking may include an assessment of its related products and services at
                risk over the short-, medium- and long-term, explaining how these are defined, how
                financial amounts are estimated, and which critical assumptions are made.
              </p>
            </Typography>
          ),
          phasedIn:
            "The undertaking may omit the information prescribed by ESRS E2-6 for the first year of preparation of its sustainability statement. Except for the information prescribed by paragraph 40 (b) on the operating and capital expenditures occurred in the reporting period in conjunction with major incidents and deposits, the undertaking may comply with ESRS E2-6 by reporting only qualitative disclosures, for the first 3 years of preparation of its sustainability statement.",
          module: "Environment",
          boldPart: "39 c",
        },
        {
          id: "q-332",
          question:
            "the share of net revenue made with products and services that are or that contain substances of concern, and the share of net revenue made with products and services that are or that contain substances of very high concern;",
          tooltip: "",
          phasedIn:
            "The undertaking may omit the information prescribed by ESRS E2-6 for the first year of preparation of its sustainability statement. Except for the information prescribed by paragraph 40 (b) on the operating and capital expenditures occurred in the reporting period in conjunction with major incidents and deposits, the undertaking may comply with ESRS E2-6 by reporting only qualitative disclosures, for the first 3 years of preparation of its sustainability statement.",
          module: "Environment",
          boldPart: "40 a",
        },
        {
          id: "q-333",
          question:
            "a description of the effects considered, the related impacts and the time horizons in which they are likely to materialise; ",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "The operating and capital expenditures related to incidents and deposits may
                include for instance: <br />
                (a) cost for eliminating and remediating the respective pollution of air, water and
                soil including environmental protection; <br />
                (b) damage compensation costs including payment of fines and penalties imposed by
                regulators or government authorities."
              </p>
            </Typography>
          ),
          phasedIn:
            "The undertaking may omit the information prescribed by ESRS E2-6 for the first year of preparation of its sustainability statement. Except for the information prescribed by paragraph 40 (b) on the operating and capital expenditures occurred in the reporting period in conjunction with major incidents and deposits, the undertaking may comply with ESRS E2-6 by reporting only qualitative disclosures, for the first 3 years of preparation of its sustainability statement.",
          module: "Environment",
          boldPart: "40 b",
        },
        {
          id: "q-334",
          question:
            "the critical assumptions used to quantify the anticipated financial effects, as well as the sources and level of uncertainty of those assumptions. ",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "The operating and capital expenditures related to incidents and deposits may
                include for instance: (a) cost for eliminating and remediating the respective
                pollution of air, water and soil including environmental protection; "
              </p>
            </Typography>
          ),
          phasedIn:
            "The undertaking may omit the information prescribed by ESRS E2-6 for the first year of preparation of its sustainability statement. Except for the information prescribed by paragraph 40 (b) on the operating and capital expenditures occurred in the reporting period in conjunction with major incidents and deposits, the undertaking may comply with ESRS E2-6 by reporting only qualitative disclosures, for the first 3 years of preparation of its sustainability statement.",
          module: "Environment",
          boldPart: "40 c",
        },
        {
          id: "q-335",
          question:
            "The undertaking shall disclose any relevant contextual information including a description of material incidents and deposits whereby pollution had negative impacts on the environment and/or is expected to have negative effects on the undertaking’s financial cash flows, financial position and financial performance with short-, medium- and long-term time horizons.",
          tooltip: "",
          module: "Environment",
          boldPart: "41",
        },
        {
          id: "q-336",
          question:
            "The undertaking may include an assessment of its related products and services at risk over the short-, medium- and long-term, explaining how these are defined, how financial amounts are estimated, and which critical assumptions are made.",
          tooltip: "",
          phasedIn:
            "The undertaking may omit the information prescribed by ESRS E2-6 for the first year of preparation of its sustainability statement. Except for the information prescribed by paragraph 40 (b) on the operating and capital expenditures occurred in the reporting period in conjunction with major incidents and deposits, the undertaking may comply with ESRS E2-6 by reporting only qualitative disclosures, for the first 3 years of preparation of its sustainability statement.",
          module: "Environment",
          boldPart: "AR 33",
        },
        // Add more questions as needed
      ],
    /**********************************************
     * Tab: Environment
     * Topic: ESRS E3 WATER AND MARINE RESOURCES
     **********************************************/
    "ESRS 2 IRO-1 – Description of the processes to identify and assess material water and marine resources-related impacts, risks and opportunities":
      [
        {
          id: "q-337",
          question:
            "The undertaking shall describe the process to identify material impacts, risks and opportunities and shall provide information on: (a) whether and how the undertaking it has screened its assets and activities in order to identify its actual and potential water and marine resources-related impacts, risks and opportunities in its own operations and its upstream and downstream value chain, and if so the methodologies, assumptions and tools used in the screening;",
          tooltip: "",
          module: "Environment",
          boldPart: "8 a",
        },
        {
          id: "q-338",
          question:
            "whether and how it has conducted consultations, in particular, with affected communities",
          tooltip: "",
          module: "Environment",
          boldPart: "8 b",
        },
        {
          id: "q-339",
          question:
            "When conducting a materiality assessment on environmental subtopics, the undertaking shall assess the materiality of water and marine resources in its own operations and its upstream and downstream value chain, and may consider the four phases below, also known as the LEAP approach: (a) Phase 1: locate where in its own operations and along the value chain the interface with nature takes place; (b) Phase 2: evaluate the dependencies and impacts; (c) Phase 3: assess the material risks and opportunities; and (d) Phase 4: prepare and report the results of the materiality assessment.",
          tooltip: "",
          module: "Environment",
          boldPart: "AR 1",
        },
        // Add more questions as needed
      ],
    "E3-1 – Policies related to water and marine resources": [
      {
        id: "q-340",
        question:
          "The disclosure required by paragraph 9 shall contain the information on the policies the undertaking has in place to manage its material impacts, risks and opportunities related to water and marine resources in accordance with ESRS 2 MDR-P Policies adopted to manage material sustainability matters.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "The policies described under this Disclosure Requirement may be integrated in broader
              environmental or sustainability policies covering different subtopics.
              <br />
              The undertaking may also disclose information about policies which:
              <br />
              (a) contribute to good ecological and chemical quality of surface water bodies and
              good chemical quality and quantity of groundwater bodies, in order to protect human
              health, water supply, natural ecosystems and biodiversity, the good environmental
              status of marine waters and the protection of the resource base upon which marine
              related activities depend;
              <br />
              (b) minimise material impacts and risks and implement mitigation measures that aim to
              maintain the value and functionality of priority services and to increase resource
              efficiency on own operations; and (c) avoid impacts on affected communities."
            </p>
          </Typography>
        ),
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 7 Table #2 of Annex 1
            </p>
          </Typography>
        ),
        module: "Environment",
        boldPart: "11",
      },
      {
        id: "q-341",
        question:
          "12a i The undertaking shall indicate whether and how its policies address the following matters where material: (a) water management including: i. the use and sourcing of water and marine resources in its own operations 12a ii. water treatment as a step towards more sustainable sourcing of water; and 12a iii. the prevention and abatement of water pollution resulting from its activities.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              The policies described under this Disclosure Requirement may be integrated in broader
              environmental or sustainability policies covering different subtopics.
            </p>
            <p className="question-tip">
              The undertaking may also disclose information about policies which:
            </p>
            <ol className="question-tip" type="a">
              <li>
                contribute to good ecological and chemical quality of surface water bodies and good
                chemical quality and quantity of groundwater bodies, in order to protect human
                health, water supply, natural ecosystems and biodiversity, the good environmental
                status of marine waters and the protection of the resource base upon which marine
                related activities depend;
              </li>
              <li>
                minimise material impacts and risks and implement mitigation measures that aim to
                maintain the value and functionality of priority services and to increase resource
                efficiency on own operations; and (c) avoid impacts on affected communities.
              </li>
            </ol>
          </Typography>
        ),
        module: "Environment",
        boldPart: "12a",
      },
      {
        id: "q-344",
        question:
          "product and service design in view of addressing water-related issues and the preservation of marine resources; and",
        tooltip: (
          <Typography>
            <p className="question-tip">
              The policies described under this Disclosure Requirement may be integrated in broader
              environmental or sustainability policies covering different subtopics.
            </p>
            <p className="question-tip">
              The undertaking may also disclose information about policies which:
            </p>
            <ol className="question-tip" type="a">
              <li>
                contribute to good ecological and chemical quality of surface water bodies and good
                chemical quality and quantity of groundwater bodies, in order to protect human
                health, water supply, natural ecosystems and biodiversity, the good environmental
                status of marine waters and the protection of the resource base upon which marine
                related activities depend;
              </li>
              <li>
                minimise material impacts and risks and implement mitigation measures that aim to
                maintain the value and functionality of priority services and to increase resource
                efficiency on own operations; and (c) avoid impacts on affected communities.
              </li>
            </ol>
          </Typography>
        ),
        module: "Environment",
        boldPart: "12 b",
      },
      {
        id: "q-345",
        question:
          "commitment to reduce material water consumption in areas at water risk in its own operations and along the upstream and downstream value chain.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              The policies described under this Disclosure Requirement may be integrated in broader
              environmental or sustainability policies covering different subtopics.
            </p>
            <p className="question-tip">
              The undertaking may also disclose information about policies which:
            </p>
            <ol className="question-tip" type="a">
              <li>
                contribute to good ecological and chemical quality of surface water bodies and good
                chemical quality and quantity of groundwater bodies, in order to protect human
                health, water supply, natural ecosystems and biodiversity, the good environmental
                status of marine waters and the protection of the resource base upon which marine
                related activities depend;
              </li>
              <li>
                minimise material impacts and risks and implement mitigation measures that aim to
                maintain the value and functionality of priority services and to increase resource
                efficiency on own operations; and (c) avoid impacts on affected communities.
              </li>
            </ol>
          </Typography>
        ),
        module: "Environment",
        boldPart: "12 c",
      },
      {
        id: "q-346",
        question:
          "If at least one of the sites of the undertaking is located in an area of high-water stress and it is not covered by a policy, the undertaking shall state this to be the case and provide reasons for not having adopted such a policy. The undertaking may disclose a timeframe in which it aims to adopt such a policy",
        tooltip: (
          <Typography>
            <p className="question-tip">
              The policies described under this Disclosure Requirement may be integrated in broader
              environmental or sustainability policies covering different subtopics.
            </p>
            <p className="question-tip">
              The undertaking may also disclose information about policies which:
            </p>
            <ol className="question-tip" type="a">
              <li>
                contribute to good ecological and chemical quality of surface water bodies and good
                chemical quality and quantity of groundwater bodies, in order to protect human
                health, water supply, natural ecosystems and biodiversity, the good environmental
                status of marine waters and the protection of the resource base upon which marine
                related activities depend;
              </li>
              <li>
                minimise material impacts and risks and implement mitigation measures that aim to
                maintain the value and functionality of priority services and to increase resource
                efficiency on own operations; and (c) avoid impacts on affected communities.
              </li>
            </ol>
          </Typography>
        ),
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 8 Table 2 of Annex 1
            </p>
          </Typography>
        ),
        voluntary: true,
        module: "Environment",
        boldPart: "13",
      },
      {
        id: "q-347",
        question:
          "The undertaking shall specify whether it has adopted policies or practices related to sustainable oceans and seas",
        tooltip: "",
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 12 Table #2 of Annex 1
            </p>
          </Typography>
        ),
        module: "Environment",
        boldPart: "14",
      },
      {
        id: "q-348",
        question:
          "The undertaking may also disclose information about policies which: (a) contribute to good ecological and chemical quality of surface water bodies and good chemical quality and quantity of groundwater bodies, in order to protect human health, water supply, natural ecosystems and biodiversity, the good environmental status of marine waters and the protection of the resource base upon which marine related activities depend;",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 18a",
      },
      {
        id: "q-349",
        question:
          "The undertaking may also disclose information about policies which: (b) minimise material impacts and risks and implement mitigation measures that aim to maintain the value and functionality of priority services and to increase resource efficiency on own operations; and",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 18b",
      },
      {
        id: "q-350",
        question:
          "The undertaking may also disclose information about policies which: (c) avoid impacts on affected communities.",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 18c",
      },
      {
        id: "q-351",
        question:
          "The undertaking shall disclose information about policies adopted to manage material sustainability matters. The disclosure shall include the following information: (a) a description of the key contents of the policy, including its general objectives and which material impacts, risks or opportunities the policy relates to and the process for monitoring; ",
        tooltip: "",
        module: "Environment",
        condition: "yes",
        boldPart: "65 a",
      },
      {
        id: "q-352",
        question:
          "a description of the scope of the policy, or of its exclusions, in terms of activities, upstream and/or downstream value chain, geographies and if relevant, affected stakeholder groups;",
        tooltip: "",
        module: "Environment",
        condition: "yes",
        boldPart: "65 b",
      },
      {
        id: "q-353",
        question:
          "the most senior level in the undertaking’s organisation that is accountable for the implementation of the policy;",
        tooltip: "",
        module: "Environment",
        condition: "yes",
        boldPart: "65 c",
      },
      {
        id: "q-354",
        question:
          "a reference, if relevant, to the third-party standards or initiatives the undertaking commits to respect through the implementation of the policy;",
        tooltip: "",
        module: "Environment",
        condition: "yes",
        boldPart: "65 d",
      },
      {
        id: "q-355",
        question:
          "if relevant, a description of the consideration given to the interests of key stakeholders in setting the policy; ",
        tooltip: "",
        module: "Environment",
        condition: "yes",
        boldPart: "65 e",
      },

      {
        id: "q-356",
        question:
          "if relevant, whether and how the undertaking makes the policy available to potentially affected stakeholders, and stakeholders who need to help implement it. ",
        tooltip: "",
        module: "Environment",
        condition: "yes",
        boldPart: "65 f",
      },
      {
        id: "q-357",
        question:
          "If the undertaking cannot disclose the information on policies and actions required under relevant ESRS, because it has not adopted policies and/or actions with reference to the specific sustainability matter concerned, it shall disclose this to be the case, and provide reasons for not having adopted policies and/or actions. ",
        tooltip: "",
        module: "Environment",
        condition: "no",
        boldPart: "62",
      },
      {
        id: "q-358",
        question: "The undertaking may disclose a timeframe in which it aims to adopt them. ",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        condition: "no",
        boldPart: "62",
      },
      // Add more questions as needed
    ],
    "E3-2 – Actions and resources related to water and marine resources": [
      {
        id: "q-359",
        question:
          "The description of the actions and resources shall follow the principles defined in ESRS 2 MDR- A Actions and resources in relation to material sustainability matters. In addition to ESRS 2 MDR- A, the undertaking may specify to which layer in the mitigation hierarchy an action and",
        tooltip: (
          <Typography>
            <p className="question-tip">
              When disclosing information required under paragraph 15, the undertaking shall
              consider the actions, or action plans, contributing to address the material impacts,
              risks and opportunities identified. Useful guidance is provided by the Alliance for
              Water Stewardship (AWS).
              <br />
              Considering that water and marine resources are shared resources which may require
              collective actions, or action plans, involving other stakeholders, the undertaking may
              provide information on those specific collective actions, including information on
              other parties (competitors, suppliers, retailers, customers, other business partners,
              local communities and authorities, government agencies…) and specific information on
              the project, its specific contribution, its sponsors and other participants.
              <br />
              When providing information on capital expenditures, the undertaking may consider
              expenditures related, for example, to stormwater drain rehabilitation, pipelines, or
              machinery used to manufacture new low water-use products.
            </p>
          </Typography>
        ),

        module: "Environment",
        boldPart: "17",
      },
      {
        id: "q-360",
        question:
          " Resources can be allocated to:(a) avoid the use of water and marine resources; (b) reduce the use of water and marine resources such as through efficiency measures; (c) reclaiming and reuse of water; or (d) restoration and regeneration of aquatic ecosystem and water bodies.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              <strong>18</strong> When disclosing information required under paragraph 15, the
              undertaking shall consider the actions, or action plans, contributing to address the
              material impacts, risks and opportunities identified. Useful guidance is provided by
              the Alliance for Water Stewardship (AWS).
              <br />
              Considering that water and marine resources are shared resources which may require
              collective actions, or action plans, involving other stakeholders, the undertaking may
              provide information on those specific collective actions, including information on
              other parties (competitors, suppliers, retailers, customers, other business partners,
              local communities and authorities, government agencies…) and specific information on
              the project, its specific contribution, its sponsors and other participants.
              <br />
              When providing information on capital expenditures, the undertaking may consider
              expenditures related, for example, to stormwater drain rehabilitation, pipelines, or
              machinery used to manufacture new low water-use products.
            </p>
          </Typography>
        ),
        voluntary: true,
        module: "Environment",
        boldPart: "18",
      },
      {
        id: "q-361",
        question:
          "Considering that water and marine resources are shared resources which may require collective actions, or action plans, involving other stakeholders, the undertaking may provide information on those specific collective actions, including information on other parties (competitors, suppliers, retailers, customers, other business partners, local communities and authorities, government agencies…) and specific information on the project, its specific contribution, its sponsors and other participants.",
        tooltip:
          "When disclosing information required under paragraph 15, the undertaking shall consider the actions, or action plans, contributing to address the material impacts, risks and opportunities identified. Useful guidance is provided by the Alliance for Water Stewardship (AWS). Considering that water and marine resources are shared resources which may require collective actions, or action plans, involving other stakeholders, the undertaking may provide information on those specific collective actions, including information on other parties (competitors, suppliers, retailers, customers, other business partners, local communities and authorities, government agencies…) and specific information on the project, its specific contribution, its sponsors and other participants. When providing information on capital expenditures, the undertaking may consider expenditures related, for example, to stormwater drain rehabilitation, pipelines, or machinery used to manufacture new low water-use products.",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 20",
      },
      {
        id: "q-362",
        question:
          "The undertaking shall specify actions and resources in relation to areas at water risk, including areas of high-water stress",
        tooltip: (
          <Typography>
            <p className="question-tip">
              When disclosing information required under paragraph 15, the undertaking shall
              consider the actions, or action plans, contributing to address the material impacts,
              risks and opportunities identified. Useful guidance is provided by the Alliance for
              Water Stewardship (AWS).
              <br />
              Considering that water and marine resources are shared resources which may require
              collective actions, or action plans, involving other stakeholders, the undertaking may
              provide information on those specific collective actions, including information on
              other parties (competitors, suppliers, retailers, customers, other business partners,
              local communities and authorities, government agencies…) and specific information on
              the project, its specific contribution, its sponsors and other participants.
              <br />
              When providing information on capital expenditures, the undertaking may consider
              expenditures related, for example, to stormwater drain rehabilitation, pipelines, or
              machinery used to manufacture new low water-use products.
            </p>
          </Typography>
        ),
        module: "Environment",
        boldPart: "19",
      },
      {
        id: "q-363",
        question:
          "Where the implementation of a policy requires actions, or a comprehensive action plan, to achieve its objectives, as well as when actions are implemented without a specific policy, the undertaking shall disclose the following information: (a) the list of key actions taken in the reporting year and planned for the future, their expected outcomes and, where relevant, how their implementation contributes to the achievement of policy objectives and targets;",
        tooltip:
          "AR 22. Key actions in the context of this Minimum Disclosure Requirement are those actions that materially contribute to achieving the undertaking’s objectives in addressing material impacts, risks and opportunities. oor reasons of understandability, key actions may be aggregated where appropriate. ",
        module: "Environment",
        condition: "yes",
        boldPart: "68 a",
      },
      {
        id: "q-364",
        question:
          "the scope of the key actions (i.e., coverage in terms of activities, upstream and/or downstream value chain, geographies and, where applicable, affected stakeholder groups); ",
        tooltip: "",
        module: "Environment",
        condition: "yes",
        boldPart: "68 b",
      },
      {
        id: "q-365",
        question:
          "the time horizons under which the undertaking intends to complete each key action;",
        tooltip: "",
        module: "Environment",
        condition: "yes",
        boldPart: "68 c",
      },
      {
        id: "q-366",
        question:
          "if applicable, key actions taken (along with results) to provide for and cooperate in or support the provision of remedy for those harmed by actual material impacts;",
        tooltip: "",
        module: "Environment",
        condition: "yes",
        boldPart: "68 d",
      },
      {
        id: "q-367",
        question:
          "if applicable, quantitative and qualitative information regarding the progress of actions or action plans disclosed in prior periods. ",
        tooltip: "",
        module: "Environment",
        condition: "yes",
        boldPart: "68 e",
      },
      {
        id: "q-368",
        question:
          "Where the implementation of an action plan requires significant operational expenditures (Opex) and/or capital expenditures (Capex) the undertaking shall: (a) describe the type of current and future financial and other resources allocated to the action plan, including if applicable, the relevant terms of sustainable finance instruments, such as green bonds, social bonds and green loans, the environmental or social objectives, and whether the ability to implement the actions or action plan depends on specific preconditions, e.g., granting of financial support or public policy and market developments; ",
        tooltip:
          "AR 23. Information on resource allocation may be presented in the form of a table and broken down between capital expenditure and operating expenditure, and across the relevant time horizons, and between resources applied in the current reporting year and the planned allocation of resources over specific time horizons.",
        module: "Environment",
        condition: "yes",
        boldPart: "69 a",
      },
      {
        id: "q-369",
        question:
          "provide the amount of current financial resources and explain how they relate to the most relevant amounts presented in the financial statements;",
        tooltip: "",
        module: "Environment",
        condition: "yes",
        boldPart: "69 b",
      },
      {
        id: "q-370",
        question: "provide the amount of future financial resources. ",
        tooltip: "",
        module: "Environment",
        condition: "yes",
        boldPart: "69 c",
      },
      {
        id: "q-371",
        question:
          "If the undertaking cannot disclose the information on policies and actions required under relevant ESRS, because it has not adopted policies and/or actions with reference to the specific sustainability matter concerned, it shall disclose this to be the case, and provide reasons for not having adopted policies and/or actions. ",
        tooltip: "",
        module: "Environment",
        condition: "no",
        boldPart: "62",
      },
      {
        id: "q-372",
        question: "The undertaking may disclose a timeframe in which it aims to adopt them. ",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        condition: "no",
        boldPart: "62",
      },
      // Add more questions as needed
    ],
    "E3-3 – Targets related to water and marine resources": [
      {
        id: "q-373",
        question:
          "The description of the targets shall contain the information requirements defined in ESRS 2 MDR-T Tracking effectiveness of policies and actions through targets.",
        tooltip: "",
        module: "Environment",
        boldPart: "22",
      },
      {
        id: "q-374",
        question:
          "The disclosure required by paragraph 20 shall indicate whether and how its targets relate to: (a) the management of material impacts, risks and opportunities related to areas at water risk, including improvement of the water quality;",
        tooltip: "",
        module: "Environment",
        boldPart: "23 a",
      },
      {
        id: "q-375",
        question:
          "the responsible management of marine resources impacts, risks and opportunities including the nature and quantity of marine resources-related commodities (such as gravels, deep-sea minerals, seafood) used by the undertaking; and",
        tooltip: "",
        module: "Environment",
        boldPart: "23 b",
      },
      {
        id: "q-376",
        question:
          "the reduction of water consumption, including an explanation of how those targets relate to areas at water risk, including areas of high water-stress.",
        tooltip: "",
        module: "Environment",
        boldPart: "23 c",
      },
      {
        id: "q-377",
        question:
          "In addition to ESRS 2 MDR-T, the undertaking may specify whether ecological thresholds and entity-specific allocations were taken into consideration when setting targets. If so, the undertaking may specify: (a) the ecological thresholds identified, and the methodology used to identify such thresholds; (b) whether or not the thresholds are entity-specific and if so, how they were determined; and (c) how responsibility for respecting identified ecological thresholds is allocated in the undertaking.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              If the undertaking refers to ecological thresholds when setting targets, it may refer
              to the guidance provided by the Science-Based Targets Initiative for Nature (SBTN) in
              its interim guidance (Initial Guidance for Business, September 2020). It may also
              refer to any other guidance with a scientifically acknowledged methodology that
              enables the setting of science- based targets by identifying ecological thresholds
              and, if applicable, organisationspecific allocations. Ecological thresholds can be
              local, national and/or global.
            </p>
          </Typography>
        ),
        voluntary: true,
        module: "Environment",
        boldPart: "24",
      },
      {
        id: "q-378",
        question:
          "In addition to ESRS 2 MDR-T, the undertaking may specify whether ecological thresholds and entity-specific allocations were taken into consideration when setting targets. If so, the undertaking may specify: (a) the ecological thresholds identified, and the methodology used to identify such thresholds;",

        tooltip: (
          <Typography>
            <p className="question-tip">
              If the undertaking refers to ecological thresholds when setting targets, it may refer
              to the guidance provided by the Science-Based Targets Initiative for Nature (SBTN) in
              its interim guidance (Initial Guidance for Business, September 2020). It may also
              refer to any other guidance with a scientifically acknowledged methodology that
              enables the setting of science- based targets by identifying ecological thresholds
              and, if applicable, organisationspecific allocations. Ecological thresholds can be
              local, national and/or global.
            </p>
          </Typography>
        ),
        voluntary: true,
        module: "Environment",
        boldPart: "24 a",
      },
      {
        id: "q-379",
        question:
          "In addition to ESRS 2 MDR-T, the undertaking may specify whether ecological thresholds and entity-specific allocations were taken into consideration when setting targets. If so, the undertaking may specify: (b) whether or not the thresholds are entity-specific and if so, how they were determined; and",

        tooltip: (
          <Typography>
            <p className="question-tip">
              If the undertaking refers to ecological thresholds when setting targets, it may refer
              to the guidance provided by the Science-Based Targets Initiative for Nature (SBTN) in
              its interim guidance (Initial Guidance for Business, September 2020). It may also
              refer to any other guidance with a scientifically acknowledged methodology that
              enables the setting of science- based targets by identifying ecological thresholds
              and, if applicable, organisationspecific allocations. Ecological thresholds can be
              local, national and/or global.
            </p>
          </Typography>
        ),
        voluntary: true,
        module: "Environment",
        boldPart: "24 b",
      },
      {
        id: "q-380",
        question:
          "In addition to ESRS 2 MDR-T, the undertaking may specify whether ecological thresholds and entity-specific allocations were taken into consideration when setting targets. If so, the undertaking may specify: (c) how responsibility for respecting identified ecological thresholds is allocated in the undertaking.",

        tooltip: (
          <Typography>
            <p className="question-tip">
              If the undertaking refers to ecological thresholds when setting targets, it may refer
              to the guidance provided by the Science-Based Targets Initiative for Nature (SBTN) in
              its interim guidance (Initial Guidance for Business, September 2020). It may also
              refer to any other guidance with a scientifically acknowledged methodology that
              enables the setting of science- based targets by identifying ecological thresholds
              and, if applicable, organisationspecific allocations. Ecological thresholds can be
              local, national and/or global.
            </p>
          </Typography>
        ),
        voluntary: true,
        module: "Environment",
        boldPart: "24 c",
      },
      {
        id: "q-381",
        question:
          "The undertaking shall specify as part of the contextual information, whether the targets it has set and presented are mandatory (required by legislation) or voluntary.",
        tooltip: "",
        module: "Environment",
        boldPart: "25",
      },
      {
        id: "q-382",
        question:
          "The undertaking may provide targets relating to: (a) the reduction of water withdrawals",
        tooltip:
          "If the undertaking provides targets on withdrawals, it may include water withdrawal from polluted soils and aquifers, and water withdrawn and treated for remediation purposes. The targets may cover its own operations and/or its upstream and downstream value chain.",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 23 a",
      },
      {
        id: "q-383",
        question:
          "The undertaking may provide targets relating to: (b) the reduction of water discharges",
        tooltip:
          "If the undertaking provides targets on withdrawals, it may include water withdrawal from polluted soils and aquifers, and water withdrawn and treated for remediation purposes. The targets may cover its own operations and/or its upstream and downstream value chain.",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 23 b",
      },
      {
        id: "q-384",
        question:
          "The undertaking shall disclose the measurable, outcome-oriented and time-bound targets on material sustainability matters it has set to assess progress. oor each target, the disclosure shall include the following information: (a) a description of the relationship of the target to the policy objectives;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy. "
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 a",
      },
      {
        id: "q-385",
        question:
          "the defined target level to be achieved, including, where applicable, whether the target is absolute or relative and in which unit it is measured;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy. "
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 b",
      },
      {
        id: "q-386",
        question:
          "the scope of the target, including the undertaking’s activities and/or its upstream and/or downstream value chain where applicable and geographical boundaries; ",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy. "
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 c",
      },
      {
        id: "q-387",
        question: "the baseline value and base year from which progress is measured;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy. "
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 d",
      },
      {
        id: "q-388",
        question:
          "the period to which the target applies and if applicable, any milestones or interim targets;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy. "
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 e",
      },
      {
        id: "q-389",
        question:
          "the methodologies and significant assumptions used to define targets, including where applicable, the selected scenario, data sources, alignment with national, EU or international policy goals and how the targets consider the wider context of sustainable development and/or local situation in which impacts take place; ",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy. "
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 f",
      },
      {
        id: "q-390",
        question:
          "whether the undertaking’s targets related to environmental matters are based on conclusive scientific evidence;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy. "
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 g",
      },
      {
        id: "q-391",
        question:
          "whether and how stakeholders have been involved in target setting for each material sustainability matter; ",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy. "
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 h",
      },
      {
        id: "q-392",
        question:
          "any changes in targets and corresponding metrics or underlying measurement methodologies, significant assumptions, limitations, sources and processes to collect data adopted within the defined 17 time horizon. This includes an explanation of the rationale for those changes and their effect on comparability (see Disclosure Requirement BP-2 Disclosures in relation to specific circumstances of this Standard);",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy. "
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 i",
      },
      {
        id: "q-393",
        question:
          "the performance against its disclosed targets, including information on how the target is monitored and reviewed and the metrics used, whether the progress is in line with what had been initially planned, and an analysis of trends or significant changes in the performance of the undertaking towards achieving the target. ",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy. "
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 j",
      },
      {
        id: "q-394",
        question:
          ". If the undertaking has not set any measurable outcome-oriented targets: (a) it may disclose whether such targets will be set and the timeframe for setting them, or the reasons why the undertaking does not plan to set such targets;",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        condition: "no",
        boldPart: "81 a",
      },
      {
        id: "q-395",
        question:
          "it shall disclose whether it nevertheless tracks the effectiveness of its policies and actions in relation to the material sustainability-related impact, risk and opportunity,",
        tooltip: "",
        module: "Environment",
        condition: "no",
        boldPart: "81 b",
      },

      {
        id: "q-396",
        question:
          "it shall disclose whether it nevertheless tracks the effectiveness of its policies and actions in relation to the material sustainability-related impact, risk and opportunity, and if so: i. any processes through which it does so",
        tooltip: "",
        module: "Environment",
        condition: "no",
        boldPart: "81 b i",
      },
      {
        id: "q-397",
        question:
          "it shall disclose whether it nevertheless tracks the effectiveness of its policies and actions in relation to the material sustainability-related impact, risk and opportunity, and if so: the defined level of ambition to be achieved and any qualitative or quantitative indicators it uses to evaluate progress, including the base period from which progress is measured. ",
        tooltip: "",
        module: "Environment",
        condition: "no",
        boldPart: "81 b ii, 80 d",
      },

      // Add more questions as needed
    ],
    "E3-4 – Water consumption": [
      {
        id: "q-398",
        question: " Table: E3-4 – Water consumption",
        tooltip: (
          <Typography>
            <p className="question-tip">
              The undertaking may operate in various areas at water risk. When disclosing
              information under paragraph 28 (b), the undertaking shall include such information
              only for those areas that have been identified as material in accordance with ESRS2
              IRO-1 and ESRS2 SBM-3.
            </p>
          </Typography>
        ),
        table: (
          <table className="question-table">
            <thead>
              <tr>
                <th></th>
                <th>Year </th>
                <th>Comparative</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Total water consumption in m3</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e3_4_1-totalWaterConsumption-year"
                    value={
                      answers2["e3_4_1-totalWaterConsumption-year"] ||
                      inputValues.e3_4_1.totalWaterConsumption.year
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e3_4_1-totalWaterConsumption-comparative"
                    value={
                      answers2["e3_4_1-totalWaterConsumption-comparative"] ||
                      inputValues.e3_4_1.totalWaterConsumption.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Total water consumption in m3 in areas at water risk, including areas of
                  high-water stress
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e3_4_1-waterConsumptionAtRiskAreas-year"
                    value={
                      answers2["e3_4_1-waterConsumptionAtRiskAreas-year"] ||
                      inputValues.e3_4_1.waterConsumptionAtRiskAreas.year
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e3_4_1-waterConsumptionAtRiskAreas-comparative"
                    value={
                      answers2["e3_4_1-waterConsumptionAtRiskAreas-comparative"] ||
                      inputValues.e3_4_1.waterConsumptionAtRiskAreas.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Total water recycled and reused in m3</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e3_4_1-totalWaterRecycled-year"
                    value={
                      answers2["e3_4_1-totalWaterRecycled-year"] ||
                      inputValues.e3_4_1.totalWaterRecycled.year
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e3_4_1-totalWaterRecycled-comparative"
                    value={
                      answers2["e3_4_1-totalWaterRecycled-comparative"] ||
                      inputValues.e3_4_1.totalWaterRecycled.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Total water stored and changes in storage in m3</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e3_4_1-totalWaterStored-year"
                    value={
                      answers2["e3_4_1-totalWaterStored-year"] ||
                      inputValues.e3_4_1.totalWaterStored.year
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e3_4_1-totalWaterStored-comparative"
                    value={
                      answers2["e3_4_1-totalWaterStored-comparative"] ||
                      inputValues.e3_4_1.totalWaterStored.comparative
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        ),
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 6.2 Table #2 of Annex 1{" "}
            </p>
          </Typography>
        ),

        module: "Environment",
        boldPart: "28 a-d",
        fun: "3Column",
      },
      {
        id: "q-399",
        question:
          "any contextual information necessary regarding points (a) to (d), including the water basins’ water quality and quantity, how the data have been compiled, such as any standards, methodologies, and assumptions used, including whether the information is calculated, estimated, modelled, or sourced from direct measurements, and the approachtaken fo r this, such as the use of any sector-specific factors.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              When disclosing contextual information on water consumption performance required by
              paragraph 26, the undertaking shall explain the calculation methodologies and more
              specifically the share of the measure obtained from direct measurement, from sampling
              and extrapolation, or from best estimates.
            </p>
          </Typography>
        ),
        module: "Environment",
        boldPart: "28 e",
      },
      {
        id: "q-400",
        question: "Table: Water Intensity",
        tooltip: "",
        table: (
          <table className="question-table">
            <thead></thead>
            <tbody>
              <tr>
                <td>total water stored and changes in storage in m3</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e3_4_2-totalWaterStored-netValue"
                    value={
                      answers2["e3_4_2-totalWaterStored-netValue"] ||
                      inputValues.e3_4_2.totalWaterStored.netValue
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>EUR net revenue</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e3_4_2-eurNetRevenue-netValue"
                    value={
                      answers2["e3_4_2-eurNetRevenue-netValue"] ||
                      inputValues.e3_4_2.eurNetRevenue.netValue
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>water intensity (m3/ EUR net revenue)</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e3_4_2-waterIntensity-netValue"
                    value={
                      answers2["e3_4_2-waterIntensity-netValue"] ||
                      inputValues.e3_4_2.waterIntensity.netValue
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        ),
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 6.2 Table #2 of Annex 1{" "}
            </p>
          </Typography>
        ),

        module: "Environment",
        boldPart: "29",
        fun: "2ColumnWH",
      },
      {
        id: "q-401",
        question:
          "The undertaking may provide information on other breakdowns (i.e., per sector or segments).",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        boldPart: "AR30",
      },
      {
        id: "q-402",
        question:
          "When disclosing information required by paragraph 29 the undertaking may provide additional intensity ratios based on other denominators.",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        boldPart: "AR31",
      },
      {
        id: "q-403",
        question: "The undertaking may also provide information on its water withdrawals and water",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        boldPart: "AR32",
      },

      // Add more questions as needed
    ],
    "E3-5 – Anticipated financial effects from water and marine resources-related impacts, risks and opportunities":
      [
        {
          id: "q-404",
          question:
            "The disclosure shall include: (a) a quantification of the anticipated financial effects in monetary terms before considering water and marine resources-related actions or where not possible without undue cost or effort, qualitative information. For financial effects arising from opportunities, a quantification is not required if it would result in disclosure that does not meet the qualitative characteristics of information (see ESRS 1 Appendix B Qualitative characteristics of information); (b) a description of the effects considered, the impacts and dependencies to which they relate, and the time horizons in which they are likely to materialise; and (c)) the critical assumptions used to quantify the anticipated financial effects, as well as the sources and level of uncertainty of those assumptions.",
          tooltip: (
            <Typography>
              <p className="question-tip">
                The quantification of the anticipated financial effects in monetary terms under
                paragraph 39(a) may be a single amount or a range.
              </p>
            </Typography>
          ),
          module: "Environment",
          boldPart: "",
        },
        {
          id: "q-405",
          question:
            "The disclosure shall include: (b) a description of the effects considered, the impacts and dependencies to which they relate, and the time horizons in which they are likely to materialise; and",
          tooltip: "",
          phasedIn:
            "The undertaking may omit the information prescribed by ESRS E3-5 for the first year of preparation of its sustainability statement. The undertaking may comply with ESRS E3-5 by reporting only qualitative disclosures, for the first 3 years of preparation of its sustainability statement. ",
          module: "Environment",
          boldPart: "33 b",
        },
        {
          id: "q-406",
          question:
            "The disclosure shall include: (c) the critical assumptions used to quantify the anticipated financial effects, as well as the sources and level of uncertainty of those assumptions.",
          tooltip: "",
          phasedIn:
            "The undertaking may omit the information prescribed by ESRS E3-5 for the first year of preparation of its sustainability statement. The undertaking may comply with ESRS E3-5 by reporting only qualitative disclosures, for the first 3 years of preparation of its sustainability statement. ",
          module: "Environment",
          boldPart: "33 c",
        },
        {
          id: "q-407",
          question:
            "The undertaking may include an assessment of its related products and services at risk over the short-, medium- and long-term, explaining how these are defined, how financial amounts are estimated, and which critical assumptions are made.",
          tooltip: "",
          phasedIn:
            "The undertaking may omit the information prescribed by ESRS E3-5 for the first year of preparation of its sustainability statement. The undertaking may comply with ESRS E3-5 by reporting only qualitative disclosures, for the first 3 years of preparation of its sustainability statement. ",
          voluntary: true,
          module: "Environment",
          boldPart: "AR 33",
        },
        // Add more questions as needed
      ],
    /**********************************************
     * Tab: Environment
     * Topic: "ESRS E4 BIODIVERSITY AND ECOSYSTEMS"
     **********************************************/
    "E4 ESRS 2 SBM-3 – Material impacts, risks and opportunities and their interaction with strategy and business model":
      [
        {
          id: "q-408",
          question:
            "The undertaking shall disclose: (a) a list of material sites in its own operations, including sites under its operational control, based on the results of paragraph 17(a). i. Specifying the activities negatively affecting biodiversity sensitive areas",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
          euReg: (
            <Typography>
              <p className="question-tip">
                {" "}
                16 a i <strong>SFDR reference:</strong> Indicator number 7 Table #1 of Annex 1{" "}
              </p>
            </Typography>
          ),
          module: "Environment",
          boldPart: "16 a i",
        },
        {
          id: "q-410",
          question:
            "Providing a breakdown of sites according to the impacts and dependencies identified, and to the ecological status of the areas (with reference to the specific ecosystem baseline level) where they are located;",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
          module: "Environment",
          boldPart: "16 a ii",
        },
        {
          id: "q-411",
          question:
            "Specifying the biodiversity-sensitive areas impacted, for users to be able to determine the location and the responsible competent authority with regards to the  activities specified in paragraph 16(a) i. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
          module: "Environment",
          boldPart: "16 a iii",
        },
        {
          id: "q-412",
          question:
            "The undertaking shall disclose: (b)Whether it has identified material negative impacts with regards to land degradation, desertification or soil sealing; (c) Whether it has operations that affect threatened species.",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
          euReg: (
            <Typography>
              <p className="question-tip">
                16 b<strong>SFDR reference:</strong> Indicator number 10 Table #2 of Annex 1{" "}
              </p>
              <p className="question-tip">
                16 c<strong>SFDR reference:</strong> Indicator number 14 Table #2 of Annex 1 1{" "}
              </p>
            </Typography>
          ),
          module: "Environment",
          boldPart: "16 b, 16 c",
        },
        // Add more questions as needed
      ],
    "ESRS 2 IRO-1 Description of processes to identify and assess material biodiversity and ecosystem-related impacts, risks and opportunities ":
      [
        {
          id: "q-414",
          question:
            "The undertaking shall describe its process to identify material impacts, risks, dependencies and opportunities. The description of the process shall include whether and how the undertaking: identified and assessed actual and potential impacts on biodiversity and ecosystems at own site locations and in the upstream and downstream value chain, including assessment criteria applied; ",
          tooltip: (
            <Typography>
              <p className="question-tip">
                AR 4. The materiality assessment under ESRS E4 includes the undertaking’s:
              </p>
              <ol className="question-tip" type="a">
                <li>
                  contribution to direct impact drivers on biodiversity loss12:
                  <ol className="question-tip" type="i">
                    <li>climate change; </li>
                    <li>
                      land-use change (e.g., land artificialisation), freshwater-use change and
                      sea-use change;
                    </li>
                    <li>direct exploitation; </li>
                    <li>invasive alien species;</li>
                    <li>pollution; and </li>
                    <li>others. </li>
                  </ol>
                </li>
                <li>
                  impacts on the state of species (i.e., species population size, species global
                  extinction risk);
                </li>
                <li>
                  impacts on the extent and condition of ecosystems including through land
                  degradation, desertification and soil sealing); and
                </li>
                <li> impacts and dependencies on ecosystem services.</li>
              </ol>
              <p className="question-tip">
                AR 5. When assessing the materiality of impacts, dependencies, risks and
                opportunities the undertaking shall consider the provisions in ESRS 2 IRO-1 and ESRS
                1 Chapter 3 Double materiality as the basis for sustainability disclosures and
                describe its considerations. <br />
                AR 6. The undertaking shall assess the materiality of biodiversity and ecosystems in
                its own operations and its upstream and downstream value chain, and may conduct its
                materiality assessment in line with the first three phases of the LEAP approach:
                Locate (paragraph AR 7), Evaluate (paragraph AR 8) and Assess (paragraph AR 9). AR
                7. Phase 1 relates to the localisation of relevant sites regarding its interface
                with biodiversity and ecosystems. To identify these relevant sites the undertaking
                may:
              </p>
              <ol className="question-tip" type="a">
                <li>
                  develop a list of locations of direct assets and operations and related upstream
                  and downstream value chain that are relevant to the undertakings business
                  activities. Furthermore, the undertaking may provide information about sites for
                  which future operations have been formally announced;
                </li>
                <li>
                  list the biomes and ecosystems it is interfacing with based on the list of
                  locations identified under paragraph AR 7(a);
                </li>
                <li>
                  identify the current integrity and importance of biodiversity and ecosystem at
                  each location taking into consideration the information provided in paragraphs 16
                  and 17;
                </li>
                <li>
                  develop a list of locations where the undertaking is interfacing with locations in
                  or near biodiversity-sensitive areas taking into consideration the information
                  provided in paragraphs 16 and 17; and
                </li>
                <li>
                  identify which sectors, business units, value chains or asset classes are
                  interfacing with biodiversity and ecosystems in these material sites. Instead of
                  identifying these interfaces per site, the undertaking may choose to identify them
                  per raw material procured or sold by weight in tons, if such practice offers
                  greater transparency.
                </li>
              </ol>
              <p className="question-tip">
                AR 8. In Phase 2, to evaluate its actual or potential impacts and dependencies on
                biodiversity and ecosystems for relevant sites, the undertaking may:
              </p>
              <ol className="question-tip" type="a">
                <li>
                  identify business processes and activities that interface with biodiversity and
                  ecosystems;
                </li>
                <li>identify actual and potential impacts and dependencies; </li>
                <li>
                  indicate the size, scale, frequency of occurrence and timeframe of the impacts on
                  biodiversity and ecosystems taking into consideration the disclosures under
                  paragraphs 16 and 17. Furthermore, the undertaking may disclose:
                  <ol className="question-tip" type="i">
                    <li>
                      the percentage of its suppliers’ facilities which are located in risk prone
                      areas (with threatened species on the IUCN Red List of Species, the Birds and
                      Habitats Directive or nationally list of threatened species, or in officially
                      recognised Protected Areas, the Natura 2000 network of protected areas and Key
                      Biodiversity Areas);
                    </li>
                    <li>
                      the percentage of its procurement spend from suppliers with facilities which
                      are located in risk prone areas (with threatened species on the IUCN Red List
                      of Species, the Birds and Habitats Directive or nationally list of threatened
                      species, or in officially recognised Protected Areas, the Natura 2000 network
                      of protected areas and Key Biodiversity Areas); and
                    </li>
                  </ol>
                </li>
                <li>
                  indicate the size and scale of the dependencies on biodiversity and ecosystems,
                  including on raw materials, natural resources and ecosystem services. The
                  undertaking may rely on the international classifications such as the Common
                  International Classification of Ecosystem Services (CICES).
                </li>
              </ol>
              <p className="question-tip">
                AR 9. In Phase 3, to assesses its material risks and opportunities based on the
                results of Phases 1 and 2, the undertaking may consider the following categories:
              </p>
              <ol className="question-tip" type="a">
                <li>
                  physical risks:
                  <ol className="question-tip" type="i">
                    <li>
                      acute risks (e.g., natural disasters exacerbated by loss of coastal protection
                      from ecosystems, leading to costs of storm damage to coastal infrastructure,
                      disease or pests affecting the species or variety of crop the undertaking
                      relies on, especially in the case of no or low genetic diversity, species loss
                      and ecosystem degradation); and
                    </li>
                    <li>
                      chronic risks (e.g., loss of crop yield due to decline in pollination
                      services, increasing scarcity or variable production of key natural inputs,
                      ecosystem degradation due to operations leading to, for example, coastal
                      erosion and forest fragmentation, ocean acidification, land loss to
                      desertification and soil degradation and consequent loss of soil fertility,
                      species loss).
                    </li>
                  </ol>
                </li>
                <li>
                  transition risks, including:
                  <ol className="question-tip" type="i">
                    <li>
                      policy and legal: e.g. introduction of regulation or policy (e.g. changes such
                      as increased land protection); exposure to sanctions and litigation (e.g.
                      spills of polluting effluents that damage human and ecosystem health; or
                      violation of biodiversity-related rights, permits or allocations; or
                      negligence towards or killing of threatened species); enhanced reporting
                      obligations on biodiversity, ecosystems and related services;
                    </li>
                    <li>
                      technology: e.g. substitution of products or services by products or services
                      with a lower impact on biodiversity or dependence on ecosystem services, lack
                      of access to data or access to poor quality data that hamper
                      biodiversity-related assessments, transition to more efficient and cleaner
                      technologies (i.e. with lower impacts on biodiversity), new monitoring
                      technologies (e.g. satellite), requirements to use certain technologies (e.g.
                      climate resistant crops, mechanical pollinators, water purification, flood
                      protection);
                    </li>
                    <li>
                      market: e.g., shifting supply, demand and financing, volatility or increased
                      costs of raw materials (e.g., biodiversity-intense inputs for which price has
                      risen due to ecosystem degradation);
                    </li>
                    <li>
                      reputation: e.g., changing societal, customer or community perceptions as a
                      result of an organisation’s role in loss of biodiversity, violation of
                      nature-related rights through operations, negative media coverage due to
                      impacts on critical species and/or ecosystems, biodiversity-related social
                      conflicts over endangered species, protected areas, resources or pollution.
                    </li>
                  </ol>
                </li>
                <li>
                  systemic risks, including:
                  <ol className="question-tip" type="i">
                    <li>
                      ecosystem collapse risks that a critical natural system no longer functions,
                      e.g., tipping points are reached and the collapse of ecosystems resulting in
                      wholesale geographic or sector losses (summing physical risks);
                    </li>
                    <li>
                      aggregated risk linked to fundamental impacts of biodiversity loss to levels
                      of transition and physical risk across one or more sectors in a portfolio
                      (corporate or financial); and
                    </li>
                    <li>
                      contagion risks that financial difficulties of certain corporations or
                      financial institutions linked to failure to account for exposure to
                      biodiversity-related risks spill over to the economic system as a whole.
                    </li>
                  </ol>
                </li>
                <li>
                  opportunities, including for example:
                  <ol className="question-tip" type="i">
                    <li>
                      business performance categories: resource efficiency; products and services;
                      markets; capital flow and financing; reputational capital; and
                    </li>
                    <li>
                      sustainability performance categories: ecosystem protection, restoration and
                      regeneration; sustainability use of natural resources.
                    </li>
                  </ol>
                </li>
              </ol>
            </Typography>
          ),
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
          module: "Environment",
          boldPart: "17 a",
        },
        {
          id: "q-415",
          question:
            "identified and assessed dependencies on biodiversity and ecosystems and their services at own site locations and in the upstream and downstream value chain, including assessment criteria applied, and, if this assessment includes ecosystem services that are disrupted or likely to be; ",
          tooltip: (
            <Typography>
              <p className="question-tip">
                AR 4. The materiality assessment under ESRS E4 includes the undertaking’s:
              </p>
              <ol className="question-tip" type="a">
                <li>
                  contribution to direct impact drivers on biodiversity loss12:
                  <ol className="question-tip" type="i">
                    <li>climate change; </li>
                    <li>
                      land-use change (e.g., land artificialisation), freshwater-use change and
                      sea-use change;
                    </li>
                    <li>direct exploitation; </li>
                    <li>invasive alien species;</li>
                    <li>pollution; and </li>
                    <li>others. </li>
                  </ol>
                </li>
                <li>
                  impacts on the state of species (i.e., species population size, species global
                  extinction risk);
                </li>
                <li>
                  impacts on the extent and condition of ecosystems including through land
                  degradation, desertification and soil sealing); and
                </li>
                <li> impacts and dependencies on ecosystem services.</li>
              </ol>
              <p className="question-tip">
                AR 5. When assessing the materiality of impacts, dependencies, risks and
                opportunities the undertaking shall consider the provisions in ESRS 2 IRO-1 and ESRS
                1 Chapter 3 Double materiality as the basis for sustainability disclosures and
                describe its considerations. <br />
                AR 6. The undertaking shall assess the materiality of biodiversity and ecosystems in
                its own operations and its upstream and downstream value chain, and may conduct its
                materiality assessment in line with the first three phases of the LEAP approach:
                Locate (paragraph AR 7), Evaluate (paragraph AR 8) and Assess (paragraph AR 9). AR
                7. Phase 1 relates to the localisation of relevant sites regarding its interface
                with biodiversity and ecosystems. To identify these relevant sites the undertaking
                may:
              </p>
              <ol className="question-tip" type="a">
                <li>
                  develop a list of locations of direct assets and operations and related upstream
                  and downstream value chain that are relevant to the undertakings business
                  activities. Furthermore, the undertaking may provide information about sites for
                  which future operations have been formally announced;
                </li>
                <li>
                  list the biomes and ecosystems it is interfacing with based on the list of
                  locations identified under paragraph AR 7(a);
                </li>
                <li>
                  identify the current integrity and importance of biodiversity and ecosystem at
                  each location taking into consideration the information provided in paragraphs 16
                  and 17;
                </li>
                <li>
                  develop a list of locations where the undertaking is interfacing with locations in
                  or near biodiversity-sensitive areas taking into consideration the information
                  provided in paragraphs 16 and 17; and
                </li>
                <li>
                  identify which sectors, business units, value chains or asset classes are
                  interfacing with biodiversity and ecosystems in these material sites. Instead of
                  identifying these interfaces per site, the undertaking may choose to identify them
                  per raw material procured or sold by weight in tons, if such practice offers
                  greater transparency.
                </li>
              </ol>
              <p className="question-tip">
                AR 8. In Phase 2, to evaluate its actual or potential impacts and dependencies on
                biodiversity and ecosystems for relevant sites, the undertaking may:
              </p>
              <ol className="question-tip" type="a">
                <li>
                  identify business processes and activities that interface with biodiversity and
                  ecosystems;
                </li>
                <li>identify actual and potential impacts and dependencies; </li>
                <li>
                  indicate the size, scale, frequency of occurrence and timeframe of the impacts on
                  biodiversity and ecosystems taking into consideration the disclosures under
                  paragraphs 16 and 17. Furthermore, the undertaking may disclose:
                  <ol className="question-tip" type="i">
                    <li>
                      the percentage of its suppliers’ facilities which are located in risk prone
                      areas (with threatened species on the IUCN Red List of Species, the Birds and
                      Habitats Directive or nationally list of threatened species, or in officially
                      recognised Protected Areas, the Natura 2000 network of protected areas and Key
                      Biodiversity Areas);
                    </li>
                    <li>
                      the percentage of its procurement spend from suppliers with facilities which
                      are located in risk prone areas (with threatened species on the IUCN Red List
                      of Species, the Birds and Habitats Directive or nationally list of threatened
                      species, or in officially recognised Protected Areas, the Natura 2000 network
                      of protected areas and Key Biodiversity Areas); and
                    </li>
                  </ol>
                </li>
                <li>
                  indicate the size and scale of the dependencies on biodiversity and ecosystems,
                  including on raw materials, natural resources and ecosystem services. The
                  undertaking may rely on the international classifications such as the Common
                  International Classification of Ecosystem Services (CICES).
                </li>
              </ol>
              <p className="question-tip">
                AR 9. In Phase 3, to assesses its material risks and opportunities based on the
                results of Phases 1 and 2, the undertaking may consider the following categories:
              </p>
              <ol className="question-tip" type="a">
                <li>
                  physical risks:
                  <ol className="question-tip" type="i">
                    <li>
                      acute risks (e.g., natural disasters exacerbated by loss of coastal protection
                      from ecosystems, leading to costs of storm damage to coastal infrastructure,
                      disease or pests affecting the species or variety of crop the undertaking
                      relies on, especially in the case of no or low genetic diversity, species loss
                      and ecosystem degradation); and
                    </li>
                    <li>
                      chronic risks (e.g., loss of crop yield due to decline in pollination
                      services, increasing scarcity or variable production of key natural inputs,
                      ecosystem degradation due to operations leading to, for example, coastal
                      erosion and forest fragmentation, ocean acidification, land loss to
                      desertification and soil degradation and consequent loss of soil fertility,
                      species loss).
                    </li>
                  </ol>
                </li>
                <li>
                  transition risks, including:
                  <ol className="question-tip" type="i">
                    <li>
                      policy and legal: e.g. introduction of regulation or policy (e.g. changes such
                      as increased land protection); exposure to sanctions and litigation (e.g.
                      spills of polluting effluents that damage human and ecosystem health; or
                      violation of biodiversity-related rights, permits or allocations; or
                      negligence towards or killing of threatened species); enhanced reporting
                      obligations on biodiversity, ecosystems and related services;
                    </li>
                    <li>
                      technology: e.g. substitution of products or services by products or services
                      with a lower impact on biodiversity or dependence on ecosystem services, lack
                      of access to data or access to poor quality data that hamper
                      biodiversity-related assessments, transition to more efficient and cleaner
                      technologies (i.e. with lower impacts on biodiversity), new monitoring
                      technologies (e.g. satellite), requirements to use certain technologies (e.g.
                      climate resistant crops, mechanical pollinators, water purification, flood
                      protection);
                    </li>
                    <li>
                      market: e.g., shifting supply, demand and financing, volatility or increased
                      costs of raw materials (e.g., biodiversity-intense inputs for which price has
                      risen due to ecosystem degradation);
                    </li>
                    <li>
                      reputation: e.g., changing societal, customer or community perceptions as a
                      result of an organisation’s role in loss of biodiversity, violation of
                      nature-related rights through operations, negative media coverage due to
                      impacts on critical species and/or ecosystems, biodiversity-related social
                      conflicts over endangered species, protected areas, resources or pollution.
                    </li>
                  </ol>
                </li>
                <li>
                  systemic risks, including:
                  <ol className="question-tip" type="i">
                    <li>
                      ecosystem collapse risks that a critical natural system no longer functions,
                      e.g., tipping points are reached and the collapse of ecosystems resulting in
                      wholesale geographic or sector losses (summing physical risks);
                    </li>
                    <li>
                      aggregated risk linked to fundamental impacts of biodiversity loss to levels
                      of transition and physical risk across one or more sectors in a portfolio
                      (corporate or financial); and
                    </li>
                    <li>
                      contagion risks that financial difficulties of certain corporations or
                      financial institutions linked to failure to account for exposure to
                      biodiversity-related risks spill over to the economic system as a whole.
                    </li>
                  </ol>
                </li>
                <li>
                  opportunities, including for example:
                  <ol className="question-tip" type="i">
                    <li>
                      business performance categories: resource efficiency; products and services;
                      markets; capital flow and financing; reputational capital; and
                    </li>
                    <li>
                      sustainability performance categories: ecosystem protection, restoration and
                      regeneration; sustainability use of natural resources.
                    </li>
                  </ol>
                </li>
              </ol>
            </Typography>
          ),
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
          module: "Environment",
          boldPart: "17 b",
        },
        {
          id: "q-416",
          question:
            "(c) identified and assessed transition and physical risks and opportunities related to biodiversity and ecosystems, including assessment criteria applied based on its impacts and dependencies; (d) Considered systemic risks; ",
          tooltip: (
            <Typography>
              <p className="question-tip">
                AR 4. The materiality assessment under ESRS E4 includes the undertaking’s:
              </p>
              <ol className="question-tip" type="a">
                <li>
                  contribution to direct impact drivers on biodiversity loss12:
                  <ol className="question-tip" type="i">
                    <li>climate change; </li>
                    <li>
                      land-use change (e.g., land artificialisation), freshwater-use change and
                      sea-use change;
                    </li>
                    <li>direct exploitation; </li>
                    <li>invasive alien species;</li>
                    <li>pollution; and </li>
                    <li>others. </li>
                  </ol>
                </li>
                <li>
                  impacts on the state of species (i.e., species population size, species global
                  extinction risk);
                </li>
                <li>
                  impacts on the extent and condition of ecosystems including through land
                  degradation, desertification and soil sealing); and
                </li>
                <li> impacts and dependencies on ecosystem services.</li>
              </ol>
              <p className="question-tip">
                AR 5. When assessing the materiality of impacts, dependencies, risks and
                opportunities the undertaking shall consider the provisions in ESRS 2 IRO-1 and ESRS
                1 Chapter 3 Double materiality as the basis for sustainability disclosures and
                describe its considerations. <br />
                AR 6. The undertaking shall assess the materiality of biodiversity and ecosystems in
                its own operations and its upstream and downstream value chain, and may conduct its
                materiality assessment in line with the first three phases of the LEAP approach:
                Locate (paragraph AR 7), Evaluate (paragraph AR 8) and Assess (paragraph AR 9). AR
                7. Phase 1 relates to the localisation of relevant sites regarding its interface
                with biodiversity and ecosystems. To identify these relevant sites the undertaking
                may:
              </p>
              <ol className="question-tip" type="a">
                <li>
                  develop a list of locations of direct assets and operations and related upstream
                  and downstream value chain that are relevant to the undertakings business
                  activities. Furthermore, the undertaking may provide information about sites for
                  which future operations have been formally announced;
                </li>
                <li>
                  list the biomes and ecosystems it is interfacing with based on the list of
                  locations identified under paragraph AR 7(a);
                </li>
                <li>
                  identify the current integrity and importance of biodiversity and ecosystem at
                  each location taking into consideration the information provided in paragraphs 16
                  and 17;
                </li>
                <li>
                  develop a list of locations where the undertaking is interfacing with locations in
                  or near biodiversity-sensitive areas taking into consideration the information
                  provided in paragraphs 16 and 17; and
                </li>
                <li>
                  identify which sectors, business units, value chains or asset classes are
                  interfacing with biodiversity and ecosystems in these material sites. Instead of
                  identifying these interfaces per site, the undertaking may choose to identify them
                  per raw material procured or sold by weight in tons, if such practice offers
                  greater transparency.
                </li>
              </ol>
              <p className="question-tip">
                AR 8. In Phase 2, to evaluate its actual or potential impacts and dependencies on
                biodiversity and ecosystems for relevant sites, the undertaking may:
              </p>
              <ol className="question-tip" type="a">
                <li>
                  identify business processes and activities that interface with biodiversity and
                  ecosystems;
                </li>
                <li>identify actual and potential impacts and dependencies; </li>
                <li>
                  indicate the size, scale, frequency of occurrence and timeframe of the impacts on
                  biodiversity and ecosystems taking into consideration the disclosures under
                  paragraphs 16 and 17. Furthermore, the undertaking may disclose:
                  <ol className="question-tip" type="i">
                    <li>
                      the percentage of its suppliers’ facilities which are located in risk prone
                      areas (with threatened species on the IUCN Red List of Species, the Birds and
                      Habitats Directive or nationally list of threatened species, or in officially
                      recognised Protected Areas, the Natura 2000 network of protected areas and Key
                      Biodiversity Areas);
                    </li>
                    <li>
                      the percentage of its procurement spend from suppliers with facilities which
                      are located in risk prone areas (with threatened species on the IUCN Red List
                      of Species, the Birds and Habitats Directive or nationally list of threatened
                      species, or in officially recognised Protected Areas, the Natura 2000 network
                      of protected areas and Key Biodiversity Areas); and
                    </li>
                  </ol>
                </li>
                <li>
                  indicate the size and scale of the dependencies on biodiversity and ecosystems,
                  including on raw materials, natural resources and ecosystem services. The
                  undertaking may rely on the international classifications such as the Common
                  International Classification of Ecosystem Services (CICES).
                </li>
              </ol>
              <p className="question-tip">
                AR 9. In Phase 3, to assesses its material risks and opportunities based on the
                results of Phases 1 and 2, the undertaking may consider the following categories:
              </p>
              <ol className="question-tip" type="a">
                <li>
                  physical risks:
                  <ol className="question-tip" type="i">
                    <li>
                      acute risks (e.g., natural disasters exacerbated by loss of coastal protection
                      from ecosystems, leading to costs of storm damage to coastal infrastructure,
                      disease or pests affecting the species or variety of crop the undertaking
                      relies on, especially in the case of no or low genetic diversity, species loss
                      and ecosystem degradation); and
                    </li>
                    <li>
                      chronic risks (e.g., loss of crop yield due to decline in pollination
                      services, increasing scarcity or variable production of key natural inputs,
                      ecosystem degradation due to operations leading to, for example, coastal
                      erosion and forest fragmentation, ocean acidification, land loss to
                      desertification and soil degradation and consequent loss of soil fertility,
                      species loss).
                    </li>
                  </ol>
                </li>
                <li>
                  transition risks, including:
                  <ol className="question-tip" type="i">
                    <li>
                      policy and legal: e.g. introduction of regulation or policy (e.g. changes such
                      as increased land protection); exposure to sanctions and litigation (e.g.
                      spills of polluting effluents that damage human and ecosystem health; or
                      violation of biodiversity-related rights, permits or allocations; or
                      negligence towards or killing of threatened species); enhanced reporting
                      obligations on biodiversity, ecosystems and related services;
                    </li>
                    <li>
                      technology: e.g. substitution of products or services by products or services
                      with a lower impact on biodiversity or dependence on ecosystem services, lack
                      of access to data or access to poor quality data that hamper
                      biodiversity-related assessments, transition to more efficient and cleaner
                      technologies (i.e. with lower impacts on biodiversity), new monitoring
                      technologies (e.g. satellite), requirements to use certain technologies (e.g.
                      climate resistant crops, mechanical pollinators, water purification, flood
                      protection);
                    </li>
                    <li>
                      market: e.g., shifting supply, demand and financing, volatility or increased
                      costs of raw materials (e.g., biodiversity-intense inputs for which price has
                      risen due to ecosystem degradation);
                    </li>
                    <li>
                      reputation: e.g., changing societal, customer or community perceptions as a
                      result of an organisation’s role in loss of biodiversity, violation of
                      nature-related rights through operations, negative media coverage due to
                      impacts on critical species and/or ecosystems, biodiversity-related social
                      conflicts over endangered species, protected areas, resources or pollution.
                    </li>
                  </ol>
                </li>
                <li>
                  systemic risks, including:
                  <ol className="question-tip" type="i">
                    <li>
                      ecosystem collapse risks that a critical natural system no longer functions,
                      e.g., tipping points are reached and the collapse of ecosystems resulting in
                      wholesale geographic or sector losses (summing physical risks);
                    </li>
                    <li>
                      aggregated risk linked to fundamental impacts of biodiversity loss to levels
                      of transition and physical risk across one or more sectors in a portfolio
                      (corporate or financial); and
                    </li>
                    <li>
                      contagion risks that financial difficulties of certain corporations or
                      financial institutions linked to failure to account for exposure to
                      biodiversity-related risks spill over to the economic system as a whole.
                    </li>
                  </ol>
                </li>
                <li>
                  opportunities, including for example:
                  <ol className="question-tip" type="i">
                    <li>
                      business performance categories: resource efficiency; products and services;
                      markets; capital flow and financing; reputational capital; and
                    </li>
                    <li>
                      sustainability performance categories: ecosystem protection, restoration and
                      regeneration; sustainability use of natural resources.
                    </li>
                  </ol>
                </li>
              </ol>
            </Typography>
          ),
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
          module: "Environment",
          boldPart: "17 c, 17 d",
        },

        {
          id: "q-418",
          question:
            "conducted consultations with affected communities on sustainability assessments of shared bio resources and ecosystems and, in particular: ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
          module: "Environment",
          boldPart: "17 e",
        },
        {
          id: "q-419",
          question:
            "when a site, a raw material production or sourcing is likely to negatively impact biodiversity and ecosystems, the identification of the specific sites, raw materials production or sourcing with negative or potentially negative impacts on affected communities;",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
          module: "Environment",
          boldPart: "17 e (i)",
        },
        {
          id: "q-420",
          question:
            "when affected communities are likely to be impacted, the undertaking, shall disclose how these communities were involved in the materiality assessment;",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
          module: "Environment",
          boldPart: "17 e (ii)",
        },
        {
          id: "q-421",
          question:
            "with respect to impacts on ecosystem services of relevance to affected communities in its own operations, the undertaking shall indicate how negative impacts may be avoided. If these impacts are unavoidable, the undertaking may indicate its plans to minimise them and implement mitigation measures that aim to maintain the value and functionality of priority services. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Environment",
          boldPart: "17 e (iii)",
        },
        {
          id: "q-422",
          question:
            "The undertaking may disclose whether and how it has used biodiversity and ecosystems scenario analysis to inform the identification and assessment of material risks and opportunities over short-, medium- and long-term time horizons. If the undertaking has used such scenario analysis: (a) why the considered scenarios were selected; ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Environment",
          boldPart: "18 a",
        },

        {
          id: "q-424",
          question:
            "how the considered scenarios are updated according to evolving conditions and emerging trends;",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Environment",
          boldPart: "18 b",
        },
        {
          id: "q-425",
          question:
            "whether the scenarios are informed by expectations published by authoritative intergovernmental bodies, such as the Convention for Biological Diversity and, where relevant, by scientific consensus, such as that expressed by the Intergovernmental Science-policy Platform on Biodiversity and Ecosystem Services (IPBES). ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Environment",
          boldPart: "18 c",
        },
        {
          id: "q-426",
          question:
            "whether or not it has sites located in or near biodiversity-sensitive areas and whether activities related to these sites negatively affect these areas by leading to the deterioration of natural habitats and the habitats of species and to the disturbance of the species for which a protected area has been designated; ",
          tooltip: (
            <Typography>
              <p className="question-tip">
                develop a list of locations where the undertaking is interfacing with locations in
                or near biodiversity-sensitive areas taking into consideration the information
                provided in paragraphs 16 and 17
              </p>
            </Typography>
          ),
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
          module: "Environment",
          boldPart: "19 a",
        },
        {
          id: "q-427",
          question:
            "whether it has been concluded that it is necessary to implement biodiversity mitigation measures, such as those identified in: Directive 2009/147/EC of the European Parliament and of the Council on the conservation of wild birds; Council Directive 92/43/EEC on the conservation of natural habitats and of wild fauna and flora; an Environmental Impact Assessment (EIA) as defined in Article 1(2), point (g), of Directive 2011/92/EU of the European Parliament and of the Council7 on the assessment of the effects of certain public and private projects on the environment; and for activities located in third countries, in accordance with equivalent national provisions or international standards, such as the International Finance Corporation (IFC) Performance Standard 6: Biodiversity Conservation and Sustainable Management of Living Natural Resources. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
          module: "Environment",
          boldPart: "19 b",
        },
        // Add more questions as needed
      ],
    "E4-1 – Transition plan and consideration of biodiversity and ecosystems in strategy and business model":
      [
        {
          id: "q-428",
          question:
            "The undertaking shall describe the resilience of its strategy and business model in relation to biodiversity and ecosystems. The description shall include: (a) an assessment of the resilience of the current business model and strategy to biodiversity and ecosystems-related physical, transition and systemic risks; (b) The scope of the resilience analysis in relation to the undertaking’s own operations and its upstream and downstream value chain and in relation to the risks considered in that analysis; (c) The key assumptions made; (d) The time horizons used; (e) The results of the resilience analysis; (f) The involvement of stakeholders, including, where appropriate, holders of indigenous and local knowledge.",
          tooltip: (
            <Typography>
              <p className="question-tip">
                AR 2. If disclosing a transition plan, the undertaking may, for example, refer to
                the following targets from the EU Biodiversity Strategy for 2030:
              </p>
              <ol className="question-tip" type="a">
                <li>The decline of pollinators is reversed. </li>
                <li>
                  The risk and use of chemical pesticides is reduced by 50%, and the use of more
                  hazardous pesticides is reduced by 50%.
                </li>
                <li>
                  At least 25% of agricultural land is under organic farming management, and the
                  uptake of agro-ecological practices is significantly increased.
                </li>
                <li>
                  Three billion additional trees are planted in the EU, in full respect of
                  ecological principles.
                </li>
                <li>Significant progress in the remediation of contaminated soil sites. </li>
                <li></li>
                <li>At least 25,000 km of free-flowing rivers are restored. </li>
                <li>
                  The losses of nutrients from fertilisers are reduced by 50%, resulting in the
                  reduction of the use of fertilisers by at least 20%.
                </li>
                <li>
                  The negative impacts on sensitive species and habitats, including on the seabed
                  through fishing and extraction activities, are substantially reduced to achieve
                  good environmental status.
                </li>
              </ol>
              <p className="question-tip">
                AR 3. If disclosing a transition plan, the undertaking may also refer to the
                Sustainable Development Goals, in particular:
              </p>
              <ol className="question-tip">
                <li>
                  SDG 2 - End hunger, achieve food security and improved nutrition and promote
                  sustainable agriculture;
                </li>
                <li>
                  SDG 6 - Ensure availability and sustainable management of water and sanitation for
                  all;
                </li>
                <li>
                  SDG 14 - Conserve and sustainably use the oceans, seas and marine resources for
                  sustainable development; and
                </li>
                <li>
                  SDG 15 - Protect, restore and promote sustainable use of terrestrial ecosystems,
                  sustainably manage forests, combat desertification, and halt and reverse land
                  degradation and halt biodiversity loss.
                </li>
              </ol>
            </Typography>
          ),
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
          module: "Environment",
          boldPart: "13",
        },

        {
          id: "q-434",
          question:
            "The undertaking may disclose its transition plan to improve and, ultimately, achieve alignment of its business model and strategy with the vision of the Kunming-Montreal Global Biodiversity Framework and its relevant goals and targets, the EU Biodiversity Strategy for 2030, and with respecting planetary boundaries related to biosphere integrity and land-system change. ",
          tooltip: (
            <Typography>
              <p className="question-tip">
                AR 2. If disclosing a transition plan, the undertaking may, for example, refer to
                the following targets from the EU Biodiversity Strategy for 2030:
              </p>
              <ol className="question-tip" type="a">
                <li>The decline of pollinators is reversed. </li>
                <li>
                  The risk and use of chemical pesticides is reduced by 50%, and the use of more
                  hazardous pesticides is reduced by 50%.
                </li>
                <li>
                  At least 25% of agricultural land is under organic farming management, and the
                  uptake of agro-ecological practices is significantly increased.
                </li>
                <li>
                  Three billion additional trees are planted in the EU, in full respect of
                  ecological principles.
                </li>
                <li>Significant progress in the remediation of contaminated soil sites. </li>
                <li></li>
                <li>At least 25,000 km of free-flowing rivers are restored. </li>
                <li>
                  The losses of nutrients from fertilisers are reduced by 50%, resulting in the
                  reduction of the use of fertilisers by at least 20%.
                </li>
                <li>
                  The negative impacts on sensitive species and habitats, including on the seabed
                  through fishing and extraction activities, are substantially reduced to achieve
                  good environmental status.
                </li>
              </ol>
              <p className="question-tip">
                AR 3. If disclosing a transition plan, the undertaking may also refer to the
                Sustainable Development Goals, in particular:
              </p>
              <ol className="question-tip">
                <li>
                  SDG 2 - End hunger, achieve food security and improved nutrition and promote
                  sustainable agriculture;
                </li>
                <li>
                  SDG 6 - Ensure availability and sustainable management of water and sanitation for
                  all;
                </li>
                <li>
                  SDG 14 - Conserve and sustainably use the oceans, seas and marine resources for
                  sustainable development; and
                </li>
                <li>
                  SDG 15 - Protect, restore and promote sustainable use of terrestrial ecosystems,
                  sustainably manage forests, combat desertification, and halt and reverse land
                  degradation and halt biodiversity loss.
                </li>
              </ol>
            </Typography>
          ),
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
          module: "Environment",
          boldPart: "15",
          voluntary: true,
        },
        {
          id: "q-435",
          question:
            "If disclosing a transition plan, the undertaking may: (a) explain how it will adjust its strategy and business model to improve and, ultimately, achieve alignment with relevant local, national and global public policy goals and targets related to biodiversity and ecosystems including the vision of the Kunming Montreal Global Biodiversity Framework and its relevant goals and targets, the EU Biodiversity Strategy for 2030, and Directive 2009/147/EC Council Directive 92/43/EEC (the EU Birds and Habitats Directives) and, as appropriate, planetary boundaries related to biosphere integrity and land-system change; ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Environment",
          boldPart: "AR 1 a",
        },
        {
          id: "q-436",
          question:
            "If disclosing a transition plan, the undertaking may: include information about its own operations and also explain how it is responding to material impacts in its upstream and downstream value chain identified in its materiality assessment in accordance with ESRS 2 IRO-1 Description of the processes to identify and assess material impacts, risks and opportunities; ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Environment",
          boldPart: "AR 1 b",
        },
        {
          id: "q-437",
          question:
            "If disclosing a transition plan, the undertaking may: explain how its strategy interacts with its transition plan;",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Environment",
          boldPart: "AR 1 c",
        },
        {
          id: "q-438",
          question:
            "If disclosing a transition plan, the undertaking may: explain how it contributes to addressing biodiversity and ecosystem impact drivers and its possible mitigation actions following the mitigation hierarchy and the main path-dependencies and locked-in assets and resources (e.g., plants, raw materials) that are associated with biodiversity and ecosystems change; ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Environment",
          boldPart: "AR 1 d",
        },
        {
          id: "q-439",
          question:
            "If disclosing a transition plan, the undertaking may: explain and quantify its investments and funding supporting the implementation of its transition plan, with a reference to the key performance indicators of taxonomy aligned CapEx, and where relevant the CapEx plans, that the undertaking discloses in accordance with Commission Delegated Regulation (EU) 2021/2178; ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Environment",
          boldPart: "AR 1 e",
        },
        {
          id: "q-440",
          question:
            "If disclosing a transition plan, the undertaking may:if it has economic activities that are covered by delegated regulations on biodiversity under the Taxonomy Regulation, explain any objective or plans (CapEX, CapEx plans) that it has for aligning its economic activities (revenues, CapEx) with the criteria established in those delegated regulations; ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Environment",
          boldPart: "AR 1 f",
        },
        {
          id: "q-441",
          question:
            "If disclosing a transition plan, the undertaking may:explain how biodiversity offsets are used as part of the transition plan, and if so, where the offsets are planned to be used, the extent of use in relation to the overall transition plan, and whether the mitigation hierarchy was considered;",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Environment",
          boldPart: "AR 1 g",
        },
        {
          id: "q-442",
          question:
            "If disclosing a transition plan, the undertaking may:explain how the process of implementing and updating the transition plan is managed;",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Environment",
          boldPart: "AR 1 h",
        },
        {
          id: "q-443",
          question:
            "If disclosing a transition plan, the undertaking may:explain how it measures progress, namely indicate the metrics and methodologies it uses for that purpose; ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Environment",
          boldPart: "AR 1 i",
        },
        {
          id: "q-444",
          question:
            "If disclosing a transition plan, the undertaking may: indicate whether the administrative, management and supervisory bodies have approved the transition plan;",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Environment",
          boldPart: "AR 1 j",
        },
        {
          id: "q-445",
          question:
            "If disclosing a transition plan, the undertaking may: indicate current challenges and limitations to draft a plan in relation to areas of significant impact and how the company is addressing those challenges.",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Environment",
          boldPart: "AR 1 k",
        },
        // Add more questions as needed
      ],
    "E4-2 – Policies related to biodiversity and ecosystems": [
      {
        id: "q-446",
        question:
          "In addition to the provisions of ESRS 2 MDR-P the undertaking shall describe whether and how its biodiversity and ecosystems-related policies: (a) relate to the matters specified in ESRS E4 AR 4; ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "23 a",
      },
      {
        id: "q-447",
        question: "Relate to its material biodiversity and ecosystems-related impacts;",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "23 b",
      },
      {
        id: "q-448",
        question:
          "Relate to material dependencies and material physical and transition risks and opportunities;",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "23 c",
      },
      {
        id: "q-449",
        question:
          "Support traceability of products, components and raw materials with material actual or potential impacts on biodiversity and ecosystems along the value chain;",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "23 d",
      },
      {
        id: "q-450",
        question:
          "Address production, sourcing or consumption from ecosystems that are managed to maintain or enhance conditions for biodiversity, as demonstrated by regular monitoring and reporting of biodiversity status and gains or losses; ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "23 e",
      },
      {
        id: "q-451",
        question: "Address social consequences of biodiversity and ecosystems-related impacts. ",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 14. When disclosing policies related to social consequences of biodiversity and
              ecosystems related dependencies and impacts under 23 (f), the undertaking may notably
              refer to the Nagoya Protocol and the Convention for Biological Diversity (CBD).
            </p>
            <p className="question-tip">
              AR 15. When disclosing information about whether and how its policies address the
              social consequences of biodiversity and ecosystems-related impacts under paragraph
              23(f), the undertaking may provide information in relation to:
            </p>
            <ol className="question-tip" type="a">
              <li>
                the fair and equitable sharing of the benefits arising from the utilisation of
                genetic resources; and
              </li>
              <li>the free, prior and informed consent for access to genetic resources.</li>
            </ol>
          </Typography>
        ),
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "23 f",
      },
      {
        id: "q-452",
        question:
          "The undertaking may also provide information on how the policy refers to the production, sourcing or consumption of raw materials, and in particular how it:",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 12",
      },
      {
        id: "q-453",
        question:
          "limits procurement from suppliers that cannot demonstrate that they are not contributing to significant damage to protected areas or key biodiversity areas (e.g., through certification); ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 12 a",
      },
      {
        id: "q-454",
        question:
          "refers to recognised standards or third-party certifications overseen by regulators; ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 12 b",
      },
      {
        id: "q-455",
        question:
          "addresses raw materials originating from ecosystems that have been managed to maintain or enhance conditions for biodiversity, as demonstrated by regular monitoring and reporting of biodiversity status and gains or losses.",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 12 c",
      },
      {
        id: "q-456",
        question:
          " The undertaking may also explain how its policy enables it to: (a) avoid negative impacts on biodiversity and ecosystems in its own operations and related upstream and downstream value chain; (d) reduce and minimise its negative impacts on biodiversity and ecosystems in its operations and throughout the upstream and downstream value chain that cannot be avoided; (c) restore and rehabilitate degraded ecosystems or restore cleared ecosystems following exposure to impacts that cannot be completely avoided and/or minimised; and (d) mitigate its contribution to material biodiversity loss drivers.",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 16",
      },
      {
        id: "q-457",
        question:
          "When disclosing its policies, if referring to third-party standards of conduct, the undertaking may disclose whether the standard used: (a) is objective and achievable based on a scientific approach to identifying issues, and realistic in assessing how these issues can be addressed on the ground under a variety of practical circumstances; ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 17 a",
      },
      {
        id: "q-458",
        question:
          "When disclosing its policies, if referring to third-party standards of conduct, the undertaking may disclose whether the standard used: is developed or maintained through a process of ongoing consultation with stakeholders with balanced input from all relevant stakeholder groups, including producers, traders, processors, financiers, local people and communities, indigenous peoples, and civil society organisations representing consumer, environmental and social interests, with no group holding undue authority or veto power over the content; ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 17 b",
      },
      {
        id: "q-459",
        question:
          "When disclosing its policies, if referring to third-party standards of conduct, the undertaking may disclose whether the standard used: encourages a step-wise approach and continuous improvement, both in the standard and its application of better management practices, and requires the establishment of meaningful targets and specific milestones to indicate progress against principles and criteria over time; ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 17 c",
      },
      {
        id: "q-460",
        question:
          "When disclosing its policies, if referring to third-party standards of conduct, the undertaking may disclose whether the standard used: is verifiable through independent certifying or verifying bodies, which have defined and rigorous assessment procedures that avoid conflicts of interest, and are compliant with ISO guidance on accreditation and verification procedures or Article 5(2) of Regulation (EC) No 765/2008; ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 17 d",
      },
      {
        id: "q-461",
        question:
          "When disclosing its policies, if referring to third-party standards of conduct, the undertaking may disclose whether the standard used: conforms to the ISEAL Code of Good Practice. ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 17 e",
      },
      {
        id: "q-462",
        question:
          "Biodiversity and ecosystem protection policy covering operational sites owned, leased, or managed in or near a biodiversity sensitive area;",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "24 a",
      },
      {
        id: "q-463",
        question: "Sustainable land / agriculture practices or policies.",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 11 Table #2 of Annex 1
            </p>
          </Typography>
        ),

        module: "Environment",
        boldPart: "24 b",
      },
      {
        id: "q-464",
        question: "Sustainable oceans / seas practices or policies.",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",

        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 12 Table #2 of Annex 1
            </p>
          </Typography>
        ),
        module: "Environment",
        boldPart: "24 c",
      },
      {
        id: "q-465",
        question: "Policies to address deforestation.",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 15 Table #2 of Annex 1
            </p>
          </Typography>
        ),
        module: "Environment",
        boldPart: "24 d",
      },
      {
        id: "q-466",
        question:
          "The undertaking shall disclose information about policies adopted to manage material sustainability matters. The disclosure shall include the following information: (a) a description of the key contents of the policy, including its general objectives and which material impacts, risks or opportunities the policy relates to and the process for monitoring; ",
        tooltip: "",
        condition: "yes",
        module: "Environment",
        boldPart: "65 a",
      },
      {
        id: "q-467",
        question:
          "a description of the scope of the policy, or of its exclusions, in terms of activities, upstream and/or downstream value chain, geographies and if relevant, affected stakeholder groups;",
        tooltip: "",
        condition: "yes",
        module: "Environment",
        boldPart: "65 b",
      },
      {
        id: "q-468",
        question:
          "the most senior level in the undertaking’s organisation that is accountable for the implementation of the policy;",
        tooltip: "",
        condition: "yes",
        module: "Environment",
        boldPart: "65 c",
      },
      {
        id: "q-469",
        question:
          "a reference, if relevant, to the third-party standards or initiatives the undertaking commits to respect through the implementation of the policy;",
        tooltip: "",
        condition: "yes",
        module: "Environment",
        boldPart: "65 d",
      },
      {
        id: "q-470",
        question:
          "if relevant, a description of the consideration given to the interests of key stakeholders in setting the policy; ",
        tooltip: "",
        condition: "yes",
        module: "Environment",
        boldPart: "65 e",
      },
      {
        id: "q-471",
        question:
          "if relevant, whether and how the undertaking makes the policy available to potentially affected stakeholders, and stakeholders who need to help implement it. ",
        tooltip: "",
        condition: "yes",
        module: "Environment",
        boldPart: "65 f",
      },
      {
        id: "q-472",
        question:
          "If the undertaking cannot disclose the information on policies and actions required under relevant ESRS, because it has not adopted policies and/or actions with reference to the specific sustainability matter concerned, it shall disclose this to be the case, and provide reasons for not having adopted policies and/or actions. ",
        tooltip: "",
        condition: "no",
        module: "Environment",
        boldPart: "62",
      },
      {
        id: "q-473",
        question: "The undertaking may disclose a timeframe in which it aims to adopt them. ",
        tooltip: "",
        condition: "no",
        module: "Environment",
        boldPart: "62",
      },
      // Add more questions as needed
    ],
    "E4-3 – Actions and resources related to biodiversity and ecosystems": [
      {
        id: "q-474",
        question:
          "The description of key actions and resources shall follow the mandatory content defined in ESRS 2 MDR-A Actions and resources in relation to material sustainability matters. ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "27",
      },
      {
        id: "q-475",
        question:
          "May disclose how it has applied the mitigation hierarchy with regard to its actions (avoidance, minimisation, restoration/rehabilitation, and compensation or offsets); ",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 19. The undertaking may disclose whether it considers an “avoidance” action plan,
              which prevents damaging actions before they take place. Avoidance often involves a
              decision to deviate from the business-as-usual project development path. An example of
              avoidance is altering the biodiversity and ecosystem footprint of a project to avoid
              destruction of natural habitat on the site and/or establishing set-asides where
              priority biodiversity values are present and will be conserved. At a minimum,
              avoidance should be considered where there are biodiversity and ecosystem-related
              values that are in one of the following categories: particularly vulnerable and
              irreplaceable, of particular concern to stakeholders, or where a cautious approach is
              warranted due to uncertainty about impact assessment or about the efficacy of
              management measures. The three main types of avoidance are defined below:
            </p>
            <ol className="question-tip" type="a">
              <li>
                avoidance through site selection (Locate the entire project away from areas
                recognised for important biodiversity values);
              </li>
              <li>
                avoidance through project design (Configure infrastructure to preserve areas at the
                project site with important biodiversity values); and
              </li>
              <li>
                avoidance through scheduling (Time project activities to account for patterns of
                species behaviour (e.g., breeding, migration) or ecosystem functions (e.g., river
                dynamics).
              </li>
            </ol>
          </Typography>
        ),
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        voluntary: true,
        module: "Environment",
        boldPart: "28 a",
      },
      {
        id: "q-476",
        question:
          "Shall disclose whether it used biodiversity offsets in its action plans. If the actions contain biodiversity offsets, the undertaking shall include the following information: ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "28 b",
      },
      {
        id: "q-477",
        question: "The aim of the offset and key performance indicators used;",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "28 b (i)",
      },
      {
        id: "q-478",
        question:
          "The financing effects (direct and indirect costs) of biodiversity offsets in monetary terms;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 18. The undertaking may relate significant monetary amounts of CapEx and OpEx
              required to implement the actions taken or planned to:
            </p>
            <ol className="question-tip" type="a">
              <li>the relevant line items or notes in the financial statements; </li>
              <li>
                the key performance indicators required under article 8 of Regulation (EU) 2020/852
                and under Commission Delegated Regulation (EU) 2021/2178; and
              </li>
              <li>
                if applicable, the CapEx plan required by Commission Delegated Regulation (EU)
                2021/2178.
              </li>
            </ol>
          </Typography>
        ),
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "28 b (ii)",
      },
      {
        id: "q-479",
        question:
          "A description of offsets including area, type, the quality criteria applied and the standards that the biodiversity offsets comply with.",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "28 b (iii)",
      },
      {
        id: "q-480",
        question:
          "Shall describe whether and how it has incorporated local and indigenous knowledge and nature- based solutions into biodiversity and ecosystems-related actions. ",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 21. In the context of this Disclosure Requirement, “local and indigenous knowledge”
              refer to the understandings, skills and philosophies developed by societies with long
              histories of interaction with their natural surroundings. For rural and indigenous
              peoples, local knowledge informs decision-making about fundamental aspects of
              day-to-day life.
            </p>
          </Typography>
        ),
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "28 c",
      },
      {
        id: "q-481",
        question:
          "With regard to key actions, the undertaking may disclose: (a) a list of key stakeholders involved (e.g., competitors, suppliers, retailers, other business partners, affected communities and authorities, government agencies) and how they are involved, mentioning key stakeholders negatively or positively impacted by actions and how they are impacted, including impacts or benefits created for affected communities, smallholders, indigenous peoples or other persons in vulnerable situations; ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 20 a",
      },
      {
        id: "q-482",
        question:
          "With regard to key actions, the undertaking may disclose:where applicable, an explanation of the need for appropriate consultations and the need to respect the decisions of affected communities; ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 20 b",
      },
      {
        id: "q-483",
        question:
          "With regard to key actions, the undertaking may disclose:a brief assessment of whether the key actions may induce significant negative sustainability impacts; ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 20 c",
      },
      {
        id: "q-484",
        question:
          "With regard to key actions, the undertaking may disclose:an explanation of whether the key action is intended to be a one-time initiative or systematic practice; ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 20 d",
      },
      {
        id: "q-485",
        question:
          "With regard to key actions, the undertaking may disclose:an explanation of whether the key action plan is carried out only by the undertaking, using the undertaking’s resources, or whether it is part of a wider initiative to which the undertaking significantly contributes. If the key action plan is part of a wider initiative, the undertaking may provide more information on the project, its sponsors and other participants; ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 20 e",
      },
      {
        id: "q-486",
        question:
          "With regard to key actions, the undertaking may disclose:a description of how it contributes to systemwide change, notably to alter the drivers of biodiversity and ecosystem change, e.g., through technological, economic, institutional, and social factors and changes in underlying values and behaviours; ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 20 f",
      },
      {
        id: "q-487",
        question:
          "Where the implementation of a policy requires actions, or a comprehensive action plan, to achieve its objectives, as well as when actions are implemented without a specific policy, the undertaking shall disclose the following information: (a) the list of key actions taken in the reporting year and planned for the future, their expected outcomes and, where relevant, how their implementation contributes to the achievement of policy objectives and targets;",
        tooltip:
          "AR 22. Key actions in the context of this Minimum Disclosure Requirement are those actions that materially contribute to achieving the undertaking’s objectives in addressing material impacts, risks and opportunities. oor reasons of understandability, key actions may be aggregated where appropriate. ",
        condition: "yes",
        module: "Environment",
        boldPart: "68 a",
      },
      {
        id: "q-488",
        question:
          "the scope of the key actions (i.e., coverage in terms of activities, upstream and/or downstream value chain, geographies and, where applicable, affected stakeholder groups); ",
        tooltip: "",
        condition: "yes",
        module: "Environment",
        boldPart: "68 b",
      },
      {
        id: "q-489",
        question:
          "the time horizons under which the undertaking intends to complete each key action;",
        tooltip: "",
        condition: "yes",
        module: "Environment",
        boldPart: "68 c",
      },
      {
        id: "q-490",
        question:
          "if applicable, key actions taken (along with results) to provide for and cooperate in or support the provision of remedy for those harmed by actual material impacts;",
        tooltip: "",
        condition: "yes",
        module: "Environment",
        boldPart: "68 d",
      },
      {
        id: "q-491",
        question:
          "if applicable, quantitative and qualitative information regarding the progress of actions or action plans disclosed in prior periods. ",
        tooltip: "",
        condition: "yes",
        module: "Environment",
        boldPart: "68 e",
      },
      {
        id: "q-492",
        question:
          "Where the implementation of an action plan requires significant operational expenditures (Opex) and/or capital expenditures (Capex) the undertaking shall: (a) describe the type of current and future financial and other resources allocated to the action plan, including if applicable, the relevant terms of sustainable finance instruments, such as green bonds, social bonds and green loans, the environmental or social objectives, and whether the ability to implement the actions or action plan depends on specific preconditions, e.g., granting of financial support or public policy and market developments; ",
        tooltip:
          "AR 23. Information on resource allocation may be presented in the form of a table and broken down between capital expenditure and operating expenditure, and across the relevant time horizons, and between resources applied in the current reporting year and the planned allocation of resources over specific time horizons.",
        condition: "yes",
        module: "Environment",
        boldPart: "69 a",
      },
      {
        id: "q-493",
        question:
          "provide the amount of current financial resources and explain how they relate to the most relevant amounts presented in the financial statements;",
        tooltip: "",
        condition: "yes",
        module: "Environment",
        boldPart: "69 b",
      },
      {
        id: "q-494",
        question: "provide the amount of future financial resources. ",
        tooltip: "",
        condition: "yes",
        module: "Environment",
        boldPart: "69 c",
      },
      {
        id: "q-495",
        question:
          "If the undertaking cannot disclose the information on policies and actions required under relevant ESRS, because it has not adopted policies and/or actions with reference to the specific sustainability matter concerned, it shall disclose this to be the case, and provide reasons for not having adopted policies and/or actions. ",
        tooltip: "",
        condition: "no",
        module: "Environment",
        boldPart: "62",
      },
      {
        id: "q-496",
        question: "The undertaking may disclose a timeframe in which it aims to adopt them. ",
        tooltip: "",
        condition: "no",
        module: "Environment",
        boldPart: "62",
      },
      // Add more questions as needed
    ],
    "E4-4 – Targets related to biodiversity and ecosystems": [
      {
        id: "q-497",
        question:
          "The description of the targets shall follow the mandatory content defined in ESRS 2 MDR-T Tracking effectiveness of policies and actions through targets.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 23. When disclosing information required under paragraph 29 for the purpose of
              setting targets the undertaking shall consider the need for an informed and willing
              consent of local and indigenous peoples, the need for appropriate consultations and
              the need to respect the decisions of these communities. AR 24. The targets related to
              material impacts may be presented in a table as illustrated below:
            </p>
            <p className="question-tip">Table: AR-23</p>
          </Typography>
        ),
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "29",
      },
      {
        id: "q-498",
        question:
          "Whether ecological thresholds and allocations of impacts to the undertaking were applied when setting targets. If so, the undertaking shall specify:",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "32 a",
      },
      {
        id: "q-499",
        question:
          "The ecological thresholds identified and the methodology used to identify such thresholds;",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "32 a i",
      },
      {
        id: "q-500",
        question:
          "Whether or not the thresholds are entity-specific and if so, how they were determined;",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "32 a ii",
      },
      {
        id: "q-501",
        question:
          "How responsibility for respecting identified ecological thresholds is allocated in the undertaking;",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "32 a iii",
      },
      {
        id: "q-502",
        question:
          "Whether the targets are informed by, and/or aligned with the Kunming-Montreal Global Biodiversity Framework, relevant aspects of the EU Biodiversity Strategy for 2030 and other biodiversity and ecosystem-related national policies and legislation; ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "32 b",
      },
      {
        id: "q-503",
        question:
          "How the targets relate to the biodiversity and ecosystem impacts, dependencies, risks and opportunities identified by the undertaking in relation to its own operations and its upstream and downstream value chain; ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "32 c",
      },
      {
        id: "q-504",
        question: "The geographical scope of the targets, if relevant; ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "32 d",
      },
      {
        id: "q-505",
        question:
          "Whether or not the undertaking used biodiversity offsets in setting its targets;",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "32 e",
      },
      {
        id: "q-506",
        question:
          "To which of the layers of the mitigation hierarchy the target can be allocated (i.e., avoidance, minimisation, restoration and rehabilitation, compensation or offsets).",
        tooltip: "",
        module: "Environment",
        boldPart: "32 f",
      },
      {
        id: "q-507",
        question:
          "The undertaking may specify whether the target addresses shortcomings related to the Substantial Contribution criteria for Biodiversity as defined in the delegated acts adopted pursuant to Article 15(2) of Regulation (EU) 2020/852. Where the Do No Significant Harm (DNSH) criteria for Biodiversity as defined in delegated acts adopted pursuant to Article 10(3), Article 11(3), Article 12(2), Article 13(2), and Article 14(2) of Regulation (EU) 2020/852 are not met, the undertaking may specify whether the target addresses shortcomings related those DNSH critera. ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 22",
      },
      {
        id: "q-508",
        question:
          "The undertaking shall disclose the measurable, outcome-oriented and time-bound targets on material sustainability matters it has set to assess progress. oor each target, the disclosure shall include the following information: (a) a description of the relationship of the target to the policy objectives;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy. "
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 a",
      },
      {
        id: "q-509",
        question:
          "the defined target level to be achieved, including, where applicable, whether the target is absolute or relative and in which unit it is measured;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy. "
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 b",
      },
      {
        id: "q-510",
        question:
          "the scope of the target, including the undertaking’s activities and/or its upstream and/or downstream value chain where applicable and geographical boundaries; ",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy. "
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 c",
      },
      {
        id: "q-511",
        question: "the baseline value and base year from which progress is measured;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy. "
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 d",
      },
      {
        id: "q-512",
        question:
          "the period to which the target applies and if applicable, any milestones or interim targets;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy. "
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 e",
      },
      {
        id: "q-513",
        question:
          "the methodologies and significant assumptions used to define targets, including where applicable, the selected scenario, data sources, alignment with national, EU or international policy goals and how the targets consider the wider context of sustainable development and/or local situation in which impacts take place; ",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy. "
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 f",
      },
      {
        id: "q-514",
        question:
          "whether the undertaking’s targets related to environmental matters are based on conclusive scientific evidence;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy. "
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 g",
      },
      {
        id: "q-515",
        question:
          "whether and how stakeholders have been involved in target setting for each material sustainability matter; ",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy. "
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 h",
      },
      {
        id: "q-516",
        question:
          "any changes in targets and corresponding metrics or underlying measurement methodologies, significant assumptions, limitations, sources and processes to collect data adopted within the defined 17 time horizon. This includes an explanation of the rationale for those changes and their effect on comparability (see Disclosure Requirement BP-2 Disclosures in relation to specific circumstances of this Standard);",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy. "
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 i",
      },
      {
        id: "q-517",
        question:
          "the performance against its disclosed targets, including information on how the target is monitored and reviewed and the metrics used, whether the progress is in line with what had been initially planned, and an analysis of trends or significant changes in the performance of the undertaking towards achieving the target. ",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy. "
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 j",
      },
      {
        id: "q-518",
        question:
          ". If the undertaking has not set any measurable outcome-oriented targets: (a) it may disclose whether such targets will be set and the timeframe for setting them, or the reasons why the undertaking does not plan to set such targets;",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        condition: "no",
        boldPart: "81 a",
      },
      {
        id: "q-519",
        question:
          "it shall disclose whether it nevertheless tracks the effectiveness of its policies and actions in relation to the material sustainability-related impact, risk and opportunity,",
        tooltip: "",
        module: "Environment",
        condition: "no",
        boldPart: "81 b",
      },

      {
        id: "q-520",
        question:
          "it shall disclose whether it nevertheless tracks the effectiveness of its policies and actions in relation to the material sustainability-related impact, risk and opportunity, and if so: i. any processes through which it does so",
        tooltip: "",
        module: "Environment",
        condition: "no",
        boldPart: "81 b i",
      },
      {
        id: "q-521",
        question:
          "it shall disclose whether it nevertheless tracks the effectiveness of its policies and actions in relation to the material sustainability-related impact, risk and opportunity, and if so: the defined level of ambition to be achieved and any qualitative or quantitative indicators it uses to evaluate progress, including the base period from which progress is measured. ",
        tooltip: "",
        module: "Environment",
        condition: "no",
        boldPart: "81 b ii, 80 d",
      },

      // Add more questions as needed
    ],
    "E4-5 – Impact metrics related to biodiversity and ecosystems change": [
      {
        id: "q-522",
        question:
          "If the undertaking identified sites located in or near biodiversity-sensitive areas that it is negatively affecting (see paragraph 19(a)), the undertaking shall disclose the number and area (in hectares) of sites owned, leased or managed in or near these protected areas or key biodiversity areas.",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "35",
      },
      {
        id: "q-523",
        question:
          "If the undertaking has identified material impacts with regards to land-use change, or impacts on the extent and condition of ecosystems, it may also disclose their land-use based on a Life Cycle Assessment. ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "36",
        voluntary: true,
      },
      {
        id: "q-524",
        question:
          "If the undertaking has concluded that it directly contributes to the impact drivers of land-use change, freshwater-use change and/or sea-use change, it shall report relevant metrics. The undertaking may disclose metrics that measure.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              For datapoints specified in paragraphs 38 to 41, the undertaking shall consider its
              own operations.
            </p>
          </Typography>
        ),
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "38",
      },
      {
        id: "q-525",
        question:
          "The conversion over time (e.g. 1 or 5 years) of land cover (e.g. deforestation or mining); ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "38 a",
        voluntary: true,
      },
      {
        id: "q-526",
        question:
          "Changes over time (e.g. 1 or 5 years) in the management of the ecosystem (e.g., through the intensification of agricultural management, or the application of better management practices or forestry harvesting); ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "38 b",
        voluntary: true,
      },
      {
        id: "q-527",
        question:
          "Changes in the spatial configuration of the landscape (e.g. fragmentation of habitats,changes in ecosystem connectivity); ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "38 c",
        voluntary: true,
      },
      {
        id: "q-528",
        question:
          "Changes in ecosystem structural connectivity (e.g. habitat permeability based on physical features and arrangements of habitat patches); ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "38 d",
        voluntary: true,
      },
      {
        id: "q-529",
        question:
          "The undertaking may disclose in units of area (e.g., m2 or ha) on land-use using guidance provided by the Eco-Management and Audit Scheme (EMAS)13: (a) total use of land;",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 34 a",
      },
      {
        id: "q-530",
        question:
          "The undertaking may disclose in units of area (e.g., m2 or ha) on land-use using guidance provided by the Eco-Management and Audit Scheme (EMAS)13: (b) total sealed area;",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 34 b",
      },
      {
        id: "q-531",
        question:
          "The undertaking may disclose in units of area (e.g., m2 or ha) on land-use using guidance provided by the Eco-Management and Audit Scheme (EMAS)13: (c) total nature-oriented area on site;",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 34 c",
      },
      {
        id: "q-532",
        question:
          "The undertaking may disclose in units of area (e.g., m2 or ha) on land-use using guidance provided by the Eco-Management and Audit Scheme (EMAS)13:  (d) total nature-oriented area off site.",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 34 d",
      },
      {
        id: "q-533",
        question:
          "The functional connectivity (e.g. how well genes or individuals move through land, freshwater and seascape). ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "39",
        voluntary: true,
      },
      {
        id: "q-534",
        question:
          "With regard to the introduction of invasive alien species, the undertaking may disclose the pathways and number of invasive alien species and the extent of surface covered by invasive alien species. ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "AR 32",
        voluntary: true,
      },
      {
        id: "q-535",
        question:
          "If the undertaking identified material impacts related to the state of species, the undertaking may report metrics it considers relevant. (a) Refer to relevant disclosure requirements in ESRS E1, ESRS E2, ESRS E3, and ESRS E5;",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "40 a",
        voluntary: true,
      },

      {
        id: "q-537",
        question:
          "Consider population size, range within specific ecosystems as well as extinction risk. These aspects provide insight on the health of a single species’ population and its relative resilience to human induced and naturally occurring change; ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "40 b",
        voluntary: true,
      },
      {
        id: "q-538",
        question:
          "Disclose metrics that measure changes in the number of individuals of a species within a specific area; ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "40 c",
        voluntary: true,
      },
      {
        id: "q-539",
        question: "Disclose metrics on species at extinction risk that measure.",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "40 d",
        voluntary: true,
      },
      {
        id: "q-540",
        question:
          "The threat status of species and how activities/pressures may affect the threat status;",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "40 d (i)",
        voluntary: true,
      },
      {
        id: "q-541",
        question:
          "changes in the relevant habitat for a threatened species as a proxy for the undertaking’s impact on the local population’s extinction risk. ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "40 d (ii)",
        voluntary: true,
      },
      {
        id: "q-542",
        question:
          "with regard to ecosystems extent, metrics that measure area coverage of a particular ecosystem without necessarily considering the quality of the area being assessed, such as habitat cover. For example, forest cover is a measure of the extent of a particular ecosystem type, without factoring in the condition of the ecosystem (e.g., provides the area without describing the species diversity within the forest)",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "41 a ",
        voluntary: true,
      },
      {
        id: "q-543",
        question:
          "Metrics that measure the quality of ecosystems relative to a pre-determined reference state; ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "41 b (i)",
        voluntary: true,
      },
      {
        id: "q-544",
        question:
          "Metrics that measure multiple species within an ecosystem rather than the number of individuals within a single species within an ecosystem (for example: scientifically established species richness and abundance indicators that measure the development of (native) species composition within an ecosystem against the reference state at the beginning of the first reporting period as well as the targeted state outlined in the Kunming-Montreal Global Biodiversity Framework, or an aggregation of species’ conservation status if relevant); ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "41 b (ii)",
        voluntary: true,
      },
      {
        id: "q-545",
        question:
          "metrics that reflect structural components of condition such as habitat connectivity (i.e., how linked habitats are to each other).",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS E4 for the first 2 years of preparation of their sustainability statement.",
        module: "Environment",
        boldPart: "41 b (iii)",
        voluntary: true,
      },
      // Add more questions as needed
    ],
    "E4-6 – Anticipated financial effects from biodiversity and ecosystem-related risks and opportunities":
      [
        {
          id: "q-546",
          question:
            "a quantification of the anticipated financial effects in monetary terms before considering biodiversity and ecosystems-related actions or where not possible without undue cost or effort, qualitative information. For financial effects arising from material opportunities, a quantification is not required if it would result in disclosure that does not meet the qualitative characteristics of information (see ESRS 1 Appendix B Qualitative characteristics of information). The quantification of the anticipated financial effects in monetary terms may be a single amount or a range; ",

          tooltip: (
            <Typography>
              <p className="question-tip">
                AR 40. The quantification of the anticipated financial effects in monetary terms
                under paragraph 45(a) may be a single amount or a range.
              </p>
            </Typography>
          ),
          phasedIn:
            "The undertaking may omit the information prescribed by ESRS E4-6 for the first year of preparation of its sustainability statement. The undertaking may comply with ESRS E46 by reporting only qualitative disclosures for the first 3 years of preparation of its sustainability statement.",
          module: "Environment",
          boldPart: "45 a",
        },
        {
          id: "q-547",
          question:
            "a description of the effects considered, the impacts and dependencies to which they relate and the time horizons in which they are likely to materialise;",
          tooltip: "",
          phasedIn:
            "The undertaking may omit the information prescribed by ESRS E4-6 for the first year of preparation of its sustainability statement. The undertaking may comply with ESRS E46 by reporting only qualitative disclosures for the first 3 years of preparation of its sustainability statement.",
          module: "Environment",
          boldPart: "45 b",
        },
        {
          id: "q-548",
          question:
            "the critical assumptions used to quantify the anticipated financial effects as well as the sources and the level of uncertainty of those assumptions. ",
          tooltip: "",
          phasedIn:
            "The undertaking may omit the information prescribed by ESRS E4-6 for the first year of preparation of its sustainability statement. The undertaking may comply with ESRS E46 by reporting only qualitative disclosures for the first 3 years of preparation of its sustainability statement.",
          module: "Environment",
          boldPart: "45 c",
        },
        {
          id: "q-549",
          question:
            "The undertaking may include an assessment of its related products and services at risk over the short-, medium- and long-term, explaining how these are defined, how financial amounts are estimated, and which critical assumptions are made. ",
          tooltip: "",
          phasedIn:
            "The undertaking may omit the information prescribed by ESRS E4-6 for the first year of preparation of its sustainability statement. The undertaking may comply with ESRS E46 by reporting only qualitative disclosures for the first 3 years of preparation of its sustainability statement.",
          voluntary: true,
          module: "Environment",
          boldPart: "AR 39",
        },

        // Add more questions as needed
      ],
    /**********************************************
     * Tab: Environment
     * Topic: "ESRS E5 RESOUCE USE AND CIRCULAR ECONOMY"
     **********************************************/
    "ESRS 2 IRO-1 – Description of the processes to identify and assess material resource use and circular economy-related impacts, risks and opportunities":
      [
        {
          id: "q-550",
          question:
            "The undertaking shall describe the process to identify material impacts, risks and opportunities related to resource use and circular economy, in particular regarding resource inflows, resource outflows and waste, and shall provide information on: whether the undertaking has screened its assets and activities in order to identify its actual and potential impacts, risks and opportunities in its own operations and its upstream and downstream value chain, and if so, the methodologies, assumptions and tools used in the screening;",
          tooltip: (
            <Typography>
              <p className="question-tip">
                When conducting a materiality assessment on environmental subtopics, the undertaking
                shall assess the materiality of resource use and circular economy in its own
                operations and its upstream and downstream value chain, and may consider the four
                phases below, also known as the LEAP approach:
              </p>
              <ol className="question-tip" type="a">
                <li>
                  Phase 1: locate where in the undertaking’s own operations and along its upstream
                  and downstream value chain the interface with nature takes place;
                </li>
                <li>Phase 2: evaluate the dependencies and impacts;</li>
                <li>Phase 3: assess the material risks and opportunities;</li>
                <li>Phase 4: prepare and report the results of the materiality assessment.</li>
              </ol>
              <p className="question-tip">
                With regard to ESRS E5, phases 1 and 2 rely mainly on the materiality assessments
                conducted under ESRS E1 (including energy consumption), ESRS E2 (pollution), ESRS E3
                (marine resources, water consumption) and ESRS E4 (biodiversity, ecosystems, raw
                materials). Indeed, circular economy eventually aims at reducing the environmental
                impact of the use of products, materials and other resources, minimizing waste and
                the release of hazardous substances and hence at reducing impacts on nature. This
                application requirement focuses mainly on the third phase of this LEAP approach,
                while the fourth phase addresses the outcome of the process.
              </p>
              <p className="question-tip">
                The process to assess the materiality of impacts, dependencies, risks and
                opportunities shall consider the provisions in ESRS 2 IRO-1 Description of the
                processes to identify and assess material impacts, risks and opportunities and IRO-2
                Disclosure Requirements in ESRS covered by the undertaking’s sustainability
                statement.
              </p>
              <p className="question-tip">
                The sub-topics related to resource use and circular economy covered by the
                materiality assessment include:
              </p>
              <ol className="question-tip" type="a">
                <li>
                  resource inflows including the circularity of material resource inflows,
                  considering resource use optimisation, intensity of materials and products and
                  renewable and non-renewable resources;
                </li>
                <li>resource outflows related to products and services; and</li>
                <li>waste, including hazardous waste and non-hazardous waste management.</li>
              </ol>
              <p className="question-tip">
                In Phase 3, to assess its material risks and opportunities based on the results of
                Phases 1 and 2, the undertaking may:
              </p>
              <ol className="question-tip" type="a">
                <li>
                  identify transition risks and opportunities in its own operations and its upstream
                  and downstream value chain, including the risk of staying in a business-as-usual
                  scenario:
                  <ol className="question-tip" type="i">
                    <li>
                      policy and legal, e.g., bans on the extraction and use of non-renewable
                      resources, regulations on waste treatment;
                    </li>
                    <li>
                      technology, e.g., introduction on the market of new technologies to replace
                      existing use of products and materials;
                    </li>
                    <li> market, e.g., shifting supply, demand and financing; </li>
                    <li>reputation, e.g. changing societal, customer or community perceptions.</li>
                  </ol>
                </li>
                <li>
                  identify physical risks including depletion of stock and use of virgin and
                  non-virgin renewable resources, and of non-renewable resources;
                </li>
                <li>
                  identify opportunities categorised by:
                  <ol className="question-tip" type="i">
                    <li>
                      resource efficiency: e.g., transition to more efficient services and processes
                      requiring less resources, eco-design for longevity, repair, reuse, recycle,
                      by- products, take-back systems, decoupling activity from extraction of
                      materials, intensifying circular material use, creation of a system that
                      allows for dematerialization (e.g., digitisation, improving utilisation rates,
                      weight reduction); practices to ensure products and materials are collected,
                      sorted, and reused, repaired, refurbished, remanufactured;
                    </li>
                    <li>
                      markets: e.g., demand for less resource-intense products and services, and new
                      consumption models such as product-as- a-service, pay-per-use, sharing,
                      leasing;
                    </li>
                    <li>financing: e.g., access to green funds, bonds or loans;</li>
                    <li>
                      resilience: e.g., diversification of resources and business activities (e.g.,
                      start a new business unit to recycle new materials), investing in green
                      infrastructures, adopting recycling and circularity mechanisms that reduce the
                      dependencies, capability of the undertaking to safeguard future stocks and
                      flows of resources; and
                    </li>
                    <li>reputation.</li>
                  </ol>
                </li>
              </ol>
              <p className="question-tip">
                The undertaking may consider the following methodologies to assess its impacts,
                risks and opportunities in its own operations and along its upstream and downstream
                value chain:
              </p>
              <ol className="question-tip" type="a">
                <li>
                  Commission Recommendation 2021/2279 on the use of the Environmental Footprint
                  methods to measure and communicate the life cycle environmental performance of
                  products and organizations (Annex I – Product Environmental Footprint; Annex III –
                  Organisation Environmental Footprint); and
                </li>
                <li>Material Flow Analysis (MFA) from the European Environment Agency.</li>
              </ol>
            </Typography>
          ),

          module: "Environment",
          boldPart: "11 a",
        },
        {
          id: "q-551",
          question:
            "Whether and how the undertaking has conducted consultations, in particular, with affected communities.",
          tooltip: "",
          module: "Environment",
          boldPart: "11 b",
        },
        {
          id: "q-552",
          question:
            "When providing information on the outcome of the materiality assessment, the undertaking shall consider: (a) a list of business units associated to resource use and circular economy material impacts, risks and opportunities in the context of the products and services of the undertaking and the waste it generates;",
          tooltip: "",
          module: "Environment",
          boldPart: "AR 7 a",
        },
        {
          id: "q-553",
          question: "A list and prioritisation of the material resources used by the undertaking;",
          tooltip: "",
          module: "Environment",
          boldPart: "AR 7 b",
        },
        {
          id: "q-554",
          question: "The material impacts and risks of staying in business as usual;",
          tooltip: "",
          module: "Environment",
          boldPart: "AR 7 c",
        },
        {
          id: "q-555",
          question: "The material opportunities related to a circular economy;",
          tooltip: "",
          module: "Environment",
          boldPart: "AR 7 d",
        },
        {
          id: "q-556",
          question: "The material impacts and risks of a transition to a circular economy; and",
          tooltip: "",
          module: "Environment",
          boldPart: "AR 7 e",
        },
        {
          id: "q-557",
          question:
            "The stages of the value chain where resource use, risks and negative impacts are concentrated.",
          tooltip: "",
          module: "Environment",
          boldPart: "AR 7 f",
        },
        // Add more questions as needed
      ],
    "E5-1 – Policies related to resource use and circular economy": [
      {
        id: "q-558",
        question:
          "The disclosure required by paragraph 12 shall contain the information on the policies the undertaking has in place to manage its material impacts, risks and opportunities related to resource use and circular economy in accordance with ESRS 2 MDR-P Policies adopted to manage material sustainability matters.",
        tooltip: "",
        module: "Environment",
        boldPart: "14",
      },
      {
        id: "q-559",
        question:
          "In the summary, the undertaking shall indicate whether and how its policies address the following matters where material: (a) transitioning away from use of virgin resources, including relative increases in use of secondary (recycled) resources; (b) sustainable sourcing and use of renewable resources.",
        tooltip: "",
        module: "Environment",
        boldPart: "15",
      },

      {
        id: "q-561",
        question:
          "When providing information on its policies, the undertaking shall consider whether and how its policies address: the waste hierarchy: (a) prevention; (b) preparing for re-use; (c) recycling; (d) other recovery, e.g., energy recovery; and (e)  In this context, the waste treatment cannot be considered as a recovery;",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 9 a",
      },
      {
        id: "q-562",
        question:
          "the prioritisation of the avoidance or minimisation waste (Re-use, Repair, Refurbish, Remanufacture and Repurpose) over waste treatment (Recycling). The concepts of eco-design4, waste as a resource or post- consumer waste (at the end of a consumer- product lifecycle), should also be taken into consideration.",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 9 b",
      },
      {
        id: "q-563",
        question:
          "The undertaking shall disclose information about policies adopted to manage material sustainability matters. The disclosure shall include the following information: (a) a description of the key contents of the policy, including its general objectives and which material impacts, risks or opportunities the policy relates to and the process for monitoring; ",
        tooltip: "",
        condition: "yes",
        module: "Environment",
        boldPart: "65 a",
      },
      {
        id: "q-564",
        question:
          "a description of the scope of the policy, or of its exclusions, in terms of activities, upstream and/or downstream value chain, geographies and if relevant, affected stakeholder groups;",
        tooltip: "",
        condition: "yes",
        module: "Environment",
        boldPart: "65 b",
      },
      {
        id: "q-565",
        question:
          "the most senior level in the undertaking’s organisation that is accountable for the implementation of the policy;",
        tooltip: "",
        condition: "yes",
        module: "Environment",
        boldPart: "65 c",
      },
      {
        id: "q-566",
        question:
          "a reference, if relevant, to the third-party standards or initiatives the undertaking commits to respect through the implementation of the policy;",
        tooltip: "",
        condition: "yes",
        module: "Environment",
        boldPart: "65 d",
      },
      {
        id: "q-567",
        question:
          "if relevant, a description of the consideration given to the interests of key stakeholders in setting the policy; ",
        tooltip: "",
        condition: "yes",
        module: "Environment",
        boldPart: "65 e",
      },
      {
        id: "q-568",
        question:
          "if relevant, whether and how the undertaking makes the policy available to potentially affected stakeholders, and stakeholders who need to help implement it. ",
        tooltip: "",
        condition: "yes",
        module: "Environment",
        boldPart: "65 f",
      },
      {
        id: "q-569",
        question:
          "If the undertaking cannot disclose the information on policies and actions required under relevant ESRS, because it has not adopted policies and/or actions with reference to the specific sustainability matter concerned, it shall disclose this to be the case, and provide reasons for not having adopted policies and/or actions. ",
        tooltip: "",
        condition: "no",
        module: "Environment",
        boldPart: "62",
      },
      {
        id: "q-570",
        question: "The undertaking may disclose a timeframe in which it aims to adopt them. ",
        tooltip: "",
        condition: "no",
        module: "Environment",
        boldPart: "62",
      },

      // Add more questions as needed
    ],
    "E5-2 – Actions and resources related to resource use and circular economy": [
      {
        id: "q-571",
        question:
          "The description of the resource use and circular economy-related actions and resources allocated shall follow the principles defined in ESRS 2 MDR-A Actions and resources inrelation to material sustainability matters.",
        tooltip: "",
        module: "Environment",
        boldPart: "19",
      },
      {
        id: "q-572",
        question:
          "In addition to ESRS 2 MDR-A, the undertaking may specify whether and how an action and resources cover: (a) higher levels of resource efficiency in use of technical and bio materials and water, particularly in relation to critical raw materials and rare earths as listed in the Raw Materials Information System; (b) higher rates of use of secondary raw materials (recyclates);",
        tooltip: "",
        module: "Environment",
        boldPart: "20 a, 20 b",
        voluntary: true,
      },

      {
        id: "q-574",
        question:
          "application of circular design, leading to increased product durability and optimisation of use, and higher rates of: Reuse, Repair, Refurbishing, Remanufacture, Repurposing and Recycling.",
        tooltip: "",
        module: "Environment",
        boldPart: "20 c",
        voluntary: true,
      },
      {
        id: "q-575",
        question:
          "application of circular business practices such as (i) value retention actions (maintenance, repair, refurbishing, remanufacturing, component harvesting, upgrading and reverse logistics, closed loop systems, second-hand retailing), (ii) value maximisation actions (product-service systems, collaborative and sharing economy business models), (iii) end-of-life actions (recycling, upcycling, extended producer responsibility), and (iv) systems efficiency actions (industrial symbiosis);",
        tooltip: "",
        module: "Environment",
        boldPart: "20 d",
        voluntary: true,
      },
      {
        id: "q-576",
        question:
          "actions taken to prevent waste generation in the undertaking’s upstream and downstream value chain; and",
        tooltip: (
          <Typography>
            <p className="question-tip">
              The actions may cover the undertaking’s own operations and/or its upstream and
              downstream value chain.
            </p>
          </Typography>
        ),

        module: "Environment",
        boldPart: "20 e",
        voluntary: true,
      },
      {
        id: "q-577",
        question: "optimistation of waste management in line with the waste hierarchy.",
        tooltip: "",
        module: "Environment",
        boldPart: "20 f",
        voluntary: true,
      },
      {
        id: "q-578",
        question:
          "When providing information under paragraph 17 and considering that resources are shared and that circular economy strategies may require collective actions, the undertaking may specify the actions taken to engage with its upstream and downstream value chain and/or its local network on the development of collaborations or initiatives increasing the circularity of products and materials.",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 11",
      },
      {
        id: "q-579",
        question:
          "In particular, the undertaking may specify the following: how it contributes to circular economy, including for instance smart waste collection systems;",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 12 a",
      },
      {
        id: "q-580",
        question:
          "the other stakeholders involved in the collective actions: competitors, suppliers, retailers, customers, other business partners, local communities and authorities, government agencies;",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 12 b",
      },
      {
        id: "q-581",
        question:
          "a description of the organisation of the collaboration or initiative, including the specific contribution of the undertaking and the roles of the different stakeholders in the project.",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 12 c",
      },
      {
        id: "q-582",
        question:
          "Where the implementation of a policy requires actions, or a comprehensive action plan, to achieve its objectives, as well as when actions are implemented without a specific policy, the undertaking shall disclose the following information: (a) the list of key actions taken in the reporting year and planned for the future, their expected outcomes and, where relevant, how their implementation contributes to the achievement of policy objectives and targets;",
        tooltip:
          "AR 22. Key actions in the context of this Minimum Disclosure Requirement are those actions that materially contribute to achieving the undertaking’s objectives in addressing material impacts, risks and opportunities. oor reasons of understandability, key actions may be aggregated where appropriate. ",
        condition: "yes",
        module: "Environment",
        boldPart: "68 a",
      },
      {
        id: "q-583",
        question:
          "the scope of the key actions (i.e., coverage in terms of activities, upstream and/or downstream value chain, geographies and, where applicable, affected stakeholder groups); ",
        tooltip: "",
        condition: "yes",
        module: "Environment",
        boldPart: "68 b",
      },
      {
        id: "q-584",
        question:
          "the time horizons under which the undertaking intends to complete each key action;",
        tooltip: "",
        condition: "yes",
        module: "Environment",
        boldPart: "68 c",
      },
      {
        id: "q-585",
        question:
          "if applicable, key actions taken (along with results) to provide for and cooperate in or support the provision of remedy for those harmed by actual material impacts;",
        tooltip: "",
        condition: "yes",
        module: "Environment",
        boldPart: "68 d",
      },
      {
        id: "q-586",
        question:
          "if applicable, quantitative and qualitative information regarding the progress of actions or action plans disclosed in prior periods. ",
        tooltip: "",
        condition: "yes",
        module: "Environment",
        boldPart: "68 e",
      },
      {
        id: "q-587",
        question:
          "Where the implementation of an action plan requires significant operational expenditures (Opex) and/or capital expenditures (Capex) the undertaking shall: (a) describe the type of current and future financial and other resources allocated to the action plan, including if applicable, the relevant terms of sustainable finance instruments, such as green bonds, social bonds and green loans, the environmental or social objectives, and whether the ability to implement the actions or action plan depends on specific preconditions, e.g., granting of financial support or public policy and market developments; ",
        tooltip:
          "AR 23. Information on resource allocation may be presented in the form of a table and broken down between capital expenditure and operating expenditure, and across the relevant time horizons, and between resources applied in the current reporting year and the planned allocation of resources over specific time horizons.",
        condition: "yes",
        module: "Environment",
        boldPart: "69 a",
      },
      {
        id: "q-588",
        question:
          "provide the amount of current financial resources and explain how they relate to the most relevant amounts presented in the financial statements;",
        tooltip: "",
        condition: "yes",
        module: "Environment",
        boldPart: "69 b",
      },
      {
        id: "q-589",
        question: "provide the amount of future financial resources. ",
        tooltip: "",
        condition: "yes",
        module: "Environment",
        boldPart: "69 c",
      },
      {
        id: "q-590",
        question:
          "If the undertaking cannot disclose the information on policies and actions required under relevant ESRS, because it has not adopted policies and/or actions with reference to the specific sustainability matter concerned, it shall disclose this to be the case, and provide reasons for not having adopted policies and/or actions. ",
        tooltip: "",
        condition: "no",
        module: "Environment",
        boldPart: "62",
      },
      {
        id: "q-591",
        question: "The undertaking may disclose a timeframe in which it aims to adopt them. ",
        tooltip: "",
        condition: "no",
        module: "Environment",
        boldPart: "62",
      },
      // Add more questions as needed
    ],
    "E5-3 – Targets related to resource use and circular economy": [
      {
        id: "q-592",
        question:
          "The disclosure required by paragraph 21 shall indicate whether and how the undertaking’s targets relate to resource inflows and resource outflows, including waste and products and materials, and, more specifically to: (a) the increase of circular product design (including for instance design for durability, dismantling, reparability, recyclability etc);",
        tooltip:
          "When providing information targets according to paragraph 24, the undertaking shall consider the production phase, the use phase, and the end of functional life of products and materials.",
        module: "Environment",
        boldPart: "24a",
      },
      {
        id: "q-595",
        question:
          "(b) the increase of circular material use rate; (c) the minimisation of primary raw material (d) sustainable sourcing and use (in line with the cascading principle) of renewable resources (e) the waste management, including preparation for proper treatment; (f) other matters related to resource use or circular economy.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              When providing information on targets related to virgin renewable raw material under
              paragraph 24(c), the undertaking shall consider how those targets may impact
              biodiversity loss, also in light of ESRS E4.
            </p>
            <p className="question-tip">
              The undertaking may disclose other targets under paragraph 24(f) including in relation
              to sustainable sourcing. If so, the undertaking shall explain the definition of
              sustainable sourcing it has adopted and how it relates to the objective set out in
              paragraph 22.
            </p>
          </Typography>
        ),

        module: "Environment",
        boldPart: "24 b-f",
      },

      {
        id: "q-599",
        question:
          "The undertaking shall specify to which layer of the waste hierarchy the target relates.",
        tooltip: "",
        module: "Environment",
        boldPart: "25",
      },
      {
        id: "q-600",
        question:
          "In addition to ESRS 2 MDR-T, the undertaking may specify whether ecological thresholds and entity-specific allocations were taken into consideration when setting targets. If so, the undertaking may specify: (a) the ecological thresholds identified, and the methodology used to identify such thresholds; (b) whether or not the thresholds are entity-specific and if so, how they were determined; and (c) how responsibility for respecting identified ecological thresholds is allocated in the undertaking.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              If the undertaking refers to ecological thresholds to set targets, it may refer to the
              guidance provided by the Science-Based Targets Initiative for Nature (SBTN) in its
              interim guidance (Initial Guidance for Business, September 2020) or any other guidance
              with a scientifically acknowledged methodology that allows to set science-based
              targets by identifying ecological thresholds and, if applicable, organisation-specific
              allocations. Ecological thresholds can be local, national and/or global.
            </p>
          </Typography>
        ),

        module: "Environment",
        boldPart: "26",
        voluntary: true,
      },

      {
        id: "q-603",
        question:
          "The undertaking shall specify as part of the contextual information, whether the targets it has set and presented are mandatory (required by legislation) or voluntary.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              The undertaking may specify whether the target addresses shortcomings related to the
              Substantial Contribution criteria for Circular Economy as defined in the delegated
              acts adopted pursuant to Article 13(2) of Regulation (EU) 2020/852. Where the Do No
              Significant Harm (DNSH) criteria for Circular Economy as defined in delegated acts
              adopted pursuant to Article 10(3), Article 11(3), Article 12(2), Article 14(2), and
              Article 15(2) of Regulation (EU) 2020/852 are not met, the undertaking may specify
              whether the target addresses shortcomings related those DNSH criteria.
            </p>
          </Typography>
        ),

        module: "Environment",
        boldPart: "27",
      },
      {
        id: "q-604",
        question:
          "The undertaking shall disclose the measurable, outcome-oriented and time-bound targets on material sustainability matters it has set to assess progress. oor each target, the disclosure shall include the following information: (a) a description of the relationship of the target to the policy objectives;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy. "
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 a",
      },
      {
        id: "q-605",
        question:
          "the defined target level to be achieved, including, where applicable, whether the target is absolute or relative and in which unit it is measured;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy. "
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 b",
      },
      {
        id: "q-606",
        question:
          "the scope of the target, including the undertaking’s activities and/or its upstream and/or downstream value chain where applicable and geographical boundaries; ",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy. "
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 c",
      },
      {
        id: "q-607",
        question: "the baseline value and base year from which progress is measured;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy. "
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 d",
      },
      {
        id: "q-608",
        question:
          "the period to which the target applies and if applicable, any milestones or interim targets;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy. "
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 e",
      },
      {
        id: "q-609",
        question:
          "the methodologies and significant assumptions used to define targets, including where applicable, the selected scenario, data sources, alignment with national, EU or international policy goals and how the targets consider the wider context of sustainable development and/or local situation in which impacts take place; ",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy. "
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 f",
      },
      {
        id: "q-610",
        question:
          "whether the undertaking’s targets related to environmental matters are based on conclusive scientific evidence;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy. "
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 g",
      },
      {
        id: "q-611",
        question:
          "whether and how stakeholders have been involved in target setting for each material sustainability matter; ",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy. "
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 h",
      },
      {
        id: "q-612",
        question:
          "any changes in targets and corresponding metrics or underlying measurement methodologies, significant assumptions, limitations, sources and processes to collect data adopted within the defined 17 time horizon. This includes an explanation of the rationale for those changes and their effect on comparability (see Disclosure Requirement BP-2 Disclosures in relation to specific circumstances of this Standard);",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy. "
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 i",
      },
      {
        id: "q-613",
        question:
          "the performance against its disclosed targets, including information on how the target is monitored and reviewed and the metrics used, whether the progress is in line with what had been initially planned, and an analysis of trends or significant changes in the performance of the undertaking towards achieving the target. ",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 24. When disclosing targets related to the prevention or mitigation of
              environmental impacts, the undertaking shall prioritise targets related to the
              reduction of the impacts in absolute terms rather than in relative terms. When targets
              address the prevention or mitigation of social impacts, they may be specified in terms
              of the effects on human rights, welfare or positive outcomes for affected
              stakeholders. <br />
              AR 25. The information on progress made towards achieving the targets may be presented
              in a comprehensive table, including information on the baseline and target value,
              milestones, and achieved performance over the prior periods. <br />
              AR 26. Where the undertaking describes progress in achieving the objectives of a
              policy in the absence of a measurable target, it may specify a baseline against which
              the progress is considered. oor example, the undertaking may assess an increase of
              wages by a certain percentage for those below a fair wage; or may assess the quality
              of its relationships with local communities by reference to the proportion of issues
              raised by communities that were resolved to their satisfaction. The baseline and the
              assessment of the progress shall be related to the impacts, risks and opportunities
              which underpin the materiality of the matter addressed by the policy. "
            </p>
          </Typography>
        ),
        module: "Environment",
        condition: "yes",
        boldPart: "80 j",
      },
      {
        id: "q-614",
        question:
          ". If the undertaking has not set any measurable outcome-oriented targets: (a) it may disclose whether such targets will be set and the timeframe for setting them, or the reasons why the undertaking does not plan to set such targets;",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        condition: "no",
        boldPart: "81 a",
      },
      {
        id: "q-615",
        question:
          "it shall disclose whether it nevertheless tracks the effectiveness of its policies and actions in relation to the material sustainability-related impact, risk and opportunity,",
        tooltip: "",
        module: "Environment",
        condition: "no",
        boldPart: "81 b",
      },

      {
        id: "q-616",
        question:
          "it shall disclose whether it nevertheless tracks the effectiveness of its policies and actions in relation to the material sustainability-related impact, risk and opportunity, and if so: i. any processes through which it does so",
        tooltip: "",
        module: "Environment",
        condition: "no",
        boldPart: "81 b i",
      },
      {
        id: "q-617",
        question:
          "it shall disclose whether it nevertheless tracks the effectiveness of its policies and actions in relation to the material sustainability-related impact, risk and opportunity, and if so: the defined level of ambition to be achieved and any qualitative or quantitative indicators it uses to evaluate progress, including the base period from which progress is measured. ",
        tooltip: "",
        module: "Environment",
        condition: "no",
        boldPart: "81 b ii, 80 d",
      },
      // Add more questions as needed
    ],
    "E5-4 – Resource inflows of Disclosure Requirement": [
      {
        id: "q-618",
        question:
          "The disclosure required by paragraph 28 shall include a description of its resource inflows where material: products (including packaging) and materials (specifying critical raw materials and rare earths), water and property, plant and equipment used in the undertaking’s own operations and along its upstream value chain.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "Resource inflows may cover the following categories: IT equipment, textiles,
              furniture, buildings, heavy machinery, mid-weight machinery, light machinery, heavy
              transport, midweight transport, light transport and warehousing equipment. With regard
              to materials, resource inflow indicators include raw materials, associated process
              materials, and semimanufactured goods or parts."
            </p>
          </Typography>
        ),
        module: "Environment",
        boldPart: "30",
      },
      {
        id: "q-619",
        question: "Table: Biological",
        tooltip: "",
        module: "Environment",
        boldPart: "31 a",
        fun: "E54B",
        table: (
          <table className="question-table">
            <thead>
              <tr>
                <th rowspan="2" colspan="2">
                  Resource and material inflows
                </th>
                <th colspan="3" style={{ textAlign: "center" }}>
                  Weight of Material
                </th>
              </tr>
              <tr>
                <th>Biological</th>
                <th>Non-bio</th>
                <th>% Biological</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="2">Packaging Material</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-packagingMaterial-bio"
                    value={
                      answers2["e5_4_1-packagingMaterial-bio"] ||
                      inputValues.e5_4_1.packagingMaterial.bio
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-packagingMaterial-nonBio"
                    value={
                      answers2["e5_4_1-packagingMaterial-nonBio"] ||
                      inputValues.e5_4_1.packagingMaterial.nonBio
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-packagingMaterial-percentBio"
                    value={
                      answers2["e5_4_1-packagingMaterial-percentBio"] ||
                      inputValues.e5_4_1.packagingMaterial.percentBio
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">IT equipment</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-itEquipment-bio"
                    value={answers2["e5_4_1-itEquipment-bio"] || inputValues.e5_4_1.itEquipment.bio}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-itEquipment-nonBio"
                    value={
                      answers2["e5_4_1-itEquipment-nonBio"] || inputValues.e5_4_1.itEquipment.nonBio
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-itEquipment-percentBio"
                    value={
                      answers2["e5_4_1-itEquipment-percentBio"] ||
                      inputValues.e5_4_1.itEquipment.percentBio
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">Textiles</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-textiles-bio"
                    value={answers2["e5_4_1-textiles-bio"] || inputValues.e5_4_1.textiles.bio}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-textiles-nonBio"
                    value={answers2["e5_4_1-textiles-nonBio"] || inputValues.e5_4_1.textiles.nonBio}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-textiles-percentBio"
                    value={
                      answers2["e5_4_1-textiles-percentBio"] ||
                      inputValues.e5_4_1.textiles.percentBio
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">Furniture</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-furniture-bio"
                    value={answers2["e5_4_1-furniture-bio"] || inputValues.e5_4_1.furniture.bio}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-furniture-nonBio"
                    value={
                      answers2["e5_4_1-furniture-nonBio"] || inputValues.e5_4_1.furniture.nonBio
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-furniture-percentBio"
                    value={
                      answers2["e5_4_1-furniture-percentBio"] ||
                      inputValues.e5_4_1.furniture.percentBio
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">Buildings</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-buildings-bio"
                    value={answers2["e5_4_1-buildings-bio"] || inputValues.e5_4_1.buildings.bio}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-buildings-nonBio"
                    value={
                      answers2["e5_4_1-buildings-nonBio"] || inputValues.e5_4_1.buildings.nonBio
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-buildings-percentBio"
                    value={
                      answers2["e5_4_1-buildings-percentBio"] ||
                      inputValues.e5_4_1.buildings.percentBio
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td rowSpan="3">Machinery</td>
                <td>Heavy machinery</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-heavyMachinery-bio"
                    value={
                      answers2["e5_4_1-heavyMachinery-bio"] || inputValues.e5_4_1.heavyMachinery.bio
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-heavyMachinery-nonBio"
                    value={
                      answers2["e5_4_1-heavyMachinery-nonBio"] ||
                      inputValues.e5_4_1.heavyMachinery.nonBio
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-heavyMachinery-percentBio"
                    value={
                      answers2["e5_4_1-heavyMachinery-percentBio"] ||
                      inputValues.e5_4_1.heavyMachinery.percentBio
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Mid-weight machinery</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-midWeightMachinery-bio"
                    value={
                      answers2["e5_4_1-midWeightMachinery-bio"] ||
                      inputValues.e5_4_1.midWeightMachinery.bio
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-midWeightMachinery-nonBio"
                    value={
                      answers2["e5_4_1-midWeightMachinery-nonBio"] ||
                      inputValues.e5_4_1.midWeightMachinery.nonBio
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-midWeightMachinery-percentBio"
                    value={
                      answers2["e5_4_1-midWeightMachinery-percentBio"] ||
                      inputValues.e5_4_1.midWeightMachinery.percentBio
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Light machinery</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-lightMachinery-bio"
                    value={
                      answers2["e5_4_1-lightMachinery-bio"] || inputValues.e5_4_1.lightMachinery.bio
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-lightMachinery-nonBio"
                    value={
                      answers2["e5_4_1-lightMachinery-nonBio"] ||
                      inputValues.e5_4_1.lightMachinery.nonBio
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-lightMachinery-percentBio"
                    value={
                      answers2["e5_4_1-lightMachinery-percentBio"] ||
                      inputValues.e5_4_1.lightMachinery.percentBio
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td rowSpan="3">Transport</td>
                <td>Heavy Transport</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-heavyTransport-bio"
                    value={
                      answers2["e5_4_1-heavyTransport-bio"] || inputValues.e5_4_1.heavyTransport.bio
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-heavyTransport-nonBio"
                    value={
                      answers2["e5_4_1-heavyTransport-nonBio"] ||
                      inputValues.e5_4_1.heavyTransport.nonBio
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-heavyTransport-percentBio"
                    value={
                      answers2["e5_4_1-heavyTransport-percentBio"] ||
                      inputValues.e5_4_1.heavyTransport.percentBio
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Mid-weight Transport</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-midWeightTransport-bio"
                    value={
                      answers2["e5_4_1-midWeightTransport-bio"] ||
                      inputValues.e5_4_1.midWeightTransport.bio
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-midWeightTransport-nonBio"
                    value={
                      answers2["e5_4_1-midWeightTransport-nonBio"] ||
                      inputValues.e5_4_1.midWeightTransport.nonBio
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-midWeightTransport-percentBio"
                    value={
                      answers2["e5_4_1-midWeightTransport-percentBio"] ||
                      inputValues.e5_4_1.midWeightTransport.percentBio
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Light Transport</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-lightTransport-bio"
                    value={
                      answers2["e5_4_1-lightTransport-bio"] || inputValues.e5_4_1.lightTransport.bio
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-lightTransport-nonBio"
                    value={
                      answers2["e5_4_1-lightTransport-nonBio"] ||
                      inputValues.e5_4_1.lightTransport.nonBio
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-lightTransport-percentBio"
                    value={
                      answers2["e5_4_1-lightTransport-percentBio"] ||
                      inputValues.e5_4_1.lightTransport.percentBio
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">Warehousing Equipment</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-equipment-bio"
                    value={answers2["e5_4_1-equipment-bio"] || inputValues.e5_4_1.equipment.bio}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-equipment-nonBio"
                    value={
                      answers2["e5_4_1-equipment-nonBio"] || inputValues.e5_4_1.equipment.nonBio
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-equipment-percentBio"
                    value={
                      answers2["e5_4_1-equipment-percentBio"] ||
                      inputValues.e5_4_1.equipment.percentBio
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td rowSpan="2">Raw Materials</td>
                <td>Critical raw material</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-criticalRawMaterial-bio"
                    value={
                      answers2["e5_4_1-criticalRawMaterial-bio"] ||
                      inputValues.e5_4_1.criticalRawMaterial.bio
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-criticalRawMaterial-nonBio"
                    value={
                      answers2["e5_4_1-criticalRawMaterial-nonBio"] ||
                      inputValues.e5_4_1.criticalRawMaterial.nonBio
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-criticalRawMaterial-percentBio"
                    value={
                      answers2["e5_4_1-criticalRawMaterial-percentBio"] ||
                      inputValues.e5_4_1.criticalRawMaterial.percentBio
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Rare earths</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-rareEarths-bio"
                    value={answers2["e5_4_1-rareEarths-bio"] || inputValues.e5_4_1.rareEarths.bio}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-rareEarths-nonBio"
                    value={
                      answers2["e5_4_1-rareEarths-nonBio"] || inputValues.e5_4_1.rareEarths.nonBio
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-rareEarths-percentBio"
                    value={
                      answers2["e5_4_1-rareEarths-percentBio"] ||
                      inputValues.e5_4_1.rareEarths.percentBio
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">Associated process materials</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-processMaterials-bio"
                    value={
                      answers2["e5_4_1-processMaterials-bio"] ||
                      inputValues.e5_4_1.processMaterials.bio
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-processMaterials-nonBio"
                    value={
                      answers2["e5_4_1-processMaterials-nonBio"] ||
                      inputValues.e5_4_1.processMaterials.nonBio
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-processMaterials-percentBio"
                    value={
                      answers2["e5_4_1-processMaterials-percentBio"] ||
                      inputValues.e5_4_1.processMaterials.percentBio
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">Semimanufactured goods or parts</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-semimanu-bio"
                    value={answers2["e5_4_1-semimanu-bio"] || inputValues.e5_4_1.semimanu.bio}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-semimanu-nonBio"
                    value={answers2["e5_4_1-semimanu-nonBio"] || inputValues.e5_4_1.semimanu.nonBio}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_1-semimanu-percentBio"
                    value={
                      answers2["e5_4_1-semimanu-percentBio"] ||
                      inputValues.e5_4_1.semimanu.percentBio
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        ),
      },
      {
        id: "q-620",
        question: "Table: Sustaianbly Sourced",
        tooltip: (
          <Typography>
            <p className="question-tip">
              The denominator of the percentage indicator required under paragraphs 31(b) and 31(c)
              is the overall total weight of materials used during the reporting period.
            </p>
          </Typography>
        ),
        table: (
          <table className="question-table">
            <thead>
              <tr>
                <th rowspan="2" colspan="2">
                  Resource and material inflows
                </th>
                <th colspan="3" style={{ textAlign: "center" }}>
                  Weight of Material
                </th>
              </tr>
              <tr>
                <th>Sustainably Sourced</th>
                <th>Non-sustainably sourced</th>
                <th>% Sustainably sourced</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="2">Packaging Material</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-packagingMaterial-sus"
                    value={
                      answers2["e5_4_2-packagingMaterial-sus"] ||
                      inputValues.e5_4_2.packagingMaterial.sus
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-packagingMaterial-nonSus"
                    value={
                      answers2["e5_4_2-packagingMaterial-nonSus"] ||
                      inputValues.e5_4_2.packagingMaterial.nonSus
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-packagingMaterial-percentSus"
                    value={
                      answers2["e5_4_2-packagingMaterial-percentSus"] ||
                      inputValues.e5_4_2.packagingMaterial.percentSus
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">IT equipment</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-itEquipment-sus"
                    value={answers2["e5_4_2-itEquipment-sus"] || inputValues.e5_4_2.itEquipment.sus}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-itEquipment-nonSus"
                    value={
                      answers2["e5_4_2-itEquipment-nonSus"] || inputValues.e5_4_2.itEquipment.nonSus
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-itEquipment-percentSus"
                    value={
                      answers2["e5_4_2-itEquipment-percentSus"] ||
                      inputValues.e5_4_2.itEquipment.percentSus
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">Textiles</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-textiles-sus"
                    value={answers2["e5_4_2-textiles-sus"] || inputValues.e5_4_2.textiles.sus}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-textiles-nonSus"
                    value={answers2["e5_4_2-textiles-nonSus"] || inputValues.e5_4_2.textiles.nonSus}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-textiles-percentSus"
                    value={
                      answers2["e5_4_2-textiles-percentSus"] ||
                      inputValues.e5_4_2.textiles.percentSus
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">Furniture</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-furniture-sus"
                    value={answers2["e5_4_2-furniture-sus"] || inputValues.e5_4_2.furniture.sus}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-furniture-nonSus"
                    value={
                      answers2["e5_4_2-furniture-nonSus"] || inputValues.e5_4_2.furniture.nonSus
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-furniture-percentSus"
                    value={
                      answers2["e5_4_2-furniture-percentSus"] ||
                      inputValues.e5_4_2.furniture.percentSus
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">Buildings</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-buildings-sus"
                    value={answers2["e5_4_2-buildings-sus"] || inputValues.e5_4_2.buildings.sus}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-buildings-nonSus"
                    value={
                      answers2["e5_4_2-buildings-nonSus"] || inputValues.e5_4_2.buildings.nonSus
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-buildings-percentSus"
                    value={
                      answers2["e5_4_2-buildings-percentSus"] ||
                      inputValues.e5_4_2.buildings.percentSus
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td rowSpan="3">Machinery</td>
                <td>Heavy machinery</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-heavyMachinery-sus"
                    value={
                      answers2["e5_4_2-heavyMachinery-sus"] || inputValues.e5_4_2.heavyMachinery.sus
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-heavyMachinery-nonSus"
                    value={
                      answers2["e5_4_2-heavyMachinery-nonSus"] ||
                      inputValues.e5_4_2.heavyMachinery.nonSus
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-heavyMachinery-percentSus"
                    value={
                      answers2["e5_4_2-heavyMachinery-percentSus"] ||
                      inputValues.e5_4_2.heavyMachinery.percentSus
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Mid-weight machinery</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-midWeightMachinery-sus"
                    value={
                      answers2["e5_4_2-midWeightMachinery-sus"] ||
                      inputValues.e5_4_2.midWeightMachinery.sus
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-midWeightMachinery-nonSus"
                    value={
                      answers2["e5_4_2-midWeightMachinery-nonSus"] ||
                      inputValues.e5_4_2.midWeightMachinery.nonSus
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-midWeightMachinery-percentSus"
                    value={
                      answers2["e5_4_2-midWeightMachinery-percentSus"] ||
                      inputValues.e5_4_2.midWeightMachinery.percentSus
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Light machinery</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-lightMachinery-sus"
                    value={
                      answers2["e5_4_2-lightMachinery-sus"] || inputValues.e5_4_2.lightMachinery.sus
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-lightMachinery-nonSus"
                    value={
                      answers2["e5_4_2-lightMachinery-nonSus"] ||
                      inputValues.e5_4_2.lightMachinery.nonSus
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-lightMachinery-percentSus"
                    value={
                      answers2["e5_4_2-lightMachinery-percentSus"] ||
                      inputValues.e5_4_2.lightMachinery.percentSus
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td rowSpan="3">Transport</td>
                <td>Heavy Transport</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-heavyTransport-sus"
                    value={
                      answers2["e5_4_2-heavyTransport-sus"] || inputValues.e5_4_2.heavyTransport.sus
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-heavyTransport-nonSus"
                    value={
                      answers2["e5_4_2-heavyTransport-nonSus"] ||
                      inputValues.e5_4_2.heavyTransport.nonSus
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-heavyTransport-percentSus"
                    value={
                      answers2["e5_4_2-heavyTransport-percentSus"] ||
                      inputValues.e5_4_2.heavyTransport.percentSus
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Mid-weight Transport</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-midWeightTransport-sus"
                    value={
                      answers2["e5_4_2-midWeightTransport-sus"] ||
                      inputValues.e5_4_2.midWeightTransport.sus
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-midWeightTransport-nonSus"
                    value={
                      answers2["e5_4_2-midWeightTransport-nonSus"] ||
                      inputValues.e5_4_2.midWeightTransport.nonSus
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-midWeightTransport-percentSus"
                    value={
                      answers2["e5_4_2-midWeightTransport-percentSus"] ||
                      inputValues.e5_4_2.midWeightTransport.percentSus
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Light Transport</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-lightTransport-sus"
                    value={
                      answers2["e5_4_2-lightTransport-sus"] || inputValues.e5_4_2.lightTransport.sus
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-lightTransport-nonSus"
                    value={
                      answers2["e5_4_2-lightTransport-nonSus"] ||
                      inputValues.e5_4_2.lightTransport.nonSus
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-lightTransport-percentSus"
                    value={
                      answers2["e5_4_2-lightTransport-percentSus"] ||
                      inputValues.e5_4_2.lightTransport.percentSus
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">Warehousing Equipment</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-equipment-sus"
                    value={answers2["e5_4_2-equipment-sus"] || inputValues.e5_4_2.equipment.sus}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-equipment-nonSus"
                    value={
                      answers2["e5_4_2-equipment-nonSus"] || inputValues.e5_4_2.equipment.nonSus
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-equipment-percentSus"
                    value={
                      answers2["e5_4_2-equipment-percentSus"] ||
                      inputValues.e5_4_2.equipment.percentSus
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td rowSpan="2">Raw Materials</td>
                <td>Critical raw material</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-criticalRawMaterial-sus"
                    value={
                      answers2["e5_4_2-criticalRawMaterial-sus"] ||
                      inputValues.e5_4_2.criticalRawMaterial.sus
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-criticalRawMaterial-nonSus"
                    value={
                      answers2["e5_4_2-criticalRawMaterial-nonSus"] ||
                      inputValues.e5_4_2.criticalRawMaterial.nonSus
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-criticalRawMaterial-percentSus"
                    value={
                      answers2["e5_4_2-criticalRawMaterial-percentSus"] ||
                      inputValues.e5_4_2.criticalRawMaterial.percentSus
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Rare earths</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-rareEarths-sus"
                    value={answers2["e5_4_2-rareEarths-sus"] || inputValues.e5_4_2.rareEarths.sus}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-rareEarths-nonSus"
                    value={
                      answers2["e5_4_2-rareEarths-nonSus"] || inputValues.e5_4_2.rareEarths.nonSus
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-rareEarths-percentSus"
                    value={
                      answers2["e5_4_2-rareEarths-percentSus"] ||
                      inputValues.e5_4_2.rareEarths.percentSus
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">Associated process materials</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-processMaterials-sus"
                    value={
                      answers2["e5_4_2-processMaterials-sus"] ||
                      inputValues.e5_4_2.processMaterials.sus
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-processMaterials-nonSus"
                    value={
                      answers2["e5_4_2-processMaterials-nonSus"] ||
                      inputValues.e5_4_2.processMaterials.nonSus
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-processMaterials-percentSus"
                    value={
                      answers2["e5_4_2-processMaterials-percentSus"] ||
                      inputValues.e5_4_2.processMaterials.percentSus
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">Semimanufactured goods or parts</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-semimanu-sus"
                    value={answers2["e5_4_2-semimanu-sus"] || inputValues.e5_4_2.semimanu.sus}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-semimanu-nonSus"
                    value={answers2["e5_4_2-semimanu-nonSus"] || inputValues.e5_4_2.semimanu.nonSus}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_2-semimanu-percentSus"
                    value={
                      answers2["e5_4_2-semimanu-percentSus"] ||
                      inputValues.e5_4_2.semimanu.percentSus
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        ),
        module: "Environment",
        boldPart: "31 b",
        fun: "E54S",
      },
      {
        id: "q-621",
        question: "Table: Recycled/reused",
        tooltip: (
          <Typography>
            <p className="question-tip">
              The denominator of the percentage indicator required under paragraphs 31(b) and 31(c)
              is the overall total weight of materials used during the reporting period.
            </p>
          </Typography>
        ),
        table: (
          <table className="question-table">
            <thead>
              <tr>
                <th rowspan="2" colspan="2">
                  Resource and material inflows
                </th>
                <th colspan="3" style={{ textAlign: "center" }}>
                  Weight of Material
                </th>
              </tr>
              <tr>
                <th>Recycled/reused</th>
                <th>Virgin</th>
                <th>% Recycled/ reused</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="2">Packaging Material</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-packagingMaterial-rec"
                    value={
                      answers2["e5_4_3-packagingMaterial-rec"] ||
                      inputValues.e5_4_3.packagingMaterial.rec
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-packagingMaterial-virgin"
                    value={
                      answers2["e5_4_3-packagingMaterial-virgin"] ||
                      inputValues.e5_4_3.packagingMaterial.virgin
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-packagingMaterial-percentRec"
                    value={
                      answers2["e5_4_3-packagingMaterial-percentRec"] ||
                      inputValues.e5_4_3.packagingMaterial.percentRec
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">IT equipment</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-itEquipment-rec"
                    value={answers2["e5_4_3-itEquipment-rec"] || inputValues.e5_4_3.itEquipment.rec}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-itEquipment-virgin"
                    value={
                      answers2["e5_4_3-itEquipment-virgin"] || inputValues.e5_4_3.itEquipment.virgin
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-itEquipment-percentRec"
                    value={
                      answers2["e5_4_3-itEquipment-percentRec"] ||
                      inputValues.e5_4_3.itEquipment.percentRec
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">Textiles</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-textiles-rec"
                    value={answers2["e5_4_3-textiles-rec"] || inputValues.e5_4_3.textiles.rec}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-textiles-virgin"
                    value={answers2["e5_4_3-textiles-virgin"] || inputValues.e5_4_3.textiles.virgin}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-textiles-percentRec"
                    value={
                      answers2["e5_4_3-textiles-percentRec"] ||
                      inputValues.e5_4_3.textiles.percentRec
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">Furniture</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-furniture-rec"
                    value={answers2["e5_4_3-furniture-rec"] || inputValues.e5_4_3.furniture.rec}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-furniture-virgin"
                    value={
                      answers2["e5_4_3-furniture-virgin"] || inputValues.e5_4_3.furniture.virgin
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-furniture-percentRec"
                    value={
                      answers2["e5_4_3-furniture-percentRec"] ||
                      inputValues.e5_4_3.furniture.percentRec
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">Buildings</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-buildings-rec"
                    value={answers2["e5_4_3-buildings-rec"] || inputValues.e5_4_3.buildings.rec}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-buildings-virgin"
                    value={
                      answers2["e5_4_3-buildings-virgin"] || inputValues.e5_4_3.buildings.virgin
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-buildings-percentRec"
                    value={
                      answers2["e5_4_3-buildings-percentRec"] ||
                      inputValues.e5_4_3.buildings.percentRec
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td rowSpan="3">Machinery</td>
                <td>Heavy machinery</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-heavyMachinery-rec"
                    value={
                      answers2["e5_4_3-heavyMachinery-rec"] || inputValues.e5_4_3.heavyMachinery.rec
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-heavyMachinery-virgin"
                    value={
                      answers2["e5_4_3-heavyMachinery-virgin"] ||
                      inputValues.e5_4_3.heavyMachinery.virgin
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-heavyMachinery-percentRec"
                    value={
                      answers2["e5_4_3-heavyMachinery-percentRec"] ||
                      inputValues.e5_4_3.heavyMachinery.percentRec
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Mid-weight machinery</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-midWeightMachinery-rec"
                    value={
                      answers2["e5_4_3-midWeightMachinery-rec"] ||
                      inputValues.e5_4_3.midWeightMachinery.rec
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-midWeightMachinery-virgin"
                    value={
                      answers2["e5_4_3-midWeightMachinery-virgin"] ||
                      inputValues.e5_4_3.midWeightMachinery.virgin
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-midWeightMachinery-percentRec"
                    value={
                      answers2["e5_4_3-midWeightMachinery-percentRec"] ||
                      inputValues.e5_4_3.midWeightMachinery.percentRec
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Light machinery</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-lightMachinery-rec"
                    value={
                      answers2["e5_4_3-lightMachinery-rec"] || inputValues.e5_4_3.lightMachinery.rec
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-lightMachinery-virgin"
                    value={
                      answers2["e5_4_3-lightMachinery-virgin"] ||
                      inputValues.e5_4_3.lightMachinery.virgin
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-lightMachinery-percentRec"
                    value={
                      answers2["e5_4_3-lightMachinery-percentRec"] ||
                      inputValues.e5_4_3.lightMachinery.percentRec
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td rowSpan="3">Transport</td>
                <td>Heavy Transport</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-heavyTransport-rec"
                    value={
                      answers2["e5_4_3-heavyTransport-rec"] || inputValues.e5_4_3.heavyTransport.rec
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-heavyTransport-virgin"
                    value={
                      answers2["e5_4_3-heavyTransport-virgin"] ||
                      inputValues.e5_4_3.heavyTransport.virgin
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-heavyTransport-percentRec"
                    value={
                      answers2["e5_4_3-heavyTransport-percentRec"] ||
                      inputValues.e5_4_3.heavyTransport.percentRec
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Mid-weight Transport</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-midWeightTransport-rec"
                    value={
                      answers2["e5_4_3-midWeightTransport-rec"] ||
                      inputValues.e5_4_3.midWeightTransport.rec
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-midWeightTransport-virgin"
                    value={
                      answers2["e5_4_3-midWeightTransport-virgin"] ||
                      inputValues.e5_4_3.midWeightTransport.virgin
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-midWeightTransport-percentRec"
                    value={
                      answers2["e5_4_3-midWeightTransport-percentRec"] ||
                      inputValues.e5_4_3.midWeightTransport.percentRec
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Light Transport</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-lightTransport-rec"
                    value={
                      answers2["e5_4_3-lightTransport-rec"] || inputValues.e5_4_3.lightTransport.rec
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-lightTransport-virgin"
                    value={
                      answers2["e5_4_3-lightTransport-virgin"] ||
                      inputValues.e5_4_3.lightTransport.virgin
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-lightTransport-percentRec"
                    value={
                      answers2["e5_4_3-lightTransport-percentRec"] ||
                      inputValues.e5_4_3.lightTransport.percentRec
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">Warehousing Equipment</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-equipment-rec"
                    value={answers2["e5_4_3-equipment-rec"] || inputValues.e5_4_3.equipment.rec}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-equipment-virgin"
                    value={
                      answers2["e5_4_3-equipment-virgin"] || inputValues.e5_4_3.equipment.virgin
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-equipment-percentRec"
                    value={
                      answers2["e5_4_3-equipment-percentRec"] ||
                      inputValues.e5_4_3.equipment.percentRec
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td rowSpan="2">Raw Materials</td>
                <td>Critical raw material</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-criticalRawMaterial-rec"
                    value={
                      answers2["e5_4_3-criticalRawMaterial-rec"] ||
                      inputValues.e5_4_3.criticalRawMaterial.rec
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-criticalRawMaterial-virgin"
                    value={
                      answers2["e5_4_3-criticalRawMaterial-virgin"] ||
                      inputValues.e5_4_3.criticalRawMaterial.virgin
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-criticalRawMaterial-percentRec"
                    value={
                      answers2["e5_4_3-criticalRawMaterial-percentRec"] ||
                      inputValues.e5_4_3.criticalRawMaterial.percentRec
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Rare earths</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-rareEarths-rec"
                    value={answers2["e5_4_3-rareEarths-rec"] || inputValues.e5_4_3.rareEarths.rec}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-rareEarths-virgin"
                    value={
                      answers2["e5_4_3-rareEarths-virgin"] || inputValues.e5_4_3.rareEarths.virgin
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-rareEarths-percentRec"
                    value={
                      answers2["e5_4_3-rareEarths-percentRec"] ||
                      inputValues.e5_4_3.rareEarths.percentRec
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">Associated process materials</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-processMaterials-rec"
                    value={
                      answers2["e5_4_3-processMaterials-rec"] ||
                      inputValues.e5_4_3.processMaterials.rec
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-processMaterials-virgin"
                    value={
                      answers2["e5_4_3-processMaterials-virgin"] ||
                      inputValues.e5_4_3.processMaterials.virgin
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-processMaterials-percentRec"
                    value={
                      answers2["e5_4_3-processMaterials-percentRec"] ||
                      inputValues.e5_4_3.processMaterials.percentRec
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">Semimanufactured goods or parts</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-semimanu-rec"
                    value={answers2["e5_4_3-semimanu-rec"] || inputValues.e5_4_3.semimanu.rec}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-semimanu-virgin"
                    value={answers2["e5_4_3-semimanu-virgin"] || inputValues.e5_4_3.semimanu.virgin}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_4_3-semimanu-percentRec"
                    value={
                      answers2["e5_4_3-semimanu-percentRec"] ||
                      inputValues.e5_4_3.semimanu.percentRec
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        ),
        module: "Environment",
        boldPart: "31 c",
        fun: "E54R",
      },
      {
        id: "q-622",
        question:
          "The undertaking shall provide information on the methodologies used to calculate the data. It shall specify whether the data is sourced from direct measurement or estimations, and disclose the key assumptions used.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              The reported usage data are to reflect the material in its original state, and not to
              be presented with further data manipulation, such as reporting it as “dry weight”.
            </p>
          </Typography>
        ),

        module: "Environment",
        boldPart: "32",
      },
      {
        id: "q-623",
        question:
          "When the undertaking is subject to paragraph 31, it may also provide transparency on the materials that are sourced from by-products/waste stream (e.g., offcuts of a material that has not previously been in a product).",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 22",
      },
      {
        id: "q-624",
        question:
          "In cases where there is an overlap between categories of reused, recycled, the undertaking shall specify how double counting was avoided and the choices that were made.",
        tooltip: "",
        module: "Environment",
        boldPart: "AR 25",
      },
      // Add more questions as needed
    ],
    "E5-5 –Resource outflows": [
      {
        id: "q-625",
        question:
          "The undertaking shall provide a description of the key products and materials that come out of the undertaking’s production process and that are designed along circular principles, including durability, reusability, repairability, disassembly, remanufacturing, refurbishment, recycling, recirculation by the bio cycle, or optimisation of the use of the product or material through other circular business models.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              Products and materials information to be provided under paragraph 35 refers to all
              materials and products that come out of the undertaking’s production process and that
              a company puts on the market (including packaging).
            </p>
          </Typography>
        ),

        module: "Environment",
        boldPart: "35",
      },
      {
        id: "q-626",
        question: "Table: Product durability",
        tooltip: "",
        table: (
          <table className="question-table">
            <thead>
              <tr>
                <th>List of Product</th>
                <th>Durability of Product</th>
                <th>Industrial Average</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Product 1</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_5_1-product1-durability"
                    value={
                      answers2["e5_5_1-product1-durability"] ||
                      inputValues.e5_5_1.product1.durability
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_5_1-product1-average"
                    value={
                      answers2["e5_5_1-product1-average"] || inputValues.e5_5_1.product1.average
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Product 2</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_5_1-product2-durability"
                    value={
                      answers2["e5_5_1-product2-durability"] ||
                      inputValues.e5_5_1.product2.durability
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_5_1-product2-average"
                    value={
                      answers2["e5_5_1-product2-average"] || inputValues.e5_5_1.product2.average
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Product 3</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_5_1-product3-durability"
                    value={
                      answers2["e5_5_1-product3-durability"] ||
                      inputValues.e5_5_1.product3.durability
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_5_1-product3-average"
                    value={
                      answers2["e5_5_1-product3-average"] || inputValues.e5_5_1.product3.average
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Product 4</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_5_1-product4-durability"
                    value={
                      answers2["e5_5_1-product4-durability"] ||
                      inputValues.e5_5_1.product4.durability
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_5_1-product4-average"
                    value={
                      answers2["e5_5_1-product4-average"] || inputValues.e5_5_1.product4.average
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        ),
        module: "Environment",
        boldPart: "36 a",
        fun: "3Column",
      },
      {
        id: "q-627",
        question:
          "Undertakings for which outflows are material shall disclose: The reparability products, using an established rating system, where possible;",
        tooltip: "",
        module: "Environment",
        boldPart: "36 b",
      },
      {
        id: "q-628",
        question: "Table: Recyclable-content",
        tooltip: (
          <Typography>
            <p className="question-tip">
              When compiling the rate, the undertaking shall use as denominator the overall total
              weight of materials used during the reporting period.
            </p>
          </Typography>
        ),
        table: (
          <table className="question-table">
            <thead>
              <tr>
                <th>List of Product</th>
                <th>% Recyclable Content in Product</th>
                <th>% Recyclable Content in Product Packaging</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Product 1</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_5_2-product1-recProduct"
                    value={
                      answers2["e5_5_2-product1-recProduct"] ||
                      inputValues.e5_5_2.product1.recProduct
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_5_2-product1-recPackaging"
                    value={
                      answers2["e5_5_2-product1-recPackaging"] ||
                      inputValues.e5_5_2.product1.recPackaging
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Product 2</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_5_2-product2-recProduct"
                    value={
                      answers2["e5_5_2-product2-recProduct"] ||
                      inputValues.e5_5_2.product2.recProduct
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_5_2-product2-recPackaging"
                    value={
                      answers2["e5_5_2-product2-recPackaging"] ||
                      inputValues.e5_5_2.product2.recPackaging
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Product 3</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_5_2-product3-recProduct"
                    value={
                      answers2["e5_5_2-product3-recProduct"] ||
                      inputValues.e5_5_2.product3.recProduct
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_5_2-product3-recPackaging"
                    value={
                      answers2["e5_5_2-product3-recPackaging"] ||
                      inputValues.e5_5_2.product3.recPackaging
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Product 4</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_5_2-product4-recProduct"
                    value={
                      answers2["e5_5_2-product4-recProduct"] ||
                      inputValues.e5_5_2.product4.recProduct
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_5_2-product4-recPackaging"
                    value={
                      answers2["e5_5_2-product4-recPackaging"] ||
                      inputValues.e5_5_2.product4.recPackaging
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        ),
        module: "Environment",
        boldPart: "36 c",
        fun: "3Column",
      },
      {
        id: "q-629",
        question: "Table: Waste",
        tooltip: (
          <Typography>
            <p className="question-tip">
              Examples of other types of recovery operations under paragraph 37(b)iii may be found
              in Annex II of Directive 2008/98/EC (Waste Framework Directive).
            </p>
          </Typography>
        ),
        table: (
          <div className="resource-table" style={{ overflowX: "auto" }}>
            <table className="question-table">
              <thead>
                <tr>
                  <th rowspan="2">Waste Category</th>
                  <th rowspan="2">Weight of total Waste Generated</th>
                  <th colspan="3">Weight of waste diverted from disposal</th>
                  <th colspan="3">Weight of waste diverted to disposal</th>
                  <th colspan="2">Non recycled waste</th>
                </tr>
                <tr>
                  <th>For reuse</th>
                  <th>For recycling</th>
                  <th>Other recovery</th>
                  <th>To incineration</th>
                  <th>To landfill</th>
                  <th>To other disposal operations</th>
                  <th>Weight of non-recycled waste</th>
                  <th>% of non-recycled waste</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Hazardous waste</td>
                  <td>
                    <textarea
                      type="text"
                      name="e5_5_3-hazardousWaste-totalWeight"
                      value={
                        answers2["e5_5_3-hazardousWaste-totalWeight"] ||
                        inputValues.e5_5_3.hazardousWaste.totalWeight
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <textarea
                      type="text"
                      name="e5_5_3-hazardousWaste-reuse"
                      value={
                        answers2["e5_5_3-hazardousWaste-reuse"] ||
                        inputValues.e5_5_3.hazardousWaste.reuse
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <textarea
                      type="text"
                      name="e5_5_3-hazardousWaste-recycling"
                      value={
                        answers2["e5_5_3-hazardousWaste-recycling"] ||
                        inputValues.e5_5_3.hazardousWaste.recycling
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <textarea
                      type="text"
                      name="e5_5_3-hazardousWaste-otherRecovery"
                      value={
                        answers2["e5_5_3-hazardousWaste-otherRecovery"] ||
                        inputValues.e5_5_3.hazardousWaste.otherRecovery
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <textarea
                      type="text"
                      name="e5_5_3-hazardousWaste-incineration"
                      value={
                        answers2["e5_5_3-hazardousWaste-incineration"] ||
                        inputValues.e5_5_3.hazardousWaste.incineration
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <textarea
                      type="text"
                      name="e5_5_3-hazardousWaste-landfill"
                      value={
                        answers2["e5_5_3-hazardousWaste-landfill"] ||
                        inputValues.e5_5_3.hazardousWaste.landfill
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <textarea
                      type="text"
                      name="e5_5_3-hazardousWaste-otherDisposal"
                      value={
                        answers2["e5_5_3-hazardousWaste-otherDisposal"] ||
                        inputValues.e5_5_3.hazardousWaste.otherDisposal
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <textarea
                      type="text"
                      name="e5_5_3-hazardousWaste-nonRecycledWeight"
                      value={
                        answers2["e5_5_3-hazardousWaste-nonRecycledWeight"] ||
                        inputValues.e5_5_3.hazardousWaste.nonRecycledWeight
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <textarea
                      type="text"
                      name="e5_5_3-hazardousWaste-nonRecycledPercentage"
                      value={
                        answers2["e5_5_3-hazardousWaste-nonRecycledPercentage"] ||
                        inputValues.e5_5_3.hazardousWaste.nonRecycledPercentage
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Non-hazardous waste</td>
                  <td>
                    <textarea
                      type="text"
                      name="e5_5_3-nonHazardousWaste-totalWeight"
                      value={
                        answers2["e5_5_3-nonHazardousWaste-totalWeight"] ||
                        inputValues.e5_5_3.nonHazardousWaste.totalWeight
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <textarea
                      type="text"
                      name="e5_5_3-nonHazardousWaste-reuse"
                      value={
                        answers2["e5_5_3-nonHazardousWaste-reuse"] ||
                        inputValues.e5_5_3.nonHazardousWaste.reuse
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <textarea
                      type="text"
                      name="e5_5_3-nonHazardousWaste-recycling"
                      value={
                        answers2["e5_5_3-nonHazardousWaste-recycling"] ||
                        inputValues.e5_5_3.nonHazardousWaste.recycling
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <textarea
                      type="text"
                      name="e5_5_3-nonHazardousWaste-otherRecovery"
                      value={
                        answers2["e5_5_3-nonHazardousWaste-otherRecovery"] ||
                        inputValues.e5_5_3.nonHazardousWaste.otherRecovery
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <textarea
                      type="text"
                      name="e5_5_3-nonHazardousWaste-incineration"
                      value={
                        answers2["e5_5_3-nonHazardousWaste-incineration"] ||
                        inputValues.e5_5_3.nonHazardousWaste.incineration
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <textarea
                      type="text"
                      name="e5_5_3-nonHazardousWaste-landfill"
                      value={
                        answers2["e5_5_3-nonHazardousWaste-landfill"] ||
                        inputValues.e5_5_3.nonHazardousWaste.landfill
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <textarea
                      type="text"
                      name="e5_5_3-nonHazardousWaste-otherDisposal"
                      value={
                        answers2["e5_5_3-nonHazardousWaste-otherDisposal"] ||
                        inputValues.e5_5_3.nonHazardousWaste.otherDisposal
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <textarea
                      type="text"
                      name="e5_5_3-nonHazardousWaste-nonRecycledWeight"
                      value={
                        answers2["e5_5_3-nonHazardousWaste-nonRecycledWeight"] ||
                        inputValues.e5_5_3.nonHazardousWaste.nonRecycledWeight
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <textarea
                      type="text"
                      name="e5_5_3-nonHazardousWaste-nonRecycledPercentage"
                      value={
                        answers2["e5_5_3-nonHazardousWaste-nonRecycledPercentage"] ||
                        inputValues.e5_5_3.nonHazardousWaste.nonRecycledPercentage
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ),
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 13 Table #2 of Annex 1
            </p>
          </Typography>
        ),
        module: "Environment",
        boldPart: "37",
        fun: "TableWaste",
      },
      {
        id: "q-630",
        question:
          "When disclosing the composition of the waste, the undertaking shall specify: the waste streams relevant to its sector or activities (e.g. tailings for the undertaking in the mining sector, electronic waste for the undertaking in the consumer electronics sector, or food waste for the undertaking in the agriculture or in the hospitality sector);",
        tooltip: "",
        module: "Environment",
        boldPart: "38 a",
      },
      {
        id: "q-631",
        question:
          "the materials that are present in the waste (e.g. biomass, metals, non-metallic minerals, plastics, textiles, critical raw materials and rare earths).",
        tooltip: "",
        module: "Environment",
        boldPart: "38 b",
      },
      {
        id: "q-632",
        question: "Table: Radioactive Waste",
        tooltip: "",
        table: (
          <table className="question-table">
            <thead>
              <tr></tr>
            </thead>

            <tbody>
              <tr>
                <td>Total Radio-active Hazardous waste</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_5_4-totalRadio-netValue"
                    value={
                      answers2["e5_5_4-totalRadio-netValue"] ||
                      inputValues.e5_5_4.totalRadio.netValue
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Total Non-radio-active hazardous waste</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_5_4-totalNonRadio-netValue"
                    value={
                      answers2["e5_5_4-totalNonRadio-netValue"] ||
                      inputValues.e5_5_4.totalNonRadio.netValue
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Total hazardous waste</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="e5_5_4-totalHazardous-netValue"
                    value={
                      answers2["e5_5_4-totalHazardous-netValue"] ||
                      inputValues.e5_5_4.totalHazardous.netValue
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        ),
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 9 Table #1 of Annex 1
            </p>
          </Typography>
        ),
        module: "Environment",
        boldPart: "39",
        fun: "2ColumnWH",
      },
      {
        id: "q-633",
        question:
          "The undertaking shall provide contextual information on the methodologies used to calculate the data and in particular the criteria and assumptions used to determine and classify products designed along circular principles under paragraph 35. It shall specify whether the data is sourced from direct measurement or estimations; and disclose the key assumptions used.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              When providing contextual information under paragraph 40 the undertaking may:
            </p>
            <ol className="question-tip" type="a">
              <li>
                explain the reasons for high weights of waste directed to disposal (e.g., local
                regulations that prohibit landfill of specific types of waste);
              </li>
              <li>
                describe sector practices, sector standards, or external regulations that mandate a
                specific disposal operation; and
              </li>
              <li>
                specify whether the data has been modelled or sourced from direct measurements, such
                as waste transfer notes from contracted waste collectors.
              </li>
            </ol>
          </Typography>
        ),

        module: "Environment",
        boldPart: "40",
      },
      {
        id: "q-634",
        question:
          "The undertaking may disclose its engagement in product end-of-life waste management, for example through extended producer responsibility schemes or take-back schemes.",
        tooltip: "",
        voluntary: true,
        module: "Environment",
        boldPart: "AR 28",
      },

      // Add more questions as needed
    ],
    "E5-6 – Anticipated financial effects from resource use and circular economy-related impacts, risks and opportunities":
      [
        {
          id: "q-635",
          question:
            "The disclosure shall include: a quantification of the anticipated financial effects in monetary terms before considering resource use and circular economy-related actions, or where not possible without undue cost or effort, qualitative information. For financial effects arising from material opportunities, a quantification is not required if it would result in disclosure that does not meet the qualitative characteristics of information (see ESRS 1 Appendix B Qualitative characteristics of information);",
          tooltip: (
            <Typography>
              <p className="question-tip">
                The quantification of the anticipated financial effects in monetary terms under
                paragraph 43(a) may be a range.
              </p>
            </Typography>
          ),
          phasedIn:
            "The undertaking may omit the information prescribed by ESRS E5-6 for the first year of preparation of its sustainability statement. The undertaking may comply with ESRS E5-6 by reporting only qualitative disclosures, for the first 3 years of preparation of its sustainability statement.",
          module: "Environment",
          boldPart: "43 a",
        },
        {
          id: "q-636",
          question:
            "a description of the effects considered, the impacts and dependencies to which they relate and the time horizons in which they are likely to materialise;",
          tooltip: "",
          phasedIn:
            "The undertaking may omit the information prescribed by ESRS E5-6 for the first year of preparation of its sustainability statement. The undertaking may comply with ESRS E5-6 by reporting only qualitative disclosures, for the first 3 years of preparation of its sustainability statement.",
          module: "Environment",
          boldPart: "43 b",
        },
        {
          id: "q-637",
          question:
            "the critical assumptions used to quantify the anticipated financial effects, as well as the sources and level of uncertainty of those assumptions.",
          tooltip: "",
          phasedIn:
            "The undertaking may omit the information prescribed by ESRS E5-6 for the first year of preparation of its sustainability statement. The undertaking may comply with ESRS E5-6 by reporting only qualitative disclosures, for the first 3 years of preparation of its sustainability statement.",
          module: "Environment",
          boldPart: "43 c",
        },
        {
          id: "q-638",
          question:
            "The undertaking may include an assessment of its related products and services at risk over the short-, medium- and long-term, explaining how these are defined, how financial amounts are estimated, and which critical assumptions are made.",
          tooltip: "",
          phasedIn:
            "The undertaking may omit the information prescribed by ESRS E5-6 for the first year of preparation of its sustainability statement. The undertaking may comply with ESRS E5-6 by reporting only qualitative disclosures, for the first 3 years of preparation of its sustainability statement.",
          voluntary: true,
          module: "Environment",
          boldPart: "AR 35",
        },

        // Add more questions as needed
      ],
    /**********************************************
     * Tab: Social
     * Topic: ESRS S1 Own workforce
     **********************************************/
    "S1 ESRS 2 SBM-3 – Material impacts, risks and opportunities and their interaction with strategy and business model":
      [
        {
          id: "q-639",
          question:
            "When fulfilling the requirements of paragraph 48, the undertaking shall disclose whether all people in its own workforce who could be materially impacted by the undertaking are included in the scope of its disclosure under ESRS 2. These material impacts shall include impacts that are connected with the undertaking’s own operations and its value chain, including through its products or services, as well as through its business relationships. ",
          tooltip: (
            <Typography>
              <p className="question-tip">
                AR 6. Impacts on the undertaking’s own workforce can originate in its strategy or
                business model in a number of different ways. For example, impacts may relate to the
                undertaking’s value proposition (such as providing lowest cost products or services,
                or high-speed delivery, in ways that put pressure on labour rights in its own
                operations), or its cost structure and the revenue model (such as shifting inventory
                risk to suppliers, with knock-on effects on the labour rights of people who work for
                them). <br />
                AR 7. Impacts on the undertaking’s own workforce that originate in the strategy or
                business model can also bring material risks to the undertaking. For example, risks
                arise if some people in the workforce are at risk of forced labour, and the
                undertaking is importing products into countries where the law allows for the
                confiscation of imported goods that are suspected of being made with forced labour.
                An example of opportunities for the undertaking may result from providing
                opportunities for the workforce such as job creation and upskilling in the context
                of a “just transition”. Another example, in the context of a pandemic or other
                severe health crisis, relates to the undertaking potentially relying on contingent
                labour with little to no access to sick care and health benefits that may face
                severe operational and business continuity risks as workers have no choice but to
                keep working while sick, further exacerbating the spread of the disease and causing
                major supply chain breakdowns. Reputational and business opportunity risks linked to
                the exploitation of lowskilled, low-paid workers in sourcing geographies with
                minimal protections for them are also increasing with media backlash and consumer
                preferences moving to more ethically sourced or sustainable goods.
              </p>
            </Typography>
          ),
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "14",
        },
        {
          id: "q-640",
          question:
            "A brief description of the types of employees and non-employees in its own workforce subject to material impacts by its operations, and specify whether they are employees, self- employed people, or people provided by third party undertakings primarily engaged in employment activities;",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "14 a",
        },
        {
          id: "q-641",
          question:
            "In the case of material negative impacts, whether they are either (i) widespread or systemic in contexts where the undertaking operates (for example, child labour or forced labour or compulsory labour in specific countries or regions outside the EU), or (ii) related to individual incidents (for example, an industrial accident or an oil spill);",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "14 b",
        },
        {
          id: "q-642",
          question:
            "In the case of material positive impacts, a brief description of the activities that result in the positive impacts, the types of employees and non-employees in its own workforce that are positively affected or could be positively affected; the undertaking may also disclose whether the positive impacts occur in specific countries or regions;",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "14 c",
        },
        {
          id: "q-643",
          question:
            "Any material risks and opportunities for the undertaking arising from impacts and dependencies on its own workforce;",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          euReg: (
            <Typography>
              <p className="question-tip">
                <strong>SFDR reference:</strong> Indicator number 13 Table #3 of Annex I{" "}
              </p>
            </Typography>
          ),

          module: "Social",
          boldPart: "14 d",
        },
        {
          id: "q-644",
          question:
            "Any material impacts on its own workforce that may arise from transition plans for reducing negative impacts on the environment and achieving greener and climate- neutral operations, including information on the impacts on own workforce caused by the undertaking’s plans and actions to reduce carbon emissions in line with international agreements. Impacts, risks and opportunities include restructuring and employment loss as well as opportunities arising from job creation and reskilling or upskilling;",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "14 e",
        },
        {
          id: "q-645",
          question:
            "Operations at significant risk of incidents of forced labour or compulsory labour either in terms of: i. type of operation (such as manufacturing plant); or ii. countries or geographic areas with operations considered at risk;",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          euReg: (
            <Typography>
              <p className="question-tip">
                14 (f) ii
                <strong>SFDR reference:</strong> Indicator number 13 Table #3 of Annex I{" "}
              </p>
            </Typography>
          ),
          module: "Social",
          boldPart: "14 f",
        },

        {
          id: "q-647",
          question:
            "operations at significant risk of incidents of child labour either in terms of: i. type of operation (such as manufacturing plant); or ii. countries or geographic areas with operations considered at risk;",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          euReg: (
            <Typography>
              <p className="question-tip">
                <strong>SFDR reference:</strong> Indicator number 12 Table #3 of Annex I{" "}
              </p>
            </Typography>
          ),
          module: "Social",
          boldPart: "14 g",
        },
        {
          id: "q-648",
          question: "countries or geographic areas with operations considered at risk",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          euReg: (
            <Typography>
              <p className="question-tip">
                <strong>SFDR reference:</strong> Indicator number 12 Table #3 of Annex I{" "}
              </p>
            </Typography>
          ),
          module: "Social",
          boldPart: "14 g (ii)",
        },
        {
          id: "q-649",
          question:
            " In describing the main types of people in its own workforce who are or could be negatively affected, based on the materiality assessment set out in ESRS 2 IRO 1, the undertaking shall disclose whether and how it has developed an understanding of how people with particular characteristics, those working in particular contexts, or those undertaking particular activities may be at greater risk of harm.",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 8. Examples of particular characteristics of people in the undertaking’s own
                workforce that may be considered by the undertaking when responding to paragraph 15
                relate to young people that may be more susceptible to impacts on their physical and
                mental development, or women in a context where women are routinely discriminated
                against in the terms and conditions of work, or migrants in a context where the
                market for the supply of labour is poorly regulated and workers are routinely
                charged recruitment fees. For some people in the workforce, the inherent nature of
                the activity that they are required to undertake may put them at risk (for example,
                people required to handle chemicals or operate certain equipment or low paid
                employees who are on “zero hours” contracts). "
              </p>
            </Typography>
          ),
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "15",
        },
        {
          id: "q-650",
          question:
            "The undertaking shall disclose which, if any, of its material risks and opportunities arising from impacts and dependencies on people in its own workforce relate to specific groups of people (for example, particular age groups, or people working in a particular factory or country) rather than to all of its own workforce (for example, a general pay cut, or training offered to all people in its own workforce).",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 9. With regard to paragraph 16, material risks could also arise because of the
                undertaking’s dependency on its own workforce where low-likelihood but high-impact
                events may trigger financial effects; for example, where a global pandemic leads to
                severe health impacts on the undertaking’s workforce resulting in major disruptions
                to production and distribution. Other examples of risk related to the undertaking’s
                dependency on its workforce include a shortage in skilled workers or political
                decisions or legislation affecting its own operations and own workforce. "
              </p>
            </Typography>
          ),
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "16",
        },

        // Add more questions as needed
      ],

    "S1-1 – Policies related to own workforce": [
      {
        id: "q-651",
        question:
          "The disclosure shall contain the information on the undertaking’s policies to manage its material impacts, risks and opportunities related to its own workforce in accordance with ESRS 2 MDR-P Policies adopted to manage material sustainability matters. In addition, the undertaking shall specify if such policies cover specific groups within its own workforce or all of its own workforce.",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        module: "Social",
        boldPart: "19",
      },
      {
        id: "q-652",
        question:
          "The undertaking shall consider whether explanations of significant changes to the policies adopted during the reporting year (for example, new expectations for foreign subsidiaries, new or additional approaches to due diligence and remedy) provide contextual information for users and may disclose such explanations. This includes policies and commitments of the undertaking to prevent or mitigate the risks and negative impacts on people in its own workforce of reducing carbon emissions and transitioning to greener and climate-neutral operations as well as to provide opportunities for the workforce such as job creation and upskilling, including explicit commitments to a ‘just transition’. ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        voluntary: true,
        module: "Social",
        boldPart: "AR 10",
      },
      {
        id: "q-653",
        question:
          "The undertaking shall describe its human rights policy commitments (89) that are relevant to its own workforce, including those processes and mechanisms to monitor compliance with the UN Guiding Principles on Business and Human Rights, ILO Declaration on Fundamental Principles and Rights at Work and the OECD Guidelines for Multinational Enterprises (90). In its disclosure it shall focus on those matters that are material in relation to, as well as its general approach to: (a) Respect for the human rights, including labour rights, of people in its own workforce; (b) engagement with people in its own workforce; (c) measures to provide and/or enable remedy for human rights impacts.",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 9 Table #3 and Indicator number 11
              Table #1 of Annex I{" "}
            </p>
          </Typography>
        ),

        module: "Social",
        boldPart: "20",
      },

      {
        id: "q-657",
        question:
          "The undertaking shall disclose whether and how its policies with regard to its own workforce are aligned with relevant internationally recognised instruments, including the UN Guiding Principles on Business and Human Rights",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 12. When disclosing the alignment of its policies with the UN Guiding Principles
              on Business and Human Rights, the undertaking shall consider that the Guiding
              Principles refer to the International Bill of Human Rights, which consist of the
              Universal Declaration of Human Rights and the two Covenants that implement it, as well
              as the International Labour Organisation’s Declaration on Fundamental Rights and
              Principles at Work and the core conventions that underpin it, and may report on
              alignment with these instruments."
            </p>
          </Typography>
        ),
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>Benchmark regulation reference:</strong> Delegated Regulation (EU) 2020/1816,
              Annex II{" "}
            </p>
          </Typography>
        ),
        module: "Social",
        boldPart: "21",
      },
      {
        id: "q-658",
        question:
          "The undertaking shall state whether its policies in relation to its own workforce explicitly address trafficking in human beings , forced labour or compulsory labour and child labour.",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 11 Table #3 of Annex I{" "}
            </p>
          </Typography>
        ),
        module: "Social",
        boldPart: "22",
      },
      {
        id: "q-659",
        question:
          "The undertaking shall state whether it has a workplace accident prevention policy or management system",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 1 Table #3 of Annex I{" "}
            </p>
          </Typography>
        ),
        module: "Social",
        boldPart: "23",
      },
      {
        id: "q-660",
        question:
          "Whether it has specific policies aimed at the elimination of discrimination, including harassment, promoting equal opportunities and other ways to advance diversity and inclusion",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        module: "Social",
        boldPart: "24 a",
      },
      {
        id: "q-661",
        question:
          "Whether the following grounds for discrimination are specifically covered in the policy: racial and ethnic origin, colour, sex, sexual orientation, gender identity, disability, age, religion, political opinion, national extraction or social origin, or other forms of discrimination covered by Union regulation and national law",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 15. Discrimination in employment and occupation occurs when someone is treated
              differently or less favourably because of characteristics that are not related to
              merit or the inherent requirements of the job. These characteristics are commonly
              defined in national laws. Besides the grounds mentioned in the Disclosure Requirement,
              the undertaking shall consider other grounds for discrimination prohibited under
              national legislation.
              <br />
              AR 16. Discrimination can arise in a variety of work-related activities. These include
              access to employment, particular occupations, training and vocational guidance and
              social security.
              <br />
              Moreover, it can occur with respect to the terms and conditions of employment, such
              as: recruitment, remuneration, hours of work and rest, paid holidays, maternity
              protection, security of tenure, job assignments, performance assessment and
              advancement, training opportunities, promotion prospects, occupational safety and
              health, termination of employment. The undertaking may address these areas
              specifically when disclosing its policies and underlying procedures to fulfil the
              disclosure requirement.
            </p>
          </Typography>
        ),
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        module: "Social",
        boldPart: "24 b",
      },
      {
        id: "q-662",
        question:
          "whether the undertaking has specific policy commitments related to inclusion or positive action for people from groups at particular risk of vulnerability in its own workforce and, if so, what these commitments are",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        module: "Social",
        boldPart: "24 c",
      },
      {
        id: "q-663",
        question:
          "Whether and how these policies are implemented through specific procedures to ensure discrimination is prevented, mitigated and acted upon once detected, as well as to advance diversity and inclusion in general.",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        module: "Social",
        boldPart: "24 d",
      },
      {
        id: "q-664",
        question:
          "The undertaking may provide an illustration of the types of communication of its policies to those individuals, group of individuals or entities for whom they are relevant, either because they are expected to implement them (for example, the undertaking’s employees, contractors and suppliers), or because they have a direct interest in their implementation (for example, people in its own workforce, investors). It may disclose communication tools and channels (for example, flyers, newsletters, dedicated websites, social media, face to face interactions, workers’ representatives), aimed at ensuring that the policy is accessible and that different audiences understand its implications. The undertaking may also explain how it identifies and removes potential barriers for dissemination, such as through translation into relevant languages or the use of graphic depictions. ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        voluntary: true,
        module: "Social",
        boldPart: "AR 14",
      },
      {
        id: "q-665",
        question:
          "The undertaking may disclose whether it: (a) has policies and procedures which make qualifications, skills and experience the basis for the recruitment, placement, training and advancement at all levels, while accounting for the fact that some individuals may have more difficulty than others to acquire such qualifications, skills and experience; ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        voluntary: true,
        module: "Social",
        boldPart: "AR 17 a",
      },
      {
        id: "q-666",
        question:
          "assigns responsibility at top management level for equal treatment and opportunities in employment, issue clear company-wide policies and procedures to guide equal employment practices, and link advancement to desired performance in this area; ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        voluntary: true,
        module: "Social",
        boldPart: "AR 17 b",
      },
      {
        id: "q-667",
        question:
          "provides staff training on non-discrimination policies and practices, with a particular focus on middle and upper management to raise awareness and address resolution strategies for preventing and addressing systemic and incidental discrimination; ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        voluntary: true,
        module: "Social",
        boldPart: "AR 17 c",
      },
      {
        id: "q-668",
        question:
          "makes adjustments to the physical environment to ensure health and safety for workers, customers and other visitors with disabilities; ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        voluntary: true,
        module: "Social",
        boldPart: "AR 17 d",
      },
      {
        id: "q-669",
        question:
          "evaluates whether job requirements have been defined in a way that could systematically disadvantage certain groups; ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        voluntary: true,
        module: "Social",
        boldPart: "AR 17 e",
      },
      {
        id: "q-670",
        question:
          "keeps up-to-date records on recruitment, training and promotion that provide a transparent view of opportunities for employees and their progression within the undertaking;",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        voluntary: true,
        module: "Social",
        boldPart: "AR 17 f",
      },
      {
        id: "q-671",
        question:
          "puts in place grievance procedures to address complaints, handle appeals and provide recourse for employees (especially in the context of negotiations and collective agreements) when discrimination is identified, and is alert to formal structures and informal cultural issues that can prevent employees from raising concerns and grievances; ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        voluntary: true,
        module: "Social",
        boldPart: "AR 17 g",
      },
      {
        id: "q-672",
        question: "has programs to promote access to skills development.",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        voluntary: true,
        module: "Social",
        boldPart: "AR 17 h",
      },
      {
        id: "q-673",
        question:
          "The undertaking shall disclose information about policies adopted to manage material sustainability matters. The disclosure shall include the following information: (a) a description of the key contents of the policy, including its general objectives and which material impacts, risks or opportunities the policy relates to and the process for monitoring; ",
        tooltip: "",
        condition: "yes",
        module: "Social",
        boldPart: "65 a",
      },
      {
        id: "q-674",
        question:
          "a description of the scope of the policy, or of its exclusions, in terms of activities, upstream and/or downstream value chain, geographies and if relevant, affected stakeholder groups;",
        tooltip: "",
        condition: "yes",
        module: "Social",
        boldPart: "65 b",
      },
      {
        id: "q-675",
        question:
          "the most senior level in the undertaking’s organisation that is accountable for the implementation of the policy;",
        tooltip: "",
        condition: "yes",
        module: "Social",
        boldPart: "65 c",
      },
      {
        id: "q-676",
        question:
          "a reference, if relevant, to the third-party standards or initiatives the undertaking commits to respect through the implementation of the policy;",
        tooltip: "",
        condition: "yes",
        module: "Social",
        boldPart: "65 d",
      },
      {
        id: "q-677",
        question:
          "if relevant, a description of the consideration given to the interests of key stakeholders in setting the policy; ",
        tooltip: "",
        condition: "yes",
        module: "Social",
        boldPart: "65 e",
      },
      {
        id: "q-678",
        question:
          "if relevant, whether and how the undertaking makes the policy available to potentially affected stakeholders, and stakeholders who need to help implement it. ",
        tooltip: "",
        condition: "yes",
        module: "Social",
        boldPart: "65 f",
      },
      {
        id: "q-679",
        question:
          "If the undertaking cannot disclose the information on policies and actions required under relevant ESRS, because it has not adopted policies and/or actions with reference to the specific sustainability matter concerned, it shall disclose this to be the case, and provide reasons for not having adopted policies and/or actions. ",
        tooltip: "",
        condition: "no",
        module: "Social",
        boldPart: "62",
      },
      {
        id: "q-680",
        question: "The undertaking may disclose a timeframe in which it aims to adopt them. ",
        tooltip: "",
        condition: "no",
        module: "Social",
        boldPart: "62",
      },
      // Add more questions as needed
    ],
    "S1-2 – Processes for engaging with own workers and workers’ representatives about impacts": [
      {
        id: "q-681",
        question:
          "The undertaking shall disclose whether and how the perspectives of its own workforce inform its decisions or activities aimed at managing the actual and potential impacts on its own workforce. This shall include, where relevant, an explanations: (a) whether engagement occurs directly with the undertaking’s own workforce or workers' representatives; (b) the stage(s) at which engagement occurs, the type of engagement and frequency of the engagement.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 21. To illustrate how the perspectives of its own workforce have informed specific
              decisions or activities, the undertaking may provide examples from the current
              reporting period. AR 23. Where possible, the undertaking may disclose examples from
              the reporting period to illustrate how the perspectives of its own workforce and
              workers' representatives have informed specific decisions or activities of the
              undertaking.
            </p>
            <p className="question-tip">
              AR 24. The undertaking shall consider the following aspects when fulfilling this
              Disclosure Requirement:
            </p>
            <ol className="question-tip" type="a">
              <li>
                The type of engagement (for example, information, consultation or participation) and
                its frequency (for example, ongoing, quarterly, annually);
              </li>
              <li>
                how feedback is recorded and integrated into decision-making, and how people in the
                workforce are informed about the way in which their feedback has influenced
                decisions;
              </li>
              <li>
                whether engagement activities take place at the organisational level or at a lower
                level, such as at the site or project level, and in the latter case, how information
                from engagement activities is centralised;
              </li>
              <li>
                the resources (for example, financial or human resources) allocated to engagement;
                and
              </li>
              <li>
                how it engages with people in its workforce and workers’ representatives on the
                impacts on its own workforce that may arise from reducing carbon emissions and
                transitioning to greener and climate-neutral operations, in particular
                restructuring, employment loss or creation, training and up/reskilling, gender and
                social equity, and health and safety.
              </li>
            </ol>
            <p className="question-tip">
              AR 19. When preparing the disclosures described in paragraph 27 b) and c), the
              following illustrations may be considered:
            </p>
            <ol className="question-tip" type="a">
              <li>
                examples of stages at which engagement occurs are
                <ol className="question-tip" type="i">
                  <li>determining the approach to mitigation and</li>
                  <li>evaluating the effectiveness of mitigation; 21</li>
                </ol>
              </li>
              <li>
                for type of engagement, these could be participation, consultation and/or
                information;
              </li>
              <li>
                for the frequency of the engagement, information may be provided on whether
                engagement occurs on a regular basis, at certain points in a project or business
                process, (for example, when a new harvest season begins or a new production line is
                opened), as well as whether it occurs in response to legal requirements and/or in
                response to stakeholder requests and whether the result of the engagement is being
                integrated into the undertaking's decision-making processes; and
              </li>
              <li>
                for the role with operational responsibility, whether the undertaking requires
                relevant staff to have certain skills, or whether it provides training or capacity
                building to relevant staff to undertake engagement.
              </li>
            </ol>
          </Typography>
        ),
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        module: "Social",
        boldPart: "27 a, 27 b",
      },

      {
        id: "q-684",
        question:
          "The function and the most senior role within the undertaking that has operational responsibility for ensuring that this engagement happens and that the results inform the undertaking’s approach.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 18. When describing what function or role has operational responsibility for such
              engagement and/or ultimate accountability, the undertaking may disclose whether this
              is a dedicated role or function or part of a broader role or function, and whether any
              capacity building activities have been offered to support the staff to undertake
              engagement. If it cannot identify such a position or function, it may state that this
              is the case. This disclosure could also be fulfilled by making reference to
              information disclosed according to ESRS 2 GOV-1 The role of the administrative,
              management and supervisory bodies.
            </p>
            <p className="question-tip">
              AR 19. When preparing the disclosures described in paragraph 27 b) and c), the
              following illustrations may be considered:
            </p>
            <ol className="question-tip" type="a">
              <li>
                examples of stages at which engagement occurs are
                <ol className="question-tip" type="i">
                  <li>determining the approach to mitigation and</li>
                  <li>evaluating the effectiveness of mitigation; 21</li>
                </ol>
              </li>
              <li>
                for type of engagement, these could be participation, consultation and/or
                information;
              </li>
              <li>
                for the frequency of the engagement, information may be provided on whether
                engagement occurs on a regular basis, at certain points in a project or business
                process, (for example, when a new harvest season begins or a new production line is
                opened), as well as whether it occurs in response to legal requirements and/or in
                response to stakeholder requests and whether the result of the engagement is being
                integrated into the undertaking's decision-making processes; and
              </li>
              <li>
                for the role with operational responsibility, whether the undertaking requires
                relevant staff to have certain skills, or whether it provides training or capacity
                building to relevant staff to undertake engagement.
              </li>
            </ol>
          </Typography>
        ),
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        module: "Social",
        boldPart: "27 c",
      },
      {
        id: "q-685",
        question:
          "Where applicable, a Global Framework Agreement or other agreements that the undertaking has with workers' representatives related to the respect of human rights of its own workforce, including an explanation of how the agreement enables the undertaking to gain insight into the perspectives of its own workforce.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 20. Global Framework Agreements (GFA) serve to establish an ongoing relationship
              between a multinational enterprise and a Global Union Federation to ensure that the
              undertaking adheres to the same standards in every country in which it operates. "
            </p>
          </Typography>
        ),
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        module: "Social",
        boldPart: "27 d",
      },
      {
        id: "q-686",
        question:
          "where applicable, how the undertaking assesses the effectiveness of its engagement with its own workforce, including, where relevant, any agreements or outcomes that result.",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        module: "Social",
        boldPart: "27 e",
      },
      {
        id: "q-687",
        question:
          "If the undertaking cannot disclose the above required information because it has not adopted a general process to engage with its own workforce, it shall disclose this to be the case. It may disclose a timeframe in which it aims to have such a process in place.",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        module: "Social",
        boldPart: "28",
      },
      {
        id: "q-688",
        question:
          "If the undertaking cannot disclose the above required information because it has not adopted a general process to engage with its own workforce, it shall disclose this to be the case. It may disclose a timeframe in which it aims to have such a process in place",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        module: "Social",
        boldPart: "29",
        voluntary: true,
      },
      {
        id: "q-689",
        question:
          "The undertaking may also disclose the following information in relation to paragraph 24 on diversity: (a) how it engages with at-risk or persons in vulnerable situations (for example whether it takes specific approaches and gives special attention to potential barriers); ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        voluntary: true,
        module: "Social",
        boldPart: "AR 25 a",
      },
      {
        id: "q-690",
        question:
          "how it takes into account potential barriers to engagement with people in its workforce (for example, language and cultural differences, gender and power imbalances, divisions within a community or group);",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        voluntary: true,
        module: "Social",
        boldPart: "AR 25 b",
      },
      {
        id: "q-691",
        question:
          "how it provides people in its workforce with information that is understandable and accessible through appropriate communication channels; ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        voluntary: true,
        module: "Social",
        boldPart: "AR 25 c",
      },
      {
        id: "q-692",
        question:
          "any conflicting interests that have arisen among its workforce and how the undertaking has resolved these conflicting interests; ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        voluntary: true,
        module: "Social",
        boldPart: "AR 25 d",
      },
      {
        id: "q-693",
        question:
          "how it seeks to respect the human rights of all stakeholders engaged, for example, their rights to privacy, freedom of expression, and peaceful assembly and protest. ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        voluntary: true,
        module: "Social",
        boldPart: "AR 25 e",
      },
      {
        id: "q-694",
        question:
          "The undertaking may also report information about the effectiveness of processes for engaging with its own workforce from previous reporting periods. This applies in cases where the undertaking has assessed the effectiveness of these processes or derived lessons during the current reporting period. Processes used to track effectiveness can include internal or external auditing or verification, impact assessments, measurement systems, stakeholder feedback, grievance mechanisms, external performance ratings, and benchmarking. ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        voluntary: true,
        module: "Social",
        boldPart: "AR 26",
      },
      // Add more questions as needed
    ],
    "S1-3 – Processes to remediate negative impacts and channels for own workers to raise concerns":
      [
        {
          id: "q-695",
          question: (
            <Typography>
              <p className="question-tip">
                <strong>32 a</strong> The undertaking shall describe the processes in place to cover
                the matters defined within paragraph 2 of the Objective section by disclosing the
                following information:
                <br />
                (a) its general approach to and processes for providing or contributing to remedy
                where it has caused or contributed to a material negative impact on people in its
                own workforce, including whether and how the undertaking assesses that the remedy
                provided is effective;
              </p>
            </Typography>
          ),
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 27. In fulfilling the requirements set out by the disclosure criteria of
                Disclosure Requirement ESRS S1-3, the undertaking may be guided by the content of
                the UN Guiding Principles on Business and Human Rights and the OECD Due Diligence
                Guidance for Responsible Business Conduct focused on remediation and grievance
                mechanisms. "
              </p>
            </Typography>
          ),
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "",
        },
        {
          id: "q-696",
          question:
            "any specific channels it has in place for its own workforce to raise their concerns or needs directly with the undertaking and have them addressed, including whether these are established by the undertaking itself and/or through participation in third-party mechanisms",
          tooltip: (
            <Typography>
              <p className="question-tip">
                AR 28. Channels for raising concerns or needs include grievance mechanisms,
                hotlines, trade unions (where people in the workforce are unionised), works
                councils, dialogue processes or other means through which the undertaking’s own
                workforce or workers’ representatives can raise concerns about impacts or explain
                needs that they would like the undertaking to address. This could include both
                channels provided by the undertaking directly and channels provided by the entities
                where their own workforce is working, in addition to any other mechanisms an
                undertaking may use to gain insight into the management of impacts on its own
                workforce, such as compliance audits. Where the undertaking is relying solely on
                information about the existence of such channels provided by its business
                relationships to answer this requirement, it may state that.
              </p>
            </Typography>
          ),
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "32 b",
        },
        {
          id: "q-697",
          question:
            "Third party mechanisms could include those operated by the government, NGOs, industry associations and other collaborative initiatives. The undertaking may disclose whether these are accessible to all of its own workforce (or workers’ representatives or, in their absence, individuals or organisations acting on their behalf or who are otherwise in a position to be aware of negative impacts). ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 29",
        },
        {
          id: "q-698",
          question:
            "The undertaking shall consider whether and how people in its own workforce that may be affected and their workers' representatives are able to access channels at the level of the undertaking they are employed by, or contracted to work for, in relation to each material impact. Relevant channels may include hotlines, trade unions (where people in the workforce are unionised) or works councils, or other grievance mechanisms operated by the relevant undertaking or by a third party. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 30",
        },
        {
          id: "q-699",
          question:
            "whether or not the undertaking has a grievance/complaints handling mechanism related to employee matters",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          euReg: (
            <Typography>
              <p className="question-tip">
                <strong>SFDR reference:</strong> Indicator number 5 Table #3 of Annex I{" "}
              </p>
            </Typography>
          ),
          module: "Social",
          boldPart: "32 c",
        },
        {
          id: "q-700",
          question:
            "The processes through which the undertaking supports the availability of such channels in the workplace of its own workforce",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "32 d",
        },
        {
          id: "q-701",
          question:
            "How it tracks and monitors issues raised and addressed, and, how it ensures the effectiveness of the channels, including through the involvement of stakeholders who are intended users.",
          tooltip: (
            <Typography>
              <p className="question-tip">
                AR 32. In describing the effectiveness of channels for its own workforce and
                workers’ representatives to raise concerns, the undertaking may be guided by the
                following questions, based on the “effectiveness criteria for non-judicial grievance
                mechanisms”, as laid out in the UN Guiding Principles on Business and Human Rights,
                in particular principle 31. The considerations below may be applied to individual
                channels or to a collective system of channels:
              </p>
              <ol className="question-tip" type="a">
                <li>
                  do the channels have legitimacy by providing appropriate accountability for their
                  fair conduct and building stakeholder trust?
                </li>
                <li>are the channels known and accessible to stakeholders? </li>
                <li>
                  do the channels have clear and known procedures, with indicative timeframes?(d) do
                  the channels ensure reasonable access for stakeholders to sources of information,
                  advice and expertise?
                </li>
                <li>
                  do the channels offer transparency by providing sufficient information both to
                  complainants and, where applicable, to meet any public interest?
                </li>
                <li>
                  do outcomes achieved through the channels accord with internationally recognised
                  human rights?
                </li>
                <li>
                  does the undertaking identify insights from the channels that support continuous
                  learning in both improving the channels and preventing future impacts?
                </li>
                <li>
                  does the undertaking focus on dialogue with complainants as the means to reach
                  agreed solutions, rather than seeking to unilaterally determine the outcome?
                </li>
              </ol>
            </Typography>
          ),
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "32 e",
        },
        {
          id: "q-702",
          question:
            "The undertaking shall disclose whether and how it assesses that people in its own workforce are aware of, and trust, these structures or processes as a way to raise their concerns or needs and have them addressed. In addition, the undertaking shall disclose whether it has policies in place regarding the protection of individuals that use them, including workers’ representatives, against retaliation. If such information has been disclosed in accordance with ESRS G1-1, the undertaking may refer to that information. ",
          tooltip: (
            <Typography>
              <p className="question-tip">
                AR 31. In explaining whether and how the undertaking knows that people in its own
                workforce are aware of and trust any of these channels, the undertaking may provide
                relevant and reliable data about the effectiveness of these channels from the
                perspective of the people concerned. Examples of sources of information are surveys
                of people in the undertaking’s workforce that have used such channels and their
                levels of satisfaction with the process and outcomes.
              </p>
            </Typography>
          ),
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "33",
        },
        {
          id: "q-703",
          question:
            "If the undertaking cannot disclose the above required information because it has not adopted a channel for raising concerns and/or does not support the availability of such a channel in the workplace for its own workforce, it shall disclose this to be the case. It may disclose a timeframe in which it aims to have such a channel in place.",
          tooltip: "",
          module: "Social",
          boldPart: "34",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          voluntary: true,
        },

        // Add more questions as needed
      ],
    "S1-4 – Taking action on material impacts on own workforce, and approaches to mitigating material risks and pursuing material opportunities related to own workforce, and effectiveness of those actions":
      [
        {
          id: "q-704",
          question:
            "The undertaking shall provide a summarised description of the action plans and resources to manage its material impacts, risks, and opportunities related to its own workforce in accordance with ESRS 2 MDR-A Actions and resources in relation to material sustainability matters. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "37",
        },
        {
          id: "q-705",
          question:
            " In relation to the material impacts related to its own workforce, the undertaking shall describe: (a) actions taken, planned or underway to prevent or mitigate material negative impacts on its own workforce;",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "38 a",
        },
        {
          id: "q-706",
          question:
            "whether and how it has taken action to provide or enable remedy in relation to an actual material impact;",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "38 b",
        },
        {
          id: "q-707",
          question:
            "any additional actions or initiatives it has in place with the primary purpose of delivering positive impacts for its own workforce;",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 42. When disclosing the intended or achieved positive outcomes of its actions
                for the undertaking’s own workforce, a distinction is to be made between evidence of
                certain activities having occurred (for example, that x number of people have
                received financial literacy training) from evidence of actual outcomes for the
                people concerned (for example, that x people report that they are able to better
                manage their pay and their household budgets). "
              </p>
            </Typography>
          ),
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "38 c",
        },
        {
          id: "q-708",
          question:
            "how it tracks and assesses the effectiveness of these actions and initiatives in delivering outcomes for its own workforce. ",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 38. Processes used to track the effectiveness of actions can include internal or
                external auditing or verification, court proceedings and/or related court decisions,
                impact assessments, measurement systems, stakeholder feedback, grievance mechanisms,
                external performance ratings, and benchmarking.
                <br />
                AR 39. Reporting on effectiveness is aimed at enabling the understanding of the
                links between actions taken by an undertaking and the effective management of
                impacts. Additional information that the undertaking may provide includes data
                showing a decrease in the number of incidents identified. "
              </p>
            </Typography>
          ),
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "38 d",
        },
        {
          id: "q-709",
          question:
            "In relation to paragraph 36, the undertaking shall describe the processes through which it identifies what action is needed and appropriate in response to a particular actual or potential negative impact on its own workforce.",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 34. Appropriate action can vary according to whether the undertaking causes or
                contributes to a material impact, or whether it is involved because the impact is
                directly linked to its operations, products or services by a business relationship.
                "
              </p>
            </Typography>
          ),
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "39",
        },
        {
          id: "q-710",
          question:
            "In relation to material risks and opportunities, the undertaking shall describe: (a) what action is planned or underway to mitigate material risks for the undertaking arising from its impacts and dependencies on its own workforce and how it tracks effectiveness in practice; (b) what action is planned or underway to pursue material opportunities for the undertaking in relation to its own workforce.",
          tooltip: (
            <Typography>
              <p className="question-tip">
                When disclosing the information required under paragraph 40, the undertaking shall
                consider ESRS 2 MDR-T Tracking effectiveness of policies and actions through targets
                if it evaluates the effectiveness of an action by setting a target.
              </p>
              <p className="question-tip">
                AR 44. When disclosing the material risks and opportunities related to its impacts
                or dependencies on its own workforce, the undertaking may consider the following:
              </p>
              <ol className="question-tip" type="a">
                <li>
                  risks related to the undertaking’s impacts on its own workforce may include the
                  reputational or legal exposure where people in the undertaking’s workforce are
                  found to be subject to forced labour or child labour;
                </li>
                <li>
                  risks related to the undertaking’s dependencies on its own workforce may include
                  disruption of business operations where significant employee turnover or lack of
                  skills/training development threaten the undertaking’s business; and
                </li>
                <li>
                  opportunities related to the undertaking’s impacts on its own workforce may
                  include market differentiation and greater customer appeal from guaranteeing
                  decent pay and conditions for non-employees.
                </li>
              </ol>
              <p className="question-tip">
                AR 45. When explaining whether dependencies turn into risks, the undertaking shall
                consider external developments. AR 47. The undertaking shall consider whether and
                how its process(es) to manage material risks related to own workforce are integrated
                into its existing risk management process(es).
              </p>
            </Typography>
          ),
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "40",
        },

        {
          id: "q-712",
          question:
            "The undertaking shall disclose whether and how it ensures that its own practices do not cause or contribute to material negative impacts on own workforce, including, where relevant, its practices in relation to procurement, sales and data use. This may include disclosing what approach is taken when tensions arise between the prevention or mitigation of material negative impacts and other business pressures.",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 37. When disclosing whether and how the undertaking considers actual and
                potential impacts on its own workforce in decisions to terminate business
                relationships and whether and how it seeks to address any negative impacts that may
                result from termination, the undertaking may include examples. "
              </p>
            </Typography>
          ),
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "41",
        },
        {
          id: "q-713",
          question:
            "The undertaking shall disclose what resources are allocated to the management of its material impacts, with information that allows users to gain an understanding of how the material impacts are managed.",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "43",
        },
        {
          id: "q-714",
          question:
            "It may take time to understand negative impacts and how the undertaking may be involved with them through its own workforce, as well as to identify appropriate responses and put them into practice). Therefore, the undertaking may disclose: (a) its general and specific approaches to addressing material negative impacts;",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 33 a",
        },
        {
          id: "q-715",
          question:
            "its initiatives aimed at contributing to additional material positive impacts;",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 33 b",
        },
        {
          id: "q-716",
          question: "how far it has progressed in its efforts during the reporting period; ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 33 c",
        },
        {
          id: "q-717",
          question: "its aims for continued improvement.",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 33 d",
        },
        {
          id: "q-718",
          question:
            "Given that material negative impacts affecting its own workforce that have occurred during the reporting period may also be connected with other entities or operations outside its direct control, the undertaking may disclose whether and how it seeks to use its leverage in its business relationships to manage those impacts. This may include using commercial leverage (for example, enforcing contractual requirements with business relationships or implementing incentives), other forms of leverage within the relationship (such as providing training or capacity-building on workers’ rights to entities with which the undertaking has a business relationship) or collaborative leverage with peers or other actors (such as initiatives aimed at responsible recruitment or ensuring workers receive an adequate wage). ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 35",
        },
        {
          id: "q-719",
          question:
            "When the undertaking discloses its participation in an industry or multi-stakeholder initiative as part of its actions to address material negative impacts, the undertaking may disclose how the initiative, and its own involvement, is aiming to address the material impact concerned. It may report under ESRS S1-5 the relevant targets set by the initiative and progress towards them. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 36",
        },
        {
          id: "q-720",
          question:
            "With regard to initiatives or processes whose primary aim is to deliver positive impacts for people in the undertaking’s own workforce that are based on their needs, and with regard to progress in the implementation of such initiatives or processes, the undertaking may disclose: (a) information about whether and how people in its own workforce and workers’ representatives play a role in decisions regarding the design and implementation of these programmes or processes;",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 40 a",
        },
        {
          id: "q-721",
          question:
            "information about the intended or achieved positive outcomes for the undertaking’s own workforce of these programmes or processes.",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 40 b",
        },
        {
          id: "q-722",
          question:
            "The undertaking may explain whether any such initiatives are designed also to support the achievement of one or more Sustainable Development Goals. For example, an undertaking committing to SDG 8 to “promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all” may be actively working towards eliminating forced labour or compulsory labour or supporting higher levels of productivity on activities in developing countries through technological upgrades and training of local labour, which can benefit both the specific people in its own workforce targeted by the actions, and also their local communities. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 41",
        },
        {
          id: "q-723",
          question:
            "If the undertaking has taken measures to mitigate negative impacts on its own workforce that arise from the transition to a greener, climate-neutral economy, such as training and reskilling, employment guarantees, and in the case of downscaling or mass dismissal, measures such as job counselling, coaching, intra-company placements and early retirement plans, the undertaking shall disclose those measures. This includes measures to comply with prevailing regulation. The undertaking may highlight present and/or expected external developments that influence whether dependencies turn into risks. This includes consideration of impacts that may arise from the transition to greener and climate-neutral operations.",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "AR 43",
        },
        {
          id: "q-724",
          question:
            "When disclosing the resources allocated to the management of material impacts, the undertaking may explain which internal functions are involved in managing the impacts and what types of action they take to address negative and advance positive impacts.",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 48",
        },
        {
          id: "q-725",
          question:
            "Where the implementation of a policy requires actions, or a comprehensive action plan, to achieve its objectives, as well as when actions are implemented without a specific policy, the undertaking shall disclose the following information: (a) the list of key actions taken in the reporting year and planned for the future, their expected outcomes and, where relevant, how their implementation contributes to the achievement of policy objectives and targets;",
          tooltip:
            "AR 22. Key actions in the context of this Minimum Disclosure Requirement are those actions that materially contribute to achieving the undertaking’s objectives in addressing material impacts, risks and opportunities. oor reasons of understandability, key actions may be aggregated where appropriate. ",
          condition: "yes",
          module: "Social",
          boldPart: "68 a",
        },
        {
          id: "q-726",
          question:
            "the scope of the key actions (i.e., coverage in terms of activities, upstream and/or downstream value chain, geographies and, where applicable, affected stakeholder groups); ",
          tooltip: "",
          condition: "yes",
          module: "Social",
          boldPart: "68 b",
        },
        {
          id: "q-727",
          question:
            "the time horizons under which the undertaking intends to complete each key action;",
          tooltip: "",
          condition: "yes",
          module: "Social",
          boldPart: "68 c",
        },
        {
          id: "q-728",
          question:
            "if applicable, key actions taken (along with results) to provide for and cooperate in or support the provision of remedy for those harmed by actual material impacts;",
          tooltip: "",
          condition: "yes",
          module: "Social",
          boldPart: "68 d",
        },
        {
          id: "q-729",
          question:
            "if applicable, quantitative and qualitative information regarding the progress of actions or action plans disclosed in prior periods. ",
          tooltip: "",
          condition: "yes",
          module: "Social",
          boldPart: "68 e",
        },
        {
          id: "q-730",
          question:
            "Where the implementation of an action plan requires significant operational expenditures (Opex) and/or capital expenditures (Capex) the undertaking shall: (a) describe the type of current and future financial and other resources allocated to the action plan, including if applicable, the relevant terms of sustainable finance instruments, such as green bonds, social bonds and green loans, the environmental or social objectives, and whether the ability to implement the actions or action plan depends on specific preconditions, e.g., granting of financial support or public policy and market developments; ",
          tooltip:
            "AR 23. Information on resource allocation may be presented in the form of a table and broken down between capital expenditure and operating expenditure, and across the relevant time horizons, and between resources applied in the current reporting year and the planned allocation of resources over specific time horizons.",
          condition: "yes",
          module: "Social",
          boldPart: "69 a",
        },
        {
          id: "q-731",
          question:
            "provide the amount of current financial resources and explain how they relate to the most relevant amounts presented in the financial statements;",
          tooltip: "",
          condition: "yes",
          module: "Social",
          boldPart: "69 b",
        },
        {
          id: "q-732",
          question: "provide the amount of future financial resources. ",
          tooltip: "",
          condition: "yes",
          module: "Social",
          boldPart: "69 c",
        },
        {
          id: "q-733",
          question:
            "If the undertaking cannot disclose the information on policies and actions required under relevant ESRS, because it has not adopted policies and/or actions with reference to the specific sustainability matter concerned, it shall disclose this to be the case, and provide reasons for not having adopted policies and/or actions. ",
          tooltip: "",
          condition: "no",
          module: "Social",
          boldPart: "62",
        },
        {
          id: "q-734",
          question: "The undertaking may disclose a timeframe in which it aims to adopt them. ",
          tooltip: "",
          condition: "no",
          module: "Social",
          boldPart: "62",
        },

        // Add more questions as needed
      ],
    "S1-5 – Targets related to managing material negative impacts, advancing positive impacts, and managing material risks and opportunities":
      [
        {
          id: "q-735",
          question:
            "The summarised description of the targets set to manage its material impacts, risks and opportunities related to the undertaking’s own workforce shall contain the information requirements defined in ESRS 2 MDR-T. ",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 50. Targets related to risks and opportunities may be the same as or distinct
                from targets related to impacts. For example, a target to reach adequate wages for
                non-employees could both reduce impacts on those people and reduce associated risks
                in terms of the quality and reliability of their output. AR 51. The undertaking may
                also distinguish between short-, medium- and long-term targets covering the same
                policy commitment. For example, the undertaking may have a long-term target to
                achieve an 80% reduction in health and safety incidents affecting its delivery
                drivers by 2030 and a near-term target to reduce the overtime hours of delivery
                drivers by x% while maintaining their income by 2024. AR 52. When modifying or
                replacing a target in the reporting period, the undertaking may explain the change
                by cross-referencing it to significant changes in the business model or to broader
                changes in the accepted standard or legislation from which the target is derived to
                provide contextual information as per ESRS 2 BP-2 Disclosures in relation to
                specific circumstances."
              </p>
            </Typography>
          ),
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "46",
        },
        {
          id: "q-736",
          question:
            "The undertaking shall disclose the process for setting the targets, including whether and how the undertaking engaged directly with its own workforce or workers’ representatives in: (a) setting any such targets; (b) tracking the undertaking’s performance against them; (c)  identifying any lessons or improvements as a result of the undertaking’s performance.",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "47",
        },
        {
          id: "q-739",
          question:
            "When disclosing information about targets in accordance with paragraph 46, the undertaking may disclose: (a) the intended outcomes to be achieved in the lives of a certain number of people in its own workforce; ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 49 a",
        },
        {
          id: "q-740",
          question:
            "the stability of the targets over time in terms of definitions and methodologies to enable comparability over time; ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 49 b",
        },
        {
          id: "q-741",
          question:
            "the standards or commitments which the targets are based on (for instance codes of conduct, sourcing policies, global frameworks, or industry codes)",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 49 c",
        },
        {
          id: "q-742",
          question:
            "The undertaking shall disclose the measurable, outcome-oriented and time-bound targets on material sustainability matters it has set to assess progress. oor each target, the disclosure shall include the following information: (a) a description of the relationship of the target to the policy objectives;",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 a",
        },
        {
          id: "q-743",
          question:
            "the defined target level to be achieved, including, where applicable, whether the target is absolute or relative and in which unit it is measured;",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 b",
        },
        {
          id: "q-744",
          question:
            "the scope of the target, including the undertaking’s activities and/or its upstream and/or downstream value chain where applicable and geographical boundaries; ",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 c",
        },
        {
          id: "q-745",
          question: "the baseline value and base year from which progress is measured;",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 d",
        },
        {
          id: "q-746",
          question:
            "the period to which the target applies and if applicable, any milestones or interim targets;",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 e",
        },
        {
          id: "q-747",
          question:
            "the methodologies and significant assumptions used to define targets, including where applicable, the selected scenario, data sources, alignment with national, EU or international policy goals and how the targets consider the wider context of sustainable development and/or local situation in which impacts take place; ",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 f",
        },
        {
          id: "q-748",
          question:
            "whether the undertaking’s targets related to environmental matters are based on conclusive scientific evidence;",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 g",
        },
        {
          id: "q-749",
          question:
            "whether and how stakeholders have been involved in target setting for each material sustainability matter; ",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 h",
        },
        {
          id: "q-750",
          question:
            "any changes in targets and corresponding metrics or underlying measurement methodologies, significant assumptions, limitations, sources and processes to collect data adopted within the defined 17 time horizon. This includes an explanation of the rationale for those changes and their effect on comparability (see Disclosure Requirement BP-2 Disclosures in relation to specific circumstances of this Standard);",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 i",
        },
        {
          id: "q-751",
          question:
            "the performance against its disclosed targets, including information on how the target is monitored and reviewed and the metrics used, whether the progress is in line with what had been initially planned, and an analysis of trends or significant changes in the performance of the undertaking towards achieving the target. ",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 j",
        },
        {
          id: "q-752",
          question:
            ". If the undertaking has not set any measurable outcome-oriented targets: (a) it may disclose whether such targets will be set and the timeframe for setting them, or the reasons why the undertaking does not plan to set such targets;",
          tooltip: "",
          voluntary: true,
          module: "Social",
          condition: "no",
          boldPart: "81 a",
        },
        {
          id: "q-753",
          question:
            "it shall disclose whether it nevertheless tracks the effectiveness of its policies and actions in relation to the material sustainability-related impact, risk and opportunity,",
          tooltip: "",
          module: "Social",
          condition: "no",
          boldPart: "81 b",
        },

        {
          id: "q-754",
          question:
            "it shall disclose whether it nevertheless tracks the effectiveness of its policies and actions in relation to the material sustainability-related impact, risk and opportunity, and if so: i. any processes through which it does so",
          tooltip: "",
          module: "Social",
          condition: "no",
          boldPart: "81 b i",
        },
        {
          id: "q-755",
          question:
            "it shall disclose whether it nevertheless tracks the effectiveness of its policies and actions in relation to the material sustainability-related impact, risk and opportunity, and if so: the defined level of ambition to be achieved and any qualitative or quantitative indicators it uses to evaluate progress, including the base period from which progress is measured. ",
          tooltip: "",
          module: "Social",
          condition: "no",
          boldPart: "81 b ii, 80 d",
        },
        // Add more questions as needed
      ],
    "S1-6 – Characteristics of the undertaking’s employees": [
      {
        id: "q-756",
        question:
          " In addition to the information required by paragraph 40(a)iii of ESRS 2 General Disclosures,",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 57. Disclosing the number of employees at the end of the reporting period provides
              information for that point in time, without capturing fluctuations during the
              reporting period. Disclosing these numbers in averages across the reporting period
              takes into account fluctuations during the reporting period. "
            </p>
          </Typography>
        ),
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        table: (
          <table className="question-table">
            <thead>
              <tr>
                <th colspan="6">
                  Table: S1-6 – Characteristics of the Undertaking’s Employees-Information by
                  country
                </th>
              </tr>
              <tr>
                <th>Informations on employess by gender</th>
                <th>Country-A</th>
                <th>Country-B</th>
                <th>Region-A</th>
                <th>Region-B</th>
                <th>Total</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <th>Total no. of employees</th>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_6-totalEmp-countryA"
                    value={answers2["s1_6-totalEmp-countryA"] || inputValues.s1_6.totalEmp.countryA}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_6-totalEmp-countryB"
                    value={answers2["s1_6-totalEmp-countryB"] || inputValues.s1_6.totalEmp.countryB}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_6-totalEmp-regionA"
                    value={answers2["s1_6-totalEmp-regionA"] || inputValues.s1_6.totalEmp.regionA}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_6-totalEmp-regionB"
                    value={answers2["s1_6-totalEmp-regionB"] || inputValues.s1_6.totalEmp.regionB}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_6-totalEmp-total"
                    value={answers2["s1_6-totalEmp-total"] || inputValues.s1_6.totalEmp.total}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <th>No. of permanent employees</th>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_6-permanent-countryA"
                    value={
                      answers2["s1_6-permanent-countryA"] || inputValues.s1_6.permanent.countryA
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_6-permanent-countryB"
                    value={
                      answers2["s1_6-permanent-countryB"] || inputValues.s1_6.permanent.countryB
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_6-permanent-regionA"
                    value={answers2["s1_6-permanent-regionA"] || inputValues.s1_6.permanent.regionA}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_6-permanent-regionB"
                    value={answers2["s1_6-permanent-regionB"] || inputValues.s1_6.permanent.regionB}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_6-permanent-total"
                    value={answers2["s1_6-permanent-total"] || inputValues.s1_6.permanent.total}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <th>No. of temporary employees</th>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_6-temporary-countryA"
                    value={
                      answers2["s1_6-temporary-countryA"] || inputValues.s1_6.temporary.countryA
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_6-temporary-countryB"
                    value={
                      answers2["s1_6-temporary-countryB"] || inputValues.s1_6.temporary.countryB
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_6-temporary-regionA"
                    value={answers2["s1_6-temporary-regionA"] || inputValues.s1_6.temporary.regionA}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_6-temporary-regionB"
                    value={answers2["s1_6-temporary-regionB"] || inputValues.s1_6.temporary.regionB}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_6-temporary-total"
                    value={answers2["s1_6-temporary-total"] || inputValues.s1_6.temporary.total}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <th>No. of non-guaranteed hours employees</th>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_6-nonGuaranteedHours-countryA"
                    value={
                      answers2["s1_6-nonGuaranteedHours-countryA"] ||
                      inputValues.s1_6.nonGuaranteedHours.countryA
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_6-nonGuaranteedHours-countryB"
                    value={
                      answers2["s1_6-nonGuaranteedHours-countryB"] ||
                      inputValues.s1_6.nonGuaranteedHours.countryB
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_6-nonGuaranteedHours-regionA"
                    value={
                      answers2["s1_6-nonGuaranteedHours-regionA"] ||
                      inputValues.s1_6.nonGuaranteedHours.regionA
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_6-nonGuaranteedHours-regionB"
                    value={
                      answers2["s1_6-nonGuaranteedHours-regionB"] ||
                      inputValues.s1_6.nonGuaranteedHours.regionB
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_6-nonGuaranteedHours-total"
                    value={
                      answers2["s1_6-nonGuaranteedHours-total"] ||
                      inputValues.s1_6.nonGuaranteedHours.total
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <th>No. of full-time employees</th>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_6-fullTime-countryA"
                    value={answers2["s1_6-fullTime-countryA"] || inputValues.s1_6.fullTime.countryA}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_6-fullTime-countryB"
                    value={answers2["s1_6-fullTime-countryB"] || inputValues.s1_6.fullTime.countryB}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_6-fullTime-regionA"
                    value={answers2["s1_6-fullTime-regionA"] || inputValues.s1_6.fullTime.regionA}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_6-fullTime-regionB"
                    value={answers2["s1_6-fullTime-regionB"] || inputValues.s1_6.fullTime.regionB}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_6-fullTime-total"
                    value={answers2["s1_6-fullTime-total"] || inputValues.s1_6.fullTime.total}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <th>No. of part-time employees</th>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_6-partTime-countryA"
                    value={answers2["s1_6-partTime-countryA"] || inputValues.s1_6.partTime.countryA}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_6-partTime-countryB"
                    value={answers2["s1_6-partTime-countryB"] || inputValues.s1_6.partTime.countryB}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_6-partTime-regionA"
                    value={answers2["s1_6-partTime-regionA"] || inputValues.s1_6.partTime.regionA}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_6-partTime-regionB"
                    value={answers2["s1_6-partTime-regionB"] || inputValues.s1_6.partTime.regionB}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_6-partTime-total"
                    value={answers2["s1_6-partTime-total"] || inputValues.s1_6.partTime.total}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        ),
        module: "Social",
        boldPart: "50 a, 50b + 51",
        fun: "6Column1",
      },
      {
        id: "q-757",
        question:
          "The total number of employees who have left the undertaking during the reporting period and the rate of employee turnover in the reporting period.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 59. For the own employee turnover calculation, the undertaking shall calculate the
              aggregate of the number of employees who leave voluntarily or due to dismissal,
              retirement, or death in service. The undertaking shall use this number for the
              numerator of the employee turnover rate and may determine the denominator used to
              calculate this rate and describe its methodology. "
            </p>
          </Typography>
        ),
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        module: "Social",
        boldPart: "50 c",
      },
      {
        id: "q-758",
        question:
          "A description of the methodologies and assumptions used to compile the data, including whether the numbers are reported:i. in head count or full-time equivalent (FTE) (including an explanation of how FTE is defined); and ii. at the end of the reporting period, as an average across the reporting period, or using another methodology.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 60. Where data is not available for detailed information, the undertaking shall
              use an estimation of the employee number or ratios, in accordance with ESRS 1, and
              clearly identify where the use of estimates has taken place."
            </p>
          </Typography>
        ),
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        module: "Social",
        boldPart: "50 d",
      },
      {
        id: "q-761",
        question:
          "Where applicable, a provision of contextual information necessary to understand the data (for example, to understand fluctuations in number of employees during the reporting period)",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 58. Quantitative data, such as the number of temporary or part-time employees, is
              unlikely to be sufficient on its own. For example, a high proportion of temporary or
              part-time employees could indicate a lack of employment security for employees, but it
              could equally signal workplace flexibility when offered as a voluntary choice. For
              this reason, the undertaking is required to disclose contextual information to help
              information users interpret the data. The undertaking can explain the reasons for
              temporary employment. An example of such a reason is the recruitment of employees to
              undertake work on a temporary or seasonal project or event. Another example is the
              standard practice of offering a temporary contract (for example, six months) to new
              employees before an offer of permanent employment is made. The undertaking may also
              explain the reasons for nonguaranteed hours employment. "
            </p>
          </Typography>
        ),
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        module: "Social",
        boldPart: "50 e",
      },
      {
        id: "q-762",
        question:
          "A cross-reference of the information reported under (a) above to the most representative number in the financial statements.",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        module: "Social",
        boldPart: "50 f",
      },
      {
        id: "q-763",
        question:
          " For the information specified in point (b) of paragraph 50, the undertaking may in addition disclose the breakdown by region.",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        table: true,
        voluntary: true,
        module: "Social",
        boldPart: "52",
      },
      {
        id: "q-764",
        question:
          "The undertaking may disclose by head count or full time equivalent (FTE) the following information: (a) full-time employees, and breakdowns by gender and by region;",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        voluntary: true,
        module: "Social",
        boldPart: "52 a",
      },
      {
        id: "q-765",
        question:
          "The undertaking may disclose by head count or full time equivalent (FTE) the following information: (b) part-time employees, and breakdowns by gender and by region",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        voluntary: true,
        module: "Social",
        boldPart: "52 b",
      },

      // Add more questions as needed
    ],
    "S1-7 – Characteristics of non-employee workers in the undertaking’s own workforce": [
      {
        id: "q-766",
        question:
          " The disclosure required by paragraph 53 shall include: (a) a disclosure of the total number of non-employees in the undertaking’s own workforce, i.e., either people with contracts with the undertaking to supply labour (“self-employed people”) or people provided by undertakings primarily engaged in “employment NACE code N78",
        tooltip: (
          <Typography>
            <p className="question-tip">
              For the information specified in point (a) of paragraph 55, the undertaking may
              disclose the most common types of non-employees (for example, self-employed people,
              people provided by undertakings primarily engaged in employment activities, and other
              types relevant to the undertaking), their relationship with the undertaking, and the
              type of work that they perform. <br />
              AR 61. This Disclosure Requirement provides insight into the undertaking’s approach to
              employment, as well as the scope and nature of impacts arising from its employment
              practices. It also provides contextual information that aids an understanding of the
              information reported in other disclosures. This disclosure covers both individual
              contractors supplying labour to the undertaking (“self-employed people”) and workers
              provided by undertakings primarily engaged in “employment activities” (NACE Code N78).
              If all the people performing work for the undertaking are employees and the
              undertaking does not have any people in its workforce who are not employees, this
              Disclosure Requirement is not material for the undertaking; notwithstanding, the
              undertaking may state this fact when disclosing the information required by Disclosure
              Requirement S1-6 as contextual information as this information can be relevant for the
              users of the Sustainability Statement.
              <br />
              "For the information specified in point (a) of paragraph 55, the undertaking may
              disclose the most common types of non-employees (for example, self-employed people,
              people provided by undertakings primarily engaged in employment activities, and other
              types relevant to the undertaking), their relationship with the undertaking, and the
              type of work that they perform.
            </p>
            <p className="question-tip">
              "For the information specified in point (a) of paragraph 55, the undertaking may
              disclose the most common types of non-employees (for example, self-employed people,
              people provided by undertakings primarily engaged in employment activities, and other
              types relevant to the undertaking), their relationship with the undertaking, and the
              type of work that they perform. AR 62. Examples of contractors (self-employed people)
              in the undertaking’s own workforce include: contractors hired by the undertaking to
              perform work that would otherwise be carried out by an employee; contractors hired by
              the undertaking to perform work in a public area (for example, on a road, on the
              street); and contractors hired by the undertaking to deliver the work/service directly
              at the workplace of a client of the organization. Examples of people employed by a
              third party engaged in ‘employment activities’ whose work is under the direction of
              the undertaking include: people who perform the same work that employees carry out,
              such as people who fill in for employees who are temporarily absent (due to illness,
              holiday, parental leave, etc.); people performing regular work at the same site as
              employees; and workers who are dispatched temporarily from another EU member state to
              work for the undertaking (‘posted workers’). Examples of value chain workers (and thus
              of workers not in the undertaking’s own workforce and reported under the scope of ESRS
              S2) include: workers for a supplier contracted by the undertaking who work on the
              supplier’s premises using the supplier’s work methods; workers for a ‘downstream’
              entity which purchases goods or services from the undertaking; and workers of an
              equipment supplier to the undertaking who, at one or more of the undertaking’s
              workplaces, perform regular maintenance on the supplier’s equipment (for example,
              photocopier) as stipulated in the contract between the equipment supplier and the
              undertaking. "
            </p>
          </Typography>
        ),
        phasedIn:
          "The undertaking may omit reporting for all datapoints in this Disclosure Requirement for the first year of preparation of its sustainability statement.",
        table: (
          <table className="question-table">
            <thead>
              <tr>
                <th colspan="6">
                  S1-7 – Characteristics of non-employee workers in the undertaking’s own workforce
                </th>
              </tr>
              <tr>
                <th>Informations on employess by gender</th>
                <th>Male</th>
                <th>Female</th>
                <th>other</th>
                <th>Not disclosed</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Contract employees</th>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_7-contractEmployees-male"
                    value={
                      answers2["s1_7-contractEmployees-male"] ||
                      inputValues.s1_7.contractEmployees.male
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_7-contractEmployees-female"
                    value={
                      answers2["s1_7-contractEmployees-female"] ||
                      inputValues.s1_7.contractEmployees.female
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_7-contractEmployees-other"
                    value={
                      answers2["s1_7-contractEmployees-other"] ||
                      inputValues.s1_7.contractEmployees.other
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_7-contractEmployees-notDisclosed"
                    value={
                      answers2["s1_7-contractEmployees-notDisclosed"] ||
                      inputValues.s1_7.contractEmployees.notDisclosed
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_7-contractEmployees-total"
                    value={
                      answers2["s1_7-contractEmployees-total"] ||
                      inputValues.s1_7.contractEmployees.total
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <th>No. of non-employees</th>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_7-nonEmployees-male"
                    value={answers2["s1_7-nonEmployees-male"] || inputValues.s1_7.nonEmployees.male}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_7-nonEmployees-female"
                    value={
                      answers2["s1_7-nonEmployees-female"] || inputValues.s1_7.nonEmployees.female
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_7-nonEmployees-other"
                    value={
                      answers2["s1_7-nonEmployees-other"] || inputValues.s1_7.nonEmployees.other
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_7-nonEmployees-notDisclosed"
                    value={
                      answers2["s1_7-nonEmployees-notDisclosed"] ||
                      inputValues.s1_7.nonEmployees.notDisclosed
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_7-nonEmployees-total"
                    value={
                      answers2["s1_7-nonEmployees-total"] || inputValues.s1_7.nonEmployees.total
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        ),
        voluntary: true,
        module: "Social",
        boldPart: "55 a, 56",
        fun: "6Column",
      },
      {
        id: "q-767",
        question:
          "An explanation of the methodologies and assumptions used to compile the data, including whether the number of non-employees is reported. i. in headcount or full-time equivalent (FTE) (including a definition of how FTE is defined); and ii. at the end of the reporting period, as an average across the reporting period, or using another methodology.",
        tooltip:
          "For the information specified in point (a) of paragraph 55, the undertaking may disclose the most common types of non-employees (for example, self-employed people, people provided by undertakings primarily engaged in employment activities, and other types relevant to the undertaking), their relationship with the undertaking, and the type of work that they perform. ",
        phasedIn:
          "The undertaking may omit reporting for all datapoints in this Disclosure Requirement for the first year of preparation of its sustainability statement.",
        module: "Social",
        boldPart: "55 b",
      },
      {
        id: "q-770",
        question:
          "where applicable, a provision of contextual information necessary to understand the data (for example, significant fluctuations in the number of non-employees in the undertaking’s own workforce during the reporting period and between the current and the previous reporting period).",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 64. Disclosing the number of people in the undertaking’s own workforce who are not
              employees at the end of the reporting period provides information for that point in
              time without capturing fluctuations during the reporting period. Disclosing this
              number as an average across the reporting period considers fluctuations during the
              reporting period and can provide more insightful and relevant information for users.
              <br />
              AR 65. The information disclosed by the undertaking allows users to understand how the
              number of non-employees in the undertaking’s own workforce varies during the reporting
              period or compared to the previous reporting period (i.e., whether the numbers have
              increased or decreased). The undertaking may also disclose the reasons for the
              fluctuations. For example, an increase in the number of non-employees during the
              reporting period could be due to a seasonal event. Conversely, a decrease in the
              number of non-employees compared to the previous reporting period could be due to the
              completion of a temporary project. If the undertaking discloses fluctuations, it shall
              also explain the criteria used to determine which 29 fluctuations it discloses. If
              there are no significant fluctuations in the number of non-employees during the
              reporting period or between the current and previous reporting period, the undertaking
              may disclose this information. "
            </p>
          </Typography>
        ),

        phasedIn:
          "The undertaking may omit reporting for all datapoints in this Disclosure Requirement for the first year of preparation of its sustainability statement.",
        module: "Social",
        boldPart: "55 c",
      },
      {
        id: "q-771",
        question:
          "Where data is not available, the undertaking shall estimate the number and state that it has done so. When the undertaking performs estimates, it shall describe the basis of preparation of this estimation. ",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 63. If the undertaking cannot report exact figures, it shall use estimates
              according to the provisions in ESRS 1 to disclose the number of people in its own
              workforce who are not employees to the nearest ten or, where the number of people in
              its own workforce who are not employees is greater than 1,000, to the nearest 100, and
              explain this. In addition, it shall clearly identify the information that derives from
              actual data and estimates. "
            </p>
          </Typography>
        ),
        module: "Social",
        boldPart: "57",
        phasedIn:
          "The undertaking may omit reporting for all datapoints in this Disclosure Requirement for the first year of preparation of its sustainability statement.",
      },

      // Add more questions as needed
    ],
    "S1-8 – Collective bargaining coverage and social dialogue": [
      {
        id: "q-772",
        question:
          " The undertaking shall disclose: the percentage of its total employees covered by collective bargaining agreements in the EEA and outside EEA",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 66. The percentage of employees covered by collective bargaining agreements is
              calculated using the following formula:
              <br />
            </p>
            <p class="formula">
              (Number of employees covered by collective bargaining agreements &#x00F7; Number of
              employees) &#x00D7; 100
            </p>
          </Typography>
        ),
        phasedIn:
          "The undertaking may omit this Disclosure Requirement with regard to its own employees in non-EEA countries for the first year of preparation of its sustainability statement.",
        table: (
          <table className="question-table">
            <thead>
              <tr>
                <th>Coverage Rate</th>
                <th>Collective Bargaining Coverage Employees – EEA</th>
                <th>Collective Bargaining Coverage Employees – Non-EEA</th>
                <th>Social dialogue-Workplace representation-EEA only</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>0-19%</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_8-rate0_19-EEA"
                    value={answers2["s1_8-rate0_19-EEA"] || inputValues.s1_8.rate0_19.EEA}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_8-rate0_19-nonEEA"
                    value={answers2["s1_8-rate0_19-nonEEA"] || inputValues.s1_8.rate0_19.nonEEA}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_8-rate0_19-socialDialogueEEA"
                    value={
                      answers2["s1_8-rate0_19-socialDialogueEEA"] ||
                      inputValues.s1_8.rate0_19.socialDialogueEEA
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>20-39%</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_8-rate20_39-EEA"
                    value={answers2["s1_8-rate20_39-EEA"] || inputValues.s1_8.rate20_39.EEA}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_8-rate20_39-nonEEA"
                    value={answers2["s1_8-rate20_39-nonEEA"] || inputValues.s1_8.rate20_39.nonEEA}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_8-rate20_39-socialDialogueEEA"
                    value={
                      answers2["s1_8-rate20_39-socialDialogueEEA"] ||
                      inputValues.s1_8.rate20_39.socialDialogueEEA
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>40-59%</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_8-rate40_59-EEA"
                    value={answers2["s1_8-rate40_59-EEA"] || inputValues.s1_8.rate40_59.EEA}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_8-rate40_59-nonEEA"
                    value={answers2["s1_8-rate40_59-nonEEA"] || inputValues.s1_8.rate40_59.nonEEA}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_8-rate40_59-socialDialogueEEA"
                    value={
                      answers2["s1_8-rate40_59-socialDialogueEEA"] ||
                      inputValues.s1_8.rate40_59.socialDialogueEEA
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>60-79%</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_8-rate60_79-EEA"
                    value={answers2["s1_8-rate60_79-EEA"] || inputValues.s1_8.rate60_79.EEA}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_8-rate60_79-nonEEA"
                    value={answers2["s1_8-rate60_79-nonEEA"] || inputValues.s1_8.rate60_79.nonEEA}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_8-rate60_79-socialDialogueEEA"
                    value={
                      answers2["s1_8-rate60_79-socialDialogueEEA"] ||
                      inputValues.s1_8.rate60_79.socialDialogueEEA
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>80-100%</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_8-rate80_100-EEA"
                    value={answers2["s1_8-rate80_100-EEA"] || inputValues.s1_8.rate80_100.EEA}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_8-rate80_100-nonEEA"
                    value={answers2["s1_8-rate80_100-nonEEA"] || inputValues.s1_8.rate80_100.nonEEA}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_8-rate80_100-socialDialogueEEA"
                    value={
                      answers2["s1_8-rate80_100-socialDialogueEEA"] ||
                      inputValues.s1_8.rate80_100.socialDialogueEEA
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        ),
        module: "Social",
        boldPart: "60 a, 60 b, 60 c",
        fun: "4Column",
      },
      {
        id: "q-773",
        question:
          "For employees not covered by collective bargaining agreements, the undertaking may disclose whether it determines their working conditions and terms of employment based on collective bargaining agreements that cover its other employees or based on collective bargaining agreements from other undertakings. ",
        tooltip: "",
        module: "Social",
        boldPart: "61",
        voluntary: true,
        phasedIn:
          "The undertaking may omit this Disclosure Requirement with regard to its own employees in non-EEA countries for the first year of preparation of its sustainability statement.",
      },
      {
        id: "q-774",
        question:
          "The undertaking may disclose the extent to which the working conditions and terms of employment of non-employees in its own workforce are determined or influenced by collective bargaining agreements, including an estimate of the coverage rate.",
        tooltip: "",
        module: "Social",
        boldPart: "62",
        voluntary: true,
        phasedIn:
          "The undertaking may omit this Disclosure Requirement with regard to its own employees in non-EEA countries for the first year of preparation of its sustainability statement.",
      },
      {
        id: "q-775",
        question:
          "The undertaking shall disclose the following information in relation to social dialogue: (a) the global percentage of employees covered by workers’ representatives, reported at the country level for each EEA country in which the undertaking has significant employment; ",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 69. For calculating the information required by paragraph 63(a), the undertaking
              shall identify in which European Economic Area (EEA) countries it has significant
              employment (i.e., at least 50 employees representing at least 10% of its total
              employees). For these countries it shall report the percentage of employees in that
              country which are employed in establishments in which employees are represented by
              workers’ representatives. Establishment is defined as any place of operations where
              the undertaking carries out a non-transitory economic activity with human means and
              goods. Examples include: a factory, a branch of a retail chain, or an undertaking’s
              headquarters. For countries in which there is only one establishment the percentage
              reported shall be either 100% or 0%.
            </p>
            <p class="formula">
              (𝑁𝑢𝑚𝑏𝑒𝑟 𝑜𝑓 𝑒𝑚𝑝𝑙𝑜𝑦𝑒𝑒𝑠 𝑤𝑜𝑟𝑘𝑖𝑛𝑔 𝑖𝑛 𝑒𝑠𝑡𝑎𝑏𝑙𝑖𝑠ℎ𝑚𝑒𝑛𝑡𝑠 𝑤𝑖𝑡ℎ 𝑤𝑜𝑟𝑘𝑒𝑟𝑠′ 𝑟𝑒𝑝𝑟𝑒𝑠𝑒𝑛𝑡𝑎𝑡𝑖𝑣𝑒𝑠 &#x00F7;
              Number of employees) &#x00D7; 100
            </p>
          </Typography>
        ),
        module: "Social",
        boldPart: "63 a",
        phasedIn:
          "The undertaking may omit this Disclosure Requirement with regard to its own employees in non-EEA countries for the first year of preparation of its sustainability statement.",
      },
      {
        id: "q-776",
        question:
          "the existence of any agreement with its employees for representation by a European Works Council (EWC), a Societas Europaea (SE) Works Council, or a Societas Cooperativa Europaea (SCE) Works Council. ",
        tooltip: "",
        module: "Social",
        boldPart: "63 b",
        phasedIn:
          "The undertaking may omit this Disclosure Requirement with regard to its own employees in non-EEA countries for the first year of preparation of its sustainability statement.",
      },

      // Add more questions as needed
    ],
    "S1-9 – Diversity metrics": [
      {
        id: "q-777",
        question:
          "The undertaking shall disclose the gender distribution in number and percentage at top management level",
        tooltip: "",
        module: "Social",
        boldPart: "66 a",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
      },
      {
        id: "q-778",
        question:
          "The distribution of employees by age group: under 30 years old; 30-50 years old; over 50 years old.",
        tooltip: "",
        module: "Social",
        boldPart: "66 b",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
      },
      {
        id: "q-779",
        question:
          "In preparing the disclosure on gender at top management, the undertaking shall use the definition of top management as one and two levels below the administrative and supervisory bodies unless this concept has already been defined with the undertaking’s operations and differs from the previous description. If this is the case, the undertaking can use its own definition for top management and disclose that fact and its own definition. ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        module: "Social",
        boldPart: "AR 71",
      },

      // Add more questions as needed
    ],
    "S1-10 – Adequate wages": [
      {
        id: "q-780",
        question:
          "The undertaking shall disclose whether all its employees are paid an adequate wage, in line with applicable benchmarks. If so, stating this will be sufficient to fulfil this disclosure requirement and no further information is needed.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 72. The lowest wage shall be calculated for the lowest pay category, excluding
              interns and apprentices. This is to be based on the basic wage plus any fixed
              additional payments that are guaranteed to all employees. The lowest wage shall be
              considered separately for each country in which the undertaking has operations, except
              outside the EEA when the relevant adequate or minimum wage is defined at a sub
              national level.
            </p>
            <p className="question-tip">
              AR 73. The adequate wage benchmark used for comparison with the lowest wage shall not
              be lower than:
            </p>
            <ol className="question-tip" type="a">
              <li>
                in the EEA: the minimum wage set in accordance with Directive (EU) 2022/2041 of the
                European Parliament and of the Council 15 on adequate minimum wages in the European
                Union. In the period until Directive (EU) 2022/2041 enters into application, where
                there is no applicable minimum wage determined by legislation or collective
                bargaining in an EEA country, the undertaking shall use an adequate wage benchmark
                that is either not lower than the minimum wage in a neighboring country with a
                similar socio-economic status or not lower than a commonly-referenced international
                norm such as 60% of the country's median wage and 50% of the gross average wage.
              </li>
              <li>
                outside of the EEA:
                <ol className="question-tip" type="i">
                  <li>
                    the wage level established in any existing international, national or
                    sub-national legislation, official norms or collective agreements, based on an
                    assessment of a wage level needed for a decent standard of living;
                  </li>
                  <li>
                    if none of the instruments identified in (i) exist, any national or sub-national
                    minimum wage established by legislation or collective bargaining; or
                  </li>
                  <li>
                    if none of the instruments identified in (i) or (ii) exist, any benchmark that
                    meets the criteria set out by the Sustainable Trade Initiative (IDH) (‘Roadmap
                    on Living Wages - A Platform to Secure Living Wages in Supply Chains’),
                    including applicable benchmarks aligned with the Anker methodology, or provided
                    by the Wage Indicator Foundation or Fair Wage Network, provided the primacy of
                    collective bargaining for the establishment of terms and conditions of
                    employment is ensured.
                  </li>
                </ol>
              </li>
            </ol>
            <p className="question-tip">
              AR 74. Directive (EU) 2022/2041 on adequate minimum wages in the European Union
              references both indicative reference values commonly used at international level such
              as 60 % of the gross median wage and 50 % of the gross average wage, and/or indicative
              reference values used at national level. Data for the indicative values of 60% of the
              national median gross wage or 50% of the national average gross wage can be obtained
              from the European Labour Force Survey.
            </p>
          </Typography>
        ),
        module: "Social",
        boldPart: "69",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
      },
      {
        id: "q-781",
        question:
          "If not all its employees are paid an adequate wage in line with applicable benchmarks, the undertaking shall disclose the countries where employees earn below the applicable adequate wage benchmark and the percentage of employees that earn below the applicable adequate wage benchmark for each of these countries.",
        tooltip: "",
        module: "Social",
        boldPart: "70",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
      },
      {
        id: "q-782",
        question:
          "The undertaking may also disclose the information specified in this disclosure requirement with regard to non-employees in its workforce.",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        module: "Social",
        boldPart: "71",
        voluntary: true,
      },
      // Add more questions as needed
    ],
    "S1-11 – Social protection": [
      {
        id: "q-783",
        question:
          "The undertaking shall disclose whether all its employees are covered by social protection, through public programs or through benefits offered by the undertaking, against loss of income due to any of the following major life events: If so, stating this is sufficient to fulfil this disclosure requirement and no further information is needed. a)Sickness b)Unemployment starting from when the own worker is working for the undertaking. c)Employment injury and acquired disability. d)Parental leave. e)Retirement.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "If so, stating this is sufficient to fulfil this disclosure requirement and no
              further information is needed."
            </p>
          </Typography>
        ),
        phasedIn:
          "The undertaking may omit the information prescribed by ESRS S1-11 for the first year of preparation of its sustainability statement",
        module: "Social",
        boldPart: "74",
      },

      {
        id: "q-788",
        question:
          "If not all of its employees are covered by social protection in accordance with paragraph 72, the undertaking shall in addition disclose the countries where employees do not have social protection with regard to one or more of the types of events listed in paragraph 72 and for each of those countries the types of employees who do not have social protection with regard to each applicable major life event.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "The undertaking may also disclose the information specified in this disclosure
              requirement with regard to non-employees in its workforce. AR 75. Social protection
              refers to all the measures that provide access to health care and income support in
              cases of challenging life events such as the loss of a job, being sick and in need of
              medical care, giving birth and raising a child, or retiring and in need of a pension.
              "
            </p>
          </Typography>
        ),
        module: "Social",
        boldPart: "75",
        phasedIn:
          "The undertaking may omit the information prescribed by ESRS S1-11 for the first year of preparation of its sustainability statement",
      },
      // Add more questions as needed
    ],
    "S1-12– Persons with disabilities": [
      {
        id: "q-789",
        question:
          "The undertaking shall disclose the percentage of persons with disabilities amongst its employees subject to legal restrictions on the collection of data.",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        module: "Social",
        boldPart: "79",
      },
      {
        id: "q-790",
        question:
          " The undertaking may disclose the percentage of employees with disabilities with a breakdown by gender.",
        tooltip: "",
        phasedIn:
          "The undertaking may omit the information prescribed by ESRS S1-12 for the first year of preparation of its sustainability statement.",
        table: (
          <table className="question-table">
            <thead>
              <tr>
                <th>Employment of persons with disabilitie</th>
                <th>Male</th>
                <th>Female</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>The percentage of persons with disabilities</th>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_12-disabilityPercent-male"
                    value={
                      answers2["s1_12-disabilityPercent-male"] ||
                      inputValues.s1_12.disabilityPercent.male
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_12-disabilityPercent-female"
                    value={
                      answers2["s1_12-disabilityPercent-female"] ||
                      inputValues.s1_12.disabilityPercent.female
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        ),
        voluntary: true,
        module: "Social",
        boldPart: "80",
        fun: "S1-12",
      },
      {
        id: "q-791",
        question:
          "When disclosing the information required in paragraph 77 regarding persons with disabilities, the undertaking shall provide any contextual information necessary to understand the data and how the data has been compiled (methodology). For example, information about the impact of different legal definitions of persons with disabilities in the different countries in which the undertaking has operations. ",
        tooltip: "",
        phasedIn:
          "The undertaking may omit the information prescribed by ESRS S1-12 for the first year of preparation of its sustainability statement.",
        module: "Social",
        boldPart: "AR 76",
      },

      // Add more questions as needed
    ],
    "S1-13 – Training and skills development metrics": [
      {
        id: "q-792",
        question: " Table: S1-13 – Training and skills development metrics",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 77. A regular performance review is defined as a review based on criteria known to
              the employee and his or her superior undertaken with the knowledge of the employee at
              least once per year. The review can include an evaluation by the worker’s direct
              superior, peers, or a wider range of employees. The review can also involve the human
              resources department. In order to disclose the information required by paragraph 83
              (a), the undertaking shall use the employee headcount figures provided in Disclosure
              Requirement ESRS S1-6 in the denominator to calculate the:
            </p>
            <ol className="question-tip" type="a">
              <li>number/proportion of performance reviews per employee; and</li>
              <li>
                number of reviews in proportion to the agreed number of reviews by the management.
              </li>
            </ol>
            <p className="question-tip">
              "AR 78. To disclose the average required per paragraph 83 (b), the undertaking shall
              perform the following calculation: total number of training hours offered to and
              completed by employees per gender category divided by the total number of employees
              per gender category. For the total training average and the average by gender, the
              head count figures for total employment and employment by gender reported in
              Disclosure Requirement ESRS S1-6 shall be used."
            </p>
            <p className="question-tip">
              "AR 79. Employee categories are a breakdown of employees by level (such as senior
              management, middle management) or function (such as technical, administrative,
              production). This information is derived from the undertaking’s own human resources
              system. In categorising the workforce, the undertaking shall define reasonable and
              meaningful employee categories which enable users of the information to understand
              different performance measures 32 between the categories. The undertaking may present
              a category for executive and non-executive employees. "
            </p>
          </Typography>
        ),
        phasedIn:
          "The undertaking may omit the information prescribed by ESRS S1-13 for the first year of preparation of its sustainability statement",
        table: (
          <table className="question-table">
            <thead>
              <tr>
                <th>Career development reviews</th>
                <th>Male</th>
                <th>Female</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Permanent employees</th>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_13-permanentEmployees-male"
                    value={
                      answers2["s1_13-permanentEmployees-male"] ||
                      inputValues.s1_13.permanentEmployees.male
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_13-permanentEmployees-female"
                    value={
                      answers2["s1_13-permanentEmployees-female"] ||
                      inputValues.s1_13.permanentEmployees.female
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <th>Temporary employees</th>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_13-temporaryEmployees-male"
                    value={
                      answers2["s1_13-temporaryEmployees-male"] ||
                      inputValues.s1_13.temporaryEmployees.male
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_13-temporaryEmployees-female"
                    value={
                      answers2["s1_13-temporaryEmployees-female"] ||
                      inputValues.s1_13.temporaryEmployees.female
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <th>Non guaranteed hours employees</th>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_13-nonGuaranteedHoursEmployees-male"
                    value={
                      answers2["s1_13-nonGuaranteedHoursEmployees-male"] ||
                      inputValues.s1_13.nonGuaranteedHoursEmployees.male
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_13-nonGuaranteedHoursEmployees-female"
                    value={
                      answers2["s1_13-nonGuaranteedHoursEmployees-female"] ||
                      inputValues.s1_13.nonGuaranteedHoursEmployees.female
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <th>Full-time employees</th>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_13-fullTimeEmployees-male"
                    value={
                      answers2["s1_13-fullTimeEmployees-male"] ||
                      inputValues.s1_13.fullTimeEmployees.male
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_13-fullTimeEmployees-female"
                    value={
                      answers2["s1_13-fullTimeEmployees-female"] ||
                      inputValues.s1_13.fullTimeEmployees.female
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <th>Part-time employees</th>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_13-partTimeEmployees-male"
                    value={
                      answers2["s1_13-partTimeEmployees-male"] ||
                      inputValues.s1_13.partTimeEmployees.male
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_13-partTimeEmployees-female"
                    value={
                      answers2["s1_13-partTimeEmployees-female"] ||
                      inputValues.s1_13.partTimeEmployees.female
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <th>Non employees</th>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_13-nonEmployees-male"
                    value={
                      answers2["s1_13-nonEmployees-male"] || inputValues.s1_13.nonEmployees.male
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_13-nonEmployees-female"
                    value={
                      answers2["s1_13-nonEmployees-female"] || inputValues.s1_13.nonEmployees.female
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        ),
        module: "Social",
        boldPart: "83-85",
        fun: "3Column",
      },
      // Add more questions as needed
    ],
    "S1-14 – Health and safety metrics": [
      {
        id: "q-793",
        question:
          "The disclosure required by paragraph 86 shall include the following information, where applicable broken down between employees and non-employees in the undertaking’s own workforce a). The percentage of people in its own workforce who are covered by the undertaking’s health and safety management system based on legal requirements and/or recognised standards or guidelines.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 80. In relation to paragraph 88 (a), the percentage of its own workforce who are
              covered by the undertaking’s health and safety management system shall be disclosed on
              a head count basis rather than a full-time equivalent basis. "
            </p>
          </Typography>
        ),
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        module: "Social",
        boldPart: "88 a",
      },
      {
        id: "q-794",
        question:
          "The number of fatalities as a result of work-related injuries and work-related ill health.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 89. In computing the rate of work-related injuries, the undertaking shall divide
              the respective number of cases by the number of total hours worked by people in its
              own workforce and multiplied by 1,000,000. Thereby, these rates represent the number
              of respective cases per one million hours worked. A rate based on 1,000,000 hours
              worked indicates the number of work-related injuries per 500 full time people in the
              workforce over a 1-year timeframe. For comparability purposes a rate based on
              1,000,000 hours worked shall be used also for undertakings with less than 500 people
              in the workforce.
              <br />
              AR 90. If the undertaking cannot directly calculate the number of hours worked, it may
              estimate this on the basis of normal or standard hours of work, taking into account
              entitlements to periods of paid leave of absence from work (for example, paid
              vacations, paid sick leave, public holidays) and explain this in its disclosures.
              <br />
              AR 91. An undertaking shall include fatalities as a result of work-related injury in
              the calculation of the number and rate of recordable work-related injuries. "
            </p>
          </Typography>
        ),
        phasedIn:
          "The undertaking may omit the data points on cases of work-related ill-health and on number of days lost to injuries accidents fatalities and work-related ill health for the first year of preparation of its sustainability statement.",
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 2 Table #3 of Annex I{" "}
            </p>

            <p className="question-tip">
              <strong>Benchmark Regulation reference:</strong> Delegated Regulation (EU) 2020/1816,
              Annex II{" "}
            </p>
          </Typography>
        ),

        module: "Social",
        boldPart: "88 b",
      },
      {
        id: "q-795",
        question:
          "The information for shall also be reported for other workers working on the undertaking’s sites, such as value chain workers if they are working on the undertaking’s sites.",
        tooltip: "",
        phasedIn:
          "The undertaking may omit the data points on cases of work-related ill-health and on number of days lost to injuries accidents fatalities and work-related ill health for the first year of preparation of its sustainability statement. ",

        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 2 Table #3 of Annex I{" "}
            </p>

            <p className="question-tip">
              <strong>Benchmark Regulation reference:</strong> Delegated Regulation (EU) 2020/1816,
              Annex II{" "}
            </p>
          </Typography>
        ),
        module: "Social",
        boldPart: "88 c",
      },
      {
        id: "q-796",
        question:
          "With regard to the undertaking’s employees, the number of cases of recordable work-related ill health, subject to legal restrictions on the collection of data.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              The undertaking may also disclose the information specified in points (d) and (e) of
              paragraph 88 with regard to non-employees.
            </p>
          </Typography>
        ),
        module: "Social",
        boldPart: "88 d",
        phasedIn:
          "The undertaking may omit the data points on cases of work-related ill-health and on number of days lost to injuries accidents fatalities and work-related ill health for the first year of preparation of its sustainability statement. ",
      },
      {
        id: "q-797",
        question:
          "With regard to the undertaking’s employees, the number of days lost to work-related injuries and fatalities from work-related accidents, work-related ill health and fatalities from ill health.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "The undertaking may also disclose the information specified in points (d) and (e) of
              paragraph 88 with regard to nonemployees AR 89. In computing the rate of work-related
              injuries, the undertaking shall divide the respective number of cases by the number of
              total hours worked by people in its own workforce and multiplied by 1,000,000.
              Thereby, these rates represent the number of respective cases per one million hours
              worked. A rate based on 1,000,000 hours worked indicates the number of work-related
              injuries per 500 full time people in the workforce over a 1-year timeframe. For
              comparability purposes a rate based on 1,000,000 hours worked shall be used also for
              undertakings with less than 500 people in the workforce.
              <br />
              AR 90. If the undertaking cannot directly calculate the number of hours worked, it may
              estimate this on the basis of normal or standard hours of work, taking into account
              entitlements to periods of paid leave of absence from work (for example, paid
              vacations, paid sick leave, public holidays) and explain this in its disclosures.
              <br />
              AR 91. An undertaking shall include fatalities as a result of work-related injury in
              the calculation of the number and rate of recordable work-related injuries. AR 95. The
              undertaking shall count the number of days lost such that the first full day and last
              day of absence shall be included. Calendar days should be considered for the
              calculation, thus days on which the affected individual is not scheduled for work (for
              example, weekends, public holidays) will count as lost days. ."
            </p>
          </Typography>
        ),
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 3 Table #3 of Annex I
            </p>
          </Typography>
        ),
        module: "Social",
        boldPart: "88 e",
        phasedIn:
          "The undertaking may omit the data points on cases of work-related ill-health and on number of days lost to injuries accidents fatalities and work-related ill health for the first year of preparation of its sustainability statement. ",
      },
      {
        id: "q-798",
        question:
          "In addition, the undertaking may include the following additional information on the health and safety coverage: the percentage of its own workers covered by a health and safety management system which is based on legal requirements and/or recognised standards or guidelines and which has been internally audited and/or audited or certified by an external party.",
        tooltip: "",
        voluntary: true,
        phasedIn:
          "The undertaking may omit the data points on cases of work-related ill-health and on number of days lost to injuries accidents fatalities and work-related ill health for the first year of preparation of its sustainability statement. ",
        module: "Social",
        boldPart: "90",
      },
      {
        id: "q-799",
        question:
          "With regard to paragraph 90, when the undertaking’s health and safety management system, or certain parts thereof, has been subject to an internal audit or external certification, the undertaking may state this fact, or absence thereof, and the underlying standards for such audits/certifications, as applicable. ",
        tooltip: "",
        phasedIn:
          "The undertaking may omit the data points on cases of work-related ill-health and on number of days lost to injuries accidents fatalities and work-related ill health for the first year of preparation of its sustainability statement. ",
        voluntary: true,
        module: "Social",
        boldPart: "AR 81",
      },
      {
        id: "q-800",
        question:
          "Fatalities may be reported separately for those resulting from work-related injuries and those resulting from work-related ill health.",
        tooltip: "",
        phasedIn:
          "The undertaking may omit the data points on cases of work-related ill-health and on number of days lost to injuries accidents fatalities and work-related ill health for the first year of preparation of its sustainability statement. ",
        voluntary: true,
        module: "Social",
        boldPart: "AR 82",
      },
      {
        id: "q-801",
        question:
          "The cases to be disclosed in paragraph 88(d) relate to cases of work-related ill health notified to the undertaking or identified by the undertaking through medical surveillance, during the reporting period. The undertaking might be notified of cases of work-related ill health through reports by affected people, compensation agencies, or healthcare professionals. The disclosure may include cases of work-related ill health that were detected during the reporting period among people who were formerly in the undertaking’s workforce. ",
        tooltip: "",
        phasedIn:
          "The undertaking may omit the data points on cases of work-related ill-health and on number of days lost to injuries accidents fatalities and work-related ill health for the first year of preparation of its sustainability statement. ",
        voluntary: true,
        module: "Social",
        boldPart: "AR 94",
      },

      // Add more questions as needed
    ],
    "S1-15 – Work-life balance metrics": [
      {
        id: "q-802",
        question:
          " The percentage of entitled employees that took family-related leave, and a breakdown by gender.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              If all of the undertaking’s employees are entitled to family-related leave through
              social policy and/or collective bargaining agreements, it is sufficient to disclose
              this in order to meet the requirement of paragraph 93a AR 96. Family-related leave
              include maternity leave, paternity leave, parental leave, and carers’ leave that is
              available under national law or collective agreements. For the purpose of this
              Standard, these concepts are defined as:
            </p>
            <ol className="question-tip" type="a">
              <li>
                maternity leave (also called pregnancy leave): employment-protected leave of absence
                for employed women directly around the time of childbirth (or, in some countries,
                adoption);
              </li>
              <li>
                paternity leave: leave from work for fathers or, where and in so far as recognised
                by national law, for equivalent second parents, on the occasion of the birth or
                adoption of a child for the purposes of providing care;
              </li>
              <li>
                parental leave: leave from work for parents on the grounds of the birth or adoption
                of a child to take care of that child, as defined by each Member State; 34
              </li>
              <li>
                carers’ leave from work: leave for workers to provide personal care or support to a
                relative, or a person who lives in the same household, in need of significant care
                or support for a serious medical reason, as defined by each Member State.
              </li>
            </ol>
            <p className="question-tip">
              AR 97. With regard to paragraph 93 (a), employees entitled to family-related leave are
              those who are covered by regulations, organisational policies, agreements, contracts
              or collective bargaining agreements that contain family-related leave entitlements and
              have reported their entitlement to the undertaking or the undertaking is aware of the
              entitlement.
            </p>
          </Typography>
        ),
        phasedIn:
          "The undertaking may omit the information prescribed by ESRS S1-15 for the first year of preparation of its sustainability statement.",
        table: (
          <table className="question-table">
            <thead>
              <tr>
                <th>Work-Life Balance indicators</th>
                <th>Male</th>
                <th>Female</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>% of employees entitled to take family-related leaves</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_15-familyRelatedLeaves-male"
                    value={
                      answers2["s1_15-familyRelatedLeaves-male"] ||
                      inputValues.s1_15.familyRelatedLeaves.male
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_15-familyRelatedLeaves-female"
                    value={
                      answers2["s1_15-familyRelatedLeaves-female"] ||
                      inputValues.s1_15.familyRelatedLeaves.female
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>% of entitled employees that took family-related leaves</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_15-takenFamilyRelatedLeaves-male"
                    value={
                      answers2["s1_15-takenFamilyRelatedLeaves-male"] ||
                      inputValues.s1_15.takenFamilyRelatedLeaves.male
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_15-takenFamilyRelatedLeaves-female"
                    value={
                      answers2["s1_15-takenFamilyRelatedLeaves-female"] ||
                      inputValues.s1_15.takenFamilyRelatedLeaves.female
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Retention rates of employees that took maternity, paternity and/or parental leave
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_15-retentionRates-male"
                    value={
                      answers2["s1_15-retentionRates-male"] || inputValues.s1_15.retentionRates.male
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_15-retentionRates-female"
                    value={
                      answers2["s1_15-retentionRates-female"] ||
                      inputValues.s1_15.retentionRates.female
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        ),
        module: "Social",
        boldPart: "93, 94",
        fun: "3Column",
      },
      // Add more questions as needed
    ],
    "S1-16 – Compensation metrics (pay gap and total compensation)": [
      {
        id: "q-803",
        question:
          " The gender pay gap, defined as the difference of average pay levels between female and male employees, expressed as percentage of the average pay level of male employees.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              The undertaking may disclose a breakdown of the gender pay gap as defined in paragraph
              97(a) by employee category and/or by country/segment. The undertaking may also
              disclose the gender pay gap between employees by categories of employees broken down
              by ordinary basic salary and complementary or variable components. AR 98. When
              compiling the information required under paragraph 97 (a) for the gap in pay between
              its female and male employees (also known as the “gender pay gap”) the undertaking
              shall use the following methodology:
            </p>
            <ol className="question-tip" type="a">
              <li>include all employees’ gross hourly pay level; and </li>
              <li>
                apply the following formula to calculate the gender pay gap:
                <br />
                (𝐴𝑣𝑒𝑟𝑎𝑔𝑒 𝑔𝑟𝑜𝑠𝑠 ℎ𝑜𝑢𝑟𝑙𝑦 pay level 𝑜𝑓 𝑚𝑎𝑙𝑒 𝑒𝑚𝑝𝑙𝑜𝑦𝑒𝑒𝑠 − 𝑎𝑣𝑒𝑟𝑎𝑔𝑒 𝑔𝑟𝑜𝑠 ℎ𝑜𝑢𝑟𝑙𝑦 pay level 𝑜𝑓
                𝑓𝑒𝑚𝑎𝑙𝑒 𝑒𝑚𝑝𝑙𝑜𝑦𝑒𝑒𝑠) / 𝑥 100 𝐴𝑣𝑒𝑟𝑎𝑔𝑒 𝑔𝑟𝑜𝑠𝑠 ℎ𝑜𝑢𝑟𝑙𝑦 pay level 𝑜𝑓 𝑚𝑎𝑙𝑒 𝑒𝑚𝑝𝑙𝑜𝑦𝑒𝑒𝑠
              </li>
            </ol>
            <p className="question-tip">
              AR 99. When disclosing the information required under paragraph 97 (a), the
              undertaking shall provide any contextual information necessary to understand the data
              and how the data has been compiled (methodology). Information regarding how objective
              factors such as type of work and country of employment influence the gender pay gap
              may be reported. AR 100.The measure of the undertaking’s gender pay gap shall be
              reported for the current reporting period and, if reported in previous sustainability
              reports, for the previous two reporting periods.
            </p>
          </Typography>
        ),
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        table: (
          <table className="question-table">
            <thead>
              <tr>
                <th>Pay gap between women and men</th>
                <th>Male</th>
                <th>Female</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Average gross hourly earnings</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_16-averageGrossHourlyEarnings-male"
                    value={
                      answers2["s1_16-averageGrossHourlyEarnings-male"] ||
                      inputValues.s1_16.averageGrossHourlyEarnings.male
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="s1_16-averageGrossHourlyEarnings-female"
                    value={
                      answers2["s1_16-averageGrossHourlyEarnings-female"] ||
                      inputValues.s1_16.averageGrossHourlyEarnings.female
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        ),
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 12 Table #1 of Annex I
            </p>

            <p className="question-tip">
              <strong>Benchmark Regulation reference:</strong> Delegated Regulation (EU) 2020/1816,
              Annex II
            </p>
          </Typography>
        ),
        module: "Social",
        boldPart: "97 a",
        fun: "3Column97",
      },
      {
        id: "q-804",
        question:
          "(b) The annual total remuneration ratio of the highest paid individual to the median annual total remuneration for all employees (excluding the highest-paid individual. (c) Where applicable, any contextual information necessary to understand the data and how the data has been compiled and other changes to the underlying data that are to be considered.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              In relation to paragraph 97 (b), the undertaking may report this figure adjusted for
              purchasing power differences between countries, in which case it shall report the
              methodology used for the calculation.
            </p>
            <p className="question-tip">
              AR 101.When compiling the information required by paragraph 97 (b), the undertaking
              shall:
            </p>
            <ol className="question-tip" type="a">
              <li>include all employees; </li>
              <li>
                consider, depending on the undertaking’s remuneration policies, all of the
                following:
                <ol className="question-tip" type="i">
                  <li>
                    base salary, which is the sum of guaranteed, short-term, and non-variable cash
                    compensation;
                  </li>
                  <li>
                    benefits in cash, which is the sum of the base salary and cash allowances,
                    bonuses, commissions, cash profit-sharing, and other forms of variable cash
                    payments;
                  </li>
                  <li>
                    benefits in kind, such as cars, private health insurance, life insurance,
                    wellness programs; and
                  </li>
                  <li>
                    direct remuneration, which is the sum of benefits in cash, benefits in kind and
                    total fair value of all annual long-term incentives (for example, stock option
                    awards, restricted stock shares or units, performance stock shares or units,
                    phantom stock shares, stock appreciation rights, and long-term cash awards).
                  </li>
                </ol>
              </li>
              <li>
                <p className="formula">
                  Annual total remuneration ratio =
                  <span style={{ textDecoration: "overline" }}>
                    Annual total remuneration for the undertaking's highest paid individual
                  </span>
                  ÷
                  <span style={{ textDecoration: "overline" }}>
                    Median employee annual total remuneration (excluding the highest - paid
                    individual)
                  </span>
                </p>
              </li>
            </ol>
            <p className="question-tip">
              In relation to paragraph 97 (b), the undertaking may report this figure adjusted for
              purchasing power differences between countries, in which case it shall report the
              methodology used for the calculation. AR 102.To illustrate the contextual information,
              the undertaking may provide an explanation to understand the data and how the data has
              been compiled (methodology). Quantitative data, such as the annual total remuneration
              ratio, may not be sufficient on its own to understand pay disparity and its drivers.
              For example, pay ratios can be influenced by the size of the undertaking (for example,
              revenue, number of employees), its sector, its employment strategy (for example,
              reliance on outsourced workers or part-time employees, a high degree of automation),
              or currency volatility.
            </p>
          </Typography>
        ),
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        euReg: (
          <Typography>
            <p className="question-tip">
              97 (b)
              <strong>SFDR reference:</strong> Indicator number 8 Table #3 of Annex I
            </p>
          </Typography>
        ),
        module: "Social",
        boldPart: "97 b, 97 c",
      },
      // Add more questions as needed
    ],
    "S1-17 – Incidents, complaints and severe human rights impacts": [
      {
        id: "q-806",
        question:
          "(a) The total number of incidents of discrimination, including harassment, reported in the reporting period; (b) The number of complaints filed through channels for people in the undertaking’s own workforce to raise concerns (including grievance mechanisms) and, where applicable, to the National Contact Points for OECD Multinational Enterprises related to the matters defined in paragraph 2 of this Standard, excluding those already reported in (a) above; (c) The total amount of fines, penalties, and compensation for damages as a result of theincidents and complaints disclosed above, and a reconciliation of such monetary amounts disclosed with the most relevant amount presented in the financial statements; and (d) where applicable, contextual information necessary to understand the data and how such data has been compiled.",
        tooltip: (
          <Typography>
            <p className="question-tip">incidents reviewed by the undertaking; </p>
            <ol className="question-tip" type="a">
              <li> incidents reviewed by the undertaking; </li>
              <li>remediation plans being implemented; </li>
              <li>
                remediation plans that have been implemented, with results reviewed through routine
                internal management review processes; and
              </li>
              <li> incidents no longer subject to action. </li>
            </ol>
            <p className="question-tip">
              AR 104. If the undertaking compiles the information described in AR 105, it shall
              consider the following:
            </p>
            <ol className="question-tip" type="a">
              <li>
                an incident is no longer subject to action if it is resolved, the case is completed,
                or no further action is required by the undertaking. For example, an incident for
                which no further action is required can include cases that are withdrawn or where
                the underlying circumstances that led to the incident no longer exist;
              </li>
              <li>
                remedial action is directed toward the alleged harasser and the alleged victim.
                Remedial action toward the victim may include offering to pay his/her expenses for
                counselling sessions, offering the victim some paid time off, offering to reinstate
                sick/vacation days if the victim has incurred any expenses due to the harassment
                (such as having used sick or vacation days); and
              </li>
              <li>
                remedial action toward the harasser may include giving the harasser a verbal and/or
                written warning, mandating anti-harassment counselling or sending the harasser to an
                appropriate seminar, harassment awareness and prevention training. A suspension
                without pay may also be an option. If the harasser has been disciplined earlier but
                his/her harassment does not cease, then more serious discipline may be required.
              </li>
            </ol>
            <p className="question-tip">
              AR 105.Severe human rights incidents include instances of lawsuits, formal complaints
              through the undertaking or third-party complaint mechanisms, serious allegations in
              public reports or the media, where these are connected to the undertaking’s own
              workforce, and the fact of the incidents is not disputed by the undertaking, as well
              as any other severe impacts of which the undertaking is aware.
            </p>
            <p className="question-tip">
              AR 106.In addition to the information required by paragraph 104 above, the undertaking
              may disclose the number of severe human rights incidents where the undertaking played
              a role securing remedy for those affected during the reporting period.
            </p>
          </Typography>
        ),
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        euReg: (
          <Typography>
            <p className="question-tip">
              {" "}
              103 (a)
              <strong>SFDR reference:</strong> Indicator number 7 Table #3 of Annex I
            </p>
          </Typography>
        ),
        module: "Social",
        boldPart: "103",
      },

      {
        id: "q-810",
        question:
          "The undertaking shall disclose the following information regarding identified cases of severe human rights incidents (e.g., forced labour, human trafficking or child labour): (a) the number of severe human rights incidents connected to the undertaking’s workforce in the reporting period, including an indication of how many of these are cases of nonrespect of the UN Guiding Principles on Business and Human Rights, ILO Declaration on Fundamental Principles and Rights at Work or OECD Guidelines for Multinational Enterprises. If no such incidents have occurred, the undertaking shall state this.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 105.Severe human rights incidents include instances of lawsuits, formal complaints
              through the undertaking or third-party complaint mechanisms, serious allegations in
              public reports or the media, where these are connected to the undertaking’s own
              workforce, and the fact of the incidents is not disputed by the undertaking, as well
              as any other severe impacts of which the undertaking is aware. "
            </p>
          </Typography>
        ),
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 10 Table #1 and Indicator n. 14
              Table #3 of Annex I{" "}
            </p>

            <p className="question-tip">
              <strong>Benchmark Regulation reference:</strong> Delegated Regulation (EU) 2020/1816,
              Annex II Delegated Regulation (EU) 2020/1818 Art 12 (1)
            </p>
          </Typography>
        ),

        module: "Social",
        boldPart: "104 a",
      },
      {
        id: "q-811",
        question:
          "The total amount of fines, penalties and compensation for damages for the incidents described in (a) above, and a reconciliation of the monetary amounts disclosed in the most relevant amount in the financial statements.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 103.In addition to the information required by paragraphs 103 and 104, the
              undertaking may disclose the status of incidents and/or complaints and actions taken
              with reference to the following:
            </p>
            <ol className="question-tip" type="a">
              <li>incidents reviewed by the undertaking; </li>
              <li> remediation plans being implemented; </li>
              <li>
                remediation plans that have been implemented, with results reviewed through routine
                internal management review processes; and
              </li>
              <li>incidents no longer subject to action. </li>
            </ol>
            <p className="question-tip">
              AR 104. If the undertaking compiles the information described in AR 105, it shall
              consider the following:
            </p>
            <ol className="question-tip" type="a">
              <li>
                an incident is no longer subject to action if it is resolved, the case is completed,
                or no further action is required by the undertaking. For example, an incident for
                which no further action is required can include cases that are withdrawn or where
                the underlying circumstances that led to the incident no longer exist;
              </li>
              <li>
                remedial action is directed toward the alleged harasser and the alleged victim.
                Remedial action toward the victim may include offering to pay his/her expenses for
                counselling sessions, offering the victim some paid time off, offering to reinstate
                sick/vacation days if the victim has incurred any expenses due to the harassment
                (such as having used sick or vacation days); and
              </li>
              <li>
                remedial action toward the harasser may include giving the harasser a verbal and/or
                written warning, mandating anti-harassment counselling or sending the harasser to an
                appropriate seminar, harassment awareness and prevention training. A suspension
                without pay may also be an option. If the harasser has been disciplined earlier but
                his/her harassment does not cease, then more serious discipline may be required.
              </li>
            </ol>
            <p className="question-tip">
              AR 105.Severe human rights incidents include instances of lawsuits, formal complaints
              through the undertaking or third-party complaint mechanisms, serious allegations in
              public reports or the media, where these are connected to the undertaking’s own
              workforce, and the fact of the incidents is not disputed by the undertaking, as well
              as any other severe impacts of which the undertaking is aware.
            </p>
            <p className="question-tip">
              AR 106.In addition to the information required by paragraph 104 above, the undertaking
              may disclose the number of severe human rights incidents where the undertaking played
              a role securing remedy for those affected during the reporting period.
            </p>
            <p className="question-tip">36 Appendix A.1: Application Requirements for ESRS 2 re</p>
          </Typography>
        ),
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",

        module: "Social",
        boldPart: "104 b",
      },
      {
        id: "q-812",
        question:
          "In addition to the information required by paragraphs 103 and 104, the undertaking may disclose the status of incidents and/or complaints and actions taken with reference to the following: (a) incidents reviewed by the undertaking; (b) remediation plans being implemented; (c)remediation plans that have been implemented, with results reviewed through routine internal management review processes; and (d) incidents no longer subject to action.",

        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        voluntary: true,
        module: "Social",
        boldPart: "",
      },
      {
        id: "q-813",
        question:
          "In addition to the information required by paragraph 104 above, the undertaking may disclose the number of severe human rights incidents where the undertaking played a role securing remedy for those affected during the reporting period. ",

        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S1 for the first year of preparation of their sustainability statement.",
        voluntary: true,
        module: "Social",
        boldPart: "AR 106",
      },
      // Add more questions as needed
    ],
    /**********************************************
     * Tab: social
     * Topic: ESRS S2 WORKERS IN THE VALUE CHAIN
     **********************************************/
    "ESRS 2 SBM-3 Material impacts, risks and opportunities and their interaction with strategy and business model":
      [
        {
          id: "q-814",
          question:
            "When fulfilling the requirements of paragraph ESRS 2 SBM-3 paragraph 48, the undertaking shall disclose whether all value chain workers who are likely to be materially impacted by the undertaking, including impacts that are connected with the undertaking’s own operations and value chain, including through its products or services , as well as through its business relationships, are included in the scope of its disclosure under ESRS 2. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "11",
        },
        {
          id: "q-815",
          question:
            "a brief description of the types of value chain workers who could be materially impacted by the undertaking, including impacts that connected with the undertaking’s own operations and value chain, including through its products or services, as well as through its business relationships, and specify whether they are:",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "11 a",
        },
        {
          id: "q-816",
          question:
            "(i) workers working on the undertaking site but who are not part of own workforce, i.e., who are not self-employed workers or workers provided by third party undertakings primarily engaged in employment activities (covered through ESRS S1); (ii) workers working for entities in the undertaking’s upstream value chain (e.g., those involved in the extraction of metals or minerals or harvesting of commodities, in refining, manufacturing or other forms of processing); (iii) workers working for entities in the undertaking’s downstream value chain (e.g., those involved in the activities of logistics or distribution providers, franchisees, retailers); (iv) workers working in the operations of a joint venture or special purpose vehicle involving the reporting undertaking; (v) workers who (within the prior categories or additionally) are particularly vulnerable to negative impacts whether due to their inherent characteristics or to the particular context, such as trade unionists, migrant workers, home workers, women or young workers.",

          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "11 a i)-v)",
        },
        {
          id: "q-817",
          question:
            "Any geographies, at country level or other levels, or commodities for which there is a significant risk of child labour, or of forced labour or compulsory labour, among workers in the undertaking’s value chain",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          euReg: (
            <Typography>
              <p className="question-tip">
                <strong>SFDR reference:</strong> Indicators number 12 and n. 13 Table #3 of Annex I{" "}
              </p>
            </Typography>
          ),
          module: "Social",
          boldPart: "11 b",
        },
        {
          id: "q-818",
          question:
            "in the case of material negative impacts, whether they are either (i) widespread or systemic in contexts where the undertaking operates or has sourcing or other business relationships (e.g., child labour or forced labour in particular commodity supply chains in specific countries or regions), or (ii) related to individual incidents (e.g., an industrial accident or an oil spill) or to specific business relationships. This includes consideration of impacts on value chain workers that may arise from the transition to greener and climate-neutral operations. Potential impacts include impacts associated with innovation and restructuring, closure of mines, increased mining of minerals needed for the transition to a sustainable economy, and solar panel production; ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "11 c",
        },
        {
          id: "q-819",
          question:
            "In the case of material positive impacts, a brief description of the activities that result in the positive impacts (e.g., updated purchasing practices, capacity-building to supply chain workers), including providing opportunities for the workforce such as job creation and upskilling in the context of a ‘just transition’, and the types of value chain workers that are positively affected or could be positively affected; the undertaking may also disclose whether the positive impacts occur in specific countries or regions;",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "11 d",
        },
        {
          id: "q-820",
          question:
            "Any material risks and opportunities for the undertaking arising from impacts and dependencies on value chain workers.",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "11 e",
        },
        {
          id: "q-821",
          question:
            "In describing the main types of value chain workers who are or could be negatively affected, based on the materiality assessment set out in ESRS 2 IRO-1, the undertaking shall disclose whether and how it has developed an understanding of how workers with particular characteristics, those working in particular contexts, or those undertaking particular activities may be at greater risk of harm. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "12",
        },
        {
          id: "q-822",
          question:
            "The undertaking shall disclose which, if any, of its material risks and opportunities arising from impacts and dependencies on its value chain workers relate to specific groups of value chain workers (for example, particular age groups, workers in a particular factory or country) rather than to all of the value chain workers. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "13",
        },
        // Add more questions as needed
      ],
    "S2-1 – Policies related to value chain workers": [
      {
        id: "q-823",
        question:
          "The disclosure required by paragraph 14 shall contain the information on the undertaking’s policies to manage its material impacts, risks and opportunities related to value chain workers in accordance with ESRS 2 MDR-P Policies adopted to manage material sustainability matters. In addition, the undertaking shall specify whether such policies cover specific groups of value chain workers or all value chain workers.",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
        module: "Social",
        boldPart: "16",
      },
      {
        id: "q-824",
        question:
          "The undertaking shall describe its human rights policy commitments2 that are relevant to value chain workers, including those processes and mechanisms to monitor compliance with the UN Guiding Principles on Business and Human Rights, ILO Declaration on Fundamental Principles and Rights at Work or OECD Guidelines for Multinational Enterprises. In its disclosure, it shall focus on those matters that are material in relation to, as well as the general approach to: (a) respect for the human rights, including labour rights, of workers; (b) Engagement with value chain workers; and (c) Measures to provide and/or enable remedy for human rights impacts.",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
        euReg: (
          <Typography>
            <p className="question-tip">
              {" "}
              17, 17a
              <strong>SFDR reference:</strong> Indicator number 9 Table #3 and Indicator n. 11 Table
              #1 of Annex 1{" "}
            </p>
          </Typography>
        ),
        module: "Social",
        boldPart: "17",
      },

      {
        id: "q-828",
        question:
          "The undertaking shall state whether its policies in relation to value chain workers explicitly address trafficking in human beings114 , forced labour or compulsory labour and child labour. It shall also state whether the undertaking has a supplier code of conduct.",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 11 and n. 4 Table #3 of Annex 1{" "}
            </p>
          </Typography>
        ),
        module: "Social",
        boldPart: "18",
      },
      {
        id: "q-829",
        question:
          "The undertaking shall disclose whether and how its policies with regard to value chain workers are aligned with internationally recognised instruments relevant to value chain workers, including the United Nations (UN) Guiding Principles on Business and Human Rights116. The undertaking shall also disclose the extent to which cases of non-respect of the UN Guiding Principles on Business and Human Rights, ILO Declaration on Fundamental Principles and Rights at Work or OECD Guidelines for Multinational Enterprises that involve value chain workers have been reported in its upstream and downstream value chain and, if applicable, an indication of the nature of such cases",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 14. When disclosing the alignment of its policies with the UN Guiding Principles
              on Business and Human Rights, the undertaking shall consider that the Guiding
              Principles refer to the International Bill of Rights, which consist of the Universal
              Declaration of Human Rights and the two Covenants that implement it, as well as the
              International Labour Organisation’s Declaration on Fundamental Rights and Principles
              at Work and the core conventions that underpin it, and may disclose its alignment with
              these instruments. "
            </p>
          </Typography>
        ),
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 10 Table #1 of Annex 1{" "}
            </p>

            <p className="question-tip">
              <strong>Benchmark Regulation reference:</strong> Delegated Regulation (EU) 2020/1816,
              Annex II Delegated Regulation (EU) 2020/1818, Art 12 (1)
            </p>
          </Typography>
        ),
        module: "Social",
        boldPart: "19",
      },
      {
        id: "q-830",
        question:
          "The undertaking may disclose explanations of significant changes to the policies adopted during the reporting year (e.g., new expectations for suppliers, new or additional approaches to due diligence and remedy). ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
        voluntary: true,
        module: "Social",
        boldPart: "AR 12",
      },
      {
        id: "q-831",
        question:
          "The undertaking may provide an illustration of the types of communication of its policies to those individuals, group of individuals or entities for whom they are relevant, either because they are expected to implement them (for example, the undertaking’s employees, contractors and suppliers), or because they have a direct interest in their implementation (for example, own workers, investors). It may disclose communication tools and channels (for example, flyers, newsletters, dedicated websites, social media, face to face interactions, workers’ representatives), aimed at ensuring that the policy is accessible and that different audiences understand its implications. The undertaking may also explain how it identifies and removes potential barriers for dissemination, such as through translation into relevant languages or the use of graphic depictions. ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
        voluntary: true,
        module: "Social",
        boldPart: "AR 16",
      },
      {
        id: "q-832",
        question:
          "The undertaking shall disclose information about policies adopted to manage material sustainability matters. The disclosure shall include the following information: (a) a description of the key contents of the policy, including its general objectives and which material impacts, risks or opportunities the policy relates to and the process for monitoring; ",
        tooltip: "",
        condition: "yes",
        module: "Social",
        boldPart: "65 a",
      },
      {
        id: "q-833",
        question:
          "a description of the scope of the policy, or of its exclusions, in terms of activities, upstream and/or downstream value chain, geographies and if relevant, affected stakeholder groups;",
        tooltip: "",
        condition: "yes",
        module: "Social",
        boldPart: "65 b",
      },
      {
        id: "q-834",
        question:
          "the most senior level in the undertaking’s organisation that is accountable for the implementation of the policy;",
        tooltip: "",
        condition: "yes",
        module: "Social",
        boldPart: "65 c",
      },
      {
        id: "q-835",
        question:
          "a reference, if relevant, to the third-party standards or initiatives the undertaking commits to respect through the implementation of the policy;",
        tooltip: "",
        condition: "yes",
        module: "Social",
        boldPart: "65 d",
      },
      {
        id: "q-836",
        question:
          "if relevant, a description of the consideration given to the interests of key stakeholders in setting the policy; ",
        tooltip: "",
        condition: "yes",
        module: "Social",
        boldPart: "65 e",
      },
      {
        id: "q-837",
        question:
          "if relevant, whether and how the undertaking makes the policy available to potentially affected stakeholders, and stakeholders who need to help implement it. ",
        tooltip: "",
        condition: "yes",
        module: "Social",
        boldPart: "65 f",
      },
      {
        id: "q-838",
        question:
          "If the undertaking cannot disclose the information on policies and actions required under relevant ESRS, because it has not adopted policies and/or actions with reference to the specific sustainability matter concerned, it shall disclose this to be the case, and provide reasons for not having adopted policies and/or actions. ",
        tooltip: "",
        condition: "no",
        module: "Social",
        boldPart: "62",
      },
      {
        id: "q-839",
        question: "The undertaking may disclose a timeframe in which it aims to adopt them. ",
        tooltip: "",
        condition: "no",
        module: "Social",
        boldPart: "62",
      },
      // Add more questions as needed
    ],
    "S2-2 – Processes for engaging with value chain workers about impacts ": [
      {
        id: "q-840",
        question:
          "The undertaking shall disclose whether and how the perspectives of value chain workers inform its decisions or activities aimed at managing the actual and potential impacts on value chain workers. (a) Whether engagement occurs with value chain workers or their legitimate representatives directly, or with credible proxies that have insight into their situation; (b) The stage(s) at which engagement occurs, the type of engagement, and the frequency of the engagement;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              {" "}
              22 "AR 20. To illustrate how the perspectives of value chain workers have informed
              specific decisions or activities of the undertaking, the undertaking may provide
              examples from the current reporting period. "
            </p>
            <p className="question-tip">
              22 (b) AR 18. When preparing the disclosures described in paragraph 22 b) and c), the
              following illustrations may be considered:
            </p>
            <ol className="question-tip" type="a">
              <li>
                examples of stages at which engagement occurs are
                <ol className="question-tip" type="i">
                  <li>determining the approach to mitigation and</li>
                  <li>evaluation the effectiveness of mitigation;</li>
                </ol>
              </li>
              <li>
                for type of engagement, these could be participation, consultation and/or
                information;
              </li>
              <li>
                for the frequency of the engagement, information may be provided on whether
                engagement occurs on a regular basis, at certain points in a project or business
                process, for example, when a new harvest season begins or a new production line is
                opened, as well as whether it occurs in response to legal requirements and/or in
                response to stakeholder requests and whether the result of the engagement is being
                integrated into the undertaking's decision- making processes; and
              </li>
              <li>
                for the role with operational responsibility, whether the undertaking requires
                relevant staff to have certain skills, or whether it provides training or capacity
                building to relevant staff to undertake engagement.
              </li>
            </ol>
          </Typography>
        ),
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
        module: "Social",
        boldPart: "22",
      },
      {
        id: "q-843",
        question:
          "The function and the most senior role within the undertaking that has operational responsibility for ensuring that this engagement happens, and that the results inform the undertaking’s approach; ",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 18. When preparing the disclosures described in paragraph 22 b) and c), the
              following illustrations may be considered:
            </p>
            <ol className="question-tip" type="a">
              <li>
                examples of stages at which engagement occurs are
                <ol className="question-tip" type="i">
                  <li>determining the approach to mitigation and</li>
                  <li>evaluation the effectiveness of mitigation;</li>
                </ol>
              </li>
              <li>
                for type of engagement, these could be participation, consultation and/or
                information;
              </li>
              <li>
                for the frequency of the engagement, information may be provided on whether
                engagement occurs on a regular basis, at certain points in a project or business
                process, for example, when a new harvest season begins or a new production line is
                opened, as well as whether it occurs in response to legal requirements and/or in
                response to stakeholder requests and whether the result of the engagement is being
                integrated into the undertaking's decision- making processes; and
              </li>
              <li>
                for the role with operational responsibility, whether the undertaking requires
                relevant staff to have certain skills, or whether it provides training or capacity
                building to relevant staff to undertake engagement.
              </li>
            </ol>
          </Typography>
        ),
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
        module: "Social",
        boldPart: "22 c",
      },
      {
        id: "q-844",
        question:
          "Where applicable, Global Framework Agreements or for agreements that theundertaking has with global union federations related to respect of human rights of workers in the value chain, including their right to bargain collectively, and including an explanation of how the agreement enables the undertaking to gain insight into those workers’ perspectives",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 19. Global Framework Agreements (GFA) serve to establish an ongoing relationship
              between a multinational enterprise and a Global Union Federation to ensure that the
              undertaking adheres to the same standards in every country in which it operates."
            </p>
          </Typography>
        ),
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
        module: "Social",
        boldPart: "22 d",
      },
      {
        id: "q-845",
        question:
          "Where applicable, how the undertaking assesses the effectiveness of its engagement with workers in the value chain, including, where relevant, any agreements or outcomes that result. ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
        module: "Social",
        boldPart: "22 e",
      },
      {
        id: "q-846",
        question:
          "Where applicable, the undertaking shall disclose the steps it takes to gain insight into the perspectives of workers that may be particularly vulnerable to impacts and/or marginalised (for example, women workers, migrant workers, workers with disabilities).",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
        module: "Social",
        boldPart: "23",
      },
      {
        id: "q-847",
        question:
          "If the undertaking cannot disclose the above required information because it has not adopted A general process to engage with workers in the value chain, it shall disclose this to be the case. It may disclose a timeframe in which it aims to have such a process in place. ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
        voluntary: true,
        module: "Social",
        boldPart: "24",
      },
      // Add more questions as needed
    ],
    "S2-3 – Processes to remediate negative impacts and channels for value chain workers to raise concerns":
      [
        {
          id: "q-848",
          question:
            "Its general approach to and processes for providing or contributing to remedy where it has caused or contributed to a material negative impact on value chain workers, including whether and how the undertaking assesses that the remedy provided is effective; ",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 21. In fulfilling the requirements set out by Disclosure Requirement ESRS S2-3,
                the undertaking may be guided by the content of the UN Guiding Principles on
                Business and Human Rights and the OECD Guidelines for Multinational Enterprises
                focused on remediation and grievance mechanisms."
              </p>
            </Typography>
          ),
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "27 a",
        },
        {
          id: "q-849",
          question:
            "Any specific channels it has in place for value chain workers to raise their concerns or needs directly with the undertaking and have them addressed, including whether these are established by the undertaking itself and/or whether they are third-party mechanisms; ",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 22. Channels for raising concerns or needs include grievance mechanisms,
                hotlines, trade unions (where workers are unionised), dialogue processes or other
                means through which value chain workers or their legitimate representatives can
                raise concerns about impacts or explain needs that they would like the undertaking
                to address. This could include both channels provided by the undertaking directly
                and channels provided by the entities where the value chain workers are working, in
                addition to any other mechanisms the undertaking may use to gain insight into the
                management of impacts on workers, such as compliance audits. Where the undertaking
                is relying solely on information about the existence of such channels provided by
                its business relationships to answer this requirement, it may state that. "
              </p>
            </Typography>
          ),
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "27 b",
        },
        {
          id: "q-850",
          question:
            "The processes through which it supports or requires the availability of such channels in the workplace of value chain workers;",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "27 c",
        },
        {
          id: "q-851",
          question:
            "How it tracks and monitors issues raised and addressed, and how it ensures the effectiveness of the channels, including through involvement of stakeholders who are the intended users. ",
          tooltip: (
            <Typography>
              <p className="question-tip">
                AR 27. In describing the effectiveness of channels for value chain workers to raise
                concerns, the undertaking may be guided by the following questions, based on the
                “effectiveness criteria for non-judicial grievance mechanisms”, as laid out in the
                UN Guiding Principles on Business and Human Rights, in particular Principle 31. The
                below considerations may be applied to individual channels or to a collective system
                of channels:
              </p>
              <ol className="question-tip" type="a">
                <li>
                  do the channels hold legitimacy by providing appropriate accountability for their
                  fair conduct and building stakeholder trust?
                </li>
                <li>are the channels known and accessible to stakeholders? </li>
                <li>
                  do the channels have clear and known procedures, with indicative timeframes?
                </li>
                <li>
                  do the channels ensure reasonable access for stakeholders to sources of
                  information, advice and expertise?
                </li>
                <li>
                  do the channels offer transparency by providing sufficient information both to
                  complainants and, where applicable, to meet any public interest?
                </li>
                <li>
                  do outcomes achieved through the channels accord with internationally recognised
                  human rights?
                </li>
                <li>
                  does the undertaking identify insights from the channels that support continuous
                  learning in both improving the channels and preventing future impacts?
                </li>
                <li>
                  does the undertaking focus on dialogue with complainants as the means to reach
                  agreed solutions, rather than seeking to unilaterally determine the outcome?
                </li>
              </ol>
            </Typography>
          ),
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "27 d",
        },
        {
          id: "q-852",
          question:
            "The undertaking shall disclose whether and how it assesses that value chain workers are aware of and trust these structures or processes as a way to raise their concerns or needs and have them addressed. In addition, the undertaking shall disclose whether it has policies in place regarding the protection of individuals that use them against retaliation. If such information has been disclosed in accordance with ESRS G1-1, the undertaking may refer to that information.",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 26. In disclosing whether and how the undertaking knows that value chain workers
                are aware of and trust any of these channels, the undertaking may provide relevant
                and reliable data about the effectiveness of these channels from the perspective of
                value chain workers themselves. Examples of sources of information are surveys of
                workers that have used such channels and their levels of satisfaction with the
                process and outcomes. "
              </p>
            </Typography>
          ),
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "28",
        },
        {
          id: "q-853",
          question:
            "If the undertaking cannot disclose the above required information because it has not adopted a channel for raising concerns and/or does not support the availability of such a channel in the workplace of value chain workers, it shall disclose this to be the case. It may disclose a timeframe in which it aims to have such a channel or processes in place. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "29",
        },
        {
          id: "q-854",
          question:
            "To provide greater insight into the information covered in Disclosure Requirement ESRS S2-3, the undertaking may explain whether and how value chain workers that may be affected are able to access channels at the level of the undertaking they are employed by, or contracted to work for, in relation to each material impact. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 23",
        },
        {
          id: "q-855",
          question:
            "Third party mechanisms could include those operated by the government, NGOs, industry associations and other collaborative initiatives. The undertaking may disclose whether these are accessible to all workers who may be potentially or actually materially impacted by the undertaking, or individuals or organisations acting on their behalf or who are otherwise in a position to be aware of negative impacts. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 24",
        },
        {
          id: "q-856",
          question:
            "In relation to the protection of individuals that use the mechanisms against the retaliation, the undertaking may describe whether it treats grievances confidentially and with respect to the rights of privacy and data protection; and whether the mechanisms allow for workers to use them anonymously (for example, through representation by a third party). ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 25",
        },
        // Add more questions as needed
      ],
    "S2-4 – Taking action on material impacts on value chain workers, and approaches to managing material risks and pursuing materials opportunities related to value chain workers, and effectiveness of those action":
      [
        {
          id: "q-857",
          question:
            "(a) Actions taken, planned or underway to prevent or mitigate material negative impacts on value chain workers; (b) Whether and how it has taken action to provide or enable remedy in relation to an actual material impact; (c) Any additional actions or initiatives it has in place with the primary purpose of delivering positive impacts for value chain workers; (d) How it tracks and assesses the effectiveness of such actions and initiatives in delivering intended outcomes for value chain workers.",
          tooltip: (
            <Typography>
              <p className="question-tip">
                {" "}
                32 (a) "AR 38. When disclosing the intended or achieved positive outcomes of its
                actions for value chain workers a distinction is to be made between evidence of
                certain activities having occurred (e.g., that x number of workers have received
                financial literacy training) from evidence of actual outcomes for workers (e.g.,
                that x workers report that they are able to better manage their household budgets so
                as to meet their savings goals). "
              </p>
              <p className="question-tip">
                32 (c) "When disclosing the information required under paragraph 32 (c), the
                undertaking shall consider ESRS 2 (see ESRS 2 MDR-T Tracking effectiveness of
                policies and actions through targets) if it evaluates the effectiveness of an action
                by setting a target. AR 39. When disclosing whether initiatives or processes also
                play a role in mitigating material negative impacts, the undertaking may e.g.,
                consider programmes that aim to advance women workers’ financial literacy that have
                resulted in more women being promoted as well as in reports of reduced sexual
                harassment in the workplace. "
              </p>
              <p className="question-tip">
                32 (d) "AR 33. In disclosing how it tracks the effectiveness of its actions to
                manage material impacts during the reporting period, the undertaking may disclose
                any lessons learned from the previous and current reporting periods. <br />
                AR 34. Processes used to track the effectiveness of actions can include internal or
                external auditing or verification, court proceedings and/or related court decisions,
                impact assessments, measurement systems, stakeholder feedback, grievance mechanisms,
                external performance ratings, and benchmarking. <br />
                AR 35. Reporting on effectiveness is aimed at enabling the understanding of the
                links between actions taken by the undertaking and the effective management of
                impacts. For example, to show the effectiveness of its actions to support its
                suppliers with improving their working conditions, the undertaking may disclose
                survey feedback from the suppliers’ workers showing that working conditions have
                improved since the time the undertaking began working with those suppliers.
                Additional information that the undertaking may provide includes data showing a
                decrease in the number of incidents identified through for instance, independent
                audits. "
              </p>
            </Typography>
          ),
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "32",
        },

        {
          id: "q-861",
          question:
            "The processes through which it identifies what action is needed and appropriate in response to a particular actual or potential negative impact on value chain workers;",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 29. Appropriate action can vary according to whether the undertaking causes or
                contributes to a material impact or whether the material impact is directly linked
                to its own operations, products or services through a business relationship."
              </p>
            </Typography>
          ),
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "33 a",
        },
        {
          id: "q-862",
          question:
            "Its approach to taking action in relation to specific material negative impacts on value chain workers, including any action in relation to its own purchasing or other internal practices, as well as capacity- building or other forms of engagement with entities in the value chain, or forms of collaborative action with industry peers or other relevant parties;",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "33 b",
        },
        {
          id: "q-863",
          question:
            "How it ensures that processes to provide or enable remedy in the event of material negative impacts are available and effective in their implementation and outcomes. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "33 c",
        },
        {
          id: "q-864",
          question:
            "What action is planned or underway to mitigate material risks for the undertaking arising from its impacts and dependencies on value chain workers and how it tracks effectiveness in practice;",
          tooltip: (
            <Typography>
              <p className="question-tip">
                AR 40. When disclosing the material risks and opportunities related to the
                undertaking’s impacts or dependencies on value chain workers, the undertaking may
                consider the following:
              </p>
              <ol className="question-tip" type="a">
                <li>
                  risks related to the undertaking’s impacts on value chain workers may include the
                  reputational or legal exposure where value chain workers are found to be subject
                  to forced labour or child labour;
                </li>
                <li>
                  risks related to the undertaking’s dependencies on value chain workers may include
                  disruption of business operations where a pandemic closes significant parts of its
                  supply chain or distribution network;
                </li>
                <li>
                  opportunities related to the undertaking’s impacts on value chain workers may
                  include market differentiation and greater customer appeal from guaranteeing
                  decent pay and conditions for non-employee workers; and
                </li>
                <li>
                  business opportunities related to the undertaking’s dependencies on value chain
                  workers might include the achievement of a future sustainable supply of a
                  commodity by ensuring smallholder farmers earn enough to persuade future
                  generations to keep farming that crop.
                </li>
              </ol>
              <p className="question-tip">
                AR 41. When disclosing whether dependencies turn into risks, the undertaking shall
                consider external developments. AR 43. The undertaking shall consider whether and
                how its process(es) to manage material risks related to value chain workers are
                integrated into its existing risk management process(es).
              </p>
            </Typography>
          ),
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "34 a",
        },
        {
          id: "q-865",
          question:
            "What action is planned or underway to pursue material opportunities for the undertaking in relation to value chain workers. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "34 b",
        },
        {
          id: "q-866",
          question:
            "The undertaking shall disclose whether and how it takes action to avoid causing or contributing to material negative impacts on value chain workers through its own practices, including, where relevant, in relation to procurement, sales and data use. This may include disclosing what approach is taken when tensions arise between the prevention or mitigation of material negative impacts and other business pressures. ",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 32. When disclosing whether and how the undertaking considers actual and
                potential impacts on value chain workers in decisions to terminate business
                relationships and whether and how it seeks to address any negative impacts that may
                result from termination, the undertaking may include examples. "
              </p>
            </Typography>
          ),
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "35",
        },
        {
          id: "q-867",
          question:
            "The undertaking shall also disclose whether severe human rights issues and incidents connected to its upstream and downstream value chain have been reported and, if applicable, disclose these.",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          euReg: (
            <Typography>
              <p className="question-tip">
                <strong>SFDR reference:</strong> Indicator number 14 Table #3 of Annex 1{" "}
              </p>
            </Typography>
          ),
          module: "Social",
          boldPart: "36",
        },
        {
          id: "q-868",
          question:
            "The undertaking shall disclose what resources are allocated to the management of its material impacts, with information that enables users to gain an understanding of how the material impacts are managed.",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "38",
        },
        {
          id: "q-869",
          question:
            "It may take time to understand negative impacts and how the undertaking may be involved with them through its value chain, as well as to identify appropriate responses and put them into practice. Therefore, the undertaking shall consider: (a) its general and specific approaches to addressing material negative impacts; (b) its initiatives aimed at contributing to additional material positive impacts; (c) How far it has progressed in its efforts during the reporting period; and (d) its aims for continued improvement.",
          tooltip:
            "AR 28 (b): AR 39. When disclosing whether initiatives or processes also play a role in mitigating material negative impacts, the undertaking may e.g., consider programmes that aim to advance women workers’ financial literacy that have resulted in more women being promoted as well as in reports of reduced sexual harassment in the workplace. ",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 28",
        },

        {
          id: "q-873",
          question:
            "Given that material negative impacts affecting value chain workers that have occurred during the reporting period may also be linked to entities or operations outside its direct control, the undertaking may disclose whether and how it seeks to use its leverage in its business relationships to manage those impacts. This may include using commercial leverage (for example, enforcing contractual requirements with business relationships or implementing incentives), other forms of leverage within the relationship (such as providing training or capacity-building on workers’ rights to entities with which the undertaking has a business relationships) or collaborative leverage with peers or other actors (such as initiatives aimed at responsible recruitment or ensuring workers receive an adequate wage). ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 30",
        },
        {
          id: "q-874",
          question:
            "When the undertaking discloses its participation in an industry or multi-stakeholder initiative as part of its actions to address material negative impacts, the undertaking may disclose how the initiative, and its own involvement, is aiming to address the material impact concerned. It may disclose under ESRS S2-5 the relevant targets set by the initiative and progress towards them. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 31",
        },
        {
          id: "q-875",
          question:
            "With regard to initiatives or processes the undertaking has in place that are based on affected workers’ needs and with regard to progress in the implementation of such initiatives or processes, the undertaking may disclose: (a) information about whether and how value chain workers and legitimate representatives or their credible proxies play a role in decisions regarding the design and implementation of these programmes or processes; ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 36 a",
        },
        {
          id: "q-876",
          question:
            "Information about the intended or achieved positive outcomes for value chain workers of these initiatives or processes. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 36 b",
        },
        {
          id: "q-877",
          question:
            "The undertaking may disclose whether any initiatives or processes whose primary aim is to deliver positive impacts for value chain workers are designed also to support the achievement of one or more of the UN Sustainable Development Goals (SDGs). For example, through a commitment to advance UN SDG 8 to “promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all” the undertaking may be providing capacity-building to smallholders in its supply chain, resulting in increases in their income; or it may be supporting training to increase the proportion of women able to take delivery jobs in its downstream value chain. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 37",
        },
        {
          id: "q-878",
          question:
            "When disclosing the resources allocated to the management of material impacts, the undertaking may disclose which internal functions are involved in managing the impacts and what types of action they take to address negative and advance positive impacts. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 44",
        },
        {
          id: "q-879",
          question:
            "Where the implementation of a policy requires actions, or a comprehensive action plan, to achieve its objectives, as well as when actions are implemented without a specific policy, the undertaking shall disclose the following information: (a) the list of key actions taken in the reporting year and planned for the future, their expected outcomes and, where relevant, how their implementation contributes to the achievement of policy objectives and targets;",
          tooltip:
            "AR 22. Key actions in the context of this Minimum Disclosure Requirement are those actions that materially contribute to achieving the undertaking’s objectives in addressing material impacts, risks and opportunities. oor reasons of understandability, key actions may be aggregated where appropriate. ",
          condition: "yes",
          module: "Social",
          boldPart: "68 a",
        },
        {
          id: "q-880",
          question:
            "the scope of the key actions (i.e., coverage in terms of activities, upstream and/or downstream value chain, geographies and, where applicable, affected stakeholder groups); ",
          tooltip: "",
          condition: "yes",
          module: "Social",
          boldPart: "68 b",
        },
        {
          id: "q-881",
          question:
            "the time horizons under which the undertaking intends to complete each key action;",
          tooltip: "",
          condition: "yes",
          module: "Social",
          boldPart: "68 c",
        },
        {
          id: "q-882",
          question:
            "if applicable, key actions taken (along with results) to provide for and cooperate in or support the provision of remedy for those harmed by actual material impacts;",
          tooltip: "",
          condition: "yes",
          module: "Social",
          boldPart: "68 d",
        },
        {
          id: "q-883",
          question:
            "if applicable, quantitative and qualitative information regarding the progress of actions or action plans disclosed in prior periods. ",
          tooltip: "",
          condition: "yes",
          module: "Social",
          boldPart: "68 e",
        },
        {
          id: "q-884",
          question:
            "Where the implementation of an action plan requires significant operational expenditures (Opex) and/or capital expenditures (Capex) the undertaking shall: (a) describe the type of current and future financial and other resources allocated to the action plan, including if applicable, the relevant terms of sustainable finance instruments, such as green bonds, social bonds and green loans, the environmental or social objectives, and whether the ability to implement the actions or action plan depends on specific preconditions, e.g., granting of financial support or public policy and market developments; ",
          tooltip:
            "AR 23. Information on resource allocation may be presented in the form of a table and broken down between capital expenditure and operating expenditure, and across the relevant time horizons, and between resources applied in the current reporting year and the planned allocation of resources over specific time horizons.",
          condition: "yes",
          module: "Social",
          boldPart: "69 a",
        },
        {
          id: "q-885",
          question:
            "provide the amount of current financial resources and explain how they relate to the most relevant amounts presented in the financial statements;",
          tooltip: "",
          condition: "yes",
          module: "Social",
          boldPart: "69 b",
        },
        {
          id: "q-886",
          question: "provide the amount of future financial resources. ",
          tooltip: "",
          condition: "yes",
          module: "Social",
          boldPart: "69 c",
        },
        {
          id: "q-887",
          question:
            "If the undertaking cannot disclose the information on policies and actions required under relevant ESRS, because it has not adopted policies and/or actions with reference to the specific sustainability matter concerned, it shall disclose this to be the case, and provide reasons for not having adopted policies and/or actions. ",
          tooltip: "",
          condition: "no",
          module: "Social",
          boldPart: "62",
        },
        {
          id: "q-888",
          question: "The undertaking may disclose a timeframe in which it aims to adopt them. ",
          tooltip: "",
          condition: "no",
          module: "Social",
          boldPart: "62",
        },

        // Add more questions as needed
      ],
    "S2-5 – Targets related to managing material negative impacts, advancing positive impacts, and managing material risks and opportunities":
      [
        {
          id: "q-889",
          question:
            "The summarised description of the targets to manage its material impacts, risks and opportunities related to value chain workers shall contain the information requirements defined in ESRS 2 MDR-T. ",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 46. Targets related to material risks and opportunities may be the same as or
                distinct from targets related to material impacts. For example, a target to reach
                living wages for supply chain workers could both reduce impacts on those workers and
                reduce associated risks in terms of the quality and reliability of supply.
                <br />
                AR 47. The undertaking may also distinguish between short-, medium- and long-term
                targets covering the same policy commitment. For example, the undertaking may have a
                longterm target to achieve an 80% reduction in health and safety incidents affecting
                the workers of a given supplier by 2030 and a near-term target to reduce their
                overtime hours of delivery drivers by x% while maintaining their income by 2024.
                <br />
                AR 48. When modifying or replacing a target in the reporting period, the undertaking
                may explain the change by cross-referencing it to significant changes in the
                business model or to broader changes in the accepted standard or legislation from
                which the target is derived to provide contextual information, as per ESRS 2 BP-2
                Disclosures in relation to specific circumstances."
              </p>
            </Typography>
          ),
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "41",
        },
        {
          id: "q-890",
          question:
            "The undertaking shall disclose the process for setting the targets, including whether and how the undertaking engaged directly with workers in the value chain, their legitimate representatives, or with credible proxies that have insight into their situation in: (a) setting any such targets; (b) tracking the undertaking’s performance against them; and (c) identifying any lessons or improvements as a result of the undertaking’s performance.",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "42",
        },

        {
          id: "q-893",
          question:
            "When disclosing information about targets in accordance with paragraph 39, the undertaking may disclose: (a) the intended outcomes to be achieved in the lives of value chain workers, being as specific as possible;",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 45 a",
        },
        {
          id: "q-894",
          question:
            "the stability of the targets over time in terms of definitions and methodologies to enable comparability over time; ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "42 a",
        },
        {
          id: "q-895",
          question:
            "the standards or commitments on which the targets are based (for instance codes of conduct, sourcing policies, global frameworks or industry codes). ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S2 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "42 a",
        },
        {
          id: "q-896",
          question:
            "The undertaking shall disclose the measurable, outcome-oriented and time-bound targets on material sustainability matters it has set to assess progress. oor each target, the disclosure shall include the following information: (a) a description of the relationship of the target to the policy objectives;",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 a",
        },
        {
          id: "q-897",
          question:
            "the defined target level to be achieved, including, where applicable, whether the target is absolute or relative and in which unit it is measured;",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 b",
        },
        {
          id: "q-898",
          question:
            "the scope of the target, including the undertaking’s activities and/or its upstream and/or downstream value chain where applicable and geographical boundaries; ",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 c",
        },
        {
          id: "q-899",
          question: "the baseline value and base year from which progress is measured;",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 d",
        },
        {
          id: "q-900",
          question:
            "the period to which the target applies and if applicable, any milestones or interim targets;",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 e",
        },
        {
          id: "q-901",
          question:
            "the methodologies and significant assumptions used to define targets, including where applicable, the selected scenario, data sources, alignment with national, EU or international policy goals and how the targets consider the wider context of sustainable development and/or local situation in which impacts take place; ",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 f",
        },
        {
          id: "q-902",
          question:
            "whether the undertaking’s targets related to environmental matters are based on conclusive scientific evidence;",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 g",
        },
        {
          id: "q-903",
          question:
            "whether and how stakeholders have been involved in target setting for each material sustainability matter; ",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 h",
        },
        {
          id: "q-904",
          question:
            "any changes in targets and corresponding metrics or underlying measurement methodologies, significant assumptions, limitations, sources and processes to collect data adopted within the defined 17 time horizon. This includes an explanation of the rationale for those changes and their effect on comparability (see Disclosure Requirement BP-2 Disclosures in relation to specific circumstances of this Standard);",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 i",
        },
        {
          id: "q-905",
          question:
            "the performance against its disclosed targets, including information on how the target is monitored and reviewed and the metrics used, whether the progress is in line with what had been initially planned, and an analysis of trends or significant changes in the performance of the undertaking towards achieving the target. ",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 j",
        },
        {
          id: "q-906",
          question:
            ". If the undertaking has not set any measurable outcome-oriented targets: (a) it may disclose whether such targets will be set and the timeframe for setting them, or the reasons why the undertaking does not plan to set such targets;",
          tooltip: "",
          voluntary: true,
          module: "Social",
          condition: "no",
          boldPart: "81 a",
        },
        {
          id: "q-907",
          question:
            "it shall disclose whether it nevertheless tracks the effectiveness of its policies and actions in relation to the material sustainability-related impact, risk and opportunity,",
          tooltip: "",
          module: "Social",
          condition: "no",
          boldPart: "81 b",
        },

        {
          id: "q-908",
          question:
            "it shall disclose whether it nevertheless tracks the effectiveness of its policies and actions in relation to the material sustainability-related impact, risk and opportunity, and if so: i. any processes through which it does so",
          tooltip: "",
          module: "Social",
          condition: "no",
          boldPart: "81 b i",
        },
        {
          id: "q-909",
          question:
            "it shall disclose whether it nevertheless tracks the effectiveness of its policies and actions in relation to the material sustainability-related impact, risk and opportunity, and if so: the defined level of ambition to be achieved and any qualitative or quantitative indicators it uses to evaluate progress, including the base period from which progress is measured. ",
          tooltip: "",
          module: "Social",
          condition: "no",
          boldPart: "81 b ii, 80 d",
        },
        // Add more questions as needed
      ],
    /**********************************************
     * Tab: social
     * Topic: ESRS S3 AFFECTED COMMUNITIES
     **********************************************/
    "ESRS 2 SBM-3 - Material impacts, risks and opportunities and their interaction with strategy and business model":
      [
        {
          id: "q-910",
          question:
            "When fulfilling the requirements of paragraph 48, the undertaking shall disclose whether all affected communities who are likely to be materially impacted by the undertaking, including impacts that are connected with the undertaking’s own operations and value chain, including through its products or services, as well as through its business relationships, are included in the scope of its disclosure under ESRS 2. In addition, the undertaking shall provide the following information",
          tooltip:
            "AR 5. Impacts on affected communities can originate in the undertaking’s strategy or business model in a number of different ways. For example, impacts may relate to the undertaking’s  value proposition (such as, construction or commencement of projects with timelines that do not allow sufficient time for consultation with groups affected by the projects), its value chain  (for example, land-use in countries in which ownership is often contested or records are unreliable or in which land-users such as indigenous peoples are unrecognised), or its cost structure and the revenue model (such as, aggressive strategies to minimise taxation, particularly with respect to operations in developing countries). AR 6. Impacts on affected communities that originate in the strategy or business model can also bring material risks to the undertaking. For example, where the undertaking’s strategy involves moving into higher risk geographies in pursuit of certain commodities, and if affected communities resist its presence or object to its local practices, this may create extensive and costly delays, and affect the undertaking’s ability to secure future land concessions or permits. Similarly, if the undertaking’s business model relies on intensive water extraction at its plants, to the extent that it affects access to water for communities’ consumption, hygiene and livelihoods, this may result in reputationally-damaging boycotts, complaints and lawsuits. ",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement. ",
          module: "Social",
          boldPart: "9",
        },
        {
          id: "q-911",
          question:
            "a brief description of the types of communities subject to material impacts by its own operations or through its upstream and downstream value chain, and specify whether they are: ",
          tooltip:
            "Examples of particular characteristics of affected communities that may be considered by the undertaking when responding to paragraph 10 may be an affected community that is physically or economically isolated and is particularly susceptible to introduced diseases or has limited access to social services and therefore relies on infrastructure set up by the undertaking. It may be because where land worked by women is purchased by the undertaking and payments go to male heads of households, women become further disenfranchised in the community. It may also be because the community is indigenous, and its members seek to exercise cultural or economic rights to the land owned or used by the undertaking – or by one of the entities with which it has a business relationship – in a context where their rights are not protected by the state. In addition, the undertaking shall consider whether different characteristics overlap. For example, characteristics such as ethnicity, socioeconomic status, migrant status and gender may create overlapping risks of harm for certain affected communities, or for distinct parts of those affected communities, since affected communities are often heterogeneous in nature. ",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement. ",
          module: "Social",
          boldPart: "9 a",
        },
        {
          id: "q-912",
          question:
            "a brief description of the types of communities subject to material impacts by its own operations or through its upstream and downstream value chain, and specify whether they are: i) communities living or working around the undertaking’s operating sites, factories, facilities or other physical operations, or more remote communities affected by activities at those sites (for example by downstream water pollution); ii)communities along the undertaking’s value chain (for example, those affected by the operations of suppliers’ facilities or by the activities of logistics or distribution providers); iii) communities at one or both endpoints of the value chain (for example, at the point of extraction of metals or minerals or harvesting of commodities, or communities around waste or recycling sites); iv) communities of indigenous peoples",
          tooltip:
            "Examples of particular characteristics of affected communities that may be considered by the undertaking when responding to paragraph 10 may be an affected community that is physically or economically isolated and is particularly susceptible to introduced diseases or has limited access to social services and therefore relies on infrastructure set up by the undertaking. It may be because where land worked by women is purchased by the undertaking and payments go to male heads of households, women become further disenfranchised in the community. It may also be because the community is indigenous, and its members seek to exercise cultural or economic rights to the land owned or used by the undertaking – or by one of the entities with which it has a business relationship – in a context where their rights are not protected by the state. In addition, the undertaking shall consider whether different characteristics overlap. For example, characteristics such as ethnicity, socioeconomic status, migrant status and gender may create overlapping risks of harm for certain affected communities, or for distinct parts of those affected communities, since affected communities are often heterogeneous in nature. ",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement. ",
          module: "Social",
          boldPart: "9 a i-iv",
        },
        {
          id: "q-913",
          question:
            "in the case of material negative impacts, whether they are either (i) widespread or systemic in contexts where the undertaking operates or has sourcing or other business relationships (for example, marginalised populations suffering impacts on their health and quality of life in a highly industrialised area), or (ii) related to individual incidents in the undertaking’s own operations (e.g., a toxic waste spill affecting a community’s access to clean drinking water) or in a specific business relationship (e.g., a peaceful protest by communities against business operations that was met with a violent response from the undertaking’s security services). This includes consideration of impacts on affected communities that may arise from the transition to greener and climate- neutral operations. Potential impacts include impacts associated with innovation and restructuring, closure of mines, increased mining of minerals needed for the transition to a sustainable economy and solar panel production",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement. ",
          module: "Social",
          boldPart: "9 b",
        },
        {
          id: "q-914",
          question:
            "in the case of material positive impacts, a brief description of the activities that result in the positive impacts (for example, capacity-building to support more and new forms of local livelihoods) and the types of communities that are positively affected or could be positively affected; the undertaking may also disclose whether the positive impacts occur in specific countries or regions;",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement. ",
          module: "Social",
          boldPart: "9 c",
        },
        {
          id: "q-915",
          question:
            "any material risks and opportunities for the business arising from impacts and dependencies on affected communities. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement. ",
          module: "Social",
          boldPart: "9 d",
        },
        {
          id: "q-916",
          question:
            "In describing the main types of communities who are or could be negatively affected, based on the materiality assessment set out in Disclosure Requirement ESRS 2 IRO-1, the undertaking shall disclose whether and how it has developed an understanding of how affected communities with particular characteristics or those living in particular contexts, or those undertaking particular activities may be at greater risk of harm. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement. ",
          module: "Social",
          boldPart: "10",
        },
        {
          id: "q-917",
          question:
            "The undertaking shall disclose which, if any, of its material risks and opportunities arising from impacts and dependencies on affected communities relate to specific groups of affected communities rather than to all affected communities. ",
          tooltip:
            "With regard to paragraph 11, material risks could also arise because of the undertaking’s dependency on affected communities where low likelihood but high impact events may trigger financial effects; for example, where a natural disaster leads to a catastrophic industrial accident involving the undertaking’s operations, resulting in severe harm to affected communities. ",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement. ",
          module: "Social",
          boldPart: "11",
        },

        // Add more questions as needed
      ],
    "S3-1 – Policies related to affected communities": [
      {
        id: "q-918",
        question:
          "The disclosure required by paragraph 12 shall contain the information on the undertaking’s policies to manage its material impacts, risks and opportunities related to affected communities in accordance with ESRS 2 MDR-P Policies adopted to manage material sustainability matters. In addition, the undertaking shall specify if such policies cover specific affected communities or all affected communities. ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement. ",
        module: "Social",
        boldPart: "14",
      },
      {
        id: "q-919",
        question:
          "The undertaking shall disclose any particular policy provisions for preventing and addressing impacts on indigenous peoples. ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement. ",
        module: "Social",
        boldPart: "15",
      },
      {
        id: "q-920",
        question:
          "The undertaking shall describe its human rights policy commitments 4 that are relevant to affected communities, including those processes and mechanisms to monitor compliance with the UN Guiding Principles on Business and Human Rights, ILO Declaration on Fundamental Principles and Rights at Work or OECD Guidelines for Multinational Enterprises. In its disclosure it shall focus on those matters that are material in relation to5, as well as its general approach to:",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement. ",
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 9 Table #3 of Annex 1 and Indicator
              number 11 Table #1 of Annex 1{" "}
            </p>
          </Typography>
        ),
        module: "Social",
        boldPart: "16",
      },
      {
        id: "q-921",
        question:
          "Respect for the human rights of communities, and indigenous peoples specifically",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement. ",
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 9 Table #3 of Annex 1 and Indicator
              number 11 Table #1 of Annex 1{" "}
            </p>
          </Typography>
        ),
        module: "Social",
        boldPart: "16 a",
      },
      {
        id: "q-922",
        question: "Engagement with affected communities",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement. ",
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 9 Table #3 of Annex 1 and Indicator
              number 11 Table #1 of Annex 1{" "}
            </p>
          </Typography>
        ),
        module: "Social",
        boldPart: "16 b",
      },
      {
        id: "q-923",
        question: "Measures to provide and/or enable remedy for human rights impacts. ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement. ",
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 9 Table #3 of Annex 1 and Indicator
              number 11 Table #1 of Annex 1{" "}
            </p>
          </Typography>
        ),
        module: "Social",
        boldPart: "16 c",
      },
      {
        id: "q-924",
        question:
          "The undertaking shall disclose whether and how its policies with regard to affected communities are aligned with internationally recognised standards relevant to communities and indigenous peoples specifically, including the United Nations (UN) Guiding Principles on Business and Human Rights. The undertaking shall also disclose the extent to which cases of non-respect of the UN Guiding Principles on Business and Human Rights, ILO Declaration on Fundamental Principles and Rights at Work or OECD Guidelines for Multinational Enterprises that involve affected communities have been reported in its own operations or in its upstream and downstream value chain and, if applicable, an indication of the nature of such cases",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "When disclosing the alignment of its policies with the UN Guiding Principles on
              Business and Human Rights, the undertaking shall consider that the Guiding Principles
              refer to the International Bill of Rights, which consists of the Universal Declaration
              of Human Rights and the two Covenants that implement it, as well as the UN Declaration
              on the Rights of Indigenous Peoples, the International Labour Organisation’s
              Convention concerning Indigenous and Tribal Peoples (ILO No. 169) and the core
              conventions that underpin it, and may disclose its alignment with these instruments."
            </p>
          </Typography>
        ),
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement. ",
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 10 Table #1 Annex 1{" "}
            </p>
            <p className="question-tip">
              <strong>Benchmark Regulation reference:</strong> Delegated Regulation (EU) 2020/1816,
              Annex II Delegated Regulation (EU) 2020/1818, Art 12 (1)
            </p>
          </Typography>
        ),
        module: "Social",
        boldPart: "17",
      },
      {
        id: "q-925",
        question:
          "The description shall include the key information necessary to ensure a faithful representation of the policies in relation to affected communities and, therefore, the undertaking shall consider explanations of significant changes to the policies adopted during the reporting year (for example, new or additional approaches to engagement, due diligence and remedy).",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement. ",
        voluntary: true,
        module: "Social",
        boldPart: "AR 9",
      },
      {
        id: "q-926",
        question:
          "The undertaking may provide an illustration of the types of communication of its policies to those individuals, group of individuals or entities for whom they are relevant, either because they are 26 expected to implement them (for example, the undertaking’s employees, contractors and suppliers), or because they have a direct interest in their implementation (for example, own workers, investors). It may disclose communication tools and channels (for example, flyers, newsletters, dedicated websites, social media, face to face interactions, workers’ representatives), aimed at ensuring that the policy is accessible and that different audiences understand its implications. The undertaking may also explain how it identifies and removes potential barriers for dissemination, such as through translation into relevant languages or the use of graphic depictions. ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement. ",
        voluntary: true,
        module: "Social",
        boldPart: "AR 11",
      },
      {
        id: "q-927",
        question:
          "The undertaking shall disclose information about policies adopted to manage material sustainability matters. The disclosure shall include the following information: (a) a description of the key contents of the policy, including its general objectives and which material impacts, risks or opportunities the policy relates to and the process for monitoring; ",
        tooltip: "",
        condition: "yes",
        module: "Social",
        boldPart: "65 a",
      },
      {
        id: "q-928",
        question:
          "a description of the scope of the policy, or of its exclusions, in terms of activities, upstream and/or downstream value chain, geographies and if relevant, affected stakeholder groups;",
        tooltip: "",
        condition: "yes",
        module: "Social",
        boldPart: "65 b",
      },
      {
        id: "q-929",
        question:
          "the most senior level in the undertaking’s organisation that is accountable for the implementation of the policy;",
        tooltip: "",
        condition: "yes",
        module: "Social",
        boldPart: "65 c",
      },
      {
        id: "q-930",
        question:
          "a reference, if relevant, to the third-party standards or initiatives the undertaking commits to respect through the implementation of the policy;",
        tooltip: "",
        condition: "yes",
        module: "Social",
        boldPart: "65 d",
      },
      {
        id: "q-931",
        question:
          "if relevant, a description of the consideration given to the interests of key stakeholders in setting the policy; ",
        tooltip: "",
        condition: "yes",
        module: "Social",
        boldPart: "65 e",
      },
      {
        id: "q-932",
        question:
          "if relevant, whether and how the undertaking makes the policy available to potentially affected stakeholders, and stakeholders who need to help implement it. ",
        tooltip: "",
        condition: "yes",
        module: "Social",
        boldPart: "65 f",
      },
      {
        id: "q-933",
        question:
          "If the undertaking cannot disclose the information on policies and actions required under relevant ESRS, because it has not adopted policies and/or actions with reference to the specific sustainability matter concerned, it shall disclose this to be the case, and provide reasons for not having adopted policies and/or actions. ",
        tooltip: "",
        condition: "no",
        module: "Social",
        boldPart: "62",
      },
      {
        id: "q-934",
        question: "The undertaking may disclose a timeframe in which it aims to adopt them. ",
        tooltip: "",
        condition: "no",
        module: "Social",
        boldPart: "62",
      },
      // Add more questions as needed
    ],
    "S3-2 – Processes for engaging with affected communities about impacts": [
      {
        id: "q-935",
        question:
          "The undertaking shall disclose whether and how the perspectives of affected communities inform its decisions or activities aimed at managing actual and potential impacts on communities. This shall include, where relevant, an explanation of: ",
        tooltip:
          "AR 16. To illustrate how the perspectives of communities have informed specific decisions or activities of the undertaking, the undertaking may provide examples from the current reporting period. ",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
        module: "Social",
        boldPart: "21",
      },
      {
        id: "q-936",
        question:
          "whether engagement occurs with affected communities or their legitimate representatives directly, or with credible proxies that have insight into their situation;",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
        module: "Social",
        boldPart: "21 a",
      },
      {
        id: "q-937",
        question:
          "the stage(s) at which engagement occurs, the type of engagement, and the frequency of the engagement; ",
        tooltip: (
          <Typography>
            <p className="question-tip">
              When preparing the disclosures described in paragraph 20 b) and c), the following
              illustrations may be considered:
            </p>
            <ol className="question-tip" type="a">
              <li>
                examples of stages at which engagement occurs are
                <ol className="question-tip" type="i">
                  <li>determining the approach to mitigation and</li>
                  <li>evaluating the effectiveness of mitigation; </li>
                </ol>
              </li>
              <li>
                for type of engagement, these could be participation, consultation and/or
                information;
              </li>
              <li>
                for the frequency of the engagement, information may be provided on whether
                engagement occurs on a regular basis, at certain points in a project or business
                process; and
              </li>
              <li>
                for the role with operational responsibility, whether the undertaking requires
                relevant staff to have certain skills, or whether it provides training or capacity
                building to relevant staff to undertake engagement. In the case of material impacts,
                risks and opportunities related to indigenous peoples, this includes training on
                indigenous people’s rights, including on free, prior and informed consent.
              </li>
            </ol>
          </Typography>
        ),
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
        module: "Social",
        boldPart: "21 b",
      },
      {
        id: "q-938",
        question:
          "the function and the most senior role within the undertaking that has operational responsibility for ensuring this engagement happens, and that the results inform the undertaking’s approach;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 14. When describing which function or role has operational responsibility for such
              engagement and/or ultimate accountability, the undertaking may disclose whether this
              is a dedicated role or function or part of a broader role or function, and whether any
              capacity building activities have been offered to support the staff to undertake
              engagement. If it cannot identify such a position or function, it may state that this
              is the case. This disclosure could also be fulfilled by making reference to
              information disclosed according to ESRS 2 GOV-1 The role of the administrative,
              management and supervisory bodies.
            </p>
            <p className="question-tip">
              AR 15. When preparing the disclosures described in paragraph 20 b) and c), the
              following illustrations may be considered:
            </p>
            <ol className="question-tip" type="a">
              <li>
                examples of stages at which engagement occurs are
                <ol className="question-tip" type="i">
                  <li>determining the approach to mitigation and</li>
                  <li>evaluating the effectiveness of mitigation; </li>
                </ol>
              </li>
              <li>
                for type of engagement, these could be participation, consultation and/or
                information;
              </li>
              <li>
                for the frequency of the engagement, information may be provided on whether
                engagement occurs on a regular basis, at certain points in a project or business
                process; and
              </li>
              <li>
                for the role with operational responsibility, whether the undertaking requires
                relevant staff to have certain skills, or whether it provides training or capacity
                building to relevant staff to undertake engagement. In the case of material impacts,
                risks and opportunities related to indigenous peoples, this includes training on
                indigenous people’s rights, including on free, prior and informed consent.
              </li>
            </ol>
          </Typography>
        ),
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
        module: "Social",
        boldPart: "21 c",
      },
      {
        id: "q-939",
        question:
          "where applicable, how the undertaking assesses the effectiveness of its engagement with affected communities, including, where relevant, any agreements or outcomes that result. ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
        module: "Social",
        boldPart: "21 d",
      },
      {
        id: "q-940",
        question:
          "Where applicable, the undertaking shall disclose the steps it takes to gain insight into the perspectives of affected communities that may be particularly vulnerable to impacts and/or marginalised, and into the perspective of specific groups within the affected communities, such as women and girls.",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
        module: "Social",
        boldPart: "22",
      },
      {
        id: "q-941",
        question:
          "Where affected communities are indigenous peoples, the undertaking shall also disclose how it takes into account and ensures respect of their particular rights in its stakeholder engagement approach, including their right to free, prior and informed consent with regard: i) their cultural, intellectual, religious and spiritual property; ii) activities affecting their lands and territories; and iii) legislative or administrative measures that affect them. In particular, where engagement occurs with indigenous peoples, the undertaking shall also disclose whether and how indigenous peoples have been consulted on the mode and parameters of the engagement (for example, in designing the agenda, nature, and timeliness of the engagement).",
        tooltip:
          "AR 13. Explanations of how the undertaking takes into account and ensures respect of the right of indigenous peoples to free, prior and informed consent may include information about processes to consult with indigenous peoples to obtain such consent. The undertaking shall consider how the consultation includes a good faith negotiation with affected indigenous peoples to obtain their free, prior and informed consent where the undertaking affects the lands, territories or resources that indigenous peoples customarily own, occupy or otherwise use; or relocates them from land or territories subject to traditional ownership or under customary use or occupation; or affects or exploits their cultural, intellectual, religious and spiritual property.",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
        module: "Social",
        boldPart: "23",
      },
      {
        id: "q-942",
        question:
          "If the undertaking cannot disclose the above required information because it has not adopted a general process to engage with affected communities, it shall disclose this to be the case. It may disclose a timeframe in which it aims to have such a process in place. ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
        voluntary: true,
        module: "Social",
        boldPart: "24",
      },
      // Add more questions as needed
    ],
    "S3-3 – Processes to remediate negative impacts and channels for affected communities to raise concerns":
      [
        {
          id: "q-943",
          question:
            "Undertaking shall describe its general approach to and processes for providing or contributing to remedy where it has identified that it has caused or contributed to a material negative impact on affected communities, including whether and how the undertaking assesses that the remedy provided is effective; ",
          tooltip:
            "AR 17. In fulfilling the requirements set out by Disclosure Requirement ESRS S3-3, the undertaking may be guided by the content of the UN Guiding Principles on Business and Human Rights and the OECD Guidelines for Multinational Enterprises focused on remediation and grievance mechanisms.  AR 22. When disclosing processes related to providing and enabling remedy for indigenous peoples, relevant information includes whether and how the undertaking has considered their customs, traditions, rules and legal systems.",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "27 a",
        },
        {
          id: "q-944",
          question:
            "any specific channels it has in place for affected communities to raise their concerns or needs directly with the undertaking and have them addressed, including whether these are established by the undertaking itself and/or through participation in third-party mechanisms; ",
          tooltip:
            "AR 18.Channels for raising concerns or needs, include grievance mechanisms, hotlines, dialogue processes or other means through which affected communities or their legitimate representatives can raise concerns about impacts or explain needs that they would like the undertaking to address. This could include channels provided by the undertaking directly, in addition to any other mechanisms the undertaking may use to gain insight into the management of impacts on communities, such as compliance audits. Where the undertaking is relying solely on information about the existence of such channels provided by its business relationships to answer this requirement, it may state that.",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "27 b",
        },
        {
          id: "q-945",
          question:
            "its processes through which the undertaking supports the availability of such channels by its business relationships; ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "27 c",
        },
        {
          id: "q-946",
          question:
            "how it tracks and monitors issues raised and addressed, and how it ensures the effectiveness of the channels, including through involvement of stakeholders who are the intended users of those channels.",
          tooltip: (
            <Typography>
              <p className="question-tip">
                AR 24. In describing the effectiveness of channels for affected communities to raise
                concerns, the undertaking may be guided by the following questions, based on the
                “effectiveness criteria for non- judicial grievance mechanisms”, as laid out in the
                UN Guiding Principles on Business and Human Rights, in particular Principle 31. The
                below considerations may be applied on an individual channel basis or for the
                collective system of channels:
              </p>
              <ol className="question-tip" type="a">
                <li>
                  do the channels hold legitimacy by providing appropriate accountability for their
                  fair conduct and building stakeholder trust?
                </li>
                <li>are the channels known and accessible to stakeholders? </li>
                <li>
                  do the channels have clear and known procedures, set timeframes and clarity on the
                  processes?
                </li>
                <li>
                  do the channels ensure reasonable access to sources of information, advice and
                  expertise?
                </li>
                <li>
                  do the channels offer transparency by providing sufficient information both to
                  complainants and, where applicable, to meet any public interest at stake?
                </li>
                <li>
                  do the outcomes achieved through the channels accord with internationally
                  recognised human rights?
                </li>
                <li>
                  does the undertaking identify insights from the channels that support continuous
                  learning in both improving the channels and preventing future impacts?
                </li>
                <li>
                  does the undertaking focus on dialogue with complainants as the means to reach
                  agreed solutions, rather than seeking to unilaterally determine the outcome?
                </li>
              </ol>
            </Typography>
          ),
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "27 d",
        },
        {
          id: "q-947",
          question:
            "The undertaking shall disclose whether and how it assesses that affected communities are aware of and trust these structures or processes as a way to raise their concerns or needs and have them addressed. In addition, the undertaking shall disclose whether it has policies in place regarding the protection of individuals that use them against retaliation. If such information has been disclosed in accordance with ESRS G1-1, the undertaking may refer to that information. ",
          tooltip:
            "AR 23. In disclosing whether and how the undertaking knows that affected communities are aware of and trust any of these channels, the undertaking may provide relevant and reliable data about the effectiveness of these channels from the perspective of affected communities themselves. Examples of sources of information are surveys of community members that have used such channels and their levels of satisfaction with the process and outcomes.",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "28",
        },
        {
          id: "q-948",
          question:
            "If the undertaking cannot disclose the above required information because it has not adopted a channel for raising concerns and/or does not support the availability of such a channel by its business relationships, it shall disclose this to be the case. It may disclose a timeframe in which it aims to have such a channel or processes in place. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "29",
        },
        {
          id: "q-949",
          question:
            "To provide greater insight into the information covered in ESRS S3-3, the undertaking may explain whether and how communities that may be affected are able to access channels at the level of the undertaking they are affected by, in relation to each material impact. Relevant insights include information on whether affected communities can access channels in a language they understand, and whether they have been consulted in the design of such channels.",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 19",
        },
        {
          id: "q-950",
          question:
            "Third party mechanisms could include those operated by the government, NGOs, industry associations and other collaborative initiatives. The undertaking may disclose whether these are accessible to all affected communities who may be potentially or actually materially impacted by the undertaking, or individuals or organisations acting on their behalf or who are otherwise in a position to be aware of negative impacts.",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 20",
        },
        {
          id: "q-951",
          question:
            "In relation to the protection of individuals that use the mechanisms against retaliation, the undertaking may describe whether it treats grievances confidentially and with respect to the rights of privacy and data protection; and whether to the mechanisms can be used anonymously (for example, through representation by a third party).",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 21",
        },

        // Add more questions as needed
      ],
    "S3-4 – Taking action on material impacts on affected communities, and approaches to managing material risks and pursuing material opportunities related to affected communities, and effectiveness of those actions":
      [
        {
          id: "q-952",
          question:
            "actions taken, planned or underway to prevent or mitigate material negative impacts on affected communities; ",
          tooltip: (
            <Typography>
              <p className="question-tip">
                AR 28. Impacts on communities may stem from environmental matters which are
                disclosed by the undertaking under the ESRS E1 to E5. Examples include:
              </p>
              <ol className="question-tip" type="a">
                <li>
                  ESRS E1 Climate Change: The implementation of climate change mitigation plans may
                  require the undertaking to invest in renewable energy projects that may affect the
                  lands, territories and natural resources of indigenous peoples. If the undertaking
                  does not consult with the affected indigenous peoples , it could negatively impact
                  the affected communities’ right to free, prior and informed consent;
                </li>
                <li>
                  ESRS E2 Pollution: The undertaking may negatively impact affected communities by
                  failing to protect them from pollution from a particular production facility that
                  causes them health-related issues;
                </li>
                <li>
                  ESRS E3 Water and marine sources: The undertaking may negatively impact the access
                  to clean drinking water of communities when withdrawing water in water stressed
                  areas; 29
                </li>
                <li>
                  ESRS E4 Biodiversity and ecosystems: The undertaking may negatively affect the
                  livelihood of local farmers through operations that contaminate soil. Additional
                  examples include the sealing of land through building new infrastructure, which
                  can eradicate plant species that are critical for, for example, local biodiversity
                  or to filter water for communities; or the introduction of invasive species
                  (whether plants or animals) that can impact ecosystems and cause subsequent harm;
                  (e) ESRS E5 Resource use and circular economy: The undertaking may negatively
                  impact the lives of communities by affecting their health through the
                  mismanagement of hazardous waste. Where the connection between environmental
                  impacts and local communities is addressed in the disclosures within the
                  Disclosure Requirements ESRS E1-E5, the undertaking may crossreference to those
                  and clearly identify such disclosures.
                </li>
              </ol>
              <p className="question-tip">
                AR 29. When the undertaking discloses its participation in an industry or
                multi-stakeholder initiative as part of its actions to address material negative
                impacts, the undertaking may disclose how the initiative, and its own involvement,
                is aiming to address the material impact concerned. It may disclose under ESRS S3-5
                the relevant targets set by the initiative and progress towards them. AR 36. When
                disclosing the intended or achieved positive outcomes of its actions for affected
                communities a distinction is to be made between evidence of certain activities
                having occurred (for example, that x number of women community members have been
                provided with 30 training on how to become local suppliers to the undertaking) from
                evidence of actual outcomes for affected communities (for example, that x women
                community members have set up small businesses and have had their contracts with the
                undertaking renewed year-onyear).
              </p>
            </Typography>
          ),

          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "32 a",
        },
        {
          id: "q-953",
          question:
            "whether and how it has taken action to provide or enable remedy in relation to an actual material impact;",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "32 b",
        },
        {
          id: "q-954",
          question:
            "any additional actions or initiatives it has in place with the primary purpose of delivering positive impacts for affected communities;",
          tooltip:
            "AR 37. When disclosing whether initiatives or processes also play a role in mitigating material negative impacts, the undertaking may for example consider programmes that aim to improve local infrastructure surrounding an undertaking’s operations, such as improvements in roads leading to a reduction in the number of severe traffic accidents involving community members.",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "32 c",
        },
        {
          id: "q-955",
          question:
            "how it tracks and assesses the effectiveness of these actions and intiatives in delivering intended outcomes for affected communities.",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 31. In disclosing how it tracks the effectiveness of actions to manage material
                impacts during the reporting period, the undertaking may disclose any lessons
                learned from the previous and current reporting periods. <br />
                AR 32. Processes used to track the effectiveness of actions can include internal or
                external auditing or verification, court proceedings and/or related court decisions,
                impact assessments, measurement systems, stakeholder feedback, grievance mechanisms,
                external performance ratings, and benchmarking. <br />
                AR 33. Reporting on effectiveness is aimed at enabling the understanding of the
                links between actions taken by an undertaking and the effective management of
                impacts.
              </p>
            </Typography>
          ),

          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "32 d",
        },
        {
          id: "q-956",
          question:
            "the processes through which it identifies what action is needed and appropriate in response to a particular actual or potential negative impact on affected communities; ",
          tooltip:
            "AR 26. Appropriate action can vary according to whether the undertaking causes or contributes to a material impact, or whether the material impact is directly linked to its own operations, products or services through a business relationship. ",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "33 a",
        },
        {
          id: "q-957",
          question:
            "its approach to taking action in relation to specific material negative impacts on communities, including any action in relation to its own practices regarding land acquisition, planning and construction, operation or closure practices, as well as whether wider industry or collaborative action with other relevant parties will be required",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "33 b",
        },
        {
          id: "q-958",
          question:
            "how it ensures that processes to provide or enable remedy in the event of material negative impacts are available and effective in their implementation and outcomes. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "33 c",
        },
        {
          id: "q-959",
          question:
            "what action is planned or underway to mitigate material risks for the undertaking arising from its impacts and dependencies on affected communities and how it tracks effectiveness in practice",
          tooltip: (
            <Typography>
              <p className="question-tip">
                AR 38. When disclosing the material risks and opportunities related to the
                undertaking’s impacts or dependencies on affected communities, the undertaking may
                consider the following:
              </p>
              <ol className="question-tip" type="a">
                <li>
                  risks related to the undertaking’s impacts on affected communities may include the
                  reputational or legal exposure, as well as operational risks, where affected
                  communities protest against resettlements or the loss of access to lands, leading
                  to costly delays, boycotts, or lawsuits;
                </li>
                <li>
                  risks related to the undertaking’s dependencies on affected communities may
                  include disruption of business operations where indigenous peoples decide to
                  withdraw their consent to a project on their lands, forcing the undertaking to
                  significantly modify or abandon the project;
                </li>
                <li>
                  business opportunities related to the undertaking’s impacts on affected
                  communities may include more easily financing projects and being a partner of
                  choice for communities, governments and other businesses; and
                </li>
                <li>
                  opportunities related to the undertaking’s dependencies on affected communities
                  may include the development of positive relationships between the undertaking and
                  indigenous peoples that enable existing projects to expand with strong support.
                </li>
              </ol>
              <p className="question-tip">
                AR 39. In disclosing the information in AR 41, the undertaking may consider
                explanations of risks and opportunities stemming from environmental impacts or
                dependencies (please refer to AR 31 for further details), including related human
                rights (or social) impacts. Examples include reputational risks stemming from the
                impact on the health of communities of unmanaged polluting discharges; or the
                financial effects of protests that may disrupt or interrupt an undertaking’s
                activities, for example, in response to operations in water stressed areas that may
                impact the lives of affected communities. <br />
                AR 40. When disclosing whether dependencies turn into risks, the undertaking shall
                consider external developments. AR 42. The undertaking shall consider whether and
                how its processes to manage material risks related to affected communities are
                integrated into its existing risk management processes.
              </p>
            </Typography>
          ),

          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "34 a",
        },
        {
          id: "q-960",
          question:
            "what action is planned or underway to pursue material opportunities for the undertaking in relation to affected communities. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "34 b",
        },
        {
          id: "q-961",
          question:
            "The undertaking shall disclose whether and how it takes action to avoid causing or contributing to material negative impacts on affected communities through its own practices, including, where relevant, in relation to planning, land acquisition and exploitation, finance, extraction or production of raw materials, use of natural resources, and management of environmental impacts. This may include disclosing what approach is taken when tensions arise between the prevention or mitigation of material negative impacts and other business pressures. ",
          tooltip:
            "AR 30. When disclosing whether and how the undertaking considers actual and potential impacts on affected communities in decisions to terminate business relationships and whether and how it seeks to address any negative impacts that may result from termination, the undertaking may include examples. ",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "35",
        },
        {
          id: "q-962",
          question:
            "The undertaking shall also disclose whether severe human rights issues and incidents connected to affected communities have been reported and, if applicable, disclose these",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          euReg: (
            <Typography>
              <p className="question-tip">
                <strong>SFDR reference:</strong> Indicator number 14 Table #3 of Annex 1{" "}
              </p>
            </Typography>
          ),
          module: "Social",
          boldPart: "36",
        },
        {
          id: "q-963",
          question:
            "The undertaking shall disclose what resources are allocated to the management of its material impacts, with information that enables users to gain an understanding of how the material impacts are managed.",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "38",
        },
        {
          id: "q-964",
          question:
            " It may take time to understand negative impacts and how the undertaking may be involved with them through its value chain, as well as to identify appropriate responses and put them into practice. Therefore, the undertaking shall consider: (a) its general and specific approaches to addressing material negative impacts; ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 25 a",
        },
        {
          id: "q-965",
          question:
            "its social investment or other development programmes aimed at contributing to additional material positive impacts;",
          tooltip:
            "AR 37. When disclosing whether initiatives or processes also play a role in mitigating material negative impacts, the undertaking may for example consider programmes that aim to improve local infrastructure surrounding an undertaking’s operations, such as improvements in roads leading to a reduction in the number of severe traffic accidents involving community members. ",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 25 b",
        },
        {
          id: "q-966",
          question: "how far it has progressed in its efforts during the reporting period;",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 25 c",
        },
        {
          id: "q-967",
          question: "its aims for continued improvement.",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 25 d",
        },
        {
          id: "q-968",
          question:
            "Given that material negative impacts affecting communities that have occurred during the reporting period may also be linked to entities or operations outside its direct control, the undertaking may disclose whether and how it seeks to use its leverage in its business relationships to manage those impacts. This may include using commercial leverage (for example, enforcing contractual requirements with business relationships or implementing incentives), other forms of leverage within the relationship (such as providing training or capacity-building on the rights of indigenous peoples to entities with which the undertaking has a business relationships) or collaborative leverage with peers or other actors (such as initiatives aimed at minimising security-related impacts on communities or participating in company-community partnerships).",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 27",
        },
        {
          id: "q-969",
          question:
            " Impacts on communities may stem from environmental matters which are disclosed by the undertaking under the ESRS E1 to E5. Examples include: (a) ESRS E1 Climate Change: The implementation of climate change mitigation plans may require the undertaking to invest in renewable energy projects that may affect the lands, territories and natural resources of indigenous peoples. If the undertaking does not consult with the affected indigenous peoples , it could negatively impact the affected communities’ right to free, prior and informed consent; (b) ESRS E2 Pollution: The undertaking may negatively impact affected communities by failing to protect them from pollution from a particular production facility that causes them health-related issues; (c) ESRS E3 Water and marine sources: The undertaking may negatively impact the access to clean drinking water of communities when withdrawing water in water stressed areas; (d) ESRS E4 Biodiversity and ecosystems: The undertaking may negatively affect the livelihood of local farmers through operations that contaminate soil. Additional examples include the sealing of land through building new infrastructure, which can eradicate plant species that are critical for, for example, local biodiversity or to filter water for communities; or the introduction of invasive species (whether plants or animals) that can impact ecosystems and cause subsequent harm; (e) ESRS E5 Resource use and circular economy: The undertaking may negatively impact the lives of communities by affecting their health through the mismanagement of hazardous waste. Where the connection between environmental impacts and local communities is addressed in the disclosures within the Disclosure Requirements ESRS E1-E5, the undertaking may cross-reference to those and clearly identify such disclosures.",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 28",
        },
        {
          id: "q-970",
          question:
            "With regard to initiatives or processes whose primary aim is to deliver positive impacts for affected communities that are based on affected communities’ needs, and with regard to progress in the implementation of such initiatives or processes, the undertaking may disclose: (a) information about whether and how affected communities and legitimate representatives or their credible proxies play a role in decisions regarding the design and implementation of these investments or programmes; ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 34 a",
        },
        {
          id: "q-971",
          question:
            "information about the intended or achieved positive outcomes for affected communities of these investments or programmes. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 34 b",
        },
        {
          id: "q-972",
          question:
            "an explanation of the approximate scope of affected communities covered by the described social investment or development programmes, and, where applicable, the rationale for why selected communities were chosen for a given social investment or development programme's implementation. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 34 c",
        },
        {
          id: "q-973",
          question:
            "The undertaking may disclose whether any initiatives or processes whose primary aim is to deliver positive impacts for affected communities are designed also to support the achievement of one or more of the UN Sustainable Development Goals (SDGs). For example, through a commitment to advance UN SDG 5 to “achieve gender equality and empower all women and girls” the undertaking may be taking thoughtful measures to include women in the consultation process with an affected community to meet standards of effective stakeholder engagement, which can help empower the women in the process itself, but potentially also in their daily lives. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 35",
        },
        {
          id: "q-974",
          question:
            "When disclosing the resources allocated to the management of material impacts, the undertaking may disclose which internal functions are involved in managing the impacts and what types of action they take to address negative and advance positive impacts.",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 44",
        },
        {
          id: "q-975",
          question:
            "Where the implementation of a policy requires actions, or a comprehensive action plan, to achieve its objectives, as well as when actions are implemented without a specific policy, the undertaking shall disclose the following information: (a) the list of key actions taken in the reporting year and planned for the future, their expected outcomes and, where relevant, how their implementation contributes to the achievement of policy objectives and targets;",
          tooltip:
            "AR 22. Key actions in the context of this Minimum Disclosure Requirement are those actions that materially contribute to achieving the undertaking’s objectives in addressing material impacts, risks and opportunities. oor reasons of understandability, key actions may be aggregated where appropriate. ",
          condition: "yes",
          module: "Social",
          boldPart: "68 a",
        },
        {
          id: "q-976",
          question:
            "the scope of the key actions (i.e., coverage in terms of activities, upstream and/or downstream value chain, geographies and, where applicable, affected stakeholder groups); ",
          tooltip: "",
          condition: "yes",
          module: "Social",
          boldPart: "68 b",
        },
        {
          id: "q-977",
          question:
            "the time horizons under which the undertaking intends to complete each key action;",
          tooltip: "",
          condition: "yes",
          module: "Social",
          boldPart: "68 c",
        },
        {
          id: "q-978",
          question:
            "if applicable, key actions taken (along with results) to provide for and cooperate in or support the provision of remedy for those harmed by actual material impacts;",
          tooltip: "",
          condition: "yes",
          module: "Social",
          boldPart: "68 d",
        },
        {
          id: "q-979",
          question:
            "if applicable, quantitative and qualitative information regarding the progress of actions or action plans disclosed in prior periods. ",
          tooltip: "",
          condition: "yes",
          module: "Social",
          boldPart: "68 e",
        },
        {
          id: "q-980",
          question:
            "Where the implementation of an action plan requires significant operational expenditures (Opex) and/or capital expenditures (Capex) the undertaking shall: (a) describe the type of current and future financial and other resources allocated to the action plan, including if applicable, the relevant terms of sustainable finance instruments, such as green bonds, social bonds and green loans, the environmental or social objectives, and whether the ability to implement the actions or action plan depends on specific preconditions, e.g., granting of financial support or public policy and market developments; ",
          tooltip:
            "AR 23. Information on resource allocation may be presented in the form of a table and broken down between capital expenditure and operating expenditure, and across the relevant time horizons, and between resources applied in the current reporting year and the planned allocation of resources over specific time horizons.",
          condition: "yes",
          module: "Social",
          boldPart: "69 a",
        },
        {
          id: "q-981",
          question:
            "provide the amount of current financial resources and explain how they relate to the most relevant amounts presented in the financial statements;",
          tooltip: "",
          condition: "yes",
          module: "Social",
          boldPart: "69 b",
        },
        {
          id: "q-982",
          question: "provide the amount of future financial resources. ",
          tooltip: "",
          condition: "yes",
          module: "Social",
          boldPart: "69 c",
        },
        {
          id: "q-983",
          question:
            "If the undertaking cannot disclose the information on policies and actions required under relevant ESRS, because it has not adopted policies and/or actions with reference to the specific sustainability matter concerned, it shall disclose this to be the case, and provide reasons for not having adopted policies and/or actions. ",
          tooltip: "",
          condition: "no",
          module: "Social",
          boldPart: "62",
        },
        {
          id: "q-984",
          question: "The undertaking may disclose a timeframe in which it aims to adopt them. ",
          tooltip: "",
          condition: "no",
          module: "Social",
          boldPart: "62",
        },

        // Add more questions as needed
      ],
    "S3-5 – Targets related to managing material negative impacts, advancing positive impacts, and managing material risks and opportunities":
      [
        {
          id: "q-985",
          question:
            "The summarised description of the targets to manage its material impacts, risks and opportunities related to affected communities shall contain the information requirements defined in ESRS 2 MDR-T. ",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 45. Targets related to material risks and opportunities may be the same as or
                distinct from targets related to material impacts. For example, a target to fully
                restore livelihoods of affected communities following resettlement could both reduce
                impacts on those communities and reduce associated risks such as community protests.
                <br />
                AR 46. The undertaking may also distinguish between short-, medium- and long-term
                targets covering the same policy commitment. For example, the undertaking may have
                as a main objective to employ community members at a local mining site, with the
                long-term goal of staffing 100% locally by 2025, and with the short-term objective
                of adding x percent of local employees every year up and until 2025. <br />
                AR 47. When modifying or replacing a target in the reporting period, the undertaking
                may explain the change by linking it to significant changes in the business model or
                to broader changes in the accepted standard or legislation from which the target is
                derived to provide contextual information as per ESRS 2 BP-2 Disclosures in relation
                to specific circumstances. "
              </p>
            </Typography>
          ),

          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "41",
        },
        {
          id: "q-986",
          question:
            "The undertaking shall disclose the process for setting the targets, including whether and how the undertaking engaged directly with affected communities, their legitimate representatives, or with credible proxies that have insight into their situation in: (a) setting any such targets;",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "42 a",
        },
        {
          id: "q-987",
          question: "tracking the undertaking’s performance against them",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "42 b",
        },
        {
          id: "q-988",
          question:
            "identifying, any, lessons or improvements as a result of the undertaking’s performance. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          module: "Social",
          boldPart: "42 c",
        },
        {
          id: "q-989",
          question:
            "When disclosing information about targets in accordance with paragraph 38, the undertaking may disclose: 31 (a) the intended outcomes to be achieved in the lives of affected communities, being as specific as possible; ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 44 a",
        },

        {
          id: "q-990",
          question:
            "the stability of the targets over time in terms of definitions and methodologies to enable comparability over time;",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 44 b",
        },
        {
          id: "q-991",
          question:
            "the standards or commitments which the targets are based on (for instance codes of conduct, sourcing policies, global frameworks or industry codes).",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S3 for the first 2 years of preparation of their sustainability statement.",
          voluntary: true,
          module: "Social",
          boldPart: "AR 44 c",
        },
        {
          id: "q-992",
          question:
            "The undertaking shall disclose the measurable, outcome-oriented and time-bound targets on material sustainability matters it has set to assess progress. oor each target, the disclosure shall include the following information: (a) a description of the relationship of the target to the policy objectives;",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 a",
        },
        {
          id: "q-993",
          question:
            "the defined target level to be achieved, including, where applicable, whether the target is absolute or relative and in which unit it is measured;",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 b",
        },
        {
          id: "q-994",
          question:
            "the scope of the target, including the undertaking’s activities and/or its upstream and/or downstream value chain where applicable and geographical boundaries; ",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 c",
        },
        {
          id: "q-995",
          question: "the baseline value and base year from which progress is measured;",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 d",
        },
        {
          id: "q-996",
          question:
            "the period to which the target applies and if applicable, any milestones or interim targets;",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 e",
        },
        {
          id: "q-997",
          question:
            "the methodologies and significant assumptions used to define targets, including where applicable, the selected scenario, data sources, alignment with national, EU or international policy goals and how the targets consider the wider context of sustainable development and/or local situation in which impacts take place; ",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 f",
        },
        {
          id: "q-998",
          question:
            "whether the undertaking’s targets related to environmental matters are based on conclusive scientific evidence;",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 g",
        },
        {
          id: "q-999",
          question:
            "whether and how stakeholders have been involved in target setting for each material sustainability matter; ",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 h",
        },
        {
          id: "q-1000",
          question:
            "any changes in targets and corresponding metrics or underlying measurement methodologies, significant assumptions, limitations, sources and processes to collect data adopted within the defined 17 time horizon. This includes an explanation of the rationale for those changes and their effect on comparability (see Disclosure Requirement BP-2 Disclosures in relation to specific circumstances of this Standard);",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 i",
        },
        {
          id: "q-1001",
          question:
            "the performance against its disclosed targets, including information on how the target is monitored and reviewed and the metrics used, whether the progress is in line with what had been initially planned, and an analysis of trends or significant changes in the performance of the undertaking towards achieving the target. ",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 j",
        },
        {
          id: "q-1002",
          question:
            ". If the undertaking has not set any measurable outcome-oriented targets: (a) it may disclose whether such targets will be set and the timeframe for setting them, or the reasons why the undertaking does not plan to set such targets;",
          tooltip: "",
          voluntary: true,
          module: "Social",
          condition: "no",
          boldPart: "81 a",
        },
        {
          id: "q-1003",
          question:
            "it shall disclose whether it nevertheless tracks the effectiveness of its policies and actions in relation to the material sustainability-related impact, risk and opportunity,",
          tooltip: "",
          module: "Social",
          condition: "no",
          boldPart: "81 b",
        },

        {
          id: "q-1004",
          question:
            "it shall disclose whether it nevertheless tracks the effectiveness of its policies and actions in relation to the material sustainability-related impact, risk and opportunity, and if so: i. any processes through which it does so",
          tooltip: "",
          module: "Social",
          condition: "no",
          boldPart: "81 b i",
        },
        {
          id: "q-1005",
          question:
            "it shall disclose whether it nevertheless tracks the effectiveness of its policies and actions in relation to the material sustainability-related impact, risk and opportunity, and if so: the defined level of ambition to be achieved and any qualitative or quantitative indicators it uses to evaluate progress, including the base period from which progress is measured. ",
          tooltip: "",
          module: "Social",
          condition: "no",
          boldPart: "81 b ii, 80 d",
        },
        // Add more questions as needed
      ],
    /**********************************************
     * Tab: social
     * Topic: ESRS S4 CONSUMERS AND END-USERS
     **********************************************/
    "S4 ESRS 2 SBM-3 – Material impacts, risks and opportunities and their interaction with strategy and business model":
      [
        {
          id: "q-1006",
          question:
            "When fulfilling the requirements of paragraph 48, the undertaking shall disclose whether all consumers and/or end-users who are likely to be materially impacted by the undertaking, including impacts connected with the undertaking’s own operations and value chain, including through its products or services, as well as through its business relationships, are included in the scope of its disclosure under ESRS 2. In addition, the undertaking shall disclose the following information",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 5. Impacts on consumers and/or end-users can originate in the undertaking’s
                business model or strategy in a number of different ways. For example, impacts may
                relate to the undertaking’s value proposition (for example, providing online
                platforms with potential for online and offline harm), its value chain (for example,
                speed in developing products or services, or delivering projects, with risks to
                health and safety), or its cost structure and the revenue model (for example,
                sales-maximising incentives that put consumers at risk). <br />
                AR 6. Impacts on consumers and/or end-users that originate in the strategy or
                business model can also bring material risks to the undertaking. For example, if the
                undertaking’s business model is premised on incentivising its sales force to sell
                high volumes of a product or service (for example, credit cards or pain medicine) at
                speed, and this results in large- scale harm to consumers, the undertaking may face
                lawsuits and reputational damage affecting its future business and credibility. "
              </p>
            </Typography>
          ),
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          module: "Social",
          boldPart: "10",
        },
        {
          id: "q-1007",
          question:
            "a brief description of the types of consumers and/or end-users subject to material impacts by its own operations or through its value chain, and specify whether they are",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          module: "Social",
          boldPart: "10 a",
        },
        {
          id: "q-1008",
          question:
            "i) consumers and/or end-users of products that are inherently harmful to people and/or increase risks for chronic disease; ii) consumers and/or end-users of services that potentially negatively impact their rights to privacy, to have their personal data protected, to freedom of expression and to non- discrimination; iii) consumers and/or end-users who are dependent on accurate and accessible product- or service- related information, such as manuals and product labels, to avoid potentially damaging use of a product or service; iv) consumers and/or end-users who are particularly vulnerable to health or privacy impacts or impacts from marketing and sales strategies, such as children or financially vulnerable individuals;",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          module: "Social",
          boldPart: "10 a i-iv",
        },
        {
          id: "q-1009",
          question:
            "in the case of material negative impacts, whether they are either (i) widespread or systemic in contexts where the undertaking sells or provides its products or services (for example, state surveillance that affects the privacy of service users), or (ii) related to individual incidents (for example, a defect linked to a particular product) or to specific business relationships (for example, a business partner uses marketing that inappropriately targets young consumers);",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          module: "Social",
          boldPart: "10 b",
        },
        {
          id: "q-1010",
          question:
            "in the case of material positive impacts, a brief description of the activities that result in the positive impacts (for example, product design that improves its accessibility for persons with disabilities) and the types of consumers and/or end-users that are positively affected or could be positively affected; the undertaking may also disclose whether the positive impacts occur in specific countries or regions;",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          module: "Social",
          boldPart: "10 c",
        },
        {
          id: "q-1011",
          question:
            "any material risks and opportunities for the business arising from impacts and dependencies on consumers and/or end-users. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          module: "Social",
          boldPart: "10 d",
        },
        {
          id: "q-1012",
          question:
            "In describing the main types of consumers and/or end-users who are or could be negatively affected, based on the materiality assessment set out in ESRS 2 IRO-1, the undertaking shall disclose whether and how it has developed an understanding of how consumers and/or end-users with particular characteristics, or those using particular products or services, may be at greater risk of harm. ",
          tooltip:
            "AR 7. Examples of particular characteristics of consumers and/or end-users that may be considered by the undertaking when responding to paragraph 11 include young consumers and/or end-users who may be more susceptible to impacts on their physical and mental development, or who lack financial literacy and may be more susceptible to exploitative sales or marketing practices. They may also include women in a context where women are routinely discriminated against in their access to particular services or in the marketing of particular products. ",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          module: "Social",
          boldPart: "11",
        },
        {
          id: "q-1013",
          question:
            "The undertaking shall disclose which, if any, of its material risks and opportunities arising from impacts and dependencies on consumers and/or end-users, relate to specific groups of consumers and/or end-users (for example, particular age groups) rather than to all consumers and/or end-users.",
          tooltip:
            "AR 8. With regard to paragraph 12, the risks could arise because of the undertaking’s dependency on consumers and/or end-users where low likelihood but high impact events may trigger financial effects, for example, where a global pandemic leads to severe impacts on certain consumers’ livelihoods resulting in major changes in patterns of consumption. ",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          module: "Social",
          boldPart: "12",
        },
        // Add more questions as needed
      ],
    "S4-1 – Policies related to consumers and end-users": [
      {
        id: "q-1014",
        question:
          "The disclosure required by paragraph 13 shall contain the information on the undertaking’s policies to manage its material impacts, risks and opportunities related to consumers and/or end-users in accordance with ESRS 2 MDR-P Policies adopted to manage material sustainability matters. In addition, the undertaking shall specify if such policies cover specific groups or all consumers and/or end-users. ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
        module: "Social",
        boldPart: "15",
      },
      {
        id: "q-1015",
        question:
          "The undertaking shall describe its human rights policy commitments9 that are relevant to consumers and/or end-users, including those processes and mechanisms to monitor compliance with the UN Guiding Principles on Business and Human Rights, ILO Declaration on Fundamental Principles and Rights at Work or OECD Guidelines for Multinational Enterprises. In its disclosure it shall focus on those matters that are material, as well as the general approach in relation to: (a) respect for the human rights of consumers and/or end-users; (b) engagement with consumers and/or end-users; (c) measures to provide and/or enable remedy for human rights impacts.",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 9 Table #3 and Indicator number 11
              Table #1 of Annex 1{" "}
            </p>
          </Typography>
        ),
        module: "Social",
        boldPart: "16",
      },

      {
        id: "q-1019",
        question:
          "The undertaking shall disclose whether and how its policies with regard to consumers and/or end-users are aligned with internationally recognised instruments relevant to consumers and/or end-users, including United Nations (UN) Guiding Principles on Business and Human Rights. The undertaking shall also disclose the extent to which cases of non respect of the UN Guiding Principles on Business and Human Rights, ILO Declaration on Fundamental Principles and Rights at Work or OECD Guidelines for Multinational Enterprises that involve consumers and/or end-users have been reported in its downstream value chain and, if applicable, an indication of the nature of such cases1",
        tooltip:
          "AR 11. In disclosing its alignment of its policies with the UN Guiding Principles on Business and Human Rights, the undertaking shall consider that the Guiding Principles refer to the International Bill of Human Rights, which consists of the Universal Declaration of Human Rights and the two Covenants that implement it, and may disclose its alignment with these instruments. ",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 10 Table #1 of Annex 1{" "}
            </p>
            <p className="question-tip">
              <strong>Benchmark Regulation reference:</strong> Delegated Regulation (EU) 2020/1816,
              Annex II Delegated Regulation (EU) 2020/1818, Art 12 (1)
            </p>
          </Typography>
        ),
        module: "Social",
        boldPart: "17",
      },
      {
        id: "q-1020",
        question:
          "The description shall include the key information necessary to ensure a faithful representation of the policies in relation to consumers and end-users, and therefore, the undertaking shall consider explanations of significant changes to the policies adopted during the reporting year (for example, new expectations for business customers, new or additional approaches to due diligence and remedy).",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
        voluntary: true,
        module: "Social",
        boldPart: "AR 9",
      },
      {
        id: "q-1021",
        question:
          "The undertaking may provide an illustration of the types of communication of its policies to those individuals, group of individuals or entities for whom they are relevant, either because they are expected to implement them (for example, the undertaking’s employees, contractors and suppliers), or because they have a direct interest in their implementation (for example, own workers, investors). It may disclose communication tools and channels (for example, flyers, newsletters, dedicated websites, social media, face to face interactions, workers’ representatives), aimed at ensuring that the policy is accessible and that different audiences understand its implications. The undertaking may also explain how it identifies and removes potential barriers for dissemination, such as through translation into relevant languages or the use of graphic depictions.",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
        voluntary: true,
        module: "Social",
        boldPart: "AR 13",
      },
      {
        id: "q-1022",
        question:
          "The undertaking shall disclose information about policies adopted to manage material sustainability matters. The disclosure shall include the following information: (a) a description of the key contents of the policy, including its general objectives and which material impacts, risks or opportunities the policy relates to and the process for monitoring; ",
        tooltip: "",
        condition: "yes",
        module: "Social",
        boldPart: "65 a",
      },
      {
        id: "q-1023",
        question:
          "a description of the scope of the policy, or of its exclusions, in terms of activities, upstream and/or downstream value chain, geographies and if relevant, affected stakeholder groups;",
        tooltip: "",
        condition: "yes",
        module: "Social",
        boldPart: "65 b",
      },
      {
        id: "q-1024",
        question:
          "the most senior level in the undertaking’s organisation that is accountable for the implementation of the policy;",
        tooltip: "",
        condition: "yes",
        module: "Social",
        boldPart: "65 c",
      },
      {
        id: "q-1025",
        question:
          "a reference, if relevant, to the third-party standards or initiatives the undertaking commits to respect through the implementation of the policy;",
        tooltip: "",
        condition: "yes",
        module: "Social",
        boldPart: "65 d",
      },
      {
        id: "q-1026",
        question:
          "if relevant, a description of the consideration given to the interests of key stakeholders in setting the policy; ",
        tooltip: "",
        condition: "yes",
        module: "Social",
        boldPart: "65 e",
      },
      {
        id: "q-1027",
        question:
          "if relevant, whether and how the undertaking makes the policy available to potentially affected stakeholders, and stakeholders who need to help implement it. ",
        tooltip: "",
        condition: "yes",
        module: "Social",
        boldPart: "65 f",
      },
      {
        id: "q-1028",
        question:
          "If the undertaking cannot disclose the information on policies and actions required under relevant ESRS, because it has not adopted policies and/or actions with reference to the specific sustainability matter concerned, it shall disclose this to be the case, and provide reasons for not having adopted policies and/or actions. ",
        tooltip: "",
        condition: "no",
        module: "Social",
        boldPart: "62",
      },
      {
        id: "q-1029",
        question: "The undertaking may disclose a timeframe in which it aims to adopt them. ",
        tooltip: "",
        condition: "no",
        module: "Social",
        boldPart: "62",
      },
      // Add more questions as needed
    ],
    "S4-2 – Processes for engaging with consumers and endusers about impacts": [
      {
        id: "q-1030",
        question:
          "The undertaking shall disclose whether and how the perspectives of consumers and/or endusers inform its decisions or activities aimed at managing actual and potential impacts on consumers and/or end-users. This shall include, where relevant, an explanation of: ",
        tooltip:
          "AR 17. To illustrate how the perspectives of consumers and/or end-users have informed specific decisions or activities of the undertaking, the undertaking may provide examples from the current reporting period. ",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
        module: "Social",
        boldPart: "20",
      },
      {
        id: "q-1031",
        question:
          "whether engagement occurs with affected consumers and/or end-users or their legitimate representatives directly, or with credible proxies that have insight into their situation; ",
        tooltip:
          "AR 14. Credible proxies who have knowledge of the interests, experiences or perspectives of consumers and end-users could include national consumer protection bodies for some consumers. ",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
        module: "Social",
        boldPart: "20 a",
      },
      {
        id: "q-1032",
        question:
          "the stage(s) at which engagement occurs, the type of engagement, and the frequency of the engagement;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 16. When preparing the disclosures described in paragraph 20 b) and c), the
              following illustrations may be considered:
            </p>
            <ol className="question-tip" type="a">
              <li>
                for stage(s) at which engagement occurs, examples could be in determining mitigation
                approaches or in evaluating their effectiveness;
              </li>
              <li>
                for type of engagement, these could be participation, consultation and/or
                information;
              </li>
              <li>
                for the frequency of the engagement, information may be provided on whether
                engagement occurs on a regular basis, at certain points in a project or business
                process, as well as whether it occurs in response to legal requirements and/or in
                response to stakeholder requests and whether the result of the engagement is being
                integrated into the undertaking's decision-making processes; and
              </li>
              <li>
                for the role with operational responsibility, whether the undertaking requires
                relevant staff to have certain skills, or whether it provides training or capacity
                building to relevant staff to undertake engagement.
              </li>
            </ol>
          </Typography>
        ),
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
        module: "Social",
        boldPart: "20 b",
      },
      {
        id: "q-1033",
        question:
          "the function and the most senior role within the undertaking that has operational responsibility for ensuring this engagement happens and that the results inform the undertaking’s approach; ",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 16. When preparing the disclosures described in paragraph 20 b) and c), the
              following illustrations may be considered:
            </p>
            <ol className="question-tip" type="a">
              <li>
                for stage(s) at which engagement occurs, examples could be in determining mitigation
                approaches or in evaluating their effectiveness;
              </li>
              <li>
                for type of engagement, these could be participation, consultation and/or
                information;
              </li>
              <li>
                for the frequency of the engagement, information may be provided on whether
                engagement occurs on a regular basis, at certain points in a project or business
                process, as well as whether it occurs in response to legal requirements and/or in
                response to stakeholder requests and whether the result of the engagement is being
                integrated into the undertaking's decision-making processes; and
              </li>
              <li>
                for the role with operational responsibility, whether the undertaking requires
                relevant staff to have certain skills, or whether it provides training or capacity
                building to relevant staff to undertake engagement.
              </li>
            </ol>
          </Typography>
        ),
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
        module: "Social",
        boldPart: "20 c",
      },
      {
        id: "q-1034",
        question:
          "where applicable, how the undertaking assesses the effectiveness of its engagement with consumers and/or end-users, and, where relevant, any agreements or outcomes that result from such engagement. ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
        module: "Social",
        boldPart: "20 d",
      },
      {
        id: "q-1035",
        question:
          "Where applicable, the undertaking shall disclose the steps it takes to gain insight into the perspectives of consumers and/or end-users that may be particularly vulnerable to impacts and/or marginalised (for example, people with disabilities, children, etc.). ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
        module: "Social",
        boldPart: "21",
      },
      {
        id: "q-1036",
        question:
          "If the undertaking cannot disclose the above required information because it has not adopted a general process to engage with consumers and/or end-users, it shall disclose this to be the case. It may disclose a timeframe in which it aims to have such a process in place. ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
        voluntary: true,
        module: "Social",
        boldPart: "22",
      },
      {
        id: "q-1037",
        question:
          "When describing which function or role has operational responsibility for such engagement and/or ultimate accountability, the undertaking may disclose whether this is a dedicated role or function or part of a broader role or function, and whether any capacity building activities have been offered to support the staff to undertake engagement. If it cannot identify such a position or function, it may state that this is the case. This disclosure could also be fulfilled by making reference to information disclosed according to ESRS 2 GOV-1 The role of the administrative, management and supervisory bodies. ",
        tooltip: "",
        phasedIn:
          "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
        voluntary: true,
        module: "Social",
        boldPart: "AR 15",
      },

      // Add more questions as needed
    ],
    "S4-3 – Processes to remediate negative impacts and channels for consumers and end-users to raise concerns":
      [
        {
          id: "q-1038",
          question:
            "The undertaking shall describe: (a) its general approach to and processes for providing or contributing to remedy where it has identified that it has caused or contributed to a material negative impact on consumers and/or end-users, including whether and how the undertaking assesses that the remedy provided is effective; ",
          tooltip:
            "AR 18. In fulfilling the requirements set out by the disclosure criteria of ESRS S4-3, the undertaking may be guided by the content of the UN Guiding Principles on Business and Human Rights and the OECD Guidelines for Multinational Enterprises focused on remediation and grievance mechanisms.",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          module: "Social",
          boldPart: "25 a",
        },
        {
          id: "q-1039",
          question:
            "Any specific channels it has in place for consumers and/or end-users to raise their concerns or needs directly with the undertaking and have them addressed, including whether these are established by the undertaking itself and/or through participation in third-party mechanisms;",
          tooltip:
            "AR 19. Channels for raising concerns or needs, include grievance mechanisms, hotlines, dialogue processes or other means through which consumers and/or end-users or their legitimate representatives can raise concerns about impacts or explain needs that they would like the undertaking to address. This could include channels provided by the undertaking directly and is to be disclosed in addition to any other mechanisms the undertaking may use to gain insight into the management of impacts on consumers and/or end-users, such as compliance audits. Where the undertaking is relying solely on information about the existence of such channels provided by its business relationships to answer this requirement, it may state that. ",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          module: "Social",
          boldPart: "25 b",
        },
        {
          id: "q-1040",
          question:
            "The processes through which the undertaking supports or requires the availability of such channels by its business relationships;",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          module: "Social",
          boldPart: "25 c",
        },
        {
          id: "q-1041",
          question:
            "How it tracks and monitors issues raised and addressed, and how it ensures the effectiveness of the channels, including through involvement of stakeholders who are the intended users. ",
          tooltip: (
            <Typography>
              <p className="question-tip">
                AR 24. In describing the effectiveness of channels for consumers and/or end-users to
                raise concerns, the undertaking may be guided by the following questions, based on
                the “effectiveness criteria for nonjudicial grievance mechanisms”, as laid out in
                the UN Guiding Principles on Business and Human Rights. The below considerations may
                be applied on an individual channel basis or for the collective system of channels:
              </p>
              <ol className="question-tip" type="a">
                <li>
                  do the channels hold legitimacy by providing appropriate accountability for their
                  fair conduct and building stakeholder trust?
                </li>
                <li>are the channels known and accessible to stakeholders? </li>
                <li>
                  do the channels have known procedures, set timeframes and clarity on the
                  processes?
                </li>
                <li>
                  do the channels ensure reasonable access to sources of information, advice and
                  expertise?
                </li>
                <li>
                  do the channels offer transparency by providing sufficient information both to
                  complainants and where applicable, to meet any public interest at stake?
                </li>
                <li>
                  do the outcomes achieved from the channels accord with internationally recognised
                  human rights?
                </li>
                <li>
                  does the undertaking identify insights from the channels that support continuous
                  learning in both improving the channels and preventing future impacts?
                </li>
                <li>
                  does the undertaking focus on dialogue with complainants as the means to reach
                  agreed solutions, rather than seeking to unilaterally determine the outcome?
                </li>
              </ol>
              <p className="question-tip">
                For more information, see Principle 31 of the UN Guiding Principles on Business and
                Human Rights.
              </p>
            </Typography>
          ),
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          module: "Social",
          boldPart: "25 d",
        },
        {
          id: "q-1042",
          question:
            "The undertaking shall disclose whether and how it assesses that consumers and/or endusers are aware of and trust these structures or processes as a way to raise their concerns or needs and have them addressed. In addition, the undertaking shall disclose whether it has policies in place to protect individuals from retaliation when they use such structures or processes. If such information has been disclosed in accordance with ESRS G1-1, the undertaking may refer to that information.",
          tooltip:
            "AR 23. In disclosing whether and how the undertaking knows that consumers and/or end-users are aware of and trust any of these channels, the undertaking may provide relevant and reliable data about the effectiveness of these channels from the perspective of consumers and/or end-users themselves. Examples of sources of information are surveys of consumers and/or end-users that have used such channels and their levels of satisfaction with the process and outcomes. To illustrate the usage level of such channels, the undertaking may disclose the number of complaints received from consumers and/or endusers during the reporting period. ",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          module: "Social",
          boldPart: "26",
        },
        {
          id: "q-1043",
          question:
            "If the undertaking cannot disclose the above required information because it has not adopted a channel for raising concerns and/or does not support the availability of mechanisms by its business relationships, it shall disclose this to be the case. It may disclose a timeframe in which it aims to have such a channel or processes in place.",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          voluntary: true,
          module: "Social",
          boldPart: "27",
        },
        {
          id: "q-1044",
          question:
            "To provide greater insight into the information covered in ESRS S4-3, the undertaking may provide insight into whether and how consumers and/or end-users that may be affected are able to access channels at the level of the undertaking they are affected by, in relation to each material impact.",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          voluntary: true,
          module: "Social",
          boldPart: "AR 20",
        },
        {
          id: "q-1045",
          question:
            "Third party mechanisms could include those operated by the government, NGOs, industry associations and other collaborative initiatives. With regard to the scope of these mechanisms, the undertaking may disclose whether these are accessible to all consumers and/or end-users who may be potentially or actually materially impacted by the undertaking, or individuals or organisations acting on their behalf or who are otherwise in a position to be aware of negative impacts, and through which consumers and/or end-users (or individuals or organisations acting on their behalf or who are otherwise in a position to be aware of negative impacts), can raise complaints or concerns related to the undertaking’s own activities. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          voluntary: true,
          module: "Social",
          boldPart: "AR 21",
        },
        {
          id: "q-1046",
          question:
            "In relation to the protection of individuals that use the mechanisms against the retaliation, the undertaking may describe whether it treats grievances confidentially and with respect to the rights of privacy and data protection; and whether they allow for consumer and/or endusers to use them anonymously (for example, through representation by a third party). ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          voluntary: true,
          module: "Social",
          boldPart: "AR 22",
        },
        {
          id: "q-1047",
          question:
            "In disclosing whether and how the undertaking knows that consumers and/or end-users are aware of and trust any of these channels, the undertaking may provide relevant and reliable data about the effectiveness of these channels from the perspective of consumers and/or end-users themselves. Examples of sources of information are surveys of consumers and/or end-users that have used such channels and their levels of satisfaction with the process and outcomes. To illustrate the usage level of such channels, the undertaking may disclose the number of complaints received from consumers and/or endusers during the reporting period. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          voluntary: true,
          module: "Social",
          boldPart: "AR 23",
        },

        // Add more questions as needed
      ],
    "S4-4 – Taking action on material impacts on consumers and end- users, and approaches to managing material risks and pursuing material opportunities related to consumers and end-users, and effectiveness of those actions":
      [
        {
          id: "q-1048",
          question:
            "In relation to material impacts, the undertaking shall describe: (a) actions taken, planned or underway to prevent, mitigate or remediate material negative impacts on consumers and/or end-users; (b) whether and how it has taken action to provide or enable remedy in relation to an actual material impact; (c) any additional actions or initiatives it has in place with the primary purpose of positively contributing to improved social outcomes for consumers and/or end-users; and (d) how it tracks and assesses the effectiveness of these actions and initiatives in delivering intended outcomes for consumers and/or end-users.",
          tooltip: (
            <Typography>
              <p className="question-tip">
                31 (a): AR 35. When disclosing the intended positive outcomes of its actions for
                consumers and/or end-users a distinction is to be made between evidence of certain
                activities having occurred (for example, that x number of consumers have received
                information about healthy eating habits) from evidence of actual outcomes for
                consumers and/or end-users (for example, that x number of consumers have adopted
                healthier eating habits).{" "}
              </p>
              <p className="question-tip">
                31 (c): AR 36. When disclosing whether initiatives or processes also play a role in
                mitigating material negative impacts, the undertaking may, for example, consider
                programmes that aim to support heightened awareness of the risk of online scams,
                leading to a reduction in the number of cases of end-users experiencing breaches of
                data privacy.
              </p>
              <p className="question-tip">
                {" "}
                31 (d) "AR 30. In disclosing how it tracks the effectiveness of actions to manage
                material impacts during the reporting period, the undertaking may disclose any
                lessons learned from the previous and current reporting periods. <br />
                AR 31. Processes used to track the effectiveness of actions can include internal or
                external auditing or verification, court proceedings and/or related court decisions,
                impact assessments, measurement systems, stakeholder feedback, grievance mechanisms,
                external performance ratings, and benchmarking. <br />
                AR 32. Reporting on effectiveness is aimed at enabling the understanding of the
                links between actions taken by the undertaking and the effective management of
                impacts. "
              </p>{" "}
            </Typography>
          ),
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          module: "Social",
          boldPart: "31",
        },

        {
          id: "q-1052",
          question:
            "In relation to paragraph 28, the undertaking shall describe: (a) the processes through which it identifies what action is needed and appropriate in response to a particular actual or potential negative impact on consumers and/or end-users;",
          tooltip:
            "AR 26. Appropriate action can vary according to whether the undertaking causes or contributes to a material impact, or whether the material impact is directly linked to its own operations, products or services through a business relationship. ",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          module: "Social",
          boldPart: "32 a",
        },
        {
          id: "q-1053",
          question:
            "Its approaches to taking action in relation to specific material negative impacts on consumers and/or end-users, including any action in relation to its own practices regarding product design, marketing or sales, as well as whether wider industry or collaborative action with other relevant parties will be required; ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          module: "Social",
          boldPart: "32 b",
        },
        {
          id: "q-1054",
          question:
            "How it ensures that processes to provide or enable remedy in the event of material negative impacts are available and effective in their implementation and outcomes.",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "Where the undertaking evaluates the effectiveness of an action by setting a target,
                in disclosing the information required under paragraph 32 (c), the undertaking shall
                consider ESRS 2 MDR-T Tracking effectiveness of policies and actions through
                targets. "
              </p>
            </Typography>
          ),
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          module: "Social",
          boldPart: "32 c",
        },
        {
          id: "q-1055",
          question:
            "In relation to material risks and opportunities, the undertaking shall describe: (a) what action is planned or underway to mitigate material risks for the undertaking arising from its impacts and dependencies on consumers and/or end-users and how it tracks effectiveness in practice;",
          tooltip: (
            <Typography>
              <p className="question-tip">
                AR 37. When disclosing the material risks and opportunities related to the
                undertaking’s impacts or dependencies on consumers and/or end-users, the undertaking
                may consider the following:
              </p>
              <ol className="question-tip" type="a">
                <li>
                  risks related to the undertaking’s impacts on consumers and/or end-users may
                  include reputational or legal exposure where poorly designed or defective products
                  result in injuries or deaths;
                </li>
                <li>
                  risks related to the undertaking’s dependencies on consumers and/or end-users may
                  include the loss of business continuity where an economic crisis makes consumers
                  unable to afford certain products or services;
                </li>
                <li>
                  business opportunities related to the undertaking’s impacts on consumers and/or
                  end-users may include market differentiation and greater customer appeal from
                  offering safe products or privacy-respecting services; and
                </li>
                <li>
                  business opportunities related to the undertaking’s dependencies on consumers
                  and/or end-users may include the achievement of a loyal future consumer base by
                  ensuring, for example, that LGBTQI people are respected and that the undertaking’s
                  selling practices do not exclude such people from the products or services it
                  offers.
                </li>
              </ol>
              <p className="question-tip">
                AR 38. When disclosing whether dependencies turn into risks, the undertaking shall
                consider external developments. AR 40. The undertaking shall consider the extent to
                which its processes to manage material risks related to consumers and/or end-users
                are integrated into its existing risk management processes and how.
              </p>
            </Typography>
          ),
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          module: "Social",
          boldPart: "33 a",
        },
        {
          id: "q-1056",
          question:
            "What action is planned or underway to pursue material opportunities for the undertaking in relation to consumers and/or end-users. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          module: "Social",
          boldPart: "33 b",
        },
        {
          id: "q-1057",
          question:
            "The undertaking shall disclose whether and how it takes action to avoid causing or contributing to material negative impacts on consumers and/or end-users through its own practices, including, where relevant, in relation to marketing, sales and data use. This may include disclosing what approach is taken when tensions arise between the prevention or mitigation of material negative impacts and other business pressures. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          module: "Social",
          boldPart: "34",
        },
        {
          id: "q-1058",
          question:
            "When preparing this disclosure, the undertaking shall consider whether severe human rights issues and incidents connected to its consumers and/or end-users have been reported and, if applicable, disclose these",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          euReg: (
            <Typography>
              <p className="question-tip">
                <strong>SFDR reference:</strong> Indicator number 14 Table #3 of Annex 1
              </p>
            </Typography>
          ),

          module: "Social",
          boldPart: "35",
        },
        {
          id: "q-1059",
          question:
            "The undertaking shall disclose what resources are allocated to the management of its material impacts with information that enables users to gain an understanding of how the material impacts are managed.",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          module: "Social",
          boldPart: "37",
        },
        {
          id: "q-1060",
          question:
            "It may take time to understand negative impacts and how the undertaking may be involved with them through its downstream value chain, as well as to identify appropriate responses and put them into practice). Therefore, the undertaking shall consider: (a) Its general and specific approaches to addressing material negative impacts; ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          voluntary: true,
          module: "Social",
          boldPart: "AR 25 a",
        },
        {
          id: "q-1061",
          question:
            "its initiatives aimed at contributing to additional material positive impacts;",
          tooltip:
            "AR 36. When disclosing whether initiatives or processes also play a role in mitigating material negative impacts, the undertaking may, for example, consider programmes that aim to support heightened awareness of the risk of online scams, leading to a reduction in the number of cases of end-users experiencing breaches of data privacy.",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          voluntary: true,
          module: "Social",
          boldPart: "AR 25 b",
        },

        {
          id: "q-1062",
          question: "How far it has progressed in its efforts during the reporting period;",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          voluntary: true,
          module: "Social",
          boldPart: "AR 25 c",
        },
        {
          id: "q-1063",
          question: "its aims for continued improvement. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          voluntary: true,
          module: "Social",
          boldPart: "AR 25 d",
        },
        {
          id: "q-1064",
          question:
            "Given that material negative impacts affecting consumers and/or end-users that have occurred during the reporting period may also be linked to entities or operations outside its direct control, the undertaking may disclose whether and how it seeks to use leverage with relevant business relationships to manage those impacts. This may include using commercial leverage (for example, enforcing contractual requirements with business relationships or implementing incentives), other forms of leverage within the relationship (such as providing training or capacity-building on proper product use or sale practices to business relationships) or collaborative leverage with peers or other actors (such as initiatives aimed at responsible marketing or product safety). ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          voluntary: true,
          module: "Social",
          boldPart: "AR 27",
        },
        {
          id: "q-1065",
          question:
            "When the undertaking discloses its participation in an industry or multi-stakeholder initiative as part of its actions to address material negative impacts, the undertaking may disclose how the initiative, and its own involvement, is aiming to address the material impact concerned. It may disclose under ESRS S4-5 the relevant targets set by the initiative and progress towards them. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          voluntary: true,
          module: "Social",
          boldPart: "AR 28",
        },
        {
          id: "q-1066",
          question:
            "With regard to initiatives or processes the undertaking has in place that are based on affected consumers and/or end-users’ needs and their level of implementation, the undertaking may disclose: (a) information about whether and how consumers and/or end-users and legitimate representatives or their credible proxies play a role in decisions regarding the design and implementation of these programmes or processes; ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          voluntary: true,
          module: "Social",
          boldPart: "AR 33 a",
        },
        {
          id: "q-1067",
          question:
            "information about the intended or achieved positive outcomes for consumers and/or end- users of these programmes or processes. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          voluntary: true,
          module: "Social",
          boldPart: "AR 33 b",
        },
        {
          id: "q-1068",
          question:
            "The undertaking may disclose whether any initiatives or processes whose primary aim is to deliver positive impacts for consumers and/or end-users are designed to also support the achievement of one or more of the UN Sustainable Development Goals (SDGs). For example, through a commitment to advance UN SDG 3 to “ensure healthy lives and promote well-being for all at all ages” the undertaking may be actively working to make its products less addictive and harmful to physical and psychological health. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          voluntary: true,
          module: "Social",
          boldPart: "AR 34",
        },
        {
          id: "q-1069",
          question:
            "When disclosing the resources allocated to the management of material impacts, the undertaking may disclose which internal functions are involved in managing the impacts and what types of action they take to address negative and advance positive impacts. ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          voluntary: true,
          module: "Social",
          boldPart: "AR 41",
        },
        {
          id: "q-1070",
          question:
            "Where the implementation of a policy requires actions, or a comprehensive action plan, to achieve its objectives, as well as when actions are implemented without a specific policy, the undertaking shall disclose the following information: (a) the list of key actions taken in the reporting year and planned for the future, their expected outcomes and, where relevant, how their implementation contributes to the achievement of policy objectives and targets;",
          tooltip:
            "AR 22. Key actions in the context of this Minimum Disclosure Requirement are those actions that materially contribute to achieving the undertaking’s objectives in addressing material impacts, risks and opportunities. oor reasons of understandability, key actions may be aggregated where appropriate. ",
          condition: "yes",
          module: "Social",
          boldPart: "68 a",
        },
        {
          id: "q-1071",
          question:
            "the scope of the key actions (i.e., coverage in terms of activities, upstream and/or downstream value chain, geographies and, where applicable, affected stakeholder groups); ",
          tooltip: "",
          condition: "yes",
          module: "Social",
          boldPart: "68 b",
        },
        {
          id: "q-1072",
          question:
            "the time horizons under which the undertaking intends to complete each key action;",
          tooltip: "",
          condition: "yes",
          module: "Social",
          boldPart: "68 c",
        },
        {
          id: "q-1073",
          question:
            "if applicable, key actions taken (along with results) to provide for and cooperate in or support the provision of remedy for those harmed by actual material impacts;",
          tooltip: "",
          condition: "yes",
          module: "Social",
          boldPart: "68 d",
        },
        {
          id: "q-1074",
          question:
            "if applicable, quantitative and qualitative information regarding the progress of actions or action plans disclosed in prior periods. ",
          tooltip: "",
          condition: "yes",
          module: "Social",
          boldPart: "68 e",
        },
        {
          id: "q-1075",
          question:
            "Where the implementation of an action plan requires significant operational expenditures (Opex) and/or capital expenditures (Capex) the undertaking shall: (a) describe the type of current and future financial and other resources allocated to the action plan, including if applicable, the relevant terms of sustainable finance instruments, such as green bonds, social bonds and green loans, the environmental or social objectives, and whether the ability to implement the actions or action plan depends on specific preconditions, e.g., granting of financial support or public policy and market developments; ",
          tooltip:
            "AR 23. Information on resource allocation may be presented in the form of a table and broken down between capital expenditure and operating expenditure, and across the relevant time horizons, and between resources applied in the current reporting year and the planned allocation of resources over specific time horizons.",
          condition: "yes",
          module: "Social",
          boldPart: "69 a",
        },
        {
          id: "q-1076",
          question:
            "provide the amount of current financial resources and explain how they relate to the most relevant amounts presented in the financial statements;",
          tooltip: "",
          condition: "yes",
          module: "Social",
          boldPart: "69 b",
        },
        {
          id: "q-1077",
          question: "provide the amount of future financial resources. ",
          tooltip: "",
          condition: "yes",
          module: "Social",
          boldPart: "69 c",
        },
        {
          id: "q-1078",
          question:
            "If the undertaking cannot disclose the information on policies and actions required under relevant ESRS, because it has not adopted policies and/or actions with reference to the specific sustainability matter concerned, it shall disclose this to be the case, and provide reasons for not having adopted policies and/or actions. ",
          tooltip: "",
          condition: "no",
          module: "Social",
          boldPart: "62",
        },
        {
          id: "q-1079",
          question: "The undertaking may disclose a timeframe in which it aims to adopt them. ",
          tooltip: "",
          condition: "no",
          module: "Social",
          boldPart: "62",
        },

        // Add more questions as needed
      ],
    "S4-5 – Targets related to managing material negative impacts, advancing positive impacts, and managing material risks and opportunities":
      [
        {
          id: "q-1080",
          question:
            "The summarised description of the targets to manage its material impacts, risks and opportunities related to consumers and/or end-users shall contain the information requirements defined in ESRS 2 MDR-T. The undertaking shall disclose the process for setting the targets, including whether and how the undertaking engaged directly with consumers and/or end-users, their legitimate representatives, or with credible proxies that have insight into their situation in: (a) setting any such targets; (b) tracking the undertaking’s performance against them; and (c) identifying, if any, lessons or improvements as a result of the undertaking’s performance.",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 43. Targets related to material risks and opportunities may be the same as or
                distinct from targets tied to material impacts. For example, a target to ensure
                equal access to finance for underserved consumers could both reduce discrimination
                impacts on those consumers and enlarge the undertaking’s pool of customers. <br />
                AR 44. The undertaking may also distinguish between short, medium and long-term
                targets covering the same policy commitment. For example, the undertaking may have
                as a main objective to make its online services accessible to people with
                disabilities, with the longterm goal of having adapted 100% of its online services
                by 2025, and with the short-term objective of adding x number of accessible features
                every year up and until 2025. <br />
                AR 45. When modifying or replacing a target in the reporting period, the undertaking
                may explain the change by cross-referencing it to significant changes in the
                business model or to broader changes in the accepted standard or legislation from
                which the target is derived to provide contextual information as per ESRS 2 BP-2
                Disclosures in relation to specific circumstances"
              </p>
            </Typography>
          ),
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          module: "Social",
          boldPart: "41",
        },

        {
          id: "q-1084",
          question:
            "When disclosing targets in relation to consumers and/or end-users, the undertaking may disclose: (a) the intended outcomes to be achieved in the lives of consumers and/or end-users, being as specific as possible; ",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          voluntary: true,
          module: "Social",
          boldPart: "AR 42 a",
        },
        {
          id: "q-1085",
          question:
            "their stability over time in terms of definitions and methodologies to enable comparability;",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          voluntary: true,
          module: "Social",
          boldPart: "AR 42 b",
        },
        {
          id: "q-1086",
          question:
            "references standards or commitments on which the targets are based are to be clearly defined in the reporting (for instance codes of conduct, sourcing policies, global frameworks or industry codes).",
          tooltip: "",
          phasedIn:
            "Undertakings or groups not exceeding on their balance sheet dates the average number of 750 employees during the financial year (on a consolidated basis where applicable) may omit the information specified in the disclosure requirements of ESRS S4 for the first 2 years of preparation of their sustainability statement. ",
          voluntary: true,
          module: "Social",
          boldPart: "AR 42 c",
        },
        {
          id: "q-1087",
          question:
            "The undertaking shall disclose the measurable, outcome-oriented and time-bound targets on material sustainability matters it has set to assess progress. oor each target, the disclosure shall include the following information: (a) a description of the relationship of the target to the policy objectives;",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 a",
        },
        {
          id: "q-1088",
          question:
            "the defined target level to be achieved, including, where applicable, whether the target is absolute or relative and in which unit it is measured;",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 b",
        },
        {
          id: "q-1089",
          question:
            "the scope of the target, including the undertaking’s activities and/or its upstream and/or downstream value chain where applicable and geographical boundaries; ",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 c",
        },
        {
          id: "q-1090",
          question: "the baseline value and base year from which progress is measured;",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 d",
        },
        {
          id: "q-1091",
          question:
            "the period to which the target applies and if applicable, any milestones or interim targets;",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 e",
        },
        {
          id: "q-1092",
          question:
            "the methodologies and significant assumptions used to define targets, including where applicable, the selected scenario, data sources, alignment with national, EU or international policy goals and how the targets consider the wider context of sustainable development and/or local situation in which impacts take place; ",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 f",
        },
        {
          id: "q-1093",
          question:
            "whether the undertaking’s targets related to environmental matters are based on conclusive scientific evidence;",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 g",
        },
        {
          id: "q-1094",
          question:
            "whether and how stakeholders have been involved in target setting for each material sustainability matter; ",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 h",
        },
        {
          id: "q-1095",
          question:
            "any changes in targets and corresponding metrics or underlying measurement methodologies, significant assumptions, limitations, sources and processes to collect data adopted within the defined 17 time horizon. This includes an explanation of the rationale for those changes and their effect on comparability (see Disclosure Requirement BP-2 Disclosures in relation to specific circumstances of this Standard);",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 i",
        },
        {
          id: "q-1096",
          question:
            "the performance against its disclosed targets, including information on how the target is monitored and reviewed and the metrics used, whether the progress is in line with what had been initially planned, and an analysis of trends or significant changes in the performance of the undertaking towards achieving the target. ",
          tooltip: (
            <Typography>
              <p className="question-tip">
                "AR 24. When disclosing targets related to the prevention or mitigation of
                environmental impacts, the undertaking shall prioritise targets related to the
                reduction of the impacts in absolute terms rather than in relative terms. When
                targets address the prevention or mitigation of social impacts, they may be
                specified in terms of the effects on human rights, welfare or positive outcomes for
                affected stakeholders. <br />
                AR 25. The information on progress made towards achieving the targets may be
                presented in a comprehensive table, including information on the baseline and target
                value, milestones, and achieved performance over the prior periods. <br />
                AR 26. Where the undertaking describes progress in achieving the objectives of a
                policy in the absence of a measurable target, it may specify a baseline against
                which the progress is considered. oor example, the undertaking may assess an
                increase of wages by a certain percentage for those below a fair wage; or may assess
                the quality of its relationships with local communities by reference to the
                proportion of issues raised by communities that were resolved to their satisfaction.
                The baseline and the assessment of the progress shall be related to the impacts,
                risks and opportunities which underpin the materiality of the matter addressed by
                the policy. "
              </p>
            </Typography>
          ),
          module: "Social",
          condition: "yes",
          boldPart: "80 j",
        },
        {
          id: "q-1097",
          question:
            ". If the undertaking has not set any measurable outcome-oriented targets: (a) it may disclose whether such targets will be set and the timeframe for setting them, or the reasons why the undertaking does not plan to set such targets;",
          tooltip: "",
          voluntary: true,
          module: "Social",
          condition: "no",
          boldPart: "81 a",
        },
        {
          id: "q-1098",
          question:
            "it shall disclose whether it nevertheless tracks the effectiveness of its policies and actions in relation to the material sustainability-related impact, risk and opportunity,",
          tooltip: "",
          module: "Social",
          condition: "no",
          boldPart: "81 b",
        },

        {
          id: "q-1099",
          question:
            "it shall disclose whether it nevertheless tracks the effectiveness of its policies and actions in relation to the material sustainability-related impact, risk and opportunity, and if so: i. any processes through which it does so",
          tooltip: "",
          module: "Social",
          condition: "no",
          boldPart: "81 b i",
        },
        {
          id: "q-1100",
          question:
            "it shall disclose whether it nevertheless tracks the effectiveness of its policies and actions in relation to the material sustainability-related impact, risk and opportunity, and if so: the defined level of ambition to be achieved and any qualitative or quantitative indicators it uses to evaluate progress, including the base period from which progress is measured. ",
          tooltip: "",
          module: "Social",
          condition: "no",
          boldPart: "81 b ii, 80 d",
        },
        // Add more questions as needed
      ],

    /**********************************************
     * Tab: Governance
     * Topic: ESRS G1 BUSINESS CONDUCT
     **********************************************/
    "ESRS 2 GOV-1 – The role of the administrative, supervisory and management bodies": [
      {
        id: "q-1101",
        question:
          "When disclosing information about the role of the administrative, management and supervisory bodies, the undertaking shall cover the following aspects: (a) the role of the administrative, management and supervisory bodies related to business conduct; and (b) the expertise of the administrative, management and supervisory bodies on business conduct matters.",
        tooltip: "",
        module: "Governance",
        boldPart: "5",
      },
      // Add more questions as needed
    ],
    "G1-1– Corporate culture and Business conduct policies and corporate culture": [
      {
        id: "q-1103",
        question:
          "The disclosures required under paragraph 7 shall include how the undertaking establishes, develops, promotes and evaluates its corporate culture.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 1. The undertaking may consider the following aspects when determining its
              disclosure under paragraph 7:
            </p>
            <ol className="question-tip" type="a">
              <li>
                the aspects of corporate culture that are taken into consideration and discussed by
                the administrative, management and supervisory bodies and with which frequency;
              </li>
              <li>
                the principal themes that are promoted and communicated as part of the corporate
                culture;
              </li>
              <li>
                how the members of the undertaking’s administrative, management and supervisory
                bodies provide direction to promote a corporate culture; and
              </li>
              <li>
                specific incentives or tools for its own workers to foster and encourage its
                corporate culture.
              </li>
            </ol>
          </Typography>
        ),
        module: "Governance",
        boldPart: "9",
      },
      {
        id: "q-1104",
        question:
          "a description of the mechanisms for identifying, reporting and investigating concerns about unlawful behaviour or behaviour in contradiction of its code of conduct or similar internal rules; and whether it accommodates reporting from internal and/or external stakeholders; ",
        tooltip: "",
        module: "Governance",
        boldPart: "10 a",
      },
      {
        id: "q-1105",
        question:
          "where the undertaking has no policies on anti-corruption or anti-bribery consistent with the United Nations Convention against Corruption1, it shall state this and whether it has plans to implement them and the timetable for implementation; ",
        tooltip: "",
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 15 Table #3 of Annex 1
            </p>
          </Typography>
        ),
        module: "Governance",
        boldPart: "10 b",
      },
      {
        id: "q-1106",
        question:
          "how the undertaking protects whistleblowers, including: (a) details on the establishment of internal whistleblower reporting channels, including whether the undertaking provides for information and training to its own workers and information about the designation and training of staff receiving reports; and (b) measures to protect against retaliation its own workers who are whistleblowers in accordance with the applicable law transposing Directive (EU) 2019/1937 of the European Parliament and of the Council2;",
        tooltip: "",
        module: "Governance",
        boldPart: "",
      },
      {
        id: "q-1107",
        question:
          "where the undertaking has no policies on the protection of whistle-blowers3, it shall state this and whether it has plans to implement them and the timetable for implementation; ",
        tooltip: "",
        euReg: (
          <Typography>
            <p className="question-tip">
              <strong>SFDR reference:</strong> Indicator number 6 Table #3 of Annex 1
            </p>
          </Typography>
        ),
        module: "Governance",
        boldPart: "10 d",
      },
      {
        id: "q-1108",
        question:
          "beyond the procedures to follow-up on reports by whistleblowers in accordance with the applicable law transposing Directive (EU) 2019/1937, whether the undertaking has procedures to investigate business conduct incidents, including incidents of corruptionand bribery, promptly, independently and objectively; ",
        tooltip: "",
        module: "Governance",
        boldPart: "10 e",
      },
      {
        id: "q-1109",
        question:
          "where applicable, whether the undertaking has in place policies with respect to animal welfare; ",
        tooltip: "",
        module: "Governance",
        boldPart: "10 f",
      },
      {
        id: "q-1110",
        question:
          "the undertaking’s policy for training within the organisation on business conduct, including target audience, frequency and depth of coverage",
        tooltip: "",
        module: "Governance",
        boldPart: "10 g",
      },
      {
        id: "q-1111",
        question:
          "the functions within the undertaking that are most at risk in respect of corruption and bribery.",
        tooltip: "",
        module: "Governance",
        boldPart: "10 h",
      },
      {
        id: "q-1112",
        question:
          "Undertakings that are subject to legal requirements under national law transposing Directive (EU) 2019/1937, or to equivalent legal requirements with regard to the protection of whistleblowers, may comply with the disclosure specified in paragraph 10 (d) by stating that they are subject to those legal requirements",
        tooltip: "",
        voluntary: true,
        module: "Governance",
        boldPart: "11",
      },
      {
        id: "q-1113",
        question:
          "The undertaking shall disclose information about policies adopted to manage material sustainability matters. The disclosure shall include the following information: (a) a description of the key contents of the policy, including its general objectives and which material impacts, risks or opportunities the policy relates to and the process for monitoring; ",
        tooltip: "",
        condition: "yes",
        module: "Governance",
        boldPart: "65 a",
      },
      {
        id: "q-1114",
        question:
          "a description of the scope of the policy, or of its exclusions, in terms of activities, upstream and/or downstream value chain, geographies and if relevant, affected stakeholder groups;",
        tooltip: "",
        condition: "yes",
        module: "Governance",
        boldPart: "65 b",
      },
      {
        id: "q-1115",
        question:
          "the most senior level in the undertaking’s organisation that is accountable for the implementation of the policy;",
        tooltip: "",
        condition: "yes",
        module: "Governance",
        boldPart: "65 c",
      },
      {
        id: "q-1116",
        question:
          "a reference, if relevant, to the third-party standards or initiatives the undertaking commits to respect through the implementation of the policy;",
        tooltip: "",
        condition: "yes",
        module: "Governance",
        boldPart: "65 d",
      },
      {
        id: "q-1117",
        question:
          "if relevant, a description of the consideration given to the interests of key stakeholders in setting the policy; ",
        tooltip: "",
        condition: "yes",
        module: "Governance",
        boldPart: "65 e",
      },
      {
        id: "q-1118",
        question:
          "if relevant, whether and how the undertaking makes the policy available to potentially affected stakeholders, and stakeholders who need to help implement it. ",
        tooltip: "",
        condition: "yes",
        module: "Governance",
        boldPart: "65 f",
      },
      {
        id: "q-1119",
        question:
          "If the undertaking cannot disclose the information on policies and actions required under relevant ESRS, because it has not adopted policies and/or actions with reference to the specific sustainability matter concerned, it shall disclose this to be the case, and provide reasons for not having adopted policies and/or actions. ",
        tooltip: "",
        condition: "no",
        module: "Governance",
        boldPart: "62",
      },
      {
        id: "q-1120",
        question: "The undertaking may disclose a timeframe in which it aims to adopt them. ",
        tooltip: "",
        condition: "no",
        module: "Governance",
        boldPart: "62",
      },

      // Add more questions as needed
    ],
    "G1-2 – Management of relationships with suppliers": [
      {
        id: "q-1121",
        question:
          "The undertaking shall provide a description of its policy to prevent late payments, specifically to SMEs.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 2. For purposes of this standard, management of relationships with the
              undertaking’s suppliers may include the following:
            </p>
            <ol className="question-tip" type="a">
              <li>
                how the undertaking’s practices, including activities to avoid or minimise the
                impacts of disruptions to its supply chain, support its strategy and risk
                management;
              </li>
              <li>
                training of the undertaking’s procurement/supply chain workforce on engagement and
                dialogue with suppliers as well as incentives of its procurement workforce including
                whether such incentives refer to price, quality or sustainability factors;
              </li>
              <li>
                the screening and evaluation of social and environmental performance of suppliers;
              </li>
              <li>
                the inclusion of locally based suppliers in its supply chain and/or suppliers with
                certification;
              </li>
              <li>how the undertaking’s practices deal with vulnerable suppliers; </li>
              <li>
                (f) the undertaking’s targets and actions with regard to communication and
                management of relationships with suppliers; and
              </li>
              <li>
                how the outcomes of these practices are evaluated, including supplier visits, audits
                or surveys.
              </li>
            </ol>
            <p className="question-tip">
              AR 3. 'Vulnerable suppliers’ includes suppliers that are exposed to significant
              economic, environmental and/or social risks.
            </p>
          </Typography>
        ),
        module: "Governance",
        boldPart: "14",
      },
      {
        id: "q-1122",
        question:
          "The disclosure required under paragraph 12 shall include the following information: (a) the undertaking’s approach to its relationships with its suppliers, taking account of risks to the undertaking related to its supply chain and of impacts on sustainability matters; and (b) whether and how it takes into account social and environmental criteria for the selection of its suppliers.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              AR 2. For purposes of this standard, management of relationships with the
              undertaking’s suppliers may include the following:
            </p>
            <ol className="question-tip" type="a">
              <li>
                how the undertaking’s practices, including activities to avoid or minimise the
                impacts of disruptions to its supply chain, support its strategy and risk
                management;
              </li>
              <li>
                training of the undertaking’s procurement/supply chain workforce on engagement and
                dialogue with suppliers as well as incentives of its procurement workforce including
                whether such incentives refer to price, quality or sustainability factors;
              </li>
              <li>
                the screening and evaluation of social and environmental performance of suppliers;
              </li>
              <li>
                the inclusion of locally based suppliers in its supply chain and/or suppliers with
                certification;
              </li>
              <li>how the undertaking’s practices deal with vulnerable suppliers; </li>
              <li>
                (f) the undertaking’s targets and actions with regard to communication and
                management of relationships with suppliers; and
              </li>
              <li>
                how the outcomes of these practices are evaluated, including supplier visits, audits
                or surveys.
              </li>
            </ol>
            <p className="question-tip">
              AR 3. 'Vulnerable suppliers’ includes suppliers that are exposed to significant
              economic, environmental and/or social risks.
            </p>
          </Typography>
        ),
        module: "Governance",
        boldPart: "15",
      },
      // Add more questions as needed
    ],
    "G1-3 – Prevention and detection of corruption and bribery": [
      {
        id: "q-1124",
        question:
          "(a) a description of the procedures in place to prevent, detect, and address allegations or incidents of corruption and bribery; (b) whether the investigators or investigating committee are separate from the chain of management involved in the matter; and (c) the process, if any, to report outcomes to the administrative, management and supervisory bodies.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 5. Disclosures may include details about the risk assessments and/or mapping, as
              well as monitoring programmes and/or internal control procedures performed by the
              undertaking to detect corruption and bribery.
              <br />
              AR 6. The undertaking’s policies on corruption and bribery may be relevant to specific
              groups of people, either because they are expected to implement them (for example, the
              undertaking’s employees, contractors and suppliers), or because they have a direct
              interest in their implementation (for example, value chain workers, investors). The
              undertaking may disclose the communication tools and channels (e.g., flyers,
              newsletters, dedicated websites, social media, face to face interactions, unions
              and/or workers representatives) to communicate policies to such groups. This may also
              include the identification and/or removal of potential barriers to dissemination, such
              as through translation into relevant languages or the use of graphic depictions. "
            </p>
          </Typography>
        ),
        module: "Governance",
        boldPart: "18",
      },
      {
        id: "q-1127",
        question:
          "Where the undertaking has no such procedures in place, it shall disclose this fact and, where applicable, its plans to adopt them. ",
        tooltip: "",
        module: "Governance",
        boldPart: "19",
      },
      {
        id: "q-1128",
        question:
          "The disclosures required by paragraph 16 shall include information about how the undertaking communicates its policies to those for whom they are relevant to ensure that the policy is accessible and that they understand its implications. ",
        tooltip: "",
        module: "Governance",
        boldPart: "20",
      },
      {
        id: "q-1129",
        question:
          "The disclosure required by paragraph 16 shall include information about the following with respect to training: (a) the nature, scope and depth of anti-corruption and anti-bribery training programmes offered or required by the undertaking; (b) the percentage of functions-at-risk covered by training programmes; and (c) the extent to which training is given to members of the administrative, management and supervisory bodies.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              21 (b) "AR 4. ‘Functions-at-risk’ means those functions deemed to be at risk of
              corruption and bribery as a result of its tasks and responsibilities. "
            </p>
          </Typography>
        ),
        module: "Governance",
        boldPart: "21",
      },

      {
        id: "q-1132",
        question:
          "The undertaking may disclose an analysis of its training activities by, for example, region of training or category of own workforce where its programmes differ significantly based on such factors and such information would be useful to users. ",
        tooltip:
          "AR 7. The undertaking may disclose an analysis of its training activities by, for example, region of training or category of own workforce where its programmes differ significantly based on such factors and such information would be useful to users.",
        voluntary: true,
        module: "Governance",
        boldPart: "AR 7",
      },
      {
        id: "q-1133",
        question: " G1-3 – Prevention and detection of corruption and bribery",
        tooltip:
          "Anti-corruption and bribery training illustrative example 8 During the 20XY financial year ABC provided training to its at-risk own workers in terms of its policy (see note x). For those at-risk functions the training is mandatory, but ABC also made available voluntary training for other own workers. Details of its training during the year is as follows: 'AR 8'!A1",
        table: (
          <table className="question-table">
            <thead>
              <tr>
                <th></th>
                <th>At-risk functions</th>
                <th>Managers </th>
                <th>AMSB</th>
                <th>Other own workers</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Training coverage</th>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-trainingCoverage-atRiskFunctions"
                    value={
                      answers2["g1_3-trainingCoverage-atRiskFunctions"] ||
                      inputValues.g1_3.trainingCoverage.atRiskFunctions
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-trainingCoverage-managers"
                    value={
                      answers2["g1_3-trainingCoverage-managers"] ||
                      inputValues.g1_3.trainingCoverage.managers
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-trainingCoverage-AMSB"
                    value={
                      answers2["g1_3-trainingCoverage-AMSB"] ||
                      inputValues.g1_3.trainingCoverage.AMSB
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-trainingCoverage-otherOwnWorkers"
                    value={
                      answers2["g1_3-trainingCoverage-otherOwnWorkers"] ||
                      inputValues.g1_3.trainingCoverage.otherOwnWorkers
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Total</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-total-atRiskFunctions"
                    value={
                      answers2["g1_3-total-atRiskFunctions"] ||
                      inputValues.g1_3.total.atRiskFunctions
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-total-managers"
                    value={answers2["g1_3-total-managers"] || inputValues.g1_3.total.managers}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-total-AMSB"
                    value={answers2["g1_3-total-AMSB"] || inputValues.g1_3.total.AMSB}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-total-otherOwnWorkers"
                    value={
                      answers2["g1_3-total-otherOwnWorkers"] ||
                      inputValues.g1_3.total.otherOwnWorkers
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Total receiving training</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-totalReceivingTraining-atRiskFunctions"
                    value={
                      answers2["g1_3-totalReceivingTraining-atRiskFunctions"] ||
                      inputValues.g1_3.totalReceivingTraining.atRiskFunctions
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-totalReceivingTraining-managers"
                    value={
                      answers2["g1_3-totalReceivingTraining-managers"] ||
                      inputValues.g1_3.totalReceivingTraining.managers
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-totalReceivingTraining-AMSB"
                    value={
                      answers2["g1_3-totalReceivingTraining-AMSB"] ||
                      inputValues.g1_3.totalReceivingTraining.AMSB
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-totalReceivingTraining-otherOwnWorkers"
                    value={
                      answers2["g1_3-totalReceivingTraining-otherOwnWorkers"] ||
                      inputValues.g1_3.totalReceivingTraining.otherOwnWorkers
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <th>Delivery method and duration</th>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-deliveryMethodAndDuration-atRiskFunctions"
                    value={
                      answers2["g1_3-deliveryMethodAndDuration-atRiskFunctions"] ||
                      inputValues.g1_3.deliveryMethodAndDuration.atRiskFunctions
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-deliveryMethodAndDuration-managers"
                    value={
                      answers2["g1_3-deliveryMethodAndDuration-managers"] ||
                      inputValues.g1_3.deliveryMethodAndDuration.managers
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-deliveryMethodAndDuration-AMSB"
                    value={
                      answers2["g1_3-deliveryMethodAndDuration-AMSB"] ||
                      inputValues.g1_3.deliveryMethodAndDuration.AMSB
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-deliveryMethodAndDuration-otherOwnWorkers"
                    value={
                      answers2["g1_3-deliveryMethodAndDuration-otherOwnWorkers"] ||
                      inputValues.g1_3.deliveryMethodAndDuration.otherOwnWorkers
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Classroom training</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-classroomTraining-atRiskFunctions"
                    value={
                      answers2["g1_3-classroomTraining-atRiskFunctions"] ||
                      inputValues.g1_3.classroomTraining.atRiskFunctions
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-classroomTraining-managers"
                    value={
                      answers2["g1_3-classroomTraining-managers"] ||
                      inputValues.g1_3.classroomTraining.managers
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-classroomTraining-AMSB"
                    value={
                      answers2["g1_3-classroomTraining-AMSB"] ||
                      inputValues.g1_3.classroomTraining.AMSB
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-classroomTraining-otherOwnWorkers"
                    value={
                      answers2["g1_3-classroomTraining-otherOwnWorkers"] ||
                      inputValues.g1_3.classroomTraining.otherOwnWorkers
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Computer-based training</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-computerBasedTraining-atRiskFunctions"
                    value={
                      answers2["g1_3-computerBasedTraining-atRiskFunctions"] ||
                      inputValues.g1_3.computerBasedTraining.atRiskFunctions
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-computerBasedTraining-managers"
                    value={
                      answers2["g1_3-computerBasedTraining-managers"] ||
                      inputValues.g1_3.computerBasedTraining.managers
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-computerBasedTraining-AMSB"
                    value={
                      answers2["g1_3-computerBasedTraining-AMSB"] ||
                      inputValues.g1_3.computerBasedTraining.AMSB
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-computerBasedTraining-otherOwnWorkers"
                    value={
                      answers2["g1_3-computerBasedTraining-otherOwnWorkers"] ||
                      inputValues.g1_3.computerBasedTraining.otherOwnWorkers
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Voluntary computer-based training</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-voluntaryComputerBasedTraining-atRiskFunctions"
                    value={
                      answers2["g1_3-voluntaryComputerBasedTraining-atRiskFunctions"] ||
                      inputValues.g1_3.voluntaryComputerBasedTraining.atRiskFunctions
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-voluntaryComputerBasedTraining-managers"
                    value={
                      answers2["g1_3-voluntaryComputerBasedTraining-managers"] ||
                      inputValues.g1_3.voluntaryComputerBasedTraining.managers
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-voluntaryComputerBasedTraining-AMSB"
                    value={
                      answers2["g1_3-voluntaryComputerBasedTraining-AMSB"] ||
                      inputValues.g1_3.voluntaryComputerBasedTraining.AMSB
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-voluntaryComputerBasedTraining-otherOwnWorkers"
                    value={
                      answers2["g1_3-voluntaryComputerBasedTraining-otherOwnWorkers"] ||
                      inputValues.g1_3.voluntaryComputerBasedTraining.otherOwnWorkers
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <th>Frequency</th>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-frequency-atRiskFunctions"
                    value={
                      answers2["g1_3-frequency-atRiskFunctions"] ||
                      inputValues.g1_3.frequency.atRiskFunctions
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-frequency-managers"
                    value={
                      answers2["g1_3-frequency-managers"] || inputValues.g1_3.frequency.managers
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-frequency-AMSB"
                    value={answers2["g1_3-frequency-AMSB"] || inputValues.g1_3.frequency.AMSB}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-frequency-otherOwnWorkers"
                    value={
                      answers2["g1_3-frequency-otherOwnWorkers"] ||
                      inputValues.g1_3.frequency.otherOwnWorkers
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>How often training is required</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-howOftenTrainingRequired-atRiskFunctions"
                    value={
                      answers2["g1_3-howOftenTrainingRequired-atRiskFunctions"] ||
                      inputValues.g1_3.howOftenTrainingRequired.atRiskFunctions
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-howOftenTrainingRequired-managers"
                    value={
                      answers2["g1_3-howOftenTrainingRequired-managers"] ||
                      inputValues.g1_3.howOftenTrainingRequired.managers
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-howOftenTrainingRequired-AMSB"
                    value={
                      answers2["g1_3-howOftenTrainingRequired-AMSB"] ||
                      inputValues.g1_3.howOftenTrainingRequired.AMSB
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-howOftenTrainingRequired-otherOwnWorkers"
                    value={
                      answers2["g1_3-howOftenTrainingRequired-otherOwnWorkers"] ||
                      inputValues.g1_3.howOftenTrainingRequired.otherOwnWorkers
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <th>Topics covered</th>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-topicsCovered-atRiskFunctions"
                    value={
                      answers2["g1_3-topicsCovered-atRiskFunctions"] ||
                      inputValues.g1_3.topicsCovered.atRiskFunctions
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-topicsCovered-managers"
                    value={
                      answers2["g1_3-topicsCovered-managers"] ||
                      inputValues.g1_3.topicsCovered.managers
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-topicsCovered-AMSB"
                    value={
                      answers2["g1_3-topicsCovered-AMSB"] || inputValues.g1_3.topicsCovered.AMSB
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-topicsCovered-otherOwnWorkers"
                    value={
                      answers2["g1_3-topicsCovered-otherOwnWorkers"] ||
                      inputValues.g1_3.topicsCovered.otherOwnWorkers
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Definition of corruption</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-definitionOfCorruption-atRiskFunctions"
                    value={
                      answers2["g1_3-definitionOfCorruption-atRiskFunctions"] ||
                      inputValues.g1_3.definitionOfCorruption.atRiskFunctions
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-definitionOfCorruption-managers"
                    value={
                      answers2["g1_3-definitionOfCorruption-managers"] ||
                      inputValues.g1_3.definitionOfCorruption.managers
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-definitionOfCorruption-AMSB"
                    value={
                      answers2["g1_3-definitionOfCorruption-AMSB"] ||
                      inputValues.g1_3.definitionOfCorruption.AMSB
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-definitionOfCorruption-otherOwnWorkers"
                    value={
                      answers2["g1_3-definitionOfCorruption-otherOwnWorkers"] ||
                      inputValues.g1_3.definitionOfCorruption.otherOwnWorkers
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Policy</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-trainingCoverage-atRiskFunctions"
                    value={
                      answers2["g1_3-trainingCoverage-atRiskFunctions"] ||
                      inputValues.g1_3.trainingCoverage.atRiskFunctions
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-policy-managers"
                    value={answers2["g1_3-policy-managers"] || inputValues.g1_3.policy.managers}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-policy-AMSB"
                    value={answers2["g1_3-policy-AMSB"] || inputValues.g1_3.policy.AMSB}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-policy-otherOwnWorkers"
                    value={
                      answers2["g1_3-policy-otherOwnWorkers"] ||
                      inputValues.g1_3.policy.otherOwnWorkers
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td>Procedures on suspicion/detection</td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-proceduresOnSuspicionDetection-atRiskFunctions"
                    value={
                      answers2["g1_3-proceduresOnSuspicionDetection-atRiskFunctions"] ||
                      inputValues.g1_3.proceduresOnSuspicionDetection.atRiskFunctions
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-proceduresOnSuspicionDetection-managers"
                    value={
                      answers2["g1_3-proceduresOnSuspicionDetection-managers"] ||
                      inputValues.g1_3.proceduresOnSuspicionDetection.managers
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-proceduresOnSuspicionDetection-AMSB"
                    value={
                      answers2["g1_3-proceduresOnSuspicionDetection-AMSB"] ||
                      inputValues.g1_3.proceduresOnSuspicionDetection.AMSB
                    }
                    onChange={handleInputChange}
                  />
                </td>
                <td className="textarea-cell">
                  <textarea
                    type="text"
                    name="g1_3-proceduresOnSuspicionDetection-otherOwnWorkers"
                    value={
                      answers2["g1_3-proceduresOnSuspicionDetection-otherOwnWorkers"] ||
                      inputValues.g1_3.proceduresOnSuspicionDetection.otherOwnWorkers
                    }
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        ),
        module: "Governance",
        boldPart: "AR 8",
        fun: "5Column",
      },
      // Add more questions as needed
    ],
    "G1-4 – Confirmed incidents of corruption or bribery": [
      {
        id: "q-1134",
        question:
          "(a) the number of convictions and the amount of fines for violation of anti-corruption and anti-bribery laws. (b) any actions taken to address breaches in procedures and standards of anti-corruption and anti-bribery",
        tooltip: "",
        euReg: (
          <Typography>
            <p className="question-tip">
              24 (a) <br />
              <strong>SFDR reference:</strong> Indicator number 17 Table #3 of Annex 1
            </p>
            <p className="question-tip">
              <strong>Benchmark Regulation reference:</strong> Delegated Regulation (EU) 2020/1816,
              Annex II
            </p>
            <p className="question-tip">
              24 (b) <br />
              <strong>SFDR reference:</strong> Indicator number 16 Table #3 of Annex 1
            </p>
          </Typography>
        ),
        module: "Governance",
        boldPart: "24",
      },

      {
        id: "q-1136",
        question:
          "(a) The total number and nature of confirmed incidents of corruption or bribery; (b) The number of confirmed incidents in which own workers were dismissed or disciplined for corruption or bribery-related incidents; (c) the number of confirmed incidents relating to contracts with business partners that were terminated or not renewed due to violations related to corruption or bribery; and (d) details of public legal cases regarding corruption or bribery brought against the undertaking and its own workers during the reporting period and the outcomes of such cases. This includes cases that were initiated in previous years where the outcome was only established in the current reporting period.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              25 (d) "The disclosures required shall include incidents involving actors in its value
              chain only where the undertaking or its employees are directly involved. "
            </p>
          </Typography>
        ),
        voluntary: true,
        module: "Governance",
        boldPart: "25",
      },

      {
        id: "q-1140",
        question:
          "Where the implementation of a policy requires actions, or a comprehensive action plan, to achieve its objectives, as well as when actions are implemented without a specific policy, the undertaking shall disclose the following information: (a) the list of key actions taken in the reporting year and planned for the future, their expected outcomes and, where relevant, how their implementation contributes to the achievement of policy objectives and targets;",
        tooltip:
          "AR 22. Key actions in the context of this Minimum Disclosure Requirement are those actions that materially contribute to achieving the undertaking’s objectives in addressing material impacts, risks and opportunities. oor reasons of understandability, key actions may be aggregated where appropriate. ",
        condition: "yes",
        module: "Governance",
        boldPart: "68 a",
      },
      {
        id: "q-1141",
        question:
          "the scope of the key actions (i.e., coverage in terms of activities, upstream and/or downstream value chain, geographies and, where applicable, affected stakeholder groups); ",
        tooltip: "",
        condition: "yes",
        module: "Governance",
        boldPart: "68 b",
      },
      {
        id: "q-1142",
        question:
          "the time horizons under which the undertaking intends to complete each key action;",
        tooltip: "",
        condition: "yes",
        module: "Governance",
        boldPart: "68 c",
      },
      {
        id: "q-1143",
        question:
          "if applicable, key actions taken (along with results) to provide for and cooperate in or support the provision of remedy for those harmed by actual material impacts;",
        tooltip: "",
        condition: "yes",
        module: "Governance",
        boldPart: "68 d",
      },
      {
        id: "q-1144",
        question:
          "if applicable, quantitative and qualitative information regarding the progress of actions or action plans disclosed in prior periods. ",
        tooltip: "",
        condition: "yes",
        module: "Governance",
        boldPart: "68 e",
      },
      {
        id: "q-1145",
        question:
          "Where the implementation of an action plan requires significant operational expenditures (Opex) and/or capital expenditures (Capex) the undertaking shall: (a) describe the type of current and future financial and other resources allocated to the action plan, including if applicable, the relevant terms of sustainable finance instruments, such as green bonds, Governance bonds and green loans, the environmental or Governance objectives, and whether the ability to implement the actions or action plan depends on specific preconditions, e.g., granting of financial support or public policy and market developments; ",
        tooltip:
          "AR 23. Information on resource allocation may be presented in the form of a table and broken down between capital expenditure and operating expenditure, and across the relevant time horizons, and between resources applied in the current reporting year and the planned allocation of resources over specific time horizons.",
        condition: "yes",
        module: "Governance",
        boldPart: "69 a",
      },
      {
        id: "q-1146",
        question:
          "provide the amount of current financial resources and explain how they relate to the most relevant amounts presented in the financial statements;",
        tooltip: "",
        condition: "yes",
        module: "Governance",
        boldPart: "69 b",
      },
      {
        id: "q-1147",
        question: "provide the amount of future financial resources. ",
        tooltip: "",
        condition: "yes",
        module: "Governance",
        boldPart: "69 c",
      },
      {
        id: "q-1148",
        question:
          "If the undertaking cannot disclose the information on policies and actions required under relevant ESRS, because it has not adopted policies and/or actions with reference to the specific sustainability matter concerned, it shall disclose this to be the case, and provide reasons for not having adopted policies and/or actions. ",
        tooltip: "",
        condition: "no",
        module: "Governance",
        boldPart: "62",
      },
      {
        id: "q-1149",
        question: "The undertaking may disclose a timeframe in which it aims to adopt them. ",
        tooltip: "",
        condition: "no",
        module: "Governance",
        boldPart: "62",
      },
      // Add more questions as needed
    ],
    "G1-5 – Political influence and lobbying activities": [
      {
        id: "q-1150",
        question:
          "(a) If applicable, the representative(s) responsible in the administrative, management and supervisory bodies for the oversight of these activities; (b) for financial or in-kind political contributions: ",
        tooltip: (
          <Typography>
            <p className="question-tip">
              29 (b) "AR 9. For purposes of this Standard ‘political contribution’ means financial
              or in-kind support provided directly to political parties, their elected
              representatives or persons seeking political office. Financial contributions can
              include donations, loans, sponsorships, advance payments for services, or the purchase
              of tickets for fundraising events and other similar practices. In-kind contributions
              can include advertising, use of facilities, design and printing, donation of
              equipment, provision of board membership, employment or consultancy work for elected
              politicians or candidates for office. <br />
              AR 10. ‘Indirect political contribution’ refers to those political contributions made
              through an intermediary organisation such as a lobbyist or charity, or support given
              to an organisation such as a think tank or trade association linked to or supporting
              particular political parties or causes. "
            </p>
          </Typography>
        ),
        module: "Governance",
        boldPart: "29",
      },

      {
        id: "q-1152",
        question:
          "The undertaking may provide the following information on its financial or in-kind contributions with regard to its lobbying expenses: (a) the total monetary amount of such internal and external expenses; (b) the total amount paid for membership to lobbying associations.",
        tooltip: "",
        voluntary: true,
        module: "Governance",
        boldPart: "AR 12",
      },

      {
        id: "q-1154",
        question:
          "i. the total monetary value of financial and in-kind political contributions made directly and indirectly by the undertaking aggregated by country or geographical area where relevant, as well as type of recipient/beneficiary; ii. where appropriate, how the monetary value of in-kind contributions is estimated.",
        tooltip: (
          <Typography>
            <p className="question-tip">
              29 (b) i. "AR 9. For purposes of this Standard ‘political contribution’ means
              financial or in-kind support provided directly to political parties, their elected
              representatives or persons seeking political office. Financial contributions can
              include donations, loans, sponsorships, advance payments for services, or the purchase
              of tickets for fundraising events and other similar practices. In-kind contributions
              can include advertising, use of facilities, design and printing, donation of
              equipment, provision of board membership, employment or consultancy work for elected
              politicians or candidates for office.
            </p>
          </Typography>
        ),
        module: "Governance",
        boldPart: "29 b",
      },

      {
        id: "q-1156",
        question:
          "the main topics covered by its lobbying activities and the undertaking’s main positions on these in brief. This shall include explanations on how this interacts with its material impacts, risks and opportunities identified in its materiality assessment per ESRS 2; ",
        tooltip: (
          <Typography>
            <p className="question-tip">
              "AR 14. In meeting the requirement in paragraph 29(c) the undertaking shall consider
              the alignment between its public statements on its material impacts, risks and
              opportunities and its lobbying activities."
            </p>
          </Typography>
        ),
        module: "Governance",
        boldPart: "29 c",
      },
      {
        id: "q-1157",
        question:
          "if the undertaking is registered in the EU Transparency Register or in an equivalent transparency register in a Member State, the name of any such register and its identification number in the register. ",
        tooltip: "",
        module: "Governance",
        boldPart: "29 d",
      },
      {
        id: "q-1158",
        question:
          "The disclosure shall also include information about the appointment of any members of the administrative, management and supervisory bodies who held a comparable position in public administration (including regulators) in the 2 years preceding such appointment in the current reporting period.",
        tooltip: (
          <Typography>
            <p className="question-tip"></p>
          </Typography>
        ),
        module: "Governance",
        boldPart: "30",
      },
      {
        id: "q-1159",
        question:
          "If the undertaking is legally obliged to be a member of a chamber of commerce or other organisation that represents its interests, it may disclose that this is the case. ",
        tooltip: "",
        voluntary: true,
        module: "Governance",
        boldPart: "AR 13",
      },

      // Add more questions as needed
    ],
    "G1-6 – Payment practices": [
      {
        id: "q-1160",
        question:
          "(a) the average time the undertaking takes to pay an invoice from the date when the contractual or statutory term of payment starts to be calculated, in number of days; (b) a description of the undertaking’s standard payment terms in number of days by main category of suppliers and the percentage of its payments aligned with these standard terms; (c) the number of legal proceedings currently outstanding for late payments;",
        tooltip: (
          <Typography>
            <p className="question-tip">
              33 (b) "AR 16. In some cases, the undertaking’s standard contractual payment terms may
              differ significantly depending on country or type of supplier. In such cases,
              information about the standard terms per main categories of suppliers or country or
              geographical region could be examples of additional contextual information to explain
              the disclosures in paragraph 33(b). AR 17. An example of what the description of
              standard contract term disclosures in paragraph 33(b) could look like:,
              <br />
              ABC’s standard contract payment terms are payment on receipt of invoice for
              wholesalers which encompass approximately 80% of its annual invoices by value. It pays
              for services received within 30 days after receipt of the invoice which are about 5%
              of its annual invoices. The remainder of its invoices are paid within 60 days of
              receipt except for those in country X which in accordance with the marketplace
              standards are paid within 90 days of receipt."
            </p>
          </Typography>
        ),
        module: "Governance",
        boldPart: "33 a-c",
      },

      {
        id: "q-1163",
        question:
          "complementary information necessary to provide sufficient context. If the undertaking has used representative sampling to calculate the information required under point (a), it shall state that fact and briefly describe the methodology used. ",
        tooltip: "",
        module: "Governance",
        boldPart: "33 d",
      },
      // Add more questions as needed
    ],

    // Add similar data for other sub-tabs
  };

  return questionData;
};

export default GetQuestionData;
