import React, { useState, useEffect } from "react";
import LoginHeader from "./components/LoginHeader.js";
import SideNavigation from "./components/SideNavigation.js";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Button from "@mui/material/Button";
import WestIcon from "@mui/icons-material/West";
import AADHeader from "./components/AADHeader.js";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Grid, Typography, Box, CircularProgress, Backdrop } from "@mui/material";
import { Checkbox } from "primereact/checkbox";

const steps = ["Start", "Materiality", "Module", "Input", "Report"];

const ReportDashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  const [loading, setLoading] = React.useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false); // Close sidebar on mobile
      } else {
        setIsSidebarOpen(true); // Open sidebar on larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Example Highcharts options for different charts
  const dataPointsChartOptions = {
    chart: {
      type: "pie",
      height: 200,
      events: {
        render: function () {
          // Add a central label (1582)
          const chart = this;
          const centerX = chart.plotWidth / 1.7 + chart.plotLeft;
          const centerY = chart.plotHeight / 2 + chart.plotTop;

          // Remove existing custom labels to prevent overlap
          if (chart.customLabel) chart.customLabel.destroy();

          chart.customLabel = chart.renderer
            .text(
              "1582", // Text to display in the center
              centerX,
              centerY
            )
            .css({
              color: "#000", // Center text color
              fontSize: "20px", // Font size
              fontWeight: "500",
              textAlign: "right",
            })
            .attr({
              align: "center",
            })
            .add()
            .toFront();

          // Center the text properly
          chart.customLabel.attr({
            x: centerX - chart.customLabel.getBBox().width / 2,
            y: centerY + chart.customLabel.getBBox().height / 4,
          });
        },
      },
    },
    title: {
      text: "Data Points Status",
      align: "left",
      margin: 30, // Increase margin to create space between the title and legend
    },
    credits: {
      enabled: false, // Hide the Highcharts.com label
    },
    legend: {
      align: "left", // Align legend to the left
      verticalAlign: "top", // Align legends vertically at the top
      layout: "vertical", // Stack legends vertically
      itemStyle: {
        fontSize: "12px", // Adjust the font size for better readability
        fontWeight: "normal",
      },
      labelFormat: "{name}", // Display the series name in the legend
      y: 40, // Add vertical offset to move the legend down
    },
    plotOptions: {
      pie: {
        size: "100%",
        innerSize: "70%", // Convert pie chart to donut chart
        dataLabels: {
          enabled: true, // Enable labels on the chart
          format: "{point.percentage:.0f}%", // Show values only
        },
        showInLegend: true, // Show data points in the legend
      },
    },
    tooltip: {
      pointFormat: "{point.name}: <b>{point.percentage:.0f}%</b>", // Tooltip with percentages
    },
    series: [
      {
        name: "Status",
        data: [
          { name: "Reported", y: 75, color: "#407775" },
          { name: "Not Material", y: 19, color: "#fab430" },
          { name: "To Do", y: 6, color: "#B4C9C8" },
        ],
      },
    ],
  };

  const dataCollectionChartOptions = {
    chart: {
      type: "bar", // Change to "bar" for horizontal bars
      height: 150,
    },
    title: {
      text: "Data Collection",
      align: "left", // Align the title to the left for consistency
    },
    xAxis: {
      categories: ["Quantitative", "Qualitative"],
      lineWidth: 0,
      title: {
        text: null, // Remove x-axis title
      },
    },
    yAxis: {
      title: {
        text: null, // Remove y-axis title
      },
      labels: {
        formatter: function () {
          return this.value + "%"; // Add % to y-axis labels
        },
      },
      max: 50, // Set the maximum value to 50%
      tickInterval: 10,
    },
    credits: {
      enabled: false, // Hide the Highcharts.com label
    },
    legend: {
      enabled: false, // Remove legend
    },
    plotOptions: {
      bar: {
        pointPadding: 0.1, // Adjust padding between bars
        groupPadding: 0.5, // Adjust padding between groups
        borderWidth: 0, // Remove border for a cleaner look
        pointWidth: 20,
      },
    },
    series: [
      {
        name: "Data",
        data: [
          { y: 50, color: "#ADA9A9" }, // Quantitative bar color
          { y: 30, color: "#FAB430" }, // Qualitative bar color
        ],
      },
    ],
  };

  const quantitativeDataSourcesOptions = {
    chart: {
      type: "pie",
      height: 200,
      events: {
        render: function () {
          // Add a central label (1582)
          const chart = this;
          const centerX = chart.plotWidth / 1.7 + chart.plotLeft;
          const centerY = chart.plotHeight / 2 + chart.plotTop;

          // Remove existing custom labels to prevent overlap
          if (chart.customLabel) chart.customLabel.destroy();

          chart.customLabel = chart.renderer
            .text(
              "820", // Text to display in the center
              centerX,
              centerY
            )
            .css({
              color: "#000", // Center text color
              fontSize: "20px", // Font size
              fontWeight: "500",
              textAlign: "right",
            })
            .attr({
              align: "center",
            })
            .add()
            .toFront();

          // Center the text properly
          chart.customLabel.attr({
            x: centerX - chart.customLabel.getBBox().width / 2,
            y: centerY + chart.customLabel.getBBox().height / 4,
          });
        },
      },
    },
    title: {
      text: "Data Points Status",
      align: "left",
      margin: 30, // Increase margin to create space between the title and legend
    },
    credits: {
      enabled: false, // Hide the Highcharts.com label
    },
    legend: {
      align: "left", // Align legend to the left
      verticalAlign: "top", // Align legends vertically at the top
      layout: "vertical", // Stack legends vertically
      itemStyle: {
        fontSize: "12px", // Adjust the font size for better readability
        fontWeight: "normal",
      },
      labelFormat: "{name}", // Display the series name in the legend
      y: 40, // Add vertical offset to move the legend down
    },
    plotOptions: {
      pie: {
        size: "100%",
        innerSize: "70%", // Convert pie chart to donut chart
        dataLabels: {
          enabled: true, // Enable labels on the chart
          format: "{point.percentage:.0f}%", // Show values only
        },
        showInLegend: true, // Show data points in the legend
      },
    },
    tooltip: {
      pointFormat: "{point.name}: <b>{point.percentage:.0f}%</b>", // Tooltip with percentages
    },
    series: [
      {
        name: "Status",
        data: [
          { name: "Automated inputs", y: 71, color: "#407775" },
          { name: "Manual Inputs", y: 24, color: "#fab430" },
          { name: "Not available", y: 5, color: "#B4C9C8" },
        ],
      },
    ],
  };

  const esgStatusChartOptions = {
    chart: {
      type: "bar",
    },
    credits: {
      enabled: false, // Hide the Highcharts.com label
    },
    title: {
      text: "Data Status for ESG",
      align: "left",
    },
    xAxis: {
      categories: ["To Do", "Not Material", "Reported"],
      lineWidth: 0, // Remove the y-axis line
    },
    yAxis: {
      title: {
        text: null, // Remove y-axis title
      },
      lineWidth: 0, // Remove the y-axis line
      labels: {
        enabled: true, // Enable/disable labels as per your needs
      },
    },
    plotOptions: {
      bar: {
        pointPadding: 0, // Remove space between bars within a category
        borderWidth: 0, // Remove bar borders for a cleaner look
        borderRadius: 0,
      },
    },
    series: [
      { name: "Governance", data: [200, 150, 170], color: "#B4C9C8" },
      { name: "Social", data: [50, 70, 60], color: "#fab430" },
      { name: "Environment", data: [400, 500, 550], color: "#407775" },
    ],
  };

  const renderHeader = () => {
    const loginType = localStorage.getItem("loginType");
    if (loginType === "AAD") {
      return <AADHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
    }
    return <LoginHeader toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} heading="" />;
  };
  return (
    <div id="wrapper">
      <div>
        {loading && (
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
      {renderHeader()}
      <SideNavigation isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div
        id="page-content-wrapper"
        className={isSidebarOpen ? "page-content-wrapper-pd-0" : "page-wrapper"}
      >
        <div class="container formpd">
          <div class="flex justify-content-between">
            <div class="steps__containt-hader">
              <h3 class="title">CSRD Report 2023</h3>
              <div role="presentation">
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <Link underline="hover" color="inherit" href="/standards">
                    Reports
                  </Link>
                  <Link underline="hover" color="inherit" href="/standards">
                    Standards & Frameworks
                  </Link>
                  <Typography color="text.primary">Followed Path</Typography>
                </Breadcrumbs>
              </div>
            </div>
            <div className="stepper-box">
              <Stepper activeStep={3} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel className="step-name">{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
          </div>
          <div className="button-box d-flex justify-content-end mb-4">
            <Button
              href="standards"
              variant="outlined"
              className="module-btn"
              size="medium"
              sx={{ marginRight: "15px" }}
            >
              <WestIcon fontSize="small" sx={{ marginRight: "4px" }} />
              Back
            </Button>
          </div>
          <div className="report-dashboard pt-6">
            <div className="row mb-4">
              <div className="col-md-6 mb-3">
                {/* First Card: EU CSRD */}
                <div className="card mb-3 title-esrs">
                  <div className="card-body">
                    <h6 className="card-subtitle text-muted mb-2">EU CSRD</h6>
                    <h4 className="card-title">CSRD Report 2024</h4>
                  </div>
                </div>

                {/* Second Card: Completion By */}
                <div className="card  title-esrs">
                  <div className="card-body">
                    <h6 className="card-subtitle text-muted mb-2">Completion By</h6>
                    <h4 className="card-title">10 Aug 2024</h4>
                  </div>
                </div>
              </div>

              {/* Data Collection Chart */}
              <div className="col-md-6 mb-3">
                <div className="card shadow">
                  <div className="card-body">
                    <HighchartsReact highcharts={Highcharts} options={dataPointsChartOptions} />
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="card shadow">
                  <div className="card-body">
                    <HighchartsReact highcharts={Highcharts} options={esgStatusChartOptions} />
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="card shadow mb-3">
                  <div className="card-body">
                    <HighchartsReact highcharts={Highcharts} options={dataCollectionChartOptions} />
                  </div>
                </div>
                <div className="card shadow">
                  <div className="card-body">
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={quantitativeDataSourcesOptions}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row main-factors">
              {/* Left Column */}
              <div className="col-md-6">
                <ul className="list-group">
                  {/* General */}
                  <li className="list-group-item mb-4">
                    <div className="factor-title">General</div>
                    {[
                      {
                        id: "general-disclosures",
                        label: "ESRS 2 General Disclosures",
                        progress: 5,
                        total: 112,
                      },
                    ].map((item) => (
                      <div className="factor-item" key={item.id}>
                        <div
                          className="d-flex justify-content-between align-items-center"
                          style={{ gap: "20px" }}
                        >
                          <label
                            className=" link-label"
                            style={{
                              cursor: "pointer",
                              fontWeight: "normal",
                              transition: "all 0.3s ease", // Smooth transition
                            }}
                            onMouseEnter={(e) => (e.target.style.fontWeight = "500")}
                            onMouseLeave={(e) => (e.target.style.fontWeight = "normal")}
                          >
                            {item.label}
                          </label>
                          <div className="d-flex align-items-center">
                            <span className="me-4 question-count">
                              {item.progress}/{item.total}
                            </span>
                            <div className="progress" style={{ width: "100px" }}>
                              <div
                                className="progress-bar"
                                style={{
                                  width: `${(item.progress / item.total) * 100}%`,
                                  background:
                                    "linear-gradient(145deg, #407775 25%, #407775 75%, transparent 75%)",
                                  height: "100%",
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </li>

                  {/* Environment */}
                  <li className="list-group-item mb-4">
                    <div className="factor-title">Environment</div>
                    {[
                      {
                        id: "climate-change",
                        label: "ESRS E1 Climate change",
                        progress: 50,
                        total: 136,
                      },
                      { id: "pollution", label: "ESRS E2 Pollution", progress: 30, total: 73 },
                      {
                        id: "water-marine-resources",
                        label: "ESRS E3 Water and marine resources",
                        progress: 70,
                        total: 69,
                      },
                      {
                        id: "biodiversity-ecosystems",
                        label: "ESRS E4 Biodiversity & ecosystems",
                        progress: 25,
                        total: 132,
                      },
                      {
                        id: "resource-circular-economy",
                        label: "ESRS E5 Resource use & circular economy",
                        progress: 15,
                        total: 80,
                      },
                    ].map((item) => (
                      <div className="factor-item" key={item.id}>
                        <div
                          className="d-flex justify-content-between align-items-center"
                          style={{ gap: "20px" }}
                        >
                          <label
                            className=" link-label"
                            style={{
                              cursor: "pointer",
                              fontWeight: "normal",
                              transition: "all 0.3s ease", // Smooth transition
                            }}
                            onMouseEnter={(e) => (e.target.style.fontWeight = "500")}
                            onMouseLeave={(e) => (e.target.style.fontWeight = "normal")}
                          >
                            {item.label}
                          </label>
                          <div className="d-flex align-items-center">
                            <span className="me-4 question-count">
                              {item.progress}/{item.total}
                            </span>
                            <div className="progress" style={{ width: "100px" }}>
                              <div
                                className="progress-bar"
                                style={{
                                  width: `${(item.progress / item.total) * 100}%`,
                                  background:
                                    "linear-gradient(145deg, #407775 25%, #407775 75%, transparent 75%)",
                                  height: "100%",
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </li>
                </ul>
              </div>

              {/* Right Column */}
              <div className="col-md-6">
                <ul className="list-group">
                  {/* Social */}
                  <li className="list-group-item mb-4">
                    <div className="factor-title">Social</div>
                    {[
                      {
                        id: "own-workforce",
                        label: "ESRS S1 Own workforce",
                        progress: 40,
                        total: 154,
                      },
                      {
                        id: "workers-value-chain",
                        label: "ESRS S2 Workers in the value chain",
                        progress: 10,
                        total: 83,
                      },
                      {
                        id: "affected-communities",
                        label: "ESRS S3 Affected communities",
                        progress: 0,
                        total: 96,
                      },
                      {
                        id: "consumers-end-users",
                        label: "ESRS S4 Consumers and end users",
                        progress: 18,
                        total: 86,
                      },
                    ].map((item) => (
                      <div className="factor-item" key={item.id}>
                        <div
                          className="d-flex justify-content-between align-items-center"
                          style={{ gap: "20px" }}
                        >
                          <label
                            className=" link-label"
                            style={{
                              cursor: "pointer",
                              fontWeight: "normal",
                              transition: "all 0.3s ease", // Smooth transition
                            }}
                            onMouseEnter={(e) => (e.target.style.fontWeight = "500")}
                            onMouseLeave={(e) => (e.target.style.fontWeight = "normal")}
                          >
                            {item.label}
                          </label>
                          <div className="d-flex align-items-center">
                            <span className="me-4 question-count">
                              {item.progress}/{item.total}
                            </span>
                            <div className="progress" style={{ width: "100px" }}>
                              <div
                                className="progress-bar"
                                style={{
                                  width: `${(item.progress / item.total) * 100}%`,
                                  background:
                                    "linear-gradient(145deg, #407775 25%, #407775 75%, transparent 75%)",
                                  height: "100%",
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </li>

                  {/* Governance */}
                  <li className="list-group-item">
                    <div className="factor-title">Governance</div>
                    {[
                      {
                        id: "business-conduct",
                        label: "ESRS G1 Business Conduct",
                        progress: 5,
                        total: 48,
                      },
                    ].map((item) => (
                      <div className="factor-item" key={item.id}>
                        <div
                          className="d-flex justify-content-between align-items-center"
                          style={{ gap: "20px" }}
                        >
                          <label
                            className=" link-label"
                            style={{
                              cursor: "pointer",
                              fontWeight: "normal",
                              transition: "all 0.3s ease", // Smooth transition
                            }}
                            onMouseEnter={(e) => (e.target.style.fontWeight = "500")}
                            onMouseLeave={(e) => (e.target.style.fontWeight = "normal")}
                          >
                            {item.label}
                          </label>
                          <div className="d-flex align-items-center">
                            <span className="me-4 question-count">
                              {item.progress}/{item.total}
                            </span>
                            <div className="progress" style={{ width: "100px" }}>
                              <div
                                className="progress-bar"
                                style={{
                                  width: `${(item.progress / item.total) * 100}%`,
                                  background:
                                    "linear-gradient(145deg, #407775 25%, #407775 75%, transparent 75%)",
                                  height: "100%",
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportDashboard;
